.blog-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 80px;
    /* background-color: #a9b1ab; */
  }
  
  .blog-image-container {
    position: relative;
  }
  
  .blog-image {
    width: 100%;
    max-width: 1100px;
    height: auto;
    border-radius: 8px;
  }
  
  .image-index {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .blog-content {
    margin-top: 20px;
    text-align: center;
    padding: 20px 0; /* Added padding above and below the border */
    border-bottom: 2px solid #ccc; /* Border below the content */
    width: 100%;
    max-width: 1100px;
  }
  
  .blog-title {
    font-size: 24px;
    /* font-weight: bold; */
    margin-bottom: 10px;
  }
  
  .blog-meta {
    font-size: 14px;
    color: #666;
  }
  