:root {
  --qode-main-color: #000;
  --qode-header-light-color: #fff;
  --qode-header-light-hover-color: #fff;
  --qode-header-dark-color: #000;
  --qode-header-dark-hover-color: #000;
}

@keyframes qode-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes qode-fade-in {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes qode-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes qode-ball-fall {
  0% {
    opacity: 0;
    transform: translateY(-145%);
  }

  10% {
    opacity: 0.5;
  }

  20% {
    opacity: 1;
    transform: translateY(0);
  }

  80% {
    opacity: 1;
    transform: translateY(0);
  }

  90% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
    transform: translateY(145%);
  }
}

@keyframes qode-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes qode-double-pulse {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

@keyframes qode-cube {
  0% {
    transform: perspective(120px) rotateX(0) rotateY(0);
  }

  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0);
  }

  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes qode-rotating-cubes {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
  }

  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
  }

  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
  }

  75% {
    transform: translateX(0) translateY(42px) rotate(-270deg) scale(0.5);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes qode-stretch-delay {
  0%,
  100%,
  40% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
}

@keyframes qode-bounce-delay {
  0%,
  100%,
  80% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes qode-rotate-circles {
  to {
    transform: rotate(360deg);
  }
}

@keyframes qode-atom-position {
  50% {
    top: 80px;
    left: 80px;
  }
}

@keyframes qode-atom-size {
  50% {
    transform: scale(0.5);
  }
}

@keyframes qode-atom-zindex {
  100% {
    z-index: 10;
  }
}

@keyframes qode-atom-shrink {
  50% {
    transform: scale(0.8);
  }
}

@keyframes qode-clock {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes qode-clock-1 {
  0% {
    transform: rotate(-135deg);
  }

  30% {
    transform: rotate(-450deg);
  }

  100% {
    transform: rotate(-450deg);
  }
}

@keyframes qode-clock-2 {
  5% {
    transform: rotate(-90deg);
  }

  35% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes qode-clock-3 {
  10% {
    transform: rotate(-45deg);
  }

  40% {
    transform: rotate(-360deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes qode-clock-4 {
  15% {
    transform: rotate(0);
  }

  45% {
    transform: rotate(-315deg);
  }

  100% {
    transform: rotate(-315deg);
  }
}

@keyframes qode-mitosis-invert {
  100% {
    transform: rotate(180deg);
  }
}

@keyframes qode-mitosis-ball-1 {
  12% {
    transform: none;
  }

  26% {
    transform: translateX(25%) scale(1, 0.8);
  }

  40% {
    transform: translateX(50%) scale(0.8, 0.8);
  }

  60% {
    transform: translateX(50%) scale(0.8, 0.8);
  }

  74% {
    transform: translate(50%, 25%) scale(0.6, 0.8);
  }

  88% {
    transform: translate(50%, 50%) scale(0.6, 0.6);
  }

  100% {
    transform: translate(50%, 50%) scale(0.6, 0.6);
  }
}

@keyframes qode-mitosis-ball-2 {
  12% {
    transform: none;
  }

  26% {
    transform: translateX(25%) scale(1, 0.8);
  }

  40% {
    transform: translateX(50%) scale(0.8, 0.8);
  }

  60% {
    transform: translateX(50%) scale(0.8, 0.8);
  }

  74% {
    transform: translate(50%, -25%) scale(0.6, 0.8);
  }

  88% {
    transform: translate(50%, -50%) scale(0.6, 0.6);
  }

  100% {
    transform: translate(50%, -50%) scale(0.6, 0.6);
  }
}

@keyframes qode-mitosis-ball-3 {
  12% {
    transform: none;
  }

  26% {
    transform: translateX(-25%) scale(1, 0.8);
  }

  40% {
    transform: translateX(-50%) scale(0.8, 0.8);
  }

  60% {
    transform: translateX(-50%) scale(0.8, 0.8);
  }

  74% {
    transform: translate(-50%, 25%) scale(0.6, 0.8);
  }

  88% {
    transform: translate(-50%, 50%) scale(0.6, 0.6);
  }

  100% {
    transform: translate(-50%, 50%) scale(0.6, 0.6);
  }
}

@keyframes qode-mitosis-ball-4 {
  12% {
    transform: none;
  }

  26% {
    transform: translateX(-25%) scale(1, 0.8);
  }

  40% {
    transform: translateX(-50%) scale(0.8, 0.8);
  }

  60% {
    transform: translateX(-50%) scale(0.8, 0.8);
  }

  74% {
    transform: translate(-50%, -25%) scale(0.6, 0.8);
  }

  88% {
    transform: translate(-50%, -50%) scale(0.6, 0.6);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.6, 0.6);
  }
}

@keyframes qode-lines-spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes qode-fussion-ball-1 {
  50% {
    top: -100%;
    left: 200%;
  }

  100% {
    top: 50%;
    left: 100%;
    z-index: 2;
  }
}

@keyframes qode-fussion-ball-2 {
  50% {
    top: 200%;
    left: 200%;
  }

  100% {
    top: 100%;
    left: 50%;
    z-index: 1;
  }
}

@keyframes qode-fussion-ball-3 {
  50% {
    top: 200%;
    left: -100%;
  }

  100% {
    top: 50%;
    left: 0;
    z-index: 2;
  }
}

@keyframes qode-fussion-ball-4 {
  50% {
    top: -100%;
    left: -100%;
  }

  100% {
    top: 0;
    left: 50%;
    z-index: 1;
  }
}

@keyframes qode-wave-jump-1 {
  12% {
    transform: translate(20px, -40px);
    animation-timing-function: ease-in;
  }

  100%,
  24% {
    transform: translate(40px, 0);
  }
}

@keyframes qode-wave-jump-2 {
  12% {
    transform: translate(0, 0);
  }

  24% {
    transform: translate(20px, -40px);
    animation-timing-function: ease-in;
  }

  100%,
  36% {
    transform: translate(40px, 0);
  }
}

@keyframes qode-wave-jump-3 {
  24% {
    transform: translate(0, 0);
  }

  36% {
    transform: translate(20px, -40px);
    animation-timing-function: ease-in;
  }

  100%,
  48% {
    transform: translate(40px, 0);
  }
}

@keyframes qode-wave-wipe {
  100%,
  48% {
    transform: translateX(-120px);
  }
}

@keyframes qode-pulse-circles-pulse {
  0% {
    transform: scale(0.1);
    opacity: 1;
    border-width: 50px;
  }

  100% {
    transform: scale(1, 1);
    opacity: 0;
    border-width: 0;
  }
}

@keyframes qode-inline-underline-initial-hover {
  0% {
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height);
  }

  35% {
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
  }

  36% {
    background-position: 0 var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
  }

  100% {
    background-position: 0 var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height);
  }
}

@keyframes qode-bounce {
  20%,
  53%,
  80%,
  from,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes qode-pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes qode-text-from-right {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  12% {
    opacity: 1;
  }

  43% {
    transform: translateX(calc(-1 * var(--qodef-move-x)));
    opacity: 0;
  }

  44% {
    transform: translateX(var(--qodef-move-x));
  }

  88% {
    opacity: 1;
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes qode-text-from-left {
  0% {
    transform: translateX(0);
    opacity: 1;
  }

  12% {
    opacity: 1;
  }

  43% {
    transform: translateX(var(--qodef-move-x));
    opacity: 0;
  }

  44% {
    transform: translateX(calc(-1 * var(--qodef-move-x)));
  }

  88% {
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes qode-draw-line {
  0% {
    -webkit-clip-path: inset(-1px -1px -1px 0);
    clip-path: inset(-1px -1px -1px 0);
  }

  39% {
    -webkit-clip-path: inset(-1px -1px -1px 100%);
    clip-path: inset(-1px -1px -1px 100%);
  }

  40% {
    -webkit-clip-path: inset(-1px 100% -1px -1px);
    clip-path: inset(-1px 100% -1px -1px);
  }

  100% {
    -webkit-clip-path: inset(-1px -1px -1px 0);
    clip-path: inset(-1px -1px -1px 0);
  }
}

@keyframes qode-draw-back-top-top {
  0% {
    -webkit-clip-path: inset(-1px -1px -1px -1px);
    clip-path: inset(-1px -1px -1px -1px);
  }

  20% {
    -webkit-clip-path: inset(-1px -1px -1px 17px);
    clip-path: inset(-1px -1px -1px 17px);
  }

  40% {
    -webkit-clip-path: inset(-1px -1px 26px 17px);
    clip-path: inset(-1px -1px 26px 17px);
  }

  41% {
    -webkit-clip-path: inset(26px 17px -1px -1px);
    clip-path: inset(26px 17px -1px -1px);
  }

  71% {
    -webkit-clip-path: inset(26px -1px -1px -1px);
    clip-path: inset(26px -1px -1px -1px);
  }

  100% {
    -webkit-clip-path: inset(-1px -1px -1px 0);
    clip-path: inset(-1px -1px -1px 0);
  }
}

@keyframes qode-draw-x {
  0%,
  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }

  42% {
    -webkit-clip-path: inset(0 0 0 100%);
    clip-path: inset(0 0 0 100%);
  }

  43% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }
}

@keyframes qodef-clip-image {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }

  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}

@keyframes qodef-clip-transform-image {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    transform: translateX(-100px);
  }

  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    transform: translateX(0);
  }
}

@keyframes qodef-transform-image {
  to {
    transform: translateX(20%);
  }
}

@keyframes qodef-slide-zoom {
  to {
    width: 46%;
  }
}

@keyframes qodef-slide-zoom {
  to {
    width: 20%;
  }
}

@keyframes qodef-reveal-image {
  0% {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
  }

  100% {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
  }
}

a,
abbr,
acronym,
address,
applet,
b,
big,
blockquote,
body,
caption,
center,
cite,
code,
dd,
del,
dfn,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
ins,
kbd,
label,
legend,
li,
object,
ol,
p,
pre,
q,
s,
samp,
small,
span,
strike,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
tr,
tt,
u,
ul,
var {
  vertical-align: baseline;
  margin: 0;
  padding: 0;
  background: 0 0;
  border: 0;
  outline: 0;
}

* {
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
  display: block;
}

del {
  text-decoration: line-through;
}

pre {
  max-width: 100%;
  margin: 15px 0;
  padding: 15px;
  background-color: #fafafa;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Alice", serif;
  font-size: 1em;
  color: #000;
}

code {
  background-color: #fafafa;
  padding: 0;
  font-family: "Alice", serif;
  font-size: 1em;
  color: #000;
}

sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  width: 100%;
  vertical-align: middle;
  border: medium none;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: inherit;
  color: inherit;
  line-height: inherit;
}

table tr {
  border: 1px solid #e3e3e3;
}

table th {
  padding: 5px 10px;
  text-align: left;
  border-right: 1px solid #e3e3e3;
}

table td {
  padding: 5px 10px;
  text-align: left;
  border: 0;
  border-right: 1px solid #e3e3e3;
}

table td br {
  display: none;
}

hr {
  /* background-color: #bbb; */
  border: 0;
  height: 1px;
  /* margin-top: 1.5em;
    margin-bottom: 1.5em */
}

hr:not(.is-style-wide):not(.is-style-dots) {
  /* max-width: 100px */
}

body:not([class*="level-core"]) hr {
  clear: both;
}

ol,
ul {
  list-style-position: inside;
  margin-bottom: 1.5em;
  padding: 0;
}

ol.wp-block,
ul.wp-block {
  margin-bottom: 1.5em;
}

/* ol:not(.wp-block),
ul:not(.wp-block) {
    margin: 0 0 1.5em
} */

li > ol:not(.wp-block),
li > ul:not(.wp-block) {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}

a img {
  border: none;
}

.wp-caption,
img {
  height: auto;
  max-width: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}

figure {
  margin: 0;
}

embed,
iframe,
object {
  display: block;
  max-width: 100%;
}

video {
  display: block;
}

embed,
object {
  height: auto;
}

label {
  display: block;
  margin-bottom: 0.5em;
}

fieldset {
  margin-bottom: 1em;
  padding: 0.35em 0.75em 0.625em;
}

input[type="submit"] {
  -webkit-appearance: none;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: bolder;
}

kbd,
samp {
  font-family: "Alice", serif;
  font-size: 1em;
  background-color: #fafafa;
}

small {
  font-size: 80%;
}

progress {
  vertical-align: baseline;
}

summary {
  display: list-item;
}

template {
  display: none;
}

.gallery-caption,
.wp-caption,
figcaption {
  display: block;
  max-width: 100%;
  font-size: 13px !important;
  line-height: 1em !important;
  color: #000;
  text-align: center;
  opacity: 1;
  margin: 0.5em auto 1em;
}

.gallery-caption a,
.wp-caption a,
figcaption a {
  color: inherit;
}

.wp-caption .wp-caption-text {
  opacity: 1;
}

blockquote.instagram-media,
iframe.instagram-media {
  margin: auto !important;
}

.bypostauthor,
.sticky {
  opacity: 1;
}

::selection {
  color: #fff;
  background: var(--qode-main-color);
}

::-moz-selection {
  color: #fff;
  background: var(--qode-main-color);
}

.screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  line-height: 1;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  word-wrap: normal !important;
  overflow: hidden;
}

.screen-reader-text:focus {
  top: 5px;
  left: 5px;
  display: block;
  width: auto;
  height: auto;
  padding: 15px 23px 14px;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  color: #21759b;
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  z-index: 100000;
}

#qodef-page-content[tabindex="-1"]:focus {
  outline: 0;
}

.alignleft {
  float: left;
  text-align: left;
  margin: 0.5em 1em 0.5em 0 !important;
  clear: left;
}

.aligncenter {
  text-align: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignright {
  float: right;
  text-align: right;
  margin: 0.5em 0 0.5em 1em !important;
  clear: right;
}

.qodef-content-alignment-left {
  text-align: left;
}

.qodef-content-alignment-center {
  text-align: center;
}

.qodef-content-alignment-right {
  text-align: right;
}

.clear:after,
.clear:before,
.comment-author:after,
.comment-author:before,
.comment-content:after,
.comment-content:before,
.comment-meta:after,
.comment-meta:before,
.nav-links:after,
.nav-links:before,
.pagination:after,
.pagination:before,
.widget-area:after,
.widget-area:before,
.widget:after,
.widget:before {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.comment-author:after,
.comment-content:after,
.comment-meta:after,
.nav-links:after,
.pagination:after,
.widget-area:after,
.widget:after {
  clear: both;
}

body {
  font-family: "Alice", serif;
  font-size: 16px;
  line-height: 1.6875em;
  font-weight: 400;
  color: #000;
  background-color: #b3b3b3;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

.qodef-h1,
h1 {
  font-family: "Alice", serif;
  font-weight: 400;
  color: #000;
  font-size: 54px;
  line-height: 1.09259em;
  font-weight: 400;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

@media only screen and (max-width: 1440px) {
  .qodef-h1,
  h1 {
    font-size: 32px;
    line-height: 1.08333em;
  }
}

.qodef-h1 a,
h1 a {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
}

.qodef-h1 a:focus,
.qodef-h1 a:hover,
h1 a:focus,
h1 a:hover {
  color: var(--qode-main-color);
}

.qodef-h2,
h2 {
  font-family: inherit;
  font-weight: 400;
  color: #000;
  font-size: 45px;
  line-height: 1.2em;
  font-weight: 400;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

@media only screen and (max-width: 1440px) {
  .qodef-h2,
  h2 {
    font-size: 24px;
    line-height: 1.13158em;
  }
}

.qodef-h2 a,
h2 a {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
}

.qodef-h2 a:focus,
.qodef-h2 a:hover,
h2 a:focus,
h2 a:hover {
  color: var(--qode-main-color);
}

.qodef-h3,
h3 {
  font-family: inherit;
  font-weight: 400;
  color: #000;
  font-size: 36px;
  line-height: 1.13889em;
  font-weight: 400;
  margin: 25px 0;
  /* -ms-word-wrap: break-word;
    word-wrap: break-word */
}

@media only screen and (max-width: 1440px) {
  .qodef-h3,
  h3 {
    font-size: 30px;
    line-height: 1.16667em;
  }
}

.qodef-h3 a,
h3 a {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
}

.qodef-h3 a:focus,
.qodef-h3 a:hover,
h3 a:focus,
h3 a:hover {
  color: var(--qode-main-color);
}

.qodef-h4,
h4 {
  font-family: "Alice", serif;
  font-weight: 400;
  color: #000;
  font-size: 24px;
  line-height: 1.20833em;
  font-weight: 400;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

@media only screen and (max-width: 1440px) {
  .qodef-h4,
  h4 {
    font-size: 22px;
    line-height: 1.22727em;
  }
}

.qodef-h4 a,
h4 a {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
}

.qodef-h4 a:focus,
.qodef-h4 a:hover,
h4 a:focus,
h4 a:hover {
  color: var(--qode-main-color);
}

.qodef-h5,
h5 {
  font-family: "Alice", serif;
  font-weight: 400;
  color: #000;
  font-size: 20px;
  line-height: 1.25em;
  font-weight: 400;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

@media only screen and (max-width: 1440px) {
  .qodef-h5,
  h5 {
    font-size: 18px;
    line-height: 1.27778em;
  }
}

.qodef-h5 a,
h5 a {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
}

.qodef-h5 a:focus,
.qodef-h5 a:hover,
h5 a:focus,
h5 a:hover {
  color: var(--qode-main-color);
}

.qodef-h6,
h6 {
  font-family: "Alice", serif;
  font-weight: 400;
  color: #000;
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  margin: 25px 0;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.qodef-h6 a,
h6 a {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
}

.qodef-h6 a:focus,
.qodef-h6 a:hover,
h6 a:focus,
h6 a:hover {
  color: var(--qode-main-color);
}

a,
p a {
  color: #000;
  text-decoration: none;
  transition: color 0.2s ease-out;
  cursor: pointer;
}

a:focus,
a:hover,
p a:focus,
p a:hover {
  color: var(--qode-main-color);
}

p {
  margin: 10px 0;
}

p.has-large-font-size {
  font-size: 36px;
  line-height: 1.6875em;
}

p.has-large-font-size + p {
  line-height: 1.6875em;
}

p.has-background {
  padding: 20px 30px;
}

.wp-block-quote,
blockquote {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  font-family: "Alice", serif;
  font-weight: 400;
  color: #000;
  font-size: 20px;
  line-height: 1.25em;
  font-weight: 400;
  position: relative;
  padding: 0 75px;
  margin: 43px 0;
  border: none;
  quotes: none;
  z-index: 1;
}

@media only screen and (max-width: 1440px) {
  .wp-block-quote,
  blockquote {
    font-size: 18px;
    line-height: 1.27778em;
  }
}

.wp-block-quote.wp-block-quote.is-large,
.wp-block-quote.wp-block-quote.is-style-large,
blockquote.wp-block-quote.is-large,
blockquote.wp-block-quote.is-style-large {
  margin: 43px 0;
  padding: 0 75px;
}

.wp-block-quote.wp-block-pullquote,
blockquote.wp-block-pullquote {
  padding: 0 75px !important;
  text-align: center;
}

.wp-block-quote.wp-block-pullquote p,
blockquote.wp-block-pullquote p {
  font-size: 28px !important;
  line-height: 1.6 !important;
}

.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
  max-width: 420px;
}

.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
  max-width: 420px;
}

.wp-block-quote.has-text-align-right:before,
blockquote.has-text-align-right:before {
  left: auto;
  right: 0;
}

.wp-block-quote:before,
blockquote:before {
  content: "“";
  position: absolute;
  top: 25px;
  left: 45px;
  height: auto;
  font-size: 52px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: -0.02em;
  transform: translateY(-50%);
  z-index: -1;
  color: #000;
}

.wp-block-quote > *,
blockquote > * {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  color: inherit;
  margin: 0;
}

.wp-block-quote p,
blockquote p {
  margin: 0 !important;
}

.wp-block-quote:after,
blockquote:after {
  content: "";
}

.wp-block-quote .wp-block-pullquote__citation,
.wp-block-quote .wp-block-quote__citation,
.wp-block-quote cite,
blockquote .wp-block-pullquote__citation,
blockquote .wp-block-quote__citation,
blockquote cite {
  display: block;
  margin: 10px 0 0;
  font-style: normal;
  font-weight: 400 !important;
  text-align: inherit !important;
  text-transform: uppercase !important;
}

.qodef-e-info > * {
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: "Alice", serif;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: #000;
}

.qodef-e-info > a:hover {
  color: var(--qode-main-color);
}

.qodef-info-separator-single {
  color: initial;
}

.qodef-info-separator-single:after {
  content: ",";
  margin-right: 5px;
}

.qodef-info-separator-end {
  color: initial;
}

.qodef-info-separator-end:after {
  display: inline-block;
  content: "";
  width: 1px;
  height: 100%;
  background-color: #bababa;
  margin: 0 12px;
}

.qodef-info-separator-end:last-of-type {
  display: none;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 1px 0;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "Alice", serif;
  font-size: 11px;
  font-weight: inherit;
  line-height: 24px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  margin: 0 0 11px;
  outline: 0;
  padding: 15px 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-color 0.2s ease-out;
}

.qodef-contact-form-7 input[type="date"],
.qodef-contact-form-7 input[type="email"],
.qodef-contact-form-7 input[type="number"],
.qodef-contact-form-7 input[type="password"],
.qodef-contact-form-7 input[type="search"],
.qodef-contact-form-7 input[type="tel"],
.qodef-contact-form-7 input[type="text"],
.qodef-contact-form-7 input[type="url"],
.qodef-contact-form-7 select,
.qodef-contact-form-7 textarea {
  margin: 0;
}

input[type="date"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="text"]:-ms-input-placeholder,
input[type="url"]:-ms-input-placeholder,
select:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

input[type="date"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[type="password"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="text"]::placeholder,
input[type="url"]::placeholder,
select::placeholder,
textarea::placeholder {
  color: inherit;
  opacity: 1;
}

input[type="date"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  background-color: transparent;
  border-color: #e3e3e3;
  color: #000;
}

input[type="date"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="url"]:focus,
select:focus,
textarea:focus {
  background-color: transparent;
  border-color: var(--qode-main-color);
  color: #000;
}

#qodef-page-footer input[type="date"],
#qodef-page-footer input[type="email"],
#qodef-page-footer input[type="number"],
#qodef-page-footer input[type="password"],
#qodef-page-footer input[type="search"],
#qodef-page-footer input[type="tel"],
#qodef-page-footer input[type="text"],
#qodef-page-footer input[type="url"],
#qodef-page-footer select,
#qodef-page-footer textarea {
  background-color: transparent;
  border-color: #e3e3e3;
  color: #000;
}

#qodef-page-footer input[type="date"]:focus,
#qodef-page-footer input[type="email"]:focus,
#qodef-page-footer input[type="number"]:focus,
#qodef-page-footer input[type="password"]:focus,
#qodef-page-footer input[type="search"]:focus,
#qodef-page-footer input[type="tel"]:focus,
#qodef-page-footer input[type="text"]:focus,
#qodef-page-footer input[type="url"]:focus,
#qodef-page-footer select:focus,
#qodef-page-footer textarea:focus {
  background-color: transparent;
  border-color: #e3e3e3;
  color: #000;
}

.qodef-contact-form-7 .wpcf7-form-control-wrap {
  margin: 0 0 11px;
}

.qodef-contact-form-7 label {
  margin: 0 0 11px;
}

.qodef-contact-form-7 label .wpcf7-form-control-wrap {
  margin: 0;
}

input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
select,
textarea {
  -webkit-appearance: none;
}

select {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB4PScwcHgnIHk9JzBweCcgd2lkdGg9JzEwLjhweCcgaGVpZ2h0PSc2LjJweCcgdmlld0JveD0nMCAwIDEwLjggNi4yJyBzdHlsZT0nZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMC44IDYuMjsnIHhtbDpzcGFjZT0ncHJlc2VydmUnPjxwYXRoIHN0eWxlPSdmaWxsOiAjQThBN0E3JyBkPSdNMTAuNiwwLjNjMC4zLDAuMywwLjMsMC43LDAsMUw1LjksNS45QzUuOCw2LjEsNS42LDYuMiw1LjQsNi4yQzUuMiw2LjIsNSw2LjEsNC45LDUuOUwwLjIsMS4zIGMtMC4zLTAuMy0wLjMtMC43LDAtMWMwLjMtMC4zLDAuNy0wLjMsMSwwbDQuMiw0LjFsNC4yLTQuMUM5LjktMC4xLDEwLjItMC4xLDEwLjYsMC4zeicvPjwvc3ZnPg==);
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  display: inline-block;
  height: auto;
  margin: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

select:disabled {
  background-color: #fafafa;
  border-color: inherit;
  box-shadow: none;
  color: inherit;
  text-shadow: none;
}

select option {
  color: #000;
}

select.qodef-arrow--light {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB4PScwcHgnIHk9JzBweCcgd2lkdGg9JzEwLjhweCcgaGVpZ2h0PSc2LjJweCcgdmlld0JveD0nMCAwIDEwLjggNi4yJyBzdHlsZT0nZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMC44IDYuMjsnIHhtbDpzcGFjZT0ncHJlc2VydmUnPjxwYXRoIHN0eWxlPSdmaWxsOiAjZmZmJyBkPSdNMTAuNiwwLjNjMC4zLDAuMywwLjMsMC43LDAsMUw1LjksNS45QzUuOCw2LjEsNS42LDYuMiw1LjQsNi4yQzUuMiw2LjIsNSw2LjEsNC45LDUuOUwwLjIsMS4zIGMtMC4zLTAuMy0wLjMtMC43LDAtMWMwLjMtMC4zLDAuNy0wLjMsMSwwbDQuMiw0LjFsNC4yLTQuMUM5LjktMC4xLDEwLjItMC4xLDEwLjYsMC4zeicvPjwvc3ZnPg==);
}

.post-password-form input[type="password"] {
  margin-top: 0.5em;
}

@media only screen and (min-width: 769px) {
  .post-password-form input[type="password"] {
    display: block;
    max-width: 50%;
  }
}

.qodef-theme-button,
button[type="submit"],
input[type="submit"] {
  font-size: 14px;
  line-height: 1.6875em;
  font-weight: 400;
  color: #000;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  width: auto;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 0;
  outline: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-color 0.2s ease-out;
  padding: 9px 68px;
  color: var(--qode-main-color);
  background-color: transparent;
  border: 1px solid var(--qode-main-color);
  z-index: 1;
  transition-delay: 0.03s;
  cursor: pointer;
}

.qodef-theme-button:after,
button[type="submit"]:after,
input[type="submit"]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: var(--qodef-hover-bg-color, var(--qode-main-color));
  transition: transform 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  z-index: -1;
  transform-origin: right;
  transform: scaleX(0);
}

.qodef-theme-button:hover,
button[type="submit"]:hover,
input[type="submit"]:hover {
  color: #fff;
  background-color: transparent;
  border-color: var(--qode-main-color);
}

.qodef-theme-button:hover:after,
button[type="submit"]:hover:after,
input[type="submit"]:hover:after {
  transform-origin: left;
  transform: scaleX(1);
}

.qodef-theme-button span,
button[type="submit"] span {
  display: block;
  line-height: inherit;
}

.qodef-theme-button span:before,
button[type="submit"] span:before {
  display: block;
  line-height: inherit;
}

[class*="level"] .select2-container--default {
  display: inline-block;
  margin: 0 0 11px;
  position: relative;
  vertical-align: middle;
  width: auto;
}

[class*="level"] .select2-container--default .select2-selection--single {
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 11px;
  font-weight: 400;
  height: auto;
  line-height: 24px;
  margin: 0;
  outline: 0;
  padding: 15px 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[class*="level"]
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  color: inherit;
  display: block;
  line-height: inherit;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[class*="level"]
  .select2-container--default
  .select2-selection--single
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: inherit;
  margin: 0;
  position: relative;
}

[class*="level"]
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: inherit;
}

[class*="level"]
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  align-items: center;
  color: inherit;
  display: flex;
  font-size: 16px;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
}

[class*="level"]
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}

[class*="level"]
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow:before {
  content: "\33";
  font-family: ElegantIcons;
  display: block;
  font-style: normal;
  line-height: inherit;
}

[class*="level"] .select2-container--default .select2-selection--multiple {
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: block;
  font-family: inherit;
  font-size: 11px;
  font-weight: 400;
  height: auto;
  line-height: 24px;
  margin: 0;
  outline: 0;
  padding: 15px 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[class*="level"]
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  color: inherit;
  display: block;
  line-height: inherit;
  list-style: none;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

[class*="level"]
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  li {
  margin: 0;
  padding: 0;
}

[class*="level"]
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__placeholder {
  color: inherit;
  float: left;
  margin: 0;
}

[class*="level"]
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: inherit;
  margin: 0;
  position: relative;
}

[class*="level"]
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  background-color: #000;
  border: 0;
  border-radius: 0;
  color: #fff;
  cursor: default;
  float: left;
  margin: 1px 1px 1px 0;
  padding: 5px 10px;
}

[class*="level"]
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice__remove {
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-weight: inherit;
  margin: 0 10px 0 0;
  transition: opacity 0.2s ease-out;
}

[class*="level"]
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice__remove:hover {
  color: inherit;
  opacity: 0.8;
}

[class*="level"] .select2-container--default .select2-search--inline {
  float: left;
}

[class*="level"]
  .select2-container--default
  .select2-search--inline
  .select2-search__field {
  -webkit-appearance: none;
  border: none !important;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0 !important;
  outline: 0;
  padding: 0 !important;
  width: 100%;
}

[class*="level"]
  .select2-container--default
  .select2-search--inline
  .select2-search__field:focus {
  border-color: #000;
}

[class*="level"]
  .select2-container--default
  .select2-search--inline
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[class*="level"] .select2-container--default .select2-dropdown {
  background-color: #fafafa;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  display: block;
  left: -100000px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
}

.admin-bar[class*="level"] .select2-container--default .select2-dropdown {
  top: 32px;
}

@media only screen and (max-width: 782px) {
  .admin-bar[class*="level"] .select2-container--default .select2-dropdown {
    top: 46px;
  }
}

[class*="level"] .select2-container--default .select2-search--dropdown {
  display: block;
  padding: 14px 15px 2px;
}

[class*="level"]
  .select2-container--default
  .select2-search--dropdown.select2-search--hide {
  display: none;
}

[class*="level"]
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field {
  -webkit-appearance: none;
  border: 1px solid #e3e3e3;
  color: #000;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: 20px;
  margin: 0;
  outline: 0;
  padding: 4px 8px;
  width: 100%;
}

[class*="level"]
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

[class*="level"]
  .select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus {
  border: 1px solid #e3e3e3;
  color: #000;
}

[class*="level"] .select2-container--default .select2-results {
  display: block;
}

[class*="level"]
  .select2-container--default
  .select2-results
  > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

[class*="level"] .select2-container--default .select2-results__options {
  font-size: 11px;
  line-height: 24px;
  list-style: none;
  margin: 5px 0;
  padding: 2px 15px;
  position: relative;
}

[class*="level"] .select2-container--default .select2-results__option {
  padding: 3px 0;
  transition: color 0.2s ease-out;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

[class*="level"]
  .select2-container--default
  .select2-results__option[aria-selected] {
  cursor: pointer;
}

[class*="level"]
  .select2-container--default
  .select2-results__option[role="group"] {
  padding: 0;
}

[class*="level"]
  .select2-container--default
  .select2-results__option[aria-disabled="true"] {
  color: #000;
}

[class*="level"]
  .select2-container--default
  .select2-results__option[aria-selected="true"],
[class*="level"]
  .select2-container--default
  .select2-results__option[data-selected="true"] {
  background: 0 0;
  color: #000;
}

[class*="level"]
  .select2-container--default
  .select2-results__option
  .select2-results__option {
  padding-left: 1em;
}

[class*="level"]
  .select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__group {
  padding-left: 0;
}

[class*="level"]
  .select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

[class*="level"]
  .select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

[class*="level"]
  .select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

[class*="level"]
  .select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

[class*="level"]
  .select2-container--default
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option
  .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

[class*="level"]
  .select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: 0 0;
  color: #000;
}

[class*="level"] .select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 7px 0;
}

[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__clear {
  float: left;
}

[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0;
}

[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--single
  .select2-selection__arrow {
  left: 0;
  right: auto;
}

[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-search--inline,
[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice,
[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__placeholder {
  float: right;
}

[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0;
}

[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice {
  margin: 7px auto 0 5px;
}

[class*="level"]
  .select2-container--default[dir="rtl"]
  .select2-selection--multiple
  .select2-selection__choice__remove {
  margin: 0 auto 0 3px;
}

[class*="level"]
  .select2-container--default.select2-container--disabled
  .select2-selection--multiple,
[class*="level"]
  .select2-container--default.select2-container--disabled
  .select2-selection--single {
  background-color: #fafafa;
  cursor: default;
}

[class*="level"]
  .select2-container--default.select2-container--disabled
  .select2-selection--multiple
  .select2-selection__clear,
[class*="level"]
  .select2-container--default.select2-container--disabled
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}

[class*="level"]
  .select2-container--default.select2-container--disabled
  .select2-selection__choice__remove {
  display: none;
}

[class*="level"]
  .select2-container--default.select2-container--open
  .select2-selection--multiple,
[class*="level"]
  .select2-container--default.select2-container--open
  .select2-selection--single {
  color: #000;
}

[class*="level"]
  .select2-container--default.select2-container--open
  .select2-dropdown {
  left: 0;
}

[class*="level"]
  .select2-container--default.select2-container--open
  .select2-dropdown.select2-dropdown--above {
  border-bottom-color: transparent;
}

[class*="level"]
  .select2-container--default.select2-container--open
  .select2-dropdown.select2-dropdown--below {
  border-top-color: transparent;
}

.select2-container--default .select2-selection {
  background-color: transparent !important;
  border-color: #e3e3e3 !important;
  color: #000 !important;
}

.select2-container--default .select2-selection:focus {
  background-color: transparent !important;
  border-color: var(--qode-main-color) !important;
  color: #000 !important;
}

#qodef-page-footer .select2-container--default .select2-selection {
  background-color: transparent !important;
  border-color: #e3e3e3 !important;
  color: #000 !important;
}

#qodef-page-footer .select2-container--default .select2-selection:focus {
  background-color: transparent !important;
  border-color: #e3e3e3 !important;
  color: #000 !important;
}

body:not([class*="level-core"])
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow:before {
  content: "\25BE";
  font-family: initial;
  font-size: 16px;
}

.select2-close-mask {
  background-color: #fff;
  border: 0;
  display: block;
  height: auto;
  left: 0;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  padding: 0;
  position: fixed;
  top: 0;
  width: auto;
  z-index: 99;
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 0 !important;
  line-height: 1;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-ms-clear,
input[type="text"]::-ms-clear {
  display: none;
}

input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: qodefAutofillFix;
}

#qodef-page-wrapper {
  position: relative;
  overflow: hidden;
}

#qodef-page-outer {
  position: relative;
}

#qodef-page-inner {
  position: relative;
  padding: 130px 0 0px;
}

#qodef-page-content.qodef-layout--template
  .qodef-page-content-section.qodef-col--12 {
  margin-bottom: 0;
}

.qodef--boxed {
  background-color: #fafafa;
}

.qodef--boxed #qodef-page-wrapper {
  display: block;
  background-color: #fff;
}

.qodef--boxed #qodef-page-inner {
  width: 100% !important;
  padding-left: 30px;
  padding-right: 30px;
}

.qodef--passepartout #qodef-page-wrapper {
  background-color: #fff;
}

.qodef-parallax-item {
  will-change: transform;
}

@media only screen and (min-width: 1441px) {
  .qodef-video-custom-bg .elementor-background-video-container video {
    object-fit: contain;
  }
}

.qodef-banner-button--underline .qodef-m-text {
  --qodef-underline-position-y: 100%;
  --qodef-underline-height: 1px;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
  background-position: 100% var(--qodef-underline-position-y);
  background-size: 100% var(--qodef-underline-height);
}

.qodef-banner-button--underline .qodef-m-text::after {
  display: none;
}

.qodef-banner-button--underline .qodef-m-text:hover {
  animation: qode-inline-underline-initial-hover 0.8s
    cubic-bezier(0.57, 0.39, 0, 0.86) 1 forwards;
}

button.mfp-arrow {
  top: calc(50% - 25px) !important;
  width: 26px !important;
  height: 51px !important;
  margin: 0 !important;
  color: #fff;
  transition: color 0.2s ease-out;
  opacity: 1 !important;
}

button.mfp-arrow svg {
  display: block;
  width: 100%;
  pointer-events: none;
}

button.mfp-arrow:after,
button.mfp-arrow:before {
  display: none !important;
}

button.mfp-arrow:hover path:nth-of-type(2) {
  animation: qode-draw-line 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
}

button.mfp-arrow.mfp-arrow-left {
  left: 30px;
}

button.mfp-arrow.mfp-arrow-left svg {
  transform: scaleX(-1);
}

button.mfp-arrow.mfp-arrow-right {
  right: 30px;
}

button.mfp-arrow.mfp-arrow-right svg {
  transform: scaleX(1);
}

button.mfp-close {
  top: 6px !important;
  right: -32px !important;
  width: 14px !important;
  height: auto !important;
  margin: 0 !important;
  padding: 0 !important;
  color: #fff;
  transition: color 0.2s ease-out;
  opacity: 1 !important;
}

button.mfp-close .qodef-svg--close {
  stroke: currentColor;
}

button.mfp-close:hover path {
  animation: qode-draw-x 0.9s cubic-bezier(0.22, 0.61, 0.36, 1) forwards;
}

button.mfp-close:hover path:nth-of-type(2) {
  animation-delay: 0.1s;
}

button.mfp-close:after,
button.mfp-close:before {
  display: none !important;
}

button.mfp-close svg {
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none;
}

.mfp-figure:after {
  background: 0 0 !important;
  box-shadow: none !important;
}

.mfp-bottom-bar .mfp-counter,
.mfp-bottom-bar .mfp-title {
  font-family: "Alice", serif;
  font-weight: 400;
  color: #000;
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: #fff;
}

.mfp-fade.mfp-bg,
.mfp-fade.mfp-wrap {
  opacity: 0;
  transition: opacity 0.35s ease-out;
}

.mfp-fade.mfp-bg.mfp-ready,
.mfp-fade.mfp-wrap.mfp-ready {
  opacity: 1;
}

.mfp-fade.mfp-bg.mfp-removing,
.mfp-fade.mfp-wrap.mfp-removing {
  opacity: 0;
}

.mfp-fade.mfp-bg {
  background-color: rgba(0, 0, 0, 0.8);
}

.qodef-swiper-container {
  position: relative;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  z-index: 1;
  visibility: hidden;
  overflow: hidden;
}

.qodef-swiper-container .swiper-slide img {
  width: 100%;
}

.qodef-swiper-container.qodef-blog .qodef-blog-item {
  margin-bottom: 0;
}

.qodef-swiper-container.qodef-swiper--initialized {
  visibility: visible;
}

.qodef-swiper-container.qodef-swiper--show-hidden-slides {
  overflow: visible;
}

.qodef-swiper-container.qodef--no-bottom-space {
  margin-bottom: 0 !important;
}

.qodef-swiper-container.qodef-swiper--show-pagination {
  padding: 0 0 44px;
}

.qodef-swiper--show-pagination .qodef-swiper-container {
  padding: 0 0 44px;
}

.swiper-pagination-fraction {
  display: block;
  top: auto;
  width: auto !important;
  font-family: "Alice", serif;
}

.swiper-pagination-bullets {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  position: relative !important;
  margin-top: 30px;
  bottom: 0 !important;
  height: 11px;
  width: auto;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 0 !important;
  height: auto;
  width: 30px;
  flex-direction: column;
}

.swiper-pagination-bullets .swiper-pagination-bullet {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11px;
  width: 32px;
  padding: 5px 0;
  margin: 0 5px !important;
  background-color: transparent;
  opacity: 1;
  transition: width 0.2s ease-out;
}

.swiper-pagination-bullets .swiper-pagination-bullet:after {
  content: "";
  position: absolute;
  left: 0;
  height: 1px;
  width: 100%;
  top: 5px;
  background-color: #000;
  opacity: 0.3;
}

.swiper-pagination-bullets .swiper-pagination-bullet:before {
  content: "";
  position: absolute;
  left: 0;
  height: 1px;
  width: 0;
  top: 5px;
  background-color: #000;
}

.swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 64px;
  transform: unset;
}

.swiper-pagination-bullets
  .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  animation-name: progressBar;
  animation-duration: 3.5s;
  animation-fill-mode: forwards;
}

@keyframes progressBar {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.swiper-button-next,
.swiper-button-prev {
  top: unset !important;
  bottom: 0 !important;
  background-image: none !important;
  width: 31px !important;
  height: auto !important;
  padding: 20px 0;
  margin: 0 !important;
  color: #000;
  transition: all 0.35s ease;
}

.swiper-button-next svg,
.swiper-button-prev svg {
  display: block;
  width: 31px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none !important;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: var(--qode-main-color);
}

.swiper-button-next:hover svg path:nth-of-type(2),
.swiper-button-prev:hover svg path:nth-of-type(2) {
  animation: qode-draw-line 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.swiper-button-prev {
  left: unset !important;
  right: 40px !important;
}

.swiper-button-prev.swiper-button-outside {
  left: -56px !important;
  right: unset !important;
}

.swiper-container-vertical .swiper-button-prev {
  left: 50% !important;
  transform-origin: 0 0;
  transform: rotate(90deg) translate(0, -50%);
  top: 30px;
}

.swiper-button-next {
  right: 0 !important;
}

.swiper-button-next.swiper-button-outside {
  right: -56px !important;
}

.swiper-container-vertical .swiper-button-next {
  right: 50% !important;
  transform-origin: 0 50%;
  transform: rotate(90deg) translate(0, -50%);
  top: auto;
  bottom: 30px;
}

.swiper-container-vertical + .swiper-button-prev {
  left: 50% !important;
  transform-origin: 0 0;
  transform: rotate(90deg) translate(0, -50%);
  top: -56px;
}

.swiper-container-vertical + .swiper-button-prev + .swiper-button-next {
  right: 50% !important;
  transform-origin: 0 50%;
  transform: rotate(90deg) translate(0, -50%);
  top: auto;
  bottom: -56px;
}

.widget_icl_lang_sel_widget {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown,
.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown-click {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}

.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click:hover
  .wpml-ls-sub-menu,
.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown:hover .wpml-ls-sub-menu {
  opacity: 1;
  visibility: visible;
  transition-delay: 0s;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown .wpml-ls-flag,
.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown-click .wpml-ls-flag {
  position: relative;
  top: -1px;
}

.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-legacy-dropdown,
.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-legacy-dropdown {
  padding: 0;
  margin: 0;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown .wpml-ls-item-toggle,
.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle {
  position: relative;
  display: flex;
  align-items: center;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown .wpml-ls-item-toggle:after,
.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle:after {
  content: "\33";
  font-family: ElegantIcons;
  line-height: 1;
  border: 0;
  position: initial;
  margin-left: auto;
}

body:not([class*="level-core"])
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle:after,
body:not([class*="level-core"])
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle:after {
  content: "\25BE";
  font-family: initial;
  font-size: 16px;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown .wpml-ls-sub-menu,
.widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown-click .wpml-ls-sub-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  list-style: none;
  margin: 0;
  border-top: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.25s, opacity 0.25s ease-in-out;
}

.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item,
.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a,
.widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a {
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  padding: 7px 0;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal {
  padding: 0;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal ul li {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0 !important;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-horizontal ul li a {
  padding: 0;
  display: block;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-vertical ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-vertical ul li {
  display: block;
  margin: 0;
}

.widget_icl_lang_sel_widget .wpml-ls-legacy-list-vertical ul li a {
  padding: 0;
  display: block;
}

#qodef-page-header .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown,
#qodef-page-header .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown-click {
  width: 200px;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle,
#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle {
  padding: 11px 20px;
  height: 46px;
  line-height: 24px;
  color: #000;
  background-color: #fff;
  border: 1px solid transparent;
  font-size: 14px;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle:hover,
#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle:hover {
  color: var(--qode-main-color);
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu,
#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu {
  margin: 0;
  padding: 0 20px;
  background-color: #fff;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a,
#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a {
  color: #000;
  font-size: 14px;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover,
#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li {
  margin-right: 10px !important;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a {
  color: #000;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li:last-child {
  margin-right: 0;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li {
  margin-bottom: 10px;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a {
  color: #000;
}

#qodef-page-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-mobile-header .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown,
#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click {
  width: 175px;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle,
#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle {
  padding: 11px 20px;
  height: 46px;
  line-height: 24px;
  color: #000;
  background-color: transparent;
  border: 1px solid transparent;
  font-size: 14px;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle:hover,
#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle:hover {
  color: var(--qode-main-color);
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu,
#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu {
  margin: 0;
  padding: 0 20px;
  background-color: transparent;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a,
#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a {
  color: #000;
  font-size: 14px;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover,
#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li {
  margin-right: 10px !important;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a {
  color: #000;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li:last-child {
  margin-right: 0;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li {
  margin-bottom: 10px;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a {
  color: #000;
}

#qodef-page-mobile-header
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a:hover {
  color: var(--qode-main-color);
}

#qodef-top-area .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown,
#qodef-top-area .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown-click {
  width: 175px;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle,
#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle {
  padding: 8px 20px;
  height: 40px;
  line-height: 24px;
  color: #fff;
  background-color: #000;
  border: 1px solid transparent;
  font-size: 14px;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle:hover,
#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle:hover {
  color: #fff;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu,
#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu {
  margin: 0;
  padding: 0 20px;
  background-color: #000;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a,
#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a {
  color: #fff;
  font-size: 14px;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover,
#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover {
  color: #fff;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li {
  margin-right: 10px !important;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a {
  color: #fff;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a:hover {
  color: #fff;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li:last-child {
  margin-right: 0;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li {
  margin-bottom: 10px;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a {
  color: #fff;
}

#qodef-top-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a:hover {
  color: #fff;
}

#qodef-page-sidebar .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown,
#qodef-page-sidebar .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown-click {
  width: 100%;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle,
#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle {
  padding: 11px 20px;
  height: 46px;
  line-height: 24px;
  color: #000;
  background-color: #fafafa;
  border: 1px solid transparent;
  font-size: 14px;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle:hover,
#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle:hover {
  color: var(--qode-main-color);
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu,
#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu {
  margin: 0;
  padding: 0 20px;
  background-color: #fafafa;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a,
#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a {
  color: #000;
  font-size: 14px;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover,
#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li {
  margin-right: 10px !important;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a {
  color: #000;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li:last-child {
  margin-right: 0;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li {
  margin-bottom: 10px;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a {
  color: #000;
}

#qodef-page-sidebar
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-footer .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown,
#qodef-page-footer .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown-click {
  width: 100%;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle,
#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle {
  padding: 11px 20px;
  height: 46px;
  line-height: 24px;
  color: #000;
  background-color: #fafafa;
  border: 1px solid transparent;
  font-size: 14px;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle:hover,
#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle:hover {
  color: var(--qode-main-color);
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu,
#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu {
  margin: 0;
  padding: 0 20px;
  background-color: #fafafa;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a,
#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a {
  color: #000;
  font-size: 14px;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover,
#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover {
  color: var(--qode-main-color);
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li {
  margin-right: 10px !important;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a {
  color: #fff;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a:hover {
  color: #fff;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li:last-child {
  margin-right: 0;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li {
  margin-bottom: 10px;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a {
  color: #fff;
}

#qodef-page-footer
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a:hover {
  color: #fff;
}

#qodef-side-area .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown,
#qodef-side-area .widget_icl_lang_sel_widget .wpml-ls-legacy-dropdown-click {
  width: 100%;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle,
#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle {
  padding: 11px 20px;
  height: 46px;
  line-height: 24px;
  color: #000;
  background-color: #fafafa;
  border: 1px solid transparent;
  font-size: 14px;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-item-toggle:hover,
#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-item-toggle:hover {
  color: var(--qode-main-color);
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu,
#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu {
  margin: 0;
  padding: 0 20px;
  background-color: #fafafa;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a,
#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a {
  color: #000;
  font-size: 14px;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover,
#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-dropdown-click
  .wpml-ls-sub-menu
  .wpml-ls-item
  a:hover {
  color: var(--qode-main-color);
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li {
  margin-right: 10px !important;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a {
  color: #fff;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li
  a:hover {
  color: #fff;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-horizontal
  ul
  li:last-child {
  margin-right: 0;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li {
  margin-bottom: 10px;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a {
  color: #fff;
}

#qodef-side-area
  .widget_icl_lang_sel_widget
  .wpml-ls-legacy-list-vertical
  ul
  li
  a:hover {
  color: #fff;
}

.error404 #qodef-page-inner {
  padding: 0;
}

#qodef-404-page {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 160px 10% 250px;
  text-align: center;
}

#qodef-404-page .qodef-404-title {
  margin: 0;
}

#qodef-404-page .qodef-404-text {
  display: block;
  margin: 14px auto 0;
}

@media only screen and (min-width: 1025px) {
  #qodef-404-page .qodef-404-text {
    max-width: 50%;
  }
}

#qodef-404-page .qodef-404-button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-top: 52px;
}

.qodef-blog {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.qodef-blog .qodef-blog-item {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: 0 0 60px;
}

.qodef-blog .qodef-blog-item .qodef-e-content,
.qodef-blog .qodef-blog-item .qodef-e-inner,
.qodef-blog .qodef-blog-item .qodef-e-media {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.qodef-blog .qodef-blog-item .qodef-e-media > * {
  margin-bottom: 30px;
}

.qodef-blog .qodef-blog-item .qodef-e-media iframe {
  display: block;
  height: 100%;
}

.qodef-blog
  .qodef-blog-item
  .qodef-e-media
  .qodef-swiper-container
  .swiper-button-next,
.qodef-blog
  .qodef-blog-item
  .qodef-e-media
  .qodef-swiper-container
  .swiper-button-prev {
  width: 46px !important;
}

.qodef-blog
  .qodef-blog-item
  .qodef-e-media
  .qodef-swiper-container
  .swiper-button-next
  svg,
.qodef-blog
  .qodef-blog-item
  .qodef-e-media
  .qodef-swiper-container
  .swiper-button-prev
  svg {
  display: block;
  width: 46px;
}

.qodef-blog .qodef-blog-item .qodef-e-media-image {
  position: relative;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
}

.qodef-blog .qodef-blog-item .qodef-e-media-image a,
.qodef-blog .qodef-blog-item .qodef-e-media-image img {
  display: block;
  width: 100%;
}

.qodef-blog .qodef-blog-item .qodef-e-media-image.qodef--background {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.qodef-blog .qodef-blog-item .qodef-e-media-image.qodef--background img {
  visibility: hidden;
}

.qodef-blog .qodef-blog-item .qodef-e-title {
  margin: 0;
}

.qodef-blog .qodef-blog-item .qodef-e-excerpt {
  margin: 15px 0 0;
}

.qodef-blog .qodef-blog-item .qodef-e-top-holder {
  margin-bottom: 2px;
}

.qodef-blog .qodef-blog-item .qodef-e-top-holder .qodef-e-info a[rel="tag"] {
  margin-right: 9px;
}

.qodef-blog .qodef-blog-item .qodef-e-text:after {
  content: "";
  clear: both;
  display: table;
}

.qodef-blog .qodef-blog-item .qodef-e-bottom-holder {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 18px;
}

.qodef-blog .qodef-blog-item .qodef-e-bottom-holder .qodef-e-left:only-child {
  flex-basis: 100%;
}

.qodef-blog .qodef-blog-item .qodef-e-bottom-holder .qodef-e-info {
  flex-wrap: wrap;
  row-gap: 5px;
}

.qodef-blog .qodef-blog-item .qodef-e-left,
.qodef-blog .qodef-blog-item .qodef-e-right {
  flex-basis: 50%;
}

.qodef-blog .qodef-blog-item .qodef-e-right {
  justify-content: flex-end;
  text-align: right;
}

.qodef-blog .qodef-blog-item .qodef-e-post-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.qodef-blog .qodef-blog-item.sticky .qodef-e-title a {
  position: relative;
  text-decoration: underline;
}

.qodef-blog .qodef-blog-item.sticky .qodef-e-title a:after {
  content: "*";
  position: absolute;
  bottom: 0;
  right: -0.7em;
  font-size: 1.5em;
}

html.elementor-html .qodef-blog .qodef-blog-item.format-video .wp-video {
  max-width: 100%;
}

html.elementor-html .qodef-blog .qodef-blog-item.format-video video {
  height: auto;
}

html.elementor-html .qodef-blog .qodef-blog-item.format-audio audio {
  display: block;
}

.qodef-blog .qodef-blog-item.format-audio .qodef-e-media-image {
  width: 100%;
}

.qodef-blog .qodef-blog-item.format-audio .qodef-e-media-image img {
  width: 100%;
}

.qodef-blog .qodef-blog-item.format-audio .qodef-e-media-audio {
  position: absolute;
  left: 0;
  bottom: 30px;
  width: 100%;
  margin: 0;
}

.qodef-blog .qodef-blog-item.format-link .qodef-e-link {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 11.7% 9.7% 11.5% 25%;
  background-color: #d9d0d0;
}

.qodef-blog .qodef-blog-item.format-link .qodef-e-link-text {
  position: relative;
  display: inline;
  margin: 0;
  font-size: 24px;
  color: #000;
  text-decoration: underline;
}

.qodef-blog .qodef-blog-item.format-link .qodef-e-link-icon {
  display: none;
}

.qodef-blog .qodef-blog-item.format-link .qodef-e-link-url {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.qodef-blog .qodef-blog-item.format-quote .qodef-e-quote {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  padding: 11.7% 9.7% 11.5% 25%;
  background-color: #f4f3ef;
}

.qodef-blog .qodef-blog-item.format-quote .qodef-e-quote-text {
  position: relative;
  margin: 0;
  color: #000;
}

.qodef-blog .qodef-blog-item.format-quote .qodef-e-quote-author {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  position: relative;
  display: block;
  margin: 33px 0 0;
  color: #000;
}

.qodef-blog .qodef-blog-item.format-quote .qodef-e-quote-url {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.qodef-blog .qodef-blog-item.format-quote::before {
  display: none;
}

.qodef-blog .qodef-blog-item.format-gallery .qodef-e-media-gallery {
  padding-bottom: 30px;
}

.qodef-blog .qodef-blog-item.format-gallery .swiper-button-next,
.qodef-blog .qodef-blog-item.format-gallery .swiper-button-prev {
  left: initial !important;
  top: initial !important;
  bottom: -5px !important;
  right: 0 !important;
  transform: none;
}

.qodef-blog .qodef-blog-item.format-gallery .swiper-button-next svg,
.qodef-blog .qodef-blog-item.format-gallery .swiper-button-prev svg {
  height: auto;
}

.qodef-blog .qodef-blog-item.format-gallery .swiper-button-prev {
  right: 57px !important;
  padding: 10px 0;
}

.qodef-blog .qodef-blog-item.format-gallery .swiper-button-next {
  padding: 10px 0;
}

.qodef-blog .qodef-blog-item.format-gallery .swiper-pagination {
  width: auto;
  left: 0;
  bottom: 0;
  line-height: 1;
}

.qodef-blog .qodef-blog-item.format-gallery .swiper-pagination > * {
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
}

.qodef-blog .qodef-blog-item .qodef-e-info {
  display: flex;
}

.qodef-blog .qodef-blog-item .qodef-e-info .qodef-info-separator-single {
  font-size: 0;
  width: 9px;
}

.qodef-blog .qodef-blog-item .qodef-e-info-author .qodef-e-info-author-before {
  margin-right: 6px;
}

.qodef-blog .qodef-blog-item .qodef-e-info-author .qodef-e-info-author-name {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-family: "Nunito Sans", sans-serif;
  letter-spacing: 0;
  text-transform: none;
}

.qodef-blog
  .qodef-blog-item
  .qodef-e-info-author
  .qodef-e-info-author-name:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 0;
  height: 1px;
  background-color: currentColor;
  transition: width 0.2s ease-out;
}

.qodef-blog
  .qodef-blog-item
  .qodef-e-info-author:hover
  .qodef-e-info-author-name:after {
  width: 100%;
}

.qodef-blog.qodef-items--fixed article .qodef-e-media,
.qodef-blog.qodef-items--fixed article .qodef-e-media-image {
  height: 100%;
}

.archive.category .qodef-blog.qodef--list .qodef-blog-item,
.blog .qodef-blog.qodef--list .qodef-blog-item {
  padding-bottom: 80px !important;
  margin-bottom: 90px !important;
  border-bottom: 1px solid #e3e3e3;
}

.qodef-blog.qodef--single .qodef-blog-item {
  margin-bottom: 97px;
}

.qodef-blog.qodef--single .qodef-blog-item .qodef-e-top-holder {
  margin-top: 0;
  margin-bottom: 43px;
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-top-holder
  .qodef-e-info.qodef-e-top {
  flex-wrap: wrap;
  margin-bottom: 17px;
  line-height: 1;
}

.qodef-blog.qodef--single .qodef-blog-item .qodef-e-top-holder .qodef-e-title {
  margin-bottom: 26px;
}

.qodef-blog.qodef--single .qodef-blog-item .qodef-e-bottom-holder {
  margin-top: 50px;
  border-bottom: 1px solid #e3e3e3;
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-bottom-holder
  .qodef-e-info
  .qodef-info-separator-single {
  display: none;
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-bottom-holder
  .qodef-e-info
  a[rel="tag"] {
  padding: 4px 8px;
  margin: 0 5px 5px 0;
  border: 1px solid #d8d8d8;
  background-color: transparent;
  color: var(--qode-main-color);
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  text-transform: lowercase;
  letter-spacing: 0;
  transition: color 0.2s ease-out, border-color 0.35s ease-out,
    background-color 0.2s ease-out;
  white-space: nowrap;
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-bottom-holder
  .qodef-e-info
  a[rel="tag"]:hover {
  border-color: var(--qode-main-color);
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-info
  .qodef-info-separator-end::after {
  margin: 0 17px;
}

.qodef-blog.qodef--single .qodef-blog-item .qodef-e-text blockquote {
  padding: 0 75px;
  margin: 43px 0;
}

.qodef-blog.qodef--single .qodef-blog-item .qodef-e-text blockquote::before {
  top: 25px;
  left: 45px;
  color: #000;
}

@media only screen and (max-width: 768px) {
  .qodef-blog.qodef--single .qodef-blog-item .qodef-e-text blockquote {
    padding: 0 25px;
  }

  .qodef-blog.qodef--single .qodef-blog-item .qodef-e-text blockquote::before {
    left: -5px;
  }
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-info-social-share
  .qodef-social-share {
  line-height: 1;
  margin-bottom: 4px;
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-info-social-share
  .qodef-social-share
  .qodef-social-title {
  display: none;
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-info-social-share
  .qodef-social-share
  .qodef-social-share-dropdown-opener {
  font-size: 16px;
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-info-social-share
  .qodef-social-share
  li
  a {
  font-size: 16px;
}

.qodef-blog.qodef--single
  .qodef-blog-item
  .qodef-e-info-social-share
  .qodef-social-share
  li
  a:hover {
  color: #787878;
}

.qodef-blog.qodef--single .qodef-blog-item.format-link .qodef-info--top,
.qodef-blog.qodef--single .qodef-blog-item.format-quote .qodef-info--top {
  display: none;
}

.qodef-blog.qodef--single
  .qodef-blog-item.format-gallery
  .qodef-e-media-gallery {
  padding-bottom: 33px;
}

.qodef-blog.qodef--single
  .qodef-blog-item.format-gallery
  .qodef-e-media-gallery
  .swiper-button-next,
.qodef-blog.qodef--single
  .qodef-blog-item.format-gallery
  .qodef-e-media-gallery
  .swiper-button-prev {
  left: initial !important;
  top: initial !important;
  bottom: 5px !important;
  right: 0 !important;
  padding-left: 18px;
  padding-right: 18px;
}

.qodef-blog.qodef--single
  .qodef-blog-item.format-gallery
  .qodef-e-media-gallery
  .swiper-button-prev {
  right: 54px !important;
  padding: 8px 8px 8px 0;
}

.qodef-blog.qodef--single
  .qodef-blog-item.format-gallery
  .qodef-e-media-gallery
  .swiper-button-next {
  padding: 8px 0 8px 8px;
}

.qodef-blog.qodef--single
  .qodef-blog-item.format-gallery
  .qodef-e-media-gallery
  .swiper-pagination {
  width: auto;
  left: 0;
  bottom: 0;
  line-height: 1;
  padding: 8px 16px 8px 0;
}

#qodef-page-comments {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-top: 82px;
}

#qodef-page-comments .qodef-page-comments-not-found {
  margin: 0;
}

#qodef-page-comments-list {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-bottom: 100px;
}

#qodef-page-comments-list .qodef-m-title {
  font-size: 36px;
  line-height: 1.13889em;
  font-weight: 400;
  letter-spacing: 0;
  margin: 0 0 40px;
}

@media only screen and (max-width: 1440px) {
  #qodef-page-comments-list .qodef-m-title {
    font-size: 30px;
    line-height: 1.16667em;
  }
}

#qodef-page-comments-list .qodef-m-comments {
  list-style: none;
  margin: 0;
  padding: 0;
}

#qodef-page-comments-list .qodef-m-comments > .qodef-comment-item:first-child {
  margin-top: 0;
}

#qodef-page-comments-list .qodef-comment-item {
  margin: 69px 0 0;
  padding: 0;
}

#qodef-page-comments-list .qodef-comment-item .children {
  list-style: none;
  margin: 0;
  padding: 0 0 0 110px;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-inner {
  display: flex;
  align-items: flex-start;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-image {
  flex-shrink: 0;
  margin-right: 32px;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-image img {
  display: block;
  border-radius: 50%;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-content {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

#qodef-page-comments-list
  .qodef-comment-item
  .qodef-e-content
  .qodef-e-content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#qodef-page-comments-list
  .qodef-comment-item
  .qodef-e-content
  .qodef-e-content-top
  .qodef-e-content-left {
  display: flex;
}

#qodef-page-comments-list
  .qodef-comment-item
  .qodef-e-content
  .qodef-e-content-top
  .qodef-e-content-left
  > * {
  line-height: 1;
}

#qodef-page-comments-list
  .qodef-comment-item
  .qodef-e-content
  .qodef-e-content-top
  .qodef-e-content-right {
  display: flex;
  align-items: center;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-title {
  font-size: 20px;
  line-height: 1.25em;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0;
  letter-spacing: 0;
}

@media only screen and (max-width: 1440px) {
  #qodef-page-comments-list .qodef-comment-item .qodef-e-title {
    font-size: 18px;
    line-height: 1.27778em;
  }
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-date {
  margin-top: 2px;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-date a {
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  line-height: 1;
  text-transform: uppercase;
  color: #b5b5b5;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-text {
  margin: 7px 0 0;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-text p {
  margin: 0;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-text img {
  margin-top: 5px;
  margin-bottom: 5px;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-links > * {
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  color: #000;
  text-transform: lowercase;
  --qodef-underline-position-y: 100%;
  --qodef-underline-height: 1px;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
  background-position: 100% var(--qodef-underline-position-y);
  background-size: 100% var(--qodef-underline-height);
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-links > :last-child {
  margin-right: 0;
}

#qodef-page-comments-list .qodef-comment-item .qodef-e-links > :hover {
  animation: qode-inline-underline-initial-hover 0.8s
    cubic-bezier(0.57, 0.39, 0, 0.86) 1 forwards;
}

#qodef-page-comments-list .qodef-m-pagination {
  margin-top: 50px;
}

#qodef-page-comments-list .comment-form-cookies-consent input[type="checkbox"] {
  flex-shrink: 0;
  margin: 0 6px 1px 0;
}

#respond {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.qodef-comment-item #respond {
  margin-top: 50px;
}

.qodef-comment-item #respond .comment-reply-title {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 24px;
  line-height: 1.20833em;
  font-weight: 400;
  letter-spacing: 0;
}

@media only screen and (max-width: 1440px) {
  .qodef-comment-item #respond .comment-reply-title {
    font-size: 22px;
    line-height: 1.22727em;
  }
}

#respond .comment-notes {
  font-size: 15px;
  color: #b5b5b5;
}

#respond .comment-reply-title {
  display: flex;
  justify-content: space-between;
  margin: 0;
  letter-spacing: 0;
}

#respond .qodef-comment-form {
  display: flex;
  flex-direction: column;
}

#respond .qodef-comment-form .comment-notes,
#respond .qodef-comment-form .logged-in-as {
  margin: 7px 0 42px 0;
}

#respond .qodef-comment-form .comment-form-author,
#respond .qodef-comment-form .comment-form-comment,
#respond .qodef-comment-form .comment-form-email,
#respond .qodef-comment-form .comment-form-url,
#respond .qodef-comment-form .form-submit {
  margin: 0;
}

#respond .qodef-comment-form .comment-form-cookies-consent {
  align-items: center;
  display: flex;
  margin: 3px 0 28px 0;
  position: relative;
}

#respond .qodef-comment-form .comment-form-cookies-consent > * {
  margin: 0;
}

#respond
  .qodef-comment-form
  .comment-form-cookies-consent
  label[for="wp-comment-cookies-consent"] {
  margin-left: 6px;
}

#respond
  .qodef-comment-form
  .comment-form-cookies-consent
  input[type="checkbox"] {
  flex-shrink: 0;
  margin: 0 6px 0 0;
}

@media only screen and (max-width: 680px) {
  #respond .qodef-comment-form .comment-form-cookies-consent {
    align-items: flex-start;
  }

  #respond
    .qodef-comment-form
    .comment-form-cookies-consent
    input[type="checkbox"] {
    margin-top: 6px;
  }
}

#respond .qodef-comment-form input[type="email"],
#respond .qodef-comment-form input[type="text"],
#respond .qodef-comment-form textarea {
  border-radius: 0;
}

#respond .qodef-comment-form textarea {
  padding: 20px 0;
  margin-bottom: 30px;
}

#respond .qodef-comment-form input[type="email"],
#respond .qodef-comment-form input[type="text"] {
  margin-bottom: 24px;
}

.qodef-filter--on .qodef-e {
  transition: -webkit-clip-path 1s cubic-bezier(0.57, 0.18, 0, 0.92) 0s;
  transition: clip-path 1s cubic-bezier(0.57, 0.18, 0, 0.92) 0s;
  transition: clip-path 1s cubic-bezier(0.57, 0.18, 0, 0.92) 0s,
    -webkit-clip-path 1s cubic-bezier(0.57, 0.18, 0, 0.92) 0s;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

.qodef-filter--on .qodef-e:nth-child(1) {
  transition-delay: calc((1 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(2) {
  transition-delay: calc((2 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(3) {
  transition-delay: calc((3 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(4) {
  transition-delay: calc((4 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(5) {
  transition-delay: calc((5 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(6) {
  transition-delay: calc((6 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(7) {
  transition-delay: calc((7 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(8) {
  transition-delay: calc((8 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(9) {
  transition-delay: calc((9 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(10) {
  transition-delay: calc((10 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(11) {
  transition-delay: calc((11 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(12) {
  transition-delay: calc((12 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(13) {
  transition-delay: calc((13 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(14) {
  transition-delay: calc((14 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(15) {
  transition-delay: calc((15 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(16) {
  transition-delay: calc((16 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(17) {
  transition-delay: calc((17 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(18) {
  transition-delay: calc((18 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(19) {
  transition-delay: calc((19 - 1) * 0.1s);
}

.qodef-filter--on .qodef-e:nth-child(20) {
  transition-delay: calc((20 - 1) * 0.1s);
}

.qodef-filter--on.qodef--filter-loading .qodef-e {
  -webkit-clip-path: inset(0 100% 0 0);
  clip-path: inset(0 100% 0 0);
}

.qodef-filter--on.qodef--filter-loading .qodef-m-pagination-spinner {
  bottom: calc(50% - 16px) !important;
}

.qodef-m-filter {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: 0 0 40px;
}

.qodef-m-filter .qodef-m-filter-items {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qodef-m-filter .qodef-m-filter-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
  padding: 0 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.2em;
  text-transform: lowercase;
  z-index: 1;
  transition-delay: 0.03s;
}

.qodef-m-filter .qodef-m-filter-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: var(--qodef-hover-bg-color, var(--qode-main-color));
  transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
  z-index: -1;
  transform-origin: right;
  transform: scaleX(0);
}

.qodef-m-filter .qodef-m-filter-item.qodef--active,
.qodef-m-filter .qodef-m-filter-item:hover {
  color: #fff;
}

.qodef-m-filter .qodef-m-filter-item.qodef--active:before,
.qodef-m-filter .qodef-m-filter-item:hover:before {
  transform-origin: left;
  transform: scaleX(1);
}

#qodef-page-footer {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

#qodef-page-footer > * {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

#qodef-page-footer-top-area {
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#qodef-page-footer-top-area .qodef-content-full-width {
  padding-left: 100px;
  padding-right: 100px;
}

.qodef--boxed #qodef-page-footer-top-area {
  padding-left: 30px;
  padding-right: 30px;
}

#qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
  .qodef-grid-item {
  clear: none !important;
}

#qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
  .qodef-grid-item:nth-child(1) {
  /* width: 50% */
}

#qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
  .qodef-grid-item:nth-child(2),
#qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
  .qodef-grid-item:nth-child(3),
#qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
  .qodef-grid-item:nth-child(4) {
  width: 16.6%;
}

@media only screen and (max-width: 768px) {
  #qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
    .qodef-grid-item {
    text-align: left !important;
  }

  #qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
    .qodef-grid-item:nth-child(1) {
    width: 100%;
    margin-bottom: 70px;
  }

  #qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
    .qodef-grid-item:nth-child(2),
  #qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
    .qodef-grid-item:nth-child(3),
  #qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
    .qodef-grid-item:nth-child(4) {
    width: 33.33%;
  }
}

@media only screen and (max-width: 680px) {
  #qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
    .qodef-grid-item {
    width: 50% !important;
    margin-bottom: 70px;
  }

  #qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
    .qodef-grid-item:nth-child(4) {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 769px) {
  #qodef-page-footer-top-area-inner.qodef-layout-columns--4-predefined
    .qodef-restricted-width {
    max-width: 400px;
  }
}

#qodef-page-footer-top-area-inner
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li {
  line-height: 1;
}

#qodef-page-footer-top-area .widget a {
  position: relative;
  display: inline-block;
}

body[class*="level-core"] #qodef-page-footer-top-area .widget a {
  --qodef-underline-position-y: calc(100% - 4px);
  --qodef-underline-height: 1px;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
  background-position: 100% var(--qodef-underline-position-y);
  background-size: 0 var(--qodef-underline-height);
  transition: background-size 0.5s cubic-bezier(0.25, 0.46, 0.35, 0.94),
    color 0.2s ease-out;
}

body[class*="level-core"] #qodef-page-footer-top-area .widget a:hover {
  background-size: 100% var(--qodef-underline-height);
  background-position: 0 var(--qodef-underline-position-y);
}

body[class*="level-core"]
  #qodef-page-footer-top-area
  .widget
  a.qodef-qi-link--hover-underline:after {
  bottom: 4px;
}

body[class*="level-core"] #qodef-page-footer-top-area .widget .qodef-m-image a {
  background-image: none;
}

#qodef-page-footer-top-area-inner {
  padding-top: 110px;
  padding-bottom: 10px;
  border-top: 1px solid #e3e3e3;
}

#qodef-page-footer-bottom-area {
  background-color: #fff;
}

#qodef-page-footer-bottom-area .qodef-content-full-width {
  padding-left: 100px;
  padding-right: 100px;
}

.qodef--boxed #qodef-page-footer-bottom-area {
  padding-left: 30px;
  padding-right: 30px;
}

#qodef-page-footer-bottom-area .widget a,
#qodef-page-footer-bottom-area .widget p {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 12px;
}

#qodef-page-footer-bottom-area-inner {
  padding-top: 42px;
  padding-bottom: 42px;
  border-top: 0 solid #e3e3e3;
}

.wp-block-button {
  float: none;
  margin: 1em 0;
}

.wp-block-button .wp-block-button__link {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6875em;
  font-weight: 400;
  color: #000;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  width: auto;
  margin: 0;
  text-decoration: none;
  text-transform: lowercase;
  border-radius: 0;
  outline: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-color 0.2s ease-out;
  padding: 9px 68px;
  color: #fff;
  background-color: transparent;
  border: 1px solid var(--qode-main-color);
  z-index: 1;
  transition-delay: 0.03s;
  overflow: hidden;
  border-radius: 28px;
  cursor: pointer;
}

.wp-block-button .wp-block-button__link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: var(--qodef-hover-bg-color, var(--qode-main-color));
  transition: transform 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  z-index: -1;
  width: calc(100% + 2px);
  transform-origin: left;
  transform: scaleX(1);
}

.wp-block-button .wp-block-button__link:hover {
  color: var(--qode-main-color);
  background-color: transparent;
  border-color: var(--qode-main-color);
}

.wp-block-button .wp-block-button__link:hover:after {
  transform-origin: right;
  transform: scaleX(0);
}

.wp-block-button.is-style-outline {
  border: none;
}

.wp-block-button.is-style-outline .wp-block-button__link {
  color: var(--qode-main-color);
  background-color: transparent;
  border: 1px solid var(--qode-main-color);
  z-index: 1;
  transition-delay: 0.03s;
  border-width: 1px;
}

.wp-block-button.is-style-outline .wp-block-button__link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: var(--qodef-hover-bg-color, var(--qode-main-color));
  transition: transform 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
  z-index: -1;
  transform-origin: right;
  transform: scaleX(0);
}

.wp-block-button.is-style-outline .wp-block-button__link:hover {
  color: #fff;
  background-color: transparent;
  border-color: var(--qode-main-color);
}

.wp-block-button.is-style-outline .wp-block-button__link:hover:after {
  transform-origin: left;
  transform: scaleX(1);
}

.wp-block-button.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.wp-block-button.alignleft {
  text-align: left;
  margin-top: 0;
  margin-right: 2rem;
}

.wp-block-button.alignright {
  text-align: right;
  margin-top: 0;
  margin-left: 2rem;
}

.wp-block-image {
  margin-bottom: 0;
}

.wp-block-image .aligncenter {
  clear: both;
}

.wp-block-cover-image {
  float: none !important;
  max-width: 100% !important;
  margin: 0 !important;
}

.wp-block-cover-image h1,
.wp-block-cover-image h2,
.wp-block-cover-image h3,
.wp-block-cover-image h4,
.wp-block-cover-image h5,
.wp-block-cover-image h6,
.wp-block-cover-image p {
  z-index: 1 !important;
  margin: 0 auto !important;
  max-width: 580px !important;
  padding: 14px !important;
  text-align: center;
  line-height: 1em !important;
}

.wp-block-cover-image h1 a,
.wp-block-cover-image h2 a,
.wp-block-cover-image h3 a,
.wp-block-cover-image h4 a,
.wp-block-cover-image h5 a,
.wp-block-cover-image h6 a,
.wp-block-cover-image p a {
  color: #fff !important;
}

.wp-block-cover-image h1 a:hover,
.wp-block-cover-image h2 a:hover,
.wp-block-cover-image h3 a:hover,
.wp-block-cover-image h4 a:hover,
.wp-block-cover-image h5 a:hover,
.wp-block-cover-image h6 a:hover,
.wp-block-cover-image p a:hover {
  color: var(--qode-main-color) !important;
}

.wp-block-cover-image :not(.has-text-color):not(.has-inline-color):not(a) {
  color: #fff !important;
  font-size: 30px !important;
}

.wp-block-cover {
  margin-top: 10px !important;
}

.wp-block-cover.alignleft {
  margin-right: 2em !important;
}

.wp-block-cover.alignright {
  margin-left: 2em !important;
}

.wp-block-cover .wp-block-cover__inner-container {
  width: 100% !important;
}

.wp-block-cover h1,
.wp-block-cover h2,
.wp-block-cover h3,
.wp-block-cover h4,
.wp-block-cover h5,
.wp-block-cover h6,
.wp-block-cover p {
  z-index: 1 !important;
  margin: 0 auto !important;
  max-width: 580px !important;
  padding: 14px !important;
  text-align: center;
  line-height: 1em !important;
}

.wp-block-cover h1 a,
.wp-block-cover h2 a,
.wp-block-cover h3 a,
.wp-block-cover h4 a,
.wp-block-cover h5 a,
.wp-block-cover h6 a,
.wp-block-cover p a {
  color: #fff !important;
}

.wp-block-cover h1 a:hover,
.wp-block-cover h2 a:hover,
.wp-block-cover h3 a:hover,
.wp-block-cover h4 a:hover,
.wp-block-cover h5 a:hover,
.wp-block-cover h6 a:hover,
.wp-block-cover p a:hover {
  color: var(--qode-main-color) !important;
}

.wp-block-cover :not(.has-text-color):not(.has-inline-color):not(a) {
  color: #fff !important;
  font-size: 30px !important;
}

.wp-block-gallery {
  display: flex;
  margin-bottom: 2em;
}

.wp-block-gallery ul.blocks-gallery-grid {
  margin: 0 !important;
}

.wp-block-gallery.alignleft {
  max-width: 420px;
}

.wp-block-gallery.alignright {
  max-width: 420px;
}

.wp-block-gallery figcaption {
  overflow-x: hidden;
}

.wp-caption {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.wp-block-pullquote {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  text-align: center !important;
  width: 100% !important;
  position: relative;
  z-index: -2;
}

.wp-block-pullquote blockquote {
  padding: 25px !important;
}

.wp-block-pullquote blockquote p {
  font-size: 28px !important;
  line-height: 1.6 !important;
}

.has-drop-cap:after {
  content: "";
  display: table;
  table-layout: fixed;
  clear: both;
}

.wp-block-file {
  display: flex;
  align-items: center;
  margin: 0;
  color: #000;
  text-decoration: none;
  transition: color 0.2s ease-out;
  cursor: pointer;
}

.wp-block-file:focus,
.wp-block-file:hover {
  color: var(--qode-main-color);
}

.wp-block-group.has-background {
  padding: 20px 30px;
}

.gallery-item img {
  border: 0 !important;
}

.wp-block-search .wp-block-search__input {
  margin-bottom: 0;
}

.wp-block-rss .wp-block-rss__item-title {
  display: inline-block;
  vertical-align: top;
}

#qodef-page-header {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: 115px;
  background-color: #ffffff00;
  z-index: 100;
}

#qodef-page-header .qodef-header-logo-link {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 115px;
  max-height: 100%;
  margin-top: 10px;
}

#qodef-page-header .qodef-header-logo-link img {
  display: block;
  margin: auto 0;
  width: auto;
  max-height: 100%;
  transition: opacity 0.3s ease;
}

#qodef-page-header .qodef-header-logo-link.qodef-height--not-set img {
  max-height: 50%;
}

#qodef-page-header-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 50px;
}

.qodef-layout--justified-gallery {
  height: auto !important;
  visibility: hidden;
}

.qodef-layout--justified-gallery.qodef--justified-gallery-init {
  visibility: visible;
}

.qodef-layout--justified-gallery .qodef-grid-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: 0 !important;
  overflow: hidden;
}

.qodef-layout--justified-gallery .qodef-grid-item {
  position: absolute;
  width: auto;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.qodef-layout--justified-gallery .qodef-grid-item img {
  height: auto !important;
  width: auto !important;
  margin: 0 !important;
}

.qodef-layout--justified-gallery > .jg-filtered {
  display: none;
}

#qodef-page-mobile-header {
  position: relative;
  display: none;
  height: 100px;
  z-index: 100;
}

#qodef-page-mobile-header .qodef-mobile-header-logo-link {
  position: relative;
  display: flex;
  align-items: center;
  height: 90px;
  max-height: 100%;
}

#qodef-page-mobile-header .qodef-mobile-header-logo-link img {
  display: block;
  margin: auto 0;
  width: auto;
  max-height: 100%;
  transition: opacity 0.3s ease;
}

#qodef-page-mobile-header
  .qodef-mobile-header-logo-link.qodef-height--not-set
  img {
  max-height: calc(50% * 100 / 70);
}

#qodef-page-mobile-header-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 0 7%;
}

#qodef-page-mobile-header-inner.qodef-content-grid {
  padding: 0;
}

.qodef-mobile-header-opener {
  font-size: 20px;
  margin-left: 20px;
}

.qodef-mobile-header-opener .qodef--close {
  top: 50% !important;
  transform: translateY(-1px);
}

.qodef-mobile-header-opener svg {
  display: block;
  width: 32px;
  height: auto;
}

.qodef-mobile-header-navigation {
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  width: 100%;
  max-height: calc(100vh - 70px);
  background-color: #fff;
  border-bottom: 1px solid #e3e3e3;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 10;
}

.qodef-mobile-header-navigation ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.qodef-mobile-header-navigation ul li {
  position: relative;
  margin: 0;
  padding: 6px 0;
}

.qodef-mobile-header-navigation ul li a {
  position: relative;
  display: block;
}

.qodef-mobile-header-navigation ul li .qodef-menu-item-arrow {
  position: absolute;
  top: 14px;
  right: 0;
  width: 7px;
  height: auto;
  color: currentColor;
  z-index: 9;
  cursor: pointer;
  transition: color 0.2s ease-out;
  transform: rotate(0);
  transition: transform 0.2s ease-out, color 0.2s ease-out;
}

.qodef-mobile-header-navigation ul li .qodef-menu-item-arrow:hover {
  color: var(--qode-main-color);
}

.qodef-mobile-header-navigation ul li ul {
  display: none;
  margin-left: 15px;
  padding-top: 3px;
}

.qodef-mobile-header-navigation ul li ul li > a {
  position: relative;
  display: flex;
  white-space: normal;
  margin: 8px 0;
  overflow: hidden;
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6875em;
  font-weight: 400;
  color: #000;
  margin: 0;
}

.qodef-mobile-header-navigation ul li ul li > a .qodef-menu-item-icon {
  flex-shrink: 0;
}

.qodef-mobile-header-navigation ul li ul li > a > span {
  --qodef-underline-position-y: calc(100% - 3px);
  --qodef-underline-height: 1px;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
  background-position: 100% var(--qodef-underline-position-y);
  background-size: 0 var(--qodef-underline-height);
  transition: background-size 0.5s cubic-bezier(0.25, 0.46, 0.35, 0.94),
    color 0.2s ease-out;
}

.qodef-mobile-header-navigation ul li ul li > a:hover > span {
  background-size: 100% var(--qodef-underline-height);
  background-position: 0 var(--qodef-underline-position-y);
}

.qodef-mobile-header-navigation ul li ul li.current-menu-ancestor > a > span,
.qodef-mobile-header-navigation ul li ul li.current-menu-item > a > span {
  background-size: 100% var(--qodef-underline-height);
  background-position: 0 var(--qodef-underline-position-y);
}

.qodef-mobile-header-navigation ul li.qodef--opened > .qodef-menu-item-arrow {
  transform: rotate(90deg);
}

.qodef-mobile-header-navigation > ul {
  margin: 0 auto;
  padding: 34px 0;
}

.qodef-mobile-header-navigation > ul > li > a {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 20px;
  line-height: 1.25em;
  font-weight: 400;
  font-family: "Nunito Sans", sans-serif;
  text-transform: lowercase;
}

@media only screen and (max-width: 1440px) {
  .qodef-mobile-header-navigation > ul > li > a {
    font-size: 18px;
    line-height: 1.27778em;
  }
}

.qodef-mobile-header-navigation > ul > li > a > span {
  position: relative;
  padding-left: 7px;
}

.qodef-mobile-header-navigation > ul > li > a > span::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 100%;
  transform-origin: bottom;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.35, 0.94),
    color 0.2s ease-out;
  transform: scaleY(0);
  background-color: currentColor;
}

.qodef-mobile-header-navigation > ul > li > a > span {
  padding-left: 0;
}

.qodef-mobile-header-navigation > ul > li > a > span::after {
  left: -7px;
}

.qodef-mobile-header-navigation > ul > li > a:hover {
  color: var(--qode-main-color);
}

.qodef-mobile-header-navigation > ul > li > a:hover > span::after {
  transform: scaleY(1);
  background-color: var(--qode-main-color);
}

.qodef-mobile-header-navigation > ul > li.current-menu-ancestor > a,
.qodef-mobile-header-navigation > ul > li.current-menu-item > a {
  color: var(--qode-main-color);
}

.qodef-mobile-header-navigation
  > ul
  > li.current-menu-ancestor
  > a
  > span::after,
.qodef-mobile-header-navigation > ul > li.current-menu-item > a > span::after {
  transform: scaleY(1);
  background-color: var(--qode-main-color);
}

.qodef-mobile-header-navigation > ul:not(.qodef-content-grid) {
  padding-left: 7%;
  padding-right: 7%;
}

.qodef-header-navigation {
  height: 100%;
}

.qodef-header-navigation ul {
  margin: 0 !important;
  padding: 0;
  list-style: none;
}

.qodef-header-navigation ul li {
  position: relative;
  margin: 0;
  padding: 0;
}

.qodef-header-navigation ul li a .qodef-menu-item-arrow {
  position: absolute;
  top: calc(50% - 4px);
  right: 0;
  width: 6px;
  height: auto;
}

.qodef-header-navigation ul li.qodef--hide-link > a {
  cursor: default;
}

.qodef-header-navigation ul li.qodef-menu-item--narrow ul {
  position: absolute;
  top: 100%;
  left: -27px;
  width: 320px;
  margin: 0;
  border: 1px solid #e3e3e3;
  padding: 24px 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out;
}

.qodef-header-navigation ul li.qodef-menu-item--narrow ul li {
  padding: 0 35px;
}

.qodef-header-navigation
  ul
  li.qodef-menu-item--narrow
  ul
  li.menu-item-has-children:hover
  > ul {
  opacity: 1;
  visibility: visible;
}

.qodef-header-navigation ul li.qodef-menu-item--narrow ul ul {
  top: calc(-24px - 9px);
  left: 100%;
}

.qodef-header-navigation
  ul
  li.qodef-menu-item--narrow
  ul.qodef-drop-down--right {
  left: auto;
  right: -35px;
  text-align: right;
}

.qodef-header-navigation
  ul
  li.qodef-menu-item--narrow
  ul.qodef-drop-down--right
  li
  a
  .qodef-menu-item-arrow {
  left: 0;
  right: auto;
  padding-bottom: 1px;
  transform: rotate(180deg);
}

.qodef-header-navigation
  ul
  li.qodef-menu-item--narrow
  ul.qodef-drop-down--right
  ul {
  left: calc(-100% - 2px);
}

.qodef-header-navigation ul li.qodef-menu-item--narrow:hover > ul {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-out;
}

.qodef-header-navigation > ul {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  white-space: nowrap;
}

.qodef-header-navigation > ul > li {
  height: 100%;
  margin: 0 22px;
}

.qodef-header-navigation > ul > li:first-child {
  margin-left: 0;
}

.qodef-header-navigation > ul > li:last-child {
  margin-right: 0;
}

.qodef-header-navigation > ul > li > a {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 20px;
  line-height: 1.25em;
  font-weight: 400;
  font-family: "Nunito Sans", sans-serif;
  text-transform: lowercase;
}

@media only screen and (max-width: 1440px) {
  .qodef-header-navigation > ul > li > a {
    font-size: 18px;
    line-height: 1.27778em;
  }
}

.qodef-header-navigation > ul > li > a > span {
  position: relative;
  padding-left: 7px;
}

.qodef-header-navigation > ul > li > a > span::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 1px;
  height: 100%;
  transform-origin: bottom;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.35, 0.94),
    color 0.2s ease-out;
  transform: scaleY(0);
  background-color: currentColor;
}

.qodef-header-navigation > ul > li > a .qodef-menu-item-arrow {
  display: none;
}

.qodef-header-navigation > ul > li > a:hover {
  color: var(--qode-main-color);
}

.qodef-header-navigation > ul > li > a:hover > span::after {
  transform: scaleY(1);
  background-color: var(--qode-main-color);
}

.qodef-header-navigation > ul > li.current-menu-ancestor > a,
.qodef-header-navigation > ul > li.current-menu-item > a {
  color: var(--qode-main-color);
}

.qodef-header-navigation > ul > li.current-menu-ancestor > a > span::after,
.qodef-header-navigation > ul > li.current-menu-item > a > span::after {
  transform: scaleY(1);
  background-color: var(--qode-main-color);
}

.qodef-header-navigation > ul > li.qodef-menu-item--narrow ul li > a {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6875em;
  font-weight: 400;
  color: #000;
}

.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul:not(.qodef-drop-down--right)
  li
  > a {
  position: relative;
  display: flex;
  white-space: normal;
  margin: 8px 0;
  overflow: hidden;
}

.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul:not(.qodef-drop-down--right)
  li
  > a
  .qodef-menu-item-icon {
  flex-shrink: 0;
}

.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul:not(.qodef-drop-down--right)
  li
  > a
  > span {
  --qodef-underline-position-y: calc(100% - 3px);
  --qodef-underline-height: 1px;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
  background-position: 100% var(--qodef-underline-position-y);
  background-size: 0 var(--qodef-underline-height);
  transition: background-size 0.5s cubic-bezier(0.25, 0.46, 0.35, 0.94),
    color 0.2s ease-out;
}

.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul:not(.qodef-drop-down--right)
  li.current-menu-ancestor
  > a
  > span,
.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul:not(.qodef-drop-down--right)
  li.current-menu-item
  > a
  > span,
.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul:not(.qodef-drop-down--right)
  li:hover
  > a
  > span {
  background-size: 100% var(--qodef-underline-height);
  background-position: 0 var(--qodef-underline-position-y);
}

.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul.qodef-drop-down--right
  li
  > a {
  position: relative;
  display: flex;
  white-space: normal;
  margin: 8px 0;
  overflow: hidden;
  justify-content: flex-end;
}

.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul.qodef-drop-down--right
  li
  > a
  .qodef-menu-item-icon {
  flex-shrink: 0;
}

.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul.qodef-drop-down--right
  li
  > a
  > span {
  --qodef-underline-position-y: calc(100% - 3px);
  --qodef-underline-height: 1px;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
  background-position: 100% var(--qodef-underline-position-y);
  background-size: 0 var(--qodef-underline-height);
  transition: background-size 0.5s cubic-bezier(0.25, 0.46, 0.35, 0.94),
    color 0.2s ease-out;
}

.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul.qodef-drop-down--right
  li.current-menu-ancestor
  > a
  > span,
.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul.qodef-drop-down--right
  li.current-menu-item
  > a
  > span,
.qodef-header-navigation
  > ul
  > li.qodef-menu-item--narrow
  ul.qodef-drop-down--right
  li:hover
  > a
  > span {
  background-size: 100% var(--qodef-underline-height);
  background-position: 0 var(--qodef-underline-position-y);
}

.qodef-m-pagination {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: 0;
}

.qodef-m-pagination.qodef--wp .nav-links {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qodef-m-pagination.qodef--wp .page-numbers {
  position: relative;
  margin: 0;
  padding: 0 20px;
}

.qodef-m-pagination.qodef--wp .page-numbers * {
  display: block;
  line-height: inherit;
}

.qodef-m-pagination.qodef--wp .page-numbers:first-child {
  margin-left: 0;
}

.qodef-m-pagination.qodef--wp .page-numbers:last-child {
  margin-right: 0;
}

.qodef-m-pagination.qodef--wp .page-numbers::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #b5b5b5;
}

.qodef-m-pagination.qodef--wp .page-numbers::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: var(--qode-main-color);
  transform-origin: right;
  transform: scaleX(0);
  z-index: 2;
  transition: transform 0.5s;
}

.qodef-m-pagination.qodef--wp .page-numbers.next::after,
.qodef-m-pagination.qodef--wp .page-numbers.prev::after,
.qodef-m-pagination.qodef--wp .page-numbers.qodef--next::after,
.qodef-m-pagination.qodef--wp .page-numbers.qodef--prev::after {
  display: none;
}

.qodef-m-pagination.qodef--wp .page-numbers.next,
.qodef-m-pagination.qodef--wp .page-numbers.prev {
  padding: 0;
}

.qodef-m-pagination.qodef--wp .page-numbers.next:before,
.qodef-m-pagination.qodef--wp .page-numbers.prev:before {
  display: none;
}

.qodef-m-pagination.qodef--wp .page-numbers.next svg,
.qodef-m-pagination.qodef--wp .page-numbers.prev svg {
  position: relative;
  top: 0;
  display: block;
  width: 32px;
  height: auto;
}

.qodef-m-pagination.qodef--wp .page-numbers.next:hover svg path:nth-of-type(2),
.qodef-m-pagination.qodef--wp .page-numbers.prev:hover svg path:nth-of-type(2) {
  animation: qode-draw-line 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.qodef-m-pagination.qodef--wp .page-numbers.prev {
  margin-right: 40px;
}

.qodef-m-pagination.qodef--wp .page-numbers.next {
  margin-left: 40px;
}

.qodef-m-pagination.qodef--wp .page-numbers:hover {
  color: #b5b5b5;
}

.qodef-m-pagination.qodef--wp .page-numbers.current,
.qodef-m-pagination.qodef--wp .page-numbers:hover {
  color: var(--qode-main-color);
}

.qodef-m-pagination.qodef--wp .page-numbers.current::before,
.qodef-m-pagination.qodef--wp .page-numbers:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-items {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item {
  position: relative;
  margin: 0;
  padding: 0 20px;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item * {
  display: block;
  line-height: inherit;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item:first-child {
  margin-left: 0;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item:last-child {
  margin-right: 0;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: #b5b5b5;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: var(--qode-main-color);
  transform-origin: right;
  transform: scaleX(0);
  z-index: 2;
  transition: transform 0.5s;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.next::after,
.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.prev::after,
.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--next::after,
.qodef-m-pagination.qodef--standard
  .qodef-m-pagination-item.qodef--prev::after {
  display: none;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--next,
.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--prev {
  padding: 0;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--next:before,
.qodef-m-pagination.qodef--standard
  .qodef-m-pagination-item.qodef--prev:before {
  display: none;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--next svg,
.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--prev svg {
  position: relative;
  top: 0;
  display: block;
  width: 32px;
  height: auto;
}

.qodef-m-pagination.qodef--standard
  .qodef-m-pagination-item.qodef--next:hover
  svg
  path:nth-of-type(2),
.qodef-m-pagination.qodef--standard
  .qodef-m-pagination-item.qodef--prev:hover
  svg
  path:nth-of-type(2) {
  animation: qode-draw-line 0.7s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--prev {
  margin-right: 40px;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--next {
  margin-left: 40px;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item:hover {
  color: #b5b5b5;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--active,
.qodef-m-pagination.qodef--standard .qodef-m-pagination-item:hover {
  color: var(--qode-main-color);
}

.qodef-m-pagination.qodef--standard
  .qodef-m-pagination-item.qodef--active::before,
.qodef-m-pagination.qodef--standard .qodef-m-pagination-item:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.qodef-m-pagination.qodef--standard
  .qodef-m-pagination-item.qodef-prev--hidden {
  margin-left: 0;
}

.qodef-m-pagination.qodef--standard .qodef-m-pagination-item.qodef--hide {
  display: none;
}

.qodef-m-pagination.qodef--load-more {
  text-align: center;
}

.qodef-m-pagination.qodef--load-more .qodef-m-pagination-inner {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.qodef-m-pagination.qodef--load-more .qodef-load-more-button {
  opacity: 1;
}

.qodef-m-pagination.qodef--infinite-scroll {
  text-align: center;
}

.qodef-filter-pagination-spinner,
.qodef-pagination--on .qodef-m-pagination-spinner {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 32px;
  height: auto;
  visibility: hidden;
  z-index: -1;
  animation: qode-rotate 2s infinite linear;
}

.qodef-filter-pagination-spinner {
  position: absolute;
  bottom: calc(50% - 32px / 2);
  left: calc(50% - 32px / 2);
}

.qodef--loading .qodef-filter-pagination-spinner {
  visibility: visible;
  z-index: 1;
}

.qodef-pagination--on.qodef-pagination-type--standard .qodef-grid-inner {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}

.qodef-pagination--on.qodef-pagination-type--standard
  .qodef-m-pagination-spinner {
  position: absolute;
  bottom: calc(50% - 32px / 2);
  left: calc(50% - 32px / 2);
}

.qodef-pagination--on.qodef-pagination-type--standard.qodef--loading
  .qodef-grid-inner {
  opacity: 0;
}

.qodef-pagination--on.qodef-pagination-type--standard.qodef--loading
  .qodef-m-pagination-inner {
  opacity: 0;
}

.qodef-pagination--on.qodef-pagination-type--load-more
  .qodef-m-pagination-spinner {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 32px / 2);
}

.qodef-pagination--on.qodef-pagination-type--load-more.qodef--loading
  .qodef-load-more-button {
  opacity: 0;
}

.qodef-pagination--on.qodef--loading .qodef-m-pagination-spinner {
  visibility: visible;
  z-index: 1;
}

.qodef-single-links {
  position: relative;
  display: flex;
  align-items: baseline;
  width: 100%;
  margin: 15px 0;
}

.qodef-single-links .qodef-m-single-links-title {
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: "DM Sans", sans-serif;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: #000;
  margin-right: 10px;
}

.qodef-single-links a,
.qodef-single-links > span:not(.qodef-m-single-links-title) {
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: "DM Sans", sans-serif;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: #000;
  margin-right: 8px;
  padding: 0 3px;
}

.qodef-single-links a:last-child,
.qodef-single-links > span:not(.qodef-m-single-links-title):last-child {
  margin-right: 0;
}

.qodef-single-links > span:not(.qodef-m-single-links-title) {
  color: var(--qode-main-color);
}

.qodef-search {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin: 0 0 50px;
}

.qodef-search .qodef-e-search-heading {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-bottom: 60px;
}

.qodef-search .qodef-e-search-heading .qodef-e-search-heading-title {
  margin: 0 0 20px;
}

.qodef-search .qodef-e-search-heading .qodef-e-search-heading-label {
  margin: 8px 0 0;
}

.qodef-search .qodef-search-form-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.qodef-search .qodef-search-form-field {
  margin: 0;
  padding-right: 50px;
}

.qodef-search .qodef-search-form-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  padding: 0;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background-color: transparent;
  color: #000;
}

.qodef-search .qodef-search-form-button:after {
  display: none;
}

.qodef-search .qodef-search-form-button:hover {
  color: #787878;
}

.qodef-search .qodef-search-form-button svg {
  display: block;
  width: 19px;
  height: auto;
}

.qodef-search .qodef-search-form-button:hover {
  background-color: transparent;
}

.qodef-search article {
  margin-bottom: 30px;
}

.qodef-search article:last-child {
  margin-bottom: 0;
}

.qodef-search article .qodef-e-inner {
  align-items: flex-start;
  display: flex;
  position: relative;
}

.qodef-search article .qodef-e-inner > * {
  width: 100%;
}

.qodef-search article .qodef-e-image {
  flex-shrink: 0;
  flex-basis: 180px;
  padding-right: 30px;
  width: auto;
}

.qodef-search article .qodef-e-title {
  margin: 0;
}

.qodef-search article .qodef-e-excerpt {
  margin: 15px 0 0;
}

body.search .qodef-page-title {
  background-color: transparent;
}

body.search .qodef-page-title.qodef-title--standard .qodef-m-title {
  margin-bottom: 0;
}

#qodef-page-sidebar {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  margin-bottom: 60px;
}

.qodef-page-sidebar-section.qodef-col--3 #qodef-page-sidebar {
  padding-left: 18%;
  padding-right: 0;
}

.qodef-page-sidebar-section.qodef-col--3.qodef-col-pull--9 #qodef-page-sidebar {
  padding-left: 0;
  padding-right: 18%;
}

.qodef-page-sidebar-section.qodef-col--4 #qodef-page-sidebar {
  padding-left: 28%;
  padding-right: 0;
}

.qodef-page-sidebar-section.qodef-col--4.qodef-col-pull--8 #qodef-page-sidebar {
  padding-left: 0;
  padding-right: 28%;
}

.qodef-svg--menu {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
  stroke-width: 3.5;
  overflow: visible;
}

.qodef-svg--menu-close {
  fill: none;
  stroke: currentColor;
  stroke-miterlimit: 10;
}

.qodef-svg--search {
  fill: none;
  stroke: currentColor;
}

.qodef-svg--star {
  fill: none;
  stroke: currentColor;
}

.qodef-svg--menu-arrow {
  fill: currentColor;
  stroke: none;
}

.qodef-svg--menu-back-arrow {
  fill: currentColor;
  stroke: none;
}

.qodef-svg--slider-arrow-left path:nth-of-type(1),
.qodef-svg--slider-arrow-right path:nth-of-type(1) {
  fill: currentColor;
  stroke: none;
}

.qodef-svg--slider-arrow-left path:nth-of-type(2),
.qodef-svg--slider-arrow-right path:nth-of-type(2) {
  fill: none;
  stroke: currentColor;
}

.qodef-svg--pagination-arrow-left,
.qodef-svg--slider-arrow-left {
  transform: scaleX(-1);
}

.qodef-svg--pagination-arrow-left path:nth-of-type(1),
.qodef-svg--pagination-arrow-right path:nth-of-type(1) {
  fill: currentColor;
  stroke: none;
}

.qodef-svg--pagination-arrow-left path:nth-of-type(2),
.qodef-svg--pagination-arrow-right path:nth-of-type(2) {
  fill: none;
  stroke: currentColor;
}

.qodef-svg--close {
  fill: currentColor;
  stroke: none;
  stroke-miterlimit: 10;
}

.qodef-svg--spinner {
  fill: var(--qode-main-color);
  stroke: none;
}

.qodef-svg--link {
  fill: none;
  stroke: #484848;
}

.qodef-svg--drag-cursor circle {
  stroke: #fff;
  fill: none;
}

.qodef-svg--drag-cursor rect {
  fill: #fff;
}

.qodef-svg--drag-cursor polygon {
  fill: #fff;
}

.qodef-svg--play {
  fill-rule: evenodd;
  fill: #fff;
}

.qodef-page-title {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: 240px;
  background-color: #fafafa;
}

.qodef-page-title .qodef-m-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  vertical-align: top;
  height: 100%;
}

.qodef-page-title .qodef-m-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.qodef-page-title .qodef-m-title {
  margin: 0;
}

.widget:last-child {
  margin-bottom: 0 !important;
}

.widget .qodef-widget-title a {
  color: inherit;
}

.widget ol:not(.qodef-shortcode-list):not(.select2-selection__rendered),
.widget ul:not(.qodef-shortcode-list):not(.select2-selection__rendered) {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget
  ol:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li:last-child,
.widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li:last-child {
  margin-bottom: 0 !important;
}

.widget ol:not(.qodef-shortcode-list):not(.select2-selection__rendered) li ul,
.widget ul:not(.qodef-shortcode-list):not(.select2-selection__rendered) li ul {
  margin-left: 1em;
}

.widget select {
  max-width: 100%;
  width: 100%;
}

.widget .select2 {
  margin-bottom: 0;
}

.widget .wp-caption {
  color: inherit;
}

.widget .qodef-search-form-inner {
  align-items: stretch;
  display: flex;
  max-width: 100%;
  position: relative;
}

.widget .qodef-search-form-field {
  margin: 0;
  letter-spacing: 0.2em;
}

.widget .qodef-search-form-field:-ms-input-placeholder {
  color: #b5b5b5;
}

.widget .qodef-search-form-field::placeholder {
  color: #b5b5b5;
}

.widget .qodef-search-form-button {
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  padding: 0 1em;
}

.widget .qodef-search-form-button.qodef--has-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 100%;
  padding: 0;
  border: 0;
  height: initial;
  padding: 0;
}

.widget .qodef-search-form-button.qodef--has-icon:after {
  display: none;
}

.widget .qodef-search-form-button.qodef--has-icon:hover {
  color: #787878;
}

.widget .qodef-search-form-button.qodef--has-icon svg {
  display: block;
  width: 19px;
  height: auto;
}

.widget .qodef-search-form-button.qodef--button-outside {
  margin-left: 1em;
}

.widget .qodef-search-form-button.qodef--button-inside {
  background-color: transparent !important;
  border: none !important;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.widget .tagcloud,
.widget .wp-block-tag-cloud {
  margin: 0 0 -5px;
}

.widget .tagcloud a,
.widget .wp-block-tag-cloud a {
  padding: 4px 8px;
  margin: 0 5px 5px 0;
  border: 1px solid #d8d8d8;
  background-color: transparent;
  color: var(--qode-main-color);
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  text-transform: lowercase;
  letter-spacing: 0;
  transition: color 0.2s ease-out, border-color 0.35s ease-out,
    background-color 0.2s ease-out;
  white-space: nowrap;
  position: relative;
  font-size: 12px !important;
}

.widget .tagcloud a:hover,
.widget .wp-block-tag-cloud a:hover {
  border-color: var(--qode-main-color);
}

.widget .wp-block-calendar,
.widget.widget_calendar {
  width: 100%;
}

.widget .wp-block-calendar table,
.widget.widget_calendar table {
  table-layout: fixed;
}

.widget .wp-block-calendar caption,
.widget.widget_calendar caption {
  margin-bottom: 5px;
}

.widget .wp-block-calendar th,
.widget.widget_calendar th {
  background-color: transparent;
  font-weight: 600;
  padding: 5px 10px;
}

.widget .wp-block-calendar td,
.widget.widget_calendar td {
  padding: 5px 10px;
  text-align: center;
}

.widget .wp-block-calendar #today a,
.widget.widget_calendar #today a {
  color: inherit;
}

.widget .wp-block-calendar a,
.widget.widget_calendar a {
  text-decoration: none;
}

.widget .wp-block-calendar .wp-calendar-nav,
.widget.widget_calendar .wp-calendar-nav {
  margin: 5px 0 0 0;
  text-align: left;
}

.widget.widget_media_image {
  margin-bottom: 0;
}

.widget.widget_media_image a {
  display: inline-block;
  vertical-align: top;
}

.widget.widget_media_image img {
  display: block;
}

.widget.widget_custom_html {
  margin-bottom: 0;
}

.widget.widget_text img {
  display: block;
}

.widget.widget_nav_menu .qodef-menu-item-arrow {
  display: none;
}

.widget.widget_rss ul li {
  margin-bottom: 35px !important;
}

.widget.widget_rss ul .rsswidget {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.25em;
  font-weight: 400;
  display: block;
}

@media only screen and (max-width: 1440px) {
  .widget.widget_rss ul .rsswidget {
    font-size: 18px;
    line-height: 1.27778em;
  }
}

.widget.widget_rss ul .rss-date {
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-family: "DM Sans", sans-serif;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  color: #000;
  margin-top: 8px;
}

.widget.widget_rss ul .rssSummary {
  margin-top: 15px;
}

.widget.widget_rss ul cite {
  display: block;
  margin-top: 12px;
}

.widget.widget_level_core_title_widget {
  margin: 0;
}

.widget.widget_block .wp-block-columns,
.widget.widget_block .wp-block-group {
  margin: 0;
}

.widget.widget_block .wp-block-social-link {
  margin: 4px 8px 4px 0 !important;
}

.widget.widget_block .wp-block-social-link:last-child {
  margin-bottom: 4px !important;
}

.widget.widget_block .wp-block-latest-posts__featured-image {
  margin-bottom: 0.5em;
}

.widget.widget_block .wp-block-latest-posts__post-author,
.widget.widget_block .wp-block-latest-posts__post-date {
  color: inherit;
}

.widget.widget_block .wp-block-rss__item-author,
.widget.widget_block .wp-block-rss__item-publish-date {
  color: inherit;
}

.widget.widget_block .wp-block-button .wp-block-button__link {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  line-height: 1.6875em;
  font-weight: 400;
  color: #000;
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  width: auto;
  margin: 0;
  text-decoration: none;
  text-transform: lowercase;
  border-radius: 0;
  outline: 0;
  transition: color 0.2s ease-out, background-color 0.2s ease-out,
    border-color 0.2s ease-out;
  padding: 9px 68px;
}

#qodef-page-sidebar .widget.widget_block .wp-block-categories {
  margin-bottom: -10px;
}

.widget.widget_block .wp-block-categories .cat-item a {
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color: #000;
  font-size: 12px;
  line-height: 1.16667em;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  display: block;
  line-height: 27px;
  margin: 0 0 8px;
  position: relative;
  vertical-align: top;
}

.widget.widget_block .wp-block-categories .cat-item:last-child a {
  margin: 0;
}

.widget.widget_block .wp-block-gallery {
  gap: 0;
}

#qodef-page-sidebar .widget.widget_block .wp-block-gallery {
  margin-bottom: 11px !important;
}

#qodef-page-sidebar
  .widget.widget_block
  .wp-block-gallery
  .wp-block-image::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  transition: 0.2s ease-out;
  z-index: 10;
  pointer-events: none;
}

#qodef-page-sidebar .widget.widget_block .wp-block-gallery .wp-block-image img {
  z-index: 1;
}

#qodef-page-sidebar
  .widget.widget_block
  .wp-block-gallery
  .wp-block-image:hover::before {
  opacity: 0.5;
}

#qodef-page-sidebar
  .widget.widget_block
  .wp-block-gallery
  .wp-block-image:hover::after {
  opacity: 1;
}

.widget h1:not([class*="qodef"]),
.widget h2:not([class*="qodef"]),
.widget h3:not([class*="qodef"]),
.widget h4:not([class*="qodef"]),
.widget h5:not([class*="qodef"]),
.widget h6:not([class*="qodef"]) {
  margin: 0;
}

#qodef-page-sidebar .widget.widget_search .wp-block-search {
  margin-top: -15px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 768px) {
  #qodef-page-sidebar .widget.widget_search .wp-block-search {
    margin-top: 0 !important;
  }
}

#qodef-page-sidebar .widget.widget_search:first-child {
  margin-top: -8px;
}

#qodef-page-sidebar .widget.widget_search:first-child .wp-block-search {
  margin-bottom: 0;
}

#qodef-page-sidebar .widget.widget_level_core_simple_blog_list {
  margin-bottom: 8px !important;
}

.widget:not(.widget_level_core_title_widget) {
  margin: 0 0 35px;
}

.widget .qodef-widget-title {
  margin: 0 0 21px;
}

.widget .qodef-widget-title a:hover {
  color: var(--qode-main-color);
}

.widget a {
  color: #000;
}

.widget a:hover {
  color: var(--qode-main-color);
}

.widget ul:not(.qodef-shortcode-list):not(.select2-selection__rendered) li {
  margin: 0 0 10px;
}

.widget ul:not(.qodef-shortcode-list):not(.select2-selection__rendered) li ul {
  margin-top: 10px;
}

.widget .select2-container--default .select2-selection--multiple,
.widget .select2-container--default .select2-selection--single {
  padding-left: 10px;
}

.widget[class*="_search"] .qodef-widget-title {
  margin-bottom: 29.5px;
}

.widget .qodef-search-form-button.qodef--button-inside {
  color: #000;
}

.widget .qodef-search-form-button.qodef--button-inside:hover {
  color: #000;
}

.widget .tagcloud a,
.widget .wp-block-tag-cloud a {
  color: #000;
}

.widget .tagcloud a:hover,
.widget .wp-block-tag-cloud a:hover {
  color: var(--qode-main-color);
}

.widget .wp-block-calendar #today,
.widget.widget_calendar #today {
  color: #fff;
  background-color: var(--qode-main-color);
}

.widget .wp-block-calendar .wp-calendar-nav a,
.widget.widget_calendar .wp-calendar-nav a {
  color: #000;
}

.widget .wp-block-calendar .wp-calendar-nav a:hover,
.widget.widget_calendar .wp-calendar-nav a:hover {
  color: var(--qode-main-color);
}

.widget .wp-block-gallery,
.widget.widget_media_gallery {
  margin-bottom: 30px !important;
}

.widget .wp-block-gallery .blocks-gallery-grid,
.widget .wp-block-gallery .gallery,
.widget.widget_media_gallery .blocks-gallery-grid,
.widget.widget_media_gallery .gallery {
  margin: 0 -5px !important;
}

.widget .wp-block-gallery .blocks-gallery-item,
.widget .wp-block-gallery .gallery-item,
.widget.widget_media_gallery .blocks-gallery-item,
.widget.widget_media_gallery .gallery-item {
  margin: 0 0 10px 0 !important;
  padding: 0 5px;
}

.widget.widget_media_image .qodef-widget-title {
  margin-bottom: 29.5px;
}

.widget.widget_block .wp-block-columns h1:not([class*="qodef"]),
.widget.widget_block .wp-block-columns h2:not([class*="qodef"]),
.widget.widget_block .wp-block-columns h3:not([class*="qodef"]),
.widget.widget_block .wp-block-columns h4:not([class*="qodef"]),
.widget.widget_block .wp-block-columns h5:not([class*="qodef"]),
.widget.widget_block .wp-block-columns h6:not([class*="qodef"]),
.widget.widget_block .wp-block-group h1:not([class*="qodef"]),
.widget.widget_block .wp-block-group h2:not([class*="qodef"]),
.widget.widget_block .wp-block-group h3:not([class*="qodef"]),
.widget.widget_block .wp-block-group h4:not([class*="qodef"]),
.widget.widget_block .wp-block-group h5:not([class*="qodef"]),
.widget.widget_block .wp-block-group h6:not([class*="qodef"]) {
  margin: 0 0 21px;
}

#qodef-top-area .widget:not(.widget_level_core_title_widget) {
  margin: 0 0 0;
  color: #fff;
}

#qodef-top-area
  .widget:not(.widget_level_core_title_widget)
  h1:not([class*="qodef"]),
#qodef-top-area
  .widget:not(.widget_level_core_title_widget)
  h2:not([class*="qodef"]),
#qodef-top-area
  .widget:not(.widget_level_core_title_widget)
  h3:not([class*="qodef"]),
#qodef-top-area
  .widget:not(.widget_level_core_title_widget)
  h4:not([class*="qodef"]),
#qodef-top-area
  .widget:not(.widget_level_core_title_widget)
  h5:not([class*="qodef"]),
#qodef-top-area
  .widget:not(.widget_level_core_title_widget)
  h6:not([class*="qodef"]) {
  color: #fff;
}

#qodef-top-area .widget .qodef-widget-title {
  margin: 0 0 21px;
  color: #fff;
}

#qodef-top-area .widget .qodef-widget-title a:hover {
  color: var(--qode-main-color);
}

#qodef-top-area .widget a {
  color: #fff;
}

#qodef-top-area .widget a:hover {
  color: var(--qode-main-color);
}

#qodef-top-area
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li {
  margin: 0 0 10px;
}

#qodef-top-area
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li
  ul {
  margin-top: 10px;
}

#qodef-top-area
  .widget
  .select2-container--default
  .select2-selection--multiple,
#qodef-top-area .widget .select2-container--default .select2-selection--single {
  padding-left: 10px;
}

#qodef-top-area .widget[class*="_search"] .qodef-widget-title {
  margin-bottom: 29.5px;
}

#qodef-top-area .widget .qodef-search-form-button.qodef--button-inside {
  color: #000;
}

#qodef-top-area .widget .qodef-search-form-button.qodef--button-inside:hover {
  color: #000;
}

#qodef-top-area .widget .tagcloud a,
#qodef-top-area .widget .wp-block-tag-cloud a {
  color: #fff;
}

#qodef-top-area .widget .tagcloud a:hover,
#qodef-top-area .widget .wp-block-tag-cloud a:hover {
  color: var(--qode-main-color);
}

#qodef-top-area .widget .wp-block-calendar caption,
#qodef-top-area .widget.widget_calendar caption {
  color: #fff;
}

#qodef-top-area .widget .wp-block-calendar tbody,
#qodef-top-area .widget.widget_calendar tbody {
  color: #fff;
}

#qodef-top-area .widget .wp-block-calendar #today,
#qodef-top-area .widget.widget_calendar #today {
  color: #fff;
  background-color: var(--qode-main-color);
}

#qodef-top-area .widget .wp-block-calendar .wp-calendar-nav a,
#qodef-top-area .widget.widget_calendar .wp-calendar-nav a {
  color: #fff;
}

#qodef-top-area .widget .wp-block-calendar .wp-calendar-nav a:hover,
#qodef-top-area .widget.widget_calendar .wp-calendar-nav a:hover {
  color: var(--qode-main-color);
}

#qodef-top-area .widget .wp-block-gallery,
#qodef-top-area .widget.widget_media_gallery {
  margin-bottom: -5px !important;
}

#qodef-top-area .widget .wp-block-gallery .blocks-gallery-grid,
#qodef-top-area .widget .wp-block-gallery .gallery,
#qodef-top-area .widget.widget_media_gallery .blocks-gallery-grid,
#qodef-top-area .widget.widget_media_gallery .gallery {
  margin: 0 -5px !important;
}

#qodef-top-area .widget .wp-block-gallery .blocks-gallery-item,
#qodef-top-area .widget .wp-block-gallery .gallery-item,
#qodef-top-area .widget.widget_media_gallery .blocks-gallery-item,
#qodef-top-area .widget.widget_media_gallery .gallery-item {
  margin: 0 0 10px 0 !important;
  padding: 0 5px;
}

#qodef-top-area .widget.widget_media_image .qodef-widget-title {
  margin-bottom: 29.5px;
}

#qodef-top-area .widget.widget_block .wp-block-columns h1:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-columns h2:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-columns h3:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-columns h4:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-columns h5:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-columns h6:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-group h1:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-group h2:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-group h3:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-group h4:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-group h5:not([class*="qodef"]),
#qodef-top-area .widget.widget_block .wp-block-group h6:not([class*="qodef"]) {
  margin: 0 0 21px;
}

#qodef-page-footer .widget:not(.widget_level_core_title_widget) {
  margin: 0 0 35px;
  color: #000;
}

#qodef-page-footer
  .widget:not(.widget_level_core_title_widget)
  h1:not([class*="qodef"]),
#qodef-page-footer
  .widget:not(.widget_level_core_title_widget)
  h2:not([class*="qodef"]),
#qodef-page-footer
  .widget:not(.widget_level_core_title_widget)
  h3:not([class*="qodef"]),
#qodef-page-footer
  .widget:not(.widget_level_core_title_widget)
  h4:not([class*="qodef"]),
#qodef-page-footer
  .widget:not(.widget_level_core_title_widget)
  h5:not([class*="qodef"]),
#qodef-page-footer
  .widget:not(.widget_level_core_title_widget)
  h6:not([class*="qodef"]) {
  color: #0a121f;
}

#qodef-page-footer .widget .qodef-widget-title {
  margin: 0 0 32px;
  color: #0a121f;
}

#qodef-page-footer .widget .qodef-widget-title a:hover {
  color: #000;
}

#qodef-page-footer .widget a {
  color: #000;
}

#qodef-page-footer .widget a:hover {
  color: #000;
}

#qodef-page-footer
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li {
  margin: 0 0 14px;
}

#qodef-page-footer
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li
  ul {
  margin-top: 14px;
}

#qodef-page-footer
  .widget
  .select2-container--default
  .select2-selection--multiple,
#qodef-page-footer
  .widget
  .select2-container--default
  .select2-selection--single {
  padding-left: 10px;
}

#qodef-page-footer .widget .button,
#qodef-page-footer .widget button {
  color: #000;
  background-color: transparent;
  border-color: #222;
}

#qodef-page-footer .widget .button:hover,
#qodef-page-footer .widget button:hover {
  color: #000;
  background-color: var(--qode-main-color);
  border-color: var(--qode-main-color);
}

#qodef-page-footer .widget[class*="_search"] .qodef-widget-title {
  margin-bottom: 40.5px;
}

#qodef-page-footer .widget .qodef-search-form-button.qodef--button-inside {
  color: #000;
}

#qodef-page-footer
  .widget
  .qodef-search-form-button.qodef--button-inside:hover {
  color: #000;
}

#qodef-page-footer .widget .tagcloud a,
#qodef-page-footer .widget .wp-block-tag-cloud a {
  color: #000;
}

#qodef-page-footer .widget .tagcloud a:hover,
#qodef-page-footer .widget .wp-block-tag-cloud a:hover {
  color: #000;
}

#qodef-page-footer .widget .wp-block-calendar caption,
#qodef-page-footer .widget.widget_calendar caption {
  color: #000;
}

#qodef-page-footer .widget .wp-block-calendar tbody,
#qodef-page-footer .widget.widget_calendar tbody {
  color: #000;
}

#qodef-page-footer .widget .wp-block-calendar td,
#qodef-page-footer .widget .wp-block-calendar th,
#qodef-page-footer .widget .wp-block-calendar tr,
#qodef-page-footer .widget.widget_calendar td,
#qodef-page-footer .widget.widget_calendar th,
#qodef-page-footer .widget.widget_calendar tr {
  border-color: #000;
}

#qodef-page-footer .widget .wp-block-calendar #today,
#qodef-page-footer .widget.widget_calendar #today {
  color: #fff;
  background-color: #000;
}

#qodef-page-footer .widget .wp-block-calendar .wp-calendar-nav a,
#qodef-page-footer .widget.widget_calendar .wp-calendar-nav a {
  color: #000;
}

#qodef-page-footer .widget .wp-block-calendar .wp-calendar-nav a:hover,
#qodef-page-footer .widget.widget_calendar .wp-calendar-nav a:hover {
  color: #000;
}

#qodef-page-footer .widget .wp-block-gallery,
#qodef-page-footer .widget.widget_media_gallery {
  margin-bottom: 30px !important;
}

#qodef-page-footer .widget .wp-block-gallery .blocks-gallery-grid,
#qodef-page-footer .widget .wp-block-gallery .gallery,
#qodef-page-footer .widget.widget_media_gallery .blocks-gallery-grid,
#qodef-page-footer .widget.widget_media_gallery .gallery {
  margin: 0 -5px !important;
}

#qodef-page-footer .widget .wp-block-gallery .blocks-gallery-item,
#qodef-page-footer .widget .wp-block-gallery .gallery-item,
#qodef-page-footer .widget.widget_media_gallery .blocks-gallery-item,
#qodef-page-footer .widget.widget_media_gallery .gallery-item {
  margin: 0 0 10px 0 !important;
  padding: 0 5px;
}

#qodef-page-footer .widget.widget_media_image .qodef-widget-title {
  margin-bottom: 40.5px;
}

#qodef-page-footer
  .widget.widget_block
  .wp-block-columns
  h1:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-columns
  h2:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-columns
  h3:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-columns
  h4:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-columns
  h5:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-columns
  h6:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-group
  h1:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-group
  h2:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-group
  h3:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-group
  h4:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-group
  h5:not([class*="qodef"]),
#qodef-page-footer
  .widget.widget_block
  .wp-block-group
  h6:not([class*="qodef"]) {
  margin: 0 0 32px;
}

#qodef-page-footer
  .widget.widget_block
  .wp-block-button
  .wp-block-button__link {
  color: #000;
  background-color: transparent;
  border-color: #222;
}

#qodef-page-footer
  .widget.widget_block
  .wp-block-button
  .wp-block-button__link:hover {
  color: #000;
  background-color: var(--qode-main-color);
  border-color: var(--qode-main-color);
}

#qodef-side-area .widget:not(.widget_level_core_title_widget) {
  margin: 0 0 35px;
  color: #000;
}

#qodef-side-area
  .widget:not(.widget_level_core_title_widget)
  h1:not([class*="qodef"]),
#qodef-side-area
  .widget:not(.widget_level_core_title_widget)
  h2:not([class*="qodef"]),
#qodef-side-area
  .widget:not(.widget_level_core_title_widget)
  h3:not([class*="qodef"]),
#qodef-side-area
  .widget:not(.widget_level_core_title_widget)
  h4:not([class*="qodef"]),
#qodef-side-area
  .widget:not(.widget_level_core_title_widget)
  h5:not([class*="qodef"]),
#qodef-side-area
  .widget:not(.widget_level_core_title_widget)
  h6:not([class*="qodef"]) {
  color: #000;
}

#qodef-side-area .widget .qodef-widget-title {
  margin: 0 0 21px;
  color: #000;
}

#qodef-side-area .widget .qodef-widget-title a:hover {
  color: var(--qode-main-color);
}

#qodef-side-area .widget a {
  color: #000;
}

#qodef-side-area .widget a:hover {
  color: var(--qode-main-color);
}

#qodef-side-area
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li {
  margin: 0 0 10px;
}

#qodef-side-area
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li
  ul {
  margin-top: 10px;
}

#qodef-side-area
  .widget
  .select2-container--default
  .select2-selection--multiple,
#qodef-side-area
  .widget
  .select2-container--default
  .select2-selection--single {
  padding-left: 10px;
}

#qodef-side-area .widget[class*="_search"] .qodef-widget-title {
  margin-bottom: 29.5px;
}

#qodef-side-area .widget .qodef-search-form-button.qodef--button-inside {
  color: #000;
}

#qodef-side-area .widget .qodef-search-form-button.qodef--button-inside:hover {
  color: #000;
}

#qodef-side-area .widget .tagcloud a,
#qodef-side-area .widget .wp-block-tag-cloud a {
  color: #000;
}

#qodef-side-area .widget .tagcloud a:hover,
#qodef-side-area .widget .wp-block-tag-cloud a:hover {
  color: var(--qode-main-color);
}

#qodef-side-area .widget .wp-block-calendar caption,
#qodef-side-area .widget.widget_calendar caption {
  color: #000;
}

#qodef-side-area .widget .wp-block-calendar tbody,
#qodef-side-area .widget.widget_calendar tbody {
  color: #000;
}

#qodef-side-area .widget .wp-block-calendar #today,
#qodef-side-area .widget.widget_calendar #today {
  color: #fff;
  background-color: var(--qode-main-color);
}

#qodef-side-area .widget .wp-block-calendar .wp-calendar-nav a,
#qodef-side-area .widget.widget_calendar .wp-calendar-nav a {
  color: #000;
}

#qodef-side-area .widget .wp-block-calendar .wp-calendar-nav a:hover,
#qodef-side-area .widget.widget_calendar .wp-calendar-nav a:hover {
  color: var(--qode-main-color);
}

#qodef-side-area .widget .wp-block-gallery,
#qodef-side-area .widget.widget_media_gallery {
  margin-bottom: 30px !important;
}

#qodef-side-area .widget .wp-block-gallery .blocks-gallery-grid,
#qodef-side-area .widget .wp-block-gallery .gallery,
#qodef-side-area .widget.widget_media_gallery .blocks-gallery-grid,
#qodef-side-area .widget.widget_media_gallery .gallery {
  margin: 0 -5px !important;
}

#qodef-side-area .widget .wp-block-gallery .blocks-gallery-item,
#qodef-side-area .widget .wp-block-gallery .gallery-item,
#qodef-side-area .widget.widget_media_gallery .blocks-gallery-item,
#qodef-side-area .widget.widget_media_gallery .gallery-item {
  margin: 0 0 10px 0 !important;
  padding: 0 5px;
}

#qodef-side-area .widget.widget_media_image .qodef-widget-title {
  margin-bottom: 29.5px;
}

#qodef-side-area
  .widget.widget_block
  .wp-block-columns
  h1:not([class*="qodef"]),
#qodef-side-area
  .widget.widget_block
  .wp-block-columns
  h2:not([class*="qodef"]),
#qodef-side-area
  .widget.widget_block
  .wp-block-columns
  h3:not([class*="qodef"]),
#qodef-side-area
  .widget.widget_block
  .wp-block-columns
  h4:not([class*="qodef"]),
#qodef-side-area
  .widget.widget_block
  .wp-block-columns
  h5:not([class*="qodef"]),
#qodef-side-area
  .widget.widget_block
  .wp-block-columns
  h6:not([class*="qodef"]),
#qodef-side-area .widget.widget_block .wp-block-group h1:not([class*="qodef"]),
#qodef-side-area .widget.widget_block .wp-block-group h2:not([class*="qodef"]),
#qodef-side-area .widget.widget_block .wp-block-group h3:not([class*="qodef"]),
#qodef-side-area .widget.widget_block .wp-block-group h4:not([class*="qodef"]),
#qodef-side-area .widget.widget_block .wp-block-group h5:not([class*="qodef"]),
#qodef-side-area .widget.widget_block .wp-block-group h6:not([class*="qodef"]) {
  margin: 0 0 21px;
}

.qodef-mega-menu-widget-holder .widget:not(.widget_level_core_title_widget) {
  margin: 0 0 35px;
  color: #fff;
}

.qodef-mega-menu-widget-holder
  .widget:not(.widget_level_core_title_widget)
  h1:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget:not(.widget_level_core_title_widget)
  h2:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget:not(.widget_level_core_title_widget)
  h3:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget:not(.widget_level_core_title_widget)
  h4:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget:not(.widget_level_core_title_widget)
  h5:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget:not(.widget_level_core_title_widget)
  h6:not([class*="qodef"]) {
  color: #fff;
}

.qodef-mega-menu-widget-holder .widget .qodef-widget-title {
  margin: 0 0 21px;
  color: #fff;
}

.qodef-mega-menu-widget-holder .widget .qodef-widget-title a:hover {
  color: var(--qode-main-color);
}

.qodef-mega-menu-widget-holder .widget a {
  color: #fff;
}

.qodef-mega-menu-widget-holder .widget a:hover {
  color: var(--qode-main-color);
}

.qodef-mega-menu-widget-holder
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li {
  margin: 0 0 10px;
}

.qodef-mega-menu-widget-holder
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li
  ul {
  margin-top: 10px;
}

.qodef-mega-menu-widget-holder
  .widget
  .select2-container--default
  .select2-selection--multiple,
.qodef-mega-menu-widget-holder
  .widget
  .select2-container--default
  .select2-selection--single {
  padding-left: 10px;
}

.qodef-mega-menu-widget-holder .widget[class*="_search"] .qodef-widget-title {
  margin-bottom: 29.5px;
}

.qodef-mega-menu-widget-holder
  .widget
  .qodef-search-form-button.qodef--button-inside {
  color: #000;
}

.qodef-mega-menu-widget-holder
  .widget
  .qodef-search-form-button.qodef--button-inside:hover {
  color: #000;
}

.qodef-mega-menu-widget-holder .widget .tagcloud a,
.qodef-mega-menu-widget-holder .widget .wp-block-tag-cloud a {
  color: #fff;
}

.qodef-mega-menu-widget-holder .widget .tagcloud a:hover,
.qodef-mega-menu-widget-holder .widget .wp-block-tag-cloud a:hover {
  color: var(--qode-main-color);
}

.qodef-mega-menu-widget-holder .widget .wp-block-calendar caption,
.qodef-mega-menu-widget-holder .widget.widget_calendar caption {
  color: #fff;
}

.qodef-mega-menu-widget-holder .widget .wp-block-calendar tbody,
.qodef-mega-menu-widget-holder .widget.widget_calendar tbody {
  color: #fff;
}

.qodef-mega-menu-widget-holder .widget .wp-block-calendar #today,
.qodef-mega-menu-widget-holder .widget.widget_calendar #today {
  color: #fff;
  background-color: var(--qode-main-color);
}

.qodef-mega-menu-widget-holder .widget .wp-block-calendar .wp-calendar-nav a,
.qodef-mega-menu-widget-holder .widget.widget_calendar .wp-calendar-nav a {
  color: #fff;
}

.qodef-mega-menu-widget-holder
  .widget
  .wp-block-calendar
  .wp-calendar-nav
  a:hover,
.qodef-mega-menu-widget-holder
  .widget.widget_calendar
  .wp-calendar-nav
  a:hover {
  color: var(--qode-main-color);
}

.qodef-mega-menu-widget-holder .widget .wp-block-gallery,
.qodef-mega-menu-widget-holder .widget.widget_media_gallery {
  margin-bottom: 30px !important;
}

.qodef-mega-menu-widget-holder .widget .wp-block-gallery .blocks-gallery-grid,
.qodef-mega-menu-widget-holder .widget .wp-block-gallery .gallery,
.qodef-mega-menu-widget-holder
  .widget.widget_media_gallery
  .blocks-gallery-grid,
.qodef-mega-menu-widget-holder .widget.widget_media_gallery .gallery {
  margin: 0 -5px !important;
}

.qodef-mega-menu-widget-holder .widget .wp-block-gallery .blocks-gallery-item,
.qodef-mega-menu-widget-holder .widget .wp-block-gallery .gallery-item,
.qodef-mega-menu-widget-holder
  .widget.widget_media_gallery
  .blocks-gallery-item,
.qodef-mega-menu-widget-holder .widget.widget_media_gallery .gallery-item {
  margin: 0 0 10px 0 !important;
  padding: 0 5px;
}

.qodef-mega-menu-widget-holder .widget.widget_media_image .qodef-widget-title {
  margin-bottom: 29.5px;
}

.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-columns
  h1:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-columns
  h2:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-columns
  h3:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-columns
  h4:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-columns
  h5:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-columns
  h6:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-group
  h1:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-group
  h2:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-group
  h3:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-group
  h4:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-group
  h5:not([class*="qodef"]),
.qodef-mega-menu-widget-holder
  .widget.widget_block
  .wp-block-group
  h6:not([class*="qodef"]) {
  margin: 0 0 21px;
}

#qodef-page-sidebar .widget:not(.widget_level_core_title_widget) {
  margin: 0 0 79px;
}

#qodef-page-sidebar .widget .qodef-widget-title {
  margin: 0 0 26px;
}

#qodef-page-sidebar .widget .qodef-widget-title a:hover {
  color: var(--qode-main-color);
}

#qodef-page-sidebar .widget a {
  color: #000;
}

#qodef-page-sidebar .widget a:hover {
  color: var(--qode-main-color);
}

#qodef-page-sidebar
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li {
  margin: 0 0 8px;
}

#qodef-page-sidebar
  .widget
  ul:not(.qodef-shortcode-list):not(.select2-selection__rendered)
  li
  ul {
  margin-top: 8px;
}

#qodef-page-sidebar
  .widget
  .select2-container--default
  .select2-selection--multiple,
#qodef-page-sidebar
  .widget
  .select2-container--default
  .select2-selection--single {
  padding-left: 10px;
}

#qodef-page-sidebar .widget[class*="_search"] .qodef-widget-title {
  margin-bottom: 34.5px;
}

#qodef-page-sidebar .widget .qodef-search-form-button.qodef--button-inside {
  color: #000;
}

#qodef-page-sidebar
  .widget
  .qodef-search-form-button.qodef--button-inside:hover {
  color: #000;
}

#qodef-page-sidebar .widget .tagcloud a,
#qodef-page-sidebar .widget .wp-block-tag-cloud a {
  color: #000;
}

#qodef-page-sidebar .widget .tagcloud a:hover,
#qodef-page-sidebar .widget .wp-block-tag-cloud a:hover {
  color: var(--qode-main-color);
}

#qodef-page-sidebar .widget .wp-block-calendar #today,
#qodef-page-sidebar .widget.widget_calendar #today {
  color: #fff;
  background-color: var(--qode-main-color);
}

#qodef-page-sidebar .widget .wp-block-calendar .wp-calendar-nav a,
#qodef-page-sidebar .widget.widget_calendar .wp-calendar-nav a {
  color: #000;
}

#qodef-page-sidebar .widget .wp-block-calendar .wp-calendar-nav a:hover,
#qodef-page-sidebar .widget.widget_calendar .wp-calendar-nav a:hover {
  color: var(--qode-main-color);
}

#qodef-page-sidebar .widget .wp-block-gallery,
#qodef-page-sidebar .widget.widget_media_gallery {
  margin-bottom: 74px !important;
}

#qodef-page-sidebar .widget .wp-block-gallery .blocks-gallery-grid,
#qodef-page-sidebar .widget .wp-block-gallery .gallery,
#qodef-page-sidebar .widget.widget_media_gallery .blocks-gallery-grid,
#qodef-page-sidebar .widget.widget_media_gallery .gallery {
  margin: 0 -5px !important;
}

#qodef-page-sidebar .widget .wp-block-gallery .blocks-gallery-item,
#qodef-page-sidebar .widget .wp-block-gallery .gallery-item,
#qodef-page-sidebar .widget.widget_media_gallery .blocks-gallery-item,
#qodef-page-sidebar .widget.widget_media_gallery .gallery-item {
  margin: 0 0 10px 0 !important;
  padding: 0 5px;
}

#qodef-page-sidebar .widget.widget_media_image .qodef-widget-title {
  margin-bottom: 34.5px;
}

#qodef-page-sidebar
  .widget.widget_block
  .wp-block-columns
  h1:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-columns
  h2:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-columns
  h3:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-columns
  h4:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-columns
  h5:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-columns
  h6:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-group
  h1:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-group
  h2:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-group
  h3:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-group
  h4:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-group
  h5:not([class*="qodef"]),
#qodef-page-sidebar
  .widget.widget_block
  .wp-block-group
  h6:not([class*="qodef"]) {
  margin: 0 0 26px;
}

@media only screen and (max-width: 680px) {
  #qodef-404-page {
    padding: 110px 40px 200px;
  }
}

@media only screen and (max-width: 768px) {
  .qodef-blog .qodef-m-pagination {
    margin-top: 5px;
    margin-bottom: 122px;
  }
}

@media only screen and (max-width: 680px) {
  .qodef-blog .qodef-blog-item .qodef-e-left,
  .qodef-blog .qodef-blog-item .qodef-e-right {
    flex-basis: 100%;
  }

  .qodef-blog .qodef-blog-item .qodef-e-right {
    text-align: left;
    margin-top: 18px;
  }

  .qodef-blog .qodef-blog-item.format-link .qodef-e-link-icon {
    right: 12%;
    width: 120px;
  }

  .qodef-blog .qodef-blog-item.format-link .qodef-e-link {
    padding: 50px;
    background-image: none !important;
    background-color: #d9d0d0 !important;
  }

  .qodef-blog .qodef-blog-item.format-quote .qodef-e-quote {
    padding: 50px;
    background-image: none !important;
    background-color: #f4f3ef !important;
  }

  .qodef-blog .qodef-blog-item.format-quote .qodef-e-quote:before {
    right: 19%;
    font-size: 470px;
  }
}

@media only screen and (max-width: 480px) {
  .qodef-blog .qodef-blog-item.format-link .qodef-e-link {
    padding: 30px;
  }

  .qodef-blog .qodef-blog-item.format-quote .qodef-e-quote {
    padding: 30px;
  }
}

@media only screen and (max-width: 1024px) {
  #qodef-page-comments {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  #qodef-page-comments {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1024px) {
  #qodef-page-comments-list .qodef-comment-item .children {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 768px) {
  #qodef-page-comments-list .qodef-comment-item .children {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 680px) {
  #qodef-page-comments-list .qodef-comment-item .qodef-e-inner {
    flex-wrap: wrap;
  }

  #qodef-page-comments-list .qodef-comment-item .qodef-e-content {
    margin-top: 23px;
    width: 100%;
  }

  #qodef-page-comments-list .qodef-comment-item .children {
    padding-left: 3%;
  }
}

@media only screen and (max-width: 480px) {
  #qodef-page-comments-list
    .qodef-comment-item
    .qodef-e-content
    .qodef-e-content-top {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
}

@media only screen and (max-width: 680px) {
  .qodef-m-filter .qodef-m-filter-items {
    flex-direction: column;
  }

  .qodef-m-filter .qodef-m-filter-item {
    margin: 5px 0;
  }
}

@media only screen and (max-width: 1024px) {
  #qodef-page-footer-top-area .qodef-content-full-width {
    padding-left: 7%;
    padding-right: 7%;
  }
}

@media only screen and (max-width: 1024px) {
  #qodef-page-footer-bottom-area .qodef-content-full-width {
    padding-left: 7%;
    padding-right: 7%;
  }
}

@media only screen and (max-width: 1024px) {
  #qodef-page-header {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  #qodef-page-mobile-header {
    display: block;
  }
}

@media only screen and (max-width: 1440px) {
  .qodef-page-sidebar-section.qodef-col--3 #qodef-page-sidebar {
    padding-left: 8%;
    padding-right: 0;
  }

  .qodef-page-sidebar-section.qodef-col--3.qodef-col-pull--9
    #qodef-page-sidebar {
    padding-left: 0;
    padding-right: 8%;
  }
}

@media only screen and (max-width: 1440px) {
  .qodef-page-sidebar-section.qodef-col--4 #qodef-page-sidebar {
    padding-left: 12%;
    padding-right: 0;
  }

  .qodef-page-sidebar-section.qodef-col--4.qodef-col-pull--8
    #qodef-page-sidebar {
    padding-left: 0;
    padding-right: 12%;
  }
}

@media only screen and (max-width: 1024px) {
  .qodef-page-sidebar-section.qodef-col--3 #qodef-page-sidebar,
  .qodef-page-sidebar-section.qodef-col--4 #qodef-page-sidebar {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
