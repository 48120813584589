/* General Reset */

.location-detail-page {
  line-height: 1.6;
  color: #333;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
}

/* Banner Section */
.location-banner {
  background: url('https://level-contractor.thepreview.pro/images/location/atherton/Banner.jpg') no-repeat center center;
  background-size: cover;
  padding: 50px;
  text-align: center;
  color: white;
  min-height: 400px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.banner-content {
  margin-top: 80px;
  background: rgba(65, 65, 65, 50%);
  padding: 2px;
  border-radius: 10px;
}


.banner-content h1 {
  color: #ffffff;
}

.banner-content p {

}

/* Responsive Image-Text Section (Project Overview & Timeline) */
.image-text-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 2rem;
  flex-wrap: wrap;
}

@media (max-width: 425px) {
  .image-text-section {
    padding: 0;
  }
}

.image-text-section img {
  width: 100%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.text-content {
  flex: 1;
  padding: 1rem;
}

.text-content h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.text-content p {
  margin-bottom: 1rem;
}

.text-content ul {
  margin-top: 1rem;
  padding-left: 1.5rem;
}

.text-content ul li {
  margin-bottom: 0.5rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .image-text-section {
    flex-direction: column;
    padding: 0;
    /* align-items: flex-start; */
  }

  .image-text-section img {
    width: 100%;
  }
}

@media (max-width: 468px) {
  .text-content h2 {
    font-size: 1.5rem;
  }
}

/* Workflow Section */
.workflow-section {
  padding: 2rem;
  background-color: #fafafa;
}

.workflow-section h2 {
  text-align: center;
  margin-bottom: 1.5rem;
}

.workflow-section .steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.workflow-section .steps div {
  padding: 1rem;
  border-left: 4px solid #A9B1AB;
}

/* Before & After Section */
.before-after-section {
  /* padding: 2rem; */
  text-align: center;
  margin: 4rem auto;
}

.before-after-section h2 {
  margin-bottom: 2rem;
}

.before-after-section .flex-container {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: center;
}

.before-after-section .flex-container div {
  flex: 1;
  min-width: 300px;
}

.before-after-section img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.before-after-images {
  /* max-width: 65%; */
  margin: 0 auto;
}

.before-after-section p {
  margin-top: 0.5rem;
  font-size: 1.5rem;
}



/* Call-to-Action Section */
.location-cta {
  max-width: 1000px;
  margin: 4rem auto;
  text-align: center;
}

.location-cta h2 {
  margin-bottom: 1rem;
}

.location-cta p {
  margin-bottom: 1rem;
}

.blur-filter {
  padding: 1rem;
  margin: 4rem auto;
  background: #848484;
}

@media (max-width: 768px) {
 
}



/* Gallery Section */
.location-gallery {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

.location-gallery h2 {
  margin-bottom: 2rem;
}

.location-gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.location-gallery-grid .location-gallery-item img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

/* Video Section */
.video-section {
  padding: 2rem;
  text-align: center;
  /* background-color: #f5f5f5; */
}

.video-section .video-content-card h2 {
  margin-bottom: 1rem;
}

.video-section img {
  width: 100%;
  max-width: 1200px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}


