.level-core-page-inline-style {
    position: absolute !important;
    width: 0;
    height: 0;
    line-height: 1;
    display: none
}

#qodef-dynamic-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;
    opacity: 1;
    visibility: visible;
    transition: background-color .5s, opacity .3s ease, visibility .3s ease
}

#qodef-dynamic-background.qodef--display {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease, visibility .3s ease
}

.ser-details2 a {
    --qodef-underline-position-y: calc(100% - 1px);
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height)
}

.ser-details2 a:hover {
    animation: qode-inline-underline-initial-hover .8s cubic-bezier(.57, .39, 0, .86) 1 forwards
}

.qodef--underline-on-hover a {
    --qodef-underline-position-y: 100%;
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
    transition: background-size .5s cubic-bezier(.25, .46, .35, .94), color .2s ease-out
}

.qodef--underline-on-hover a:hover {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

.kc-elm.qodef-shortcode .fa,
.kc-elm.qodef-shortcode .fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900
}

.kc-elm.qodef-shortcode .fa:before,
.kc-elm.qodef-shortcode .fas:before {
    font-family: inherit;
    font-weight: inherit
}

.kc-elm.qodef-shortcode .fab {
    font-family: 'Font Awesome 5 Brands';
    font-weight: 400
}

.kc-elm.qodef-shortcode .fab:before {
    font-family: inherit;
    font-weight: inherit
}

#qodef-back-to-top {
    position: fixed;
    right: 37px;
    bottom: 60px;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 99
}

#qodef-back-to-top .qodef-back-to-top-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    transition: color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out
}

#qodef-back-to-top .qodef-back-to-top-icon * {
    display: block;
    line-height: inherit
}

#qodef-back-to-top svg {
    display: block;
    height: auto;
    fill: currentColor
}

#qodef-back-to-top.qodef--off {
    opacity: 0;
    visibility: hidden;
    transition: opacity .15s ease, visibility 0s .15s
}

#qodef-back-to-top.qodef--on {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease
}

@media only screen and (max-width:680px) {
    #qodef-back-to-top {
        right: 30px;
        bottom: 80px
    }
}

#qodef-back-to-top.qodef--predefined:hover path {
    animation: qode-draw-back-top-top 1.3s cubic-bezier(.22, .61, .36, 1)
}

.qodef-background-text {
    position: relative
}

.qodef-background-text .qodef-m-background-text-holder {
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%
}

.qodef-background-text .qodef-m-background-text {
    color: var(--qode-main-color);
    font-family: "DM Sans", sans-serif;
    font-size: 200px;
    line-height: 1em
}

.qodef-blog.qodef-bottom-border--enabled .qodef-blog-item {
    margin-bottom: 0 !important
}

.qodef-blog.qodef-bottom-border--enabled .qodef-blog-item .qodef-e-inner {
    padding-bottom: 80px;
    margin-bottom: 90px;
    border-bottom: 1px solid #e3e3e3
}

.qodef-blog.qodef-bottom-border--enabled.qodef-pagination--on .qodef-blog-item:last-of-type {
    margin-bottom: 78px !important
}

.qodef-blog.qodef-item-layout--info-right .qodef-e-content {
    display: flex;
    align-items: center
}

.qodef-blog.qodef-item-layout--info-right .qodef-e-content-inner {
    padding: 30px
}

.qodef-blog.qodef-item-layout--info-right .qodef-e-media {
    flex: 1 0 60%
}

.qodef-blog.qodef-item-layout--info-right .qodef-e-media-image {
    margin-bottom: 0
}

.qodef-blog.qodef-item-layout--info-right:not(.qodef-col-num--1) .qodef-e-media-image {
    width: 100%;
    overflow: hidden
}

.qodef-blog.qodef-item-layout--info-right:not(.qodef-col-num--1) .qodef-e-media-image img {
    width: calc(100% + 10px);
    transform: translateX(-8px);
    transition: transform .5s cubic-bezier(.25, .46, .35, .94);
    max-width: unset
}

.qodef-blog.qodef-item-layout--info-right:not(.qodef-col-num--1) .qodef-e:hover .qodef-e-media-image img {
    transform: translateX(0)
}

.qodef-blog.qodef-item-layout--minimal article .qodef-e-bottom-holder {
    margin-top: 12px
}

.qodef-blog.qodef-item-layout--simple article .qodef-e-inner {
    display: flex;
    align-items: flex-start
}

.qodef-blog.qodef-item-layout--simple article .qodef-e-media-image {
    flex-shrink: 0;
    width: 120px !important;
    margin: 0 20px 0 0
}

.qodef-blog.qodef-item-layout--simple article .qodef-e-bottom-holder {
    margin-top: 5px
}

.qodef-blog.qodef-item-layout--standard.qodef-col-num--1 article:not(:last-child) .qodef-e-info.qodef-info--bottom {
    margin-bottom: 36px
}

.qodef-blog.qodef-item-layout--standard.qodef-col-num--1 article:not(:last-child).format-link .qodef-e-link {
    margin-bottom: 50px
}

.qodef-blog.qodef-item-layout--standard.qodef-col-num--1 article:not(:last-child).format-quote .qodef-e-quote {
    margin-bottom: 50px
}

.qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article.format-link .qodef-e-link {
    padding: 76px
}

.qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article.format-link .qodef-e-link-icon {
    right: 40px;
    width: 140px
}

.qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article.format-quote .qodef-e-quote {
    padding: 76px
}

.qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article.format-quote .qodef-e-quote:before {
    font-size: 540px
}

.qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article .qodef-e-media-image {
    width: 100%;
    overflow: hidden
}

.qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article .qodef-e-media-image img {
    width: calc(100% + 10px);
    transform: translateX(-8px);
    transition: transform .5s cubic-bezier(.25, .46, .35, .94);
    max-width: unset
}

.qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article:hover .qodef-e-media-image img {
    transform: translateX(0)
}

.qodef-blog.qodef-item-layout--standard .qodef-e-bottom-holder .qodef-e-left {
    flex-basis: 100%
}

.qodef-blog.qodef-item-layout--standard.qodef-bottom-border--enabled.qodef-col-num--1 article:not(:last-child) .qodef-e-info.qodef-info--bottom {
    margin-bottom: 36px
}

.qodef-blog.qodef-item-layout--standard.qodef-bottom-border--enabled.qodef-col-num--1 article:not(:last-child).format-link .qodef-e-link {
    margin-bottom: 0
}

.qodef-blog.qodef-item-layout--standard.qodef-bottom-border--enabled.qodef-col-num--1 article:not(:last-child).format-quote .qodef-e-quote {
    margin-bottom: 0
}

.qodef-blog.qodef-item-layout--standard.qodef-bottom-border--enabled.qodef-col-num--1 article:last-child .qodef-e-inner {
    margin-bottom: 0
}

@media only screen and (max-width:1024px) {
    .qodef-blog.qodef-item-layout--standard.qodef-gutter--tiny article {
        margin-bottom: 54px !important
    }
}

.qodef-blog.qodef-item-layout--standard.qodef-info--vertical .qodef-e-bottom-holder .qodef-e-left {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px
}

.qodef-blog.qodef-item-layout--standard.qodef-info--vertical .qodef-e-bottom-holder .qodef-e-left .qodef-info-separator-end {
    display: none
}

.qodef-blog.qodef-item-layout--standard.qodef--list:not(.qodef-col-num--1) .qodef-e-content {
    max-width: 95%
}

.widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:not(:last-child) {
    margin-bottom: 20px
}

.widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:first-child {
    margin-top: 8.5px
}

.widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article .qodef-e-media-image {
    margin-right: 20px;
    width: 80px !important
}

.widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--minimal article:not(:last-child) {
    margin: 0 0 35px
}

#qodef-top-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:not(:last-child) {
    margin-bottom: 20px
}

#qodef-top-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:first-child {
    margin-top: 8.5px
}

#qodef-top-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article .qodef-e-media-image {
    margin-right: 20px;
    width: 80px !important
}

#qodef-top-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--minimal article:not(:last-child) {
    margin: 0 0 35px
}

#qodef-page-footer .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:not(:last-child) {
    margin-bottom: 20px
}

#qodef-page-footer .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:first-child {
    margin-top: 8.5px
}

#qodef-page-footer .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article .qodef-e-media-image {
    margin-right: 20px;
    width: 80px !important
}

#qodef-page-footer .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--minimal article:not(:last-child) {
    margin: 0 0 35px
}

#qodef-side-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:not(:last-child) {
    margin-bottom: 20px
}

#qodef-side-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:first-child {
    margin-top: 8.5px
}

#qodef-side-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article .qodef-e-media-image {
    margin-right: 20px;
    width: 80px !important
}

#qodef-side-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--minimal article:not(:last-child) {
    margin: 0 0 35px
}

.qodef-mega-menu-widget-holder .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:not(:last-child) {
    margin-bottom: 20px
}

.qodef-mega-menu-widget-holder .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:first-child {
    margin-top: 8.5px
}

.qodef-mega-menu-widget-holder .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article .qodef-e-media-image {
    margin-right: 20px;
    width: 80px !important
}

.qodef-mega-menu-widget-holder .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--minimal article:not(:last-child) {
    margin: 0 0 35px
}

#qodef-page-sidebar .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:not(:last-child) {
    margin-bottom: 20px
}

#qodef-page-sidebar .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:first-child {
    margin-top: 8.5px
}

#qodef-page-sidebar .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article .qodef-e-media-image {
    margin-right: 20px;
    width: 80px !important
}

#qodef-page-sidebar .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--minimal article:not(:last-child) {
    margin: 0 0 35px
}

#qodef-page-footer-bottom-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:not(:last-child) {
    margin-bottom: 20px
}

#qodef-page-footer-bottom-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article:first-child {
    margin-top: 8.5px
}

#qodef-page-footer-bottom-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--simple article .qodef-e-media-image {
    margin-right: 20px;
    width: 80px !important
}

#qodef-page-footer-bottom-area .widget.widget_level_core_simple_blog_list .qodef-blog.qodef-item-layout--minimal article:not(:last-child) {
    margin: 0 0 35px
}

body[class*=qodef-blog] #qodef-page-content #qodef-single-post-navigation {
    display: block
}

body[class*=qodef-blog] #qodef-page-content .qodef-author-info {
    display: block
}

body[class*=qodef-blog] #qodef-page-content #qodef-page-comments {
    display: block
}

.qodef-blog--wide .qodef--single>.qodef-blog-item--wide {
    margin-bottom: 67px
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-top-holder {
    margin-top: 55px;
    margin-bottom: 39px;
    text-align: center
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-top-holder .qodef-e-title {
    margin-bottom: 0
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-top-holder .qodef-e-info {
    max-width: 48.5%;
    margin: 0 auto
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-top-holder .qodef-e-bottom,
.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-top-holder .qodef-e-top {
    justify-content: center
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-top-holder .qodef-e-top {
    margin-top: 30px
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-top-holder .qodef-e-bottom {
    margin-top: 18px
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-author-holder {
    max-width: 48.5%;
    margin: 11px auto 0;
    text-align: center
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-media {
    text-align: center
}

.qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-media .qodef-e-media-image {
    margin-bottom: 54px
}

.qodef-blog--wide #qodef-author-info {
    margin-top: 14px !important
}

@media only screen and (max-width:680px) {
    .qodef-blog--wide #qodef-author-info {
        margin-top: 10px !important
    }
}

.qodef-blog--wide #qodef-page-comments-form {
    margin-top: 98px !important
}

.qodef-blog--wide #qodef-author-info,
.qodef-blog--wide #qodef-page-comments-form,
.qodef-blog--wide #qodef-page-comments-list {
    max-width: 48.5%;
    margin: 0 auto;
    display: block
}

.qodef-blog--wide .qodef-info--bottom {
    display: flex;
    justify-content: space-between;
    padding: 40px 0 8px;
    border-bottom: 1px solid #e3e3e3
}

.qodef-blog--wide .qodef-info--bottom .qodef-e-info-left>a[rel=tag] {
    padding: 4px 8px;
    margin: 0 5px 5px 0;
    border: 1px solid #d8d8d8;
    background-color: transparent;
    color: var(--qode-main-color);
    font-family: "Nunito Sans", sans-serif;
    font-size: 12px;
    text-transform: lowercase;
    letter-spacing: 0;
    transition: color .2s ease-out, border-color .35s ease-out, background-color .2s ease-out;
    white-space: nowrap
}

.qodef-blog--wide .qodef-info--bottom .qodef-e-info-left>a[rel=tag]:hover {
    border-color: var(--qode-main-color)
}

.qodef-blog--wide.single #qodef-page-outer>#qodef-media img {
    width: 100%
}

.qodef-blog--wide #qodef-page-inner {
    padding-top: 0
}

.qodef-blog--wide .qodef-page-title {
    margin-bottom: 73px
}

@media only screen and (max-width:1024px) {
    .qodef-blog--wide .qodef-page-title {
        margin-bottom: 80px
    }
}

#qodef-author-info {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 13px;
    margin-bottom: 43px
}

#qodef-author-info .qodef-m-inner {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #f4f3ef
}

#qodef-author-info .qodef-m-image {
    flex-shrink: 0
}

#qodef-author-info .qodef-m-image a,
#qodef-author-info .qodef-m-image img {
    display: block
}

#qodef-author-info .qodef-m-content {
    padding: 50px 40px
}

#qodef-author-info .qodef-m-content>* {
    color: #000
}

#qodef-author-info .qodef-m-content .qodef-m-author {
    margin: 0
}

#qodef-author-info .qodef-m-content .qodef-m-email {
    margin: 5px 0 0
}

#qodef-author-info .qodef-m-content .qodef-m-description {
    margin: 16px 0 0;
    line-height: 25px;
    color: #000
}

#qodef-author-info .qodef-m-content .qodef-m-social-icons {
    position: absolute;
    top: 23px;
    right: 35px;
    margin-top: 0
}

#qodef-author-info .qodef-m-content .qodef-m-social-icons a {
    position: relative;
    display: inline-block;
    font-size: 15px;
    vertical-align: top;
    margin-right: 11px
}

#qodef-author-info .qodef-m-content .qodef-m-social-icons a:hover {
    color: #787878
}

#qodef-author-info .qodef-m-content .qodef-m-social-icons a:last-child {
    margin-right: 0
}

.qodef-blog--wide #qodef-author-info .qodef-m-content {
    padding: 27px 40px
}

#qodef-related-posts {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 108px
}

#qodef-related-posts>.qodef-e-title {
    margin: 0 0 51px
}

@media only screen and (max-width:768px) {
    #qodef-related-posts {
        margin-top: 10px
    }

    #qodef-related-posts article.qodef-blog-item {
        margin-bottom: 50px
    }
}

#qodef-single-post-navigation {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 0 0 81px
}

#qodef-single-post-navigation .qodef-m-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between
}

#qodef-single-post-navigation .qodef-m-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left
}

#qodef-single-post-navigation .qodef-m-nav>* {
    display: block;
    line-height: inherit
}

#qodef-single-post-navigation .qodef-m-nav .qodef-m-pagination-icon {
    position: relative;
    top: -2px;
    display: block;
    width: 30px;
    height: auto;
    fill: currentColor;
    stroke: none
}

#qodef-single-post-navigation .qodef-m-nav.qodef--prev {
    left: 0
}

#qodef-single-post-navigation .qodef-m-nav.qodef--prev .qodef-m-pagination-icon {
    margin-right: 4px
}

#qodef-single-post-navigation .qodef-m-nav.qodef--next {
    right: 0;
    margin-left: auto
}

#qodef-single-post-navigation .qodef-m-nav.qodef--next .qodef-m-pagination-icon {
    margin-left: 4px
}

@media only screen and (max-width:768px) {
    #qodef-single-post-navigation .qodef-m-nav.qodef--next {
        align-items: flex-end
    }
}

#qodef-single-post-navigation .qodef-m-nav-label {
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000;
    margin-bottom: 10px
}

#qodef-single-post-navigation .qodef-m-nav-title {
    max-width: 250px;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400
}

@media only screen and (max-width:1440px) {
    #qodef-single-post-navigation .qodef-m-nav-title {
        font-size: 18px;
        line-height: 1.27778em
    }
}

@media only screen and (max-width:768px) {
    #qodef-single-post-navigation .qodef-m-nav-title {
        display: none
    }
}

.qodef-swiper--show-drag-cursor {
    cursor: none !important
}

.qodef-swiper--show-drag-cursor .swiper-wrapper {
    min-width: 300vw
}

.qodef-swiper--show-drag-cursor .qodef-m-custom-cursor {
    height: 120px;
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 50%;
    transition: opacity .3s ease-out, visibility .3s ease-out;
    visibility: hidden;
    width: 120px;
    z-index: 2
}

.qodef-swiper--show-drag-cursor .qodef-m-custom-cursor-inner {
    border-radius: 50%;
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%
}

.qodef-swiper--show-drag-cursor.qodef-swiper-drag-cursor--active .qodef-m-custom-cursor {
    opacity: 1;
    visibility: visible
}

.qodef-swiper--show-drag-cursor.qodef-swiper-drag-cursor--active a {
    cursor: none !important
}

#qodef-page-footer .qodef-alignment--left {
    text-align: left
}

#qodef-page-footer .qodef-alignment--left .widget img {
    margin-right: auto
}

#qodef-page-footer .qodef-alignment--center {
    text-align: center
}

#qodef-page-footer .qodef-alignment--center .widget img {
    margin-right: auto;
    margin-left: auto
}

#qodef-page-footer .qodef-alignment--right {
    text-align: right
}

#qodef-page-footer .qodef-alignment--right .widget img {
    margin-left: auto
}

@media (min-width:681px) {
    #qodef-page-footer-bottom-area-inner .qodef-alignment--space-between .qodef-grid-item:nth-child(2) {
        text-align: right
    }
}

.qodef-page-footer--uncover #qodef-page-outer {
    z-index: 90
}

.no-touchevents .qodef-page-footer--uncover #qodef-page-footer {
    position: fixed;
    bottom: 0;
    left: 0
}

#qodef-fullscreen-area {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 50px;
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden !important;
    visibility: hidden;
    opacity: 0;
    z-index: 99
}

#qodef-fullscreen-area .qodef-content-grid {
    height: 100%
}

#qodef-fullscreen-area .qodef-content-grid .qodef-fullscreen-menu {
    width: 100%
}

.qodef-fullscreen-menu-animate--in #qodef-fullscreen-area {
    animation: qode-fade-in .25s linear both
}

.qodef-fullscreen-menu-animate--out #qodef-fullscreen-area {
    animation: qode-fade-out .25s linear both
}

#qodef-fullscreen-area-inner {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    height: 100%
}

#qodef-fullscreen-area-inner .qodef-fullscreen-menu {
    max-width: 100%
}

@media only screen and (max-width:480px) {
    #qodef-fullscreen-area-inner {
        width: 100%
    }
}

#qodef-fullscreen-area-right {
    position: relative;
    display: inline-flex;
    width: 50%;
    height: 100%;
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat
}

.admin-bar #qodef-fullscreen-area-right {
    height: calc(100% - 32px)
}

@media only screen and (max-width:480px) {
    #qodef-fullscreen-area-right {
        display: none
    }
}

.qodef-fullscreen-area-right-inner {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    background-size: contain;
    background-position: right center;
    background-repeat: no-repeat
}

.qodef-fullscreen-area-right-inner>.qodef-widget-holder {
    white-space: nowrap;
    min-width: 400px
}

.qodef-fullscreen-menu {
    position: relative;
    width: 100%;
    top: 0;
    margin: 0 auto;
    text-align: left
}

.qodef-fullscreen-menu ul {
    margin: 0 !important;
    padding: 0;
    list-style: none
}

.qodef-fullscreen-menu ul li {
    position: relative;
    margin: 0;
    padding: 0
}

.qodef-fullscreen-menu ul li a .qodef-menu-item-arrow {
    display: none
}

.qodef-fullscreen-menu ul li.qodef--hide-link>a {
    cursor: default
}

.qodef-fullscreen-menu ul.sub-menu {
    display: none
}

.qodef-fullscreen-menu .qodef-menu-item-icon {
    margin-right: 5px
}

.qodef-fullscreen-menu .qodef-mega-menu-widget-holder {
    display: none
}

.qodef-fullscreen-menu>ul>li>a {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 45px;
    line-height: 1.2em;
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;
    text-transform: lowercase
}

@media only screen and (max-width:1440px) {
    .qodef-fullscreen-menu>ul>li>a {
        font-size: 38px;
        line-height: 1.13158em
    }
}

.qodef-fullscreen-menu>ul>li>a>span {
    position: relative;
    padding-left: 7px
}

.qodef-fullscreen-menu>ul>li>a>span::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 100%;
    transform-origin: bottom;
    transition: transform .3s cubic-bezier(.25, .46, .35, .94), color .2s ease-out;
    transform: scaleY(0);
    background-color: currentColor
}

.qodef-fullscreen-menu>ul>li>a:hover {
    color: var(--qode-main-color)
}

.qodef-fullscreen-menu>ul>li>a:hover>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-fullscreen-menu>ul>li>a>span {
    padding-left: 15px
}

.qodef-fullscreen-menu>ul>li.current-menu-ancestor>a,
.qodef-fullscreen-menu>ul>li.current-menu-item>a {
    color: var(--qode-main-color)
}

.qodef-fullscreen-menu>ul>li.current-menu-ancestor>a>span::after,
.qodef-fullscreen-menu>ul>li.current-menu-item>a>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-fullscreen-menu .qodef-drop-down-second-inner>ul {
    padding: 10px 0 20px
}

.qodef-fullscreen-menu .qodef-drop-down-second-inner ul {
    padding-left: 15px
}

.qodef-fullscreen-menu .qodef-drop-down-second-inner ul li>a {
    position: relative;
    display: flex;
    white-space: normal;
    margin: 8px 0;
    overflow: hidden;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 1.6875em;
    font-weight: 400;
    color: #000
}

.qodef-fullscreen-menu .qodef-drop-down-second-inner ul li>a .qodef-menu-item-icon {
    flex-shrink: 0
}

.qodef-fullscreen-menu .qodef-drop-down-second-inner ul li>a>span {
    --qodef-underline-position-y: calc(100% - 3px);
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
    transition: background-size .5s cubic-bezier(.25, .46, .35, .94), color .2s ease-out
}

.qodef-fullscreen-menu .qodef-drop-down-second-inner ul li>a:hover>span {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

.qodef-fullscreen-menu .qodef-drop-down-second-inner ul li.current-menu-ancestor>a>span,
.qodef-fullscreen-menu .qodef-drop-down-second-inner ul li.current-menu-item>a>span {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

.qodef-fullscreen-menu--opened #qodef-page-header-inner .qodef-header-logo-link img.qodef--main {
    opacity: 1 !important
}

.qodef-fullscreen-menu--opened #qodef-page-header-inner .qodef-header-logo-link img:not(.qodef--main) {
    opacity: 0 !important
}

.qodef-fullscreen-menu--opened .qodef-fullscreen-menu-opener.qodef--opened .qodef--open {
    visibility: hidden
}

.qodef-fullscreen-menu--opened .qodef-fullscreen-menu-opener.qodef--opened .qodef--close {
    visibility: visible;
    transform: translateX(-50%)
}

.qodef-fullscreen-menu--opened #qodef-page-header,
.qodef-fullscreen-menu--opened #qodef-page-mobile-header,
.qodef-fullscreen-menu--opened .qodef-widget-holder .qodef-opener-icon .qodef-m-icon {
    visibility: hidden
}

.qodef-fullscreen-menu--opened .qodef-fullscreen-menu-opener,
.qodef-fullscreen-menu--opened .qodef-header-logo-image.qodef--customizer,
.qodef-fullscreen-menu--opened .qodef-header-logo-link,
.qodef-fullscreen-menu--opened .qodef-mobile-header-logo-link {
    visibility: visible
}

.qodef-fullscreen-menu--opened #qodef-page-header .qodef-header-logo-link img.qodef--light {
    opacity: 1
}

.qodef-fullscreen-menu--opened #qodef-page-header .qodef-header-logo-link img.qodef--dark,
.qodef-fullscreen-menu--opened #qodef-page-header .qodef-header-logo-link img.qodef--main {
    opacity: 0
}

.qodef-fullscreen-menu--opened.qodef-fullscreen-menu--hide-logo .qodef-header-logo-image.qodef--customizer,
.qodef-fullscreen-menu--opened.qodef-fullscreen-menu--hide-logo .qodef-header-logo-link,
.qodef-fullscreen-menu--opened.qodef-fullscreen-menu--hide-logo .qodef-mobile-header-logo-link {
    visibility: hidden
}

#qodef-page-header .qodef-widget-holder {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 24px
}

#qodef-page-header .qodef-widget-holder .widget {
    margin: 0 12px
}

#qodef-page-header .qodef-widget-holder .widget:first-child {
    margin-left: 0
}

#qodef-page-header .qodef-widget-holder .widget:last-child {
    margin-right: 0
}

#qodef-page-header div[id^=monster-widget-placeholder],
#qodef-page-header div[id^=woo-monster-widget-placeholder] {
    display: none
}

#qodef-page-header-inner {
    border-bottom: 0 solid transparent
}

#qodef-page-header-inner.qodef-content-grid {
    padding: 0
}

#qodef-page-header-inner.qodef-skin--light nav>ul>li>a,
.qodef-custom-header-layout.qodef-skin--light nav>ul>li>a {
    color: var(--qode-header-light-color) !important
}

#qodef-page-header-inner.qodef-skin--light nav>ul>li>a:hover,
.qodef-custom-header-layout.qodef-skin--light nav>ul>li>a:hover {
    color: var(--qode-header-light-hover-color) !important
}

#qodef-page-header-inner.qodef-skin--light nav>ul>li>a>span,
.qodef-custom-header-layout.qodef-skin--light nav>ul>li>a>span {
    color: var(--qode-header-light-hover-color) !important
}

#qodef-page-header-inner.qodef-skin--light nav>ul>li>a>span::after,
.qodef-custom-header-layout.qodef-skin--light nav>ul>li>a>span::after {
    background-color: var(--qode-header-light-hover-color) !important
}

#qodef-page-header-inner.qodef-skin--light nav>ul>li.current-menu-ancestor>a,
#qodef-page-header-inner.qodef-skin--light nav>ul>li.current-menu-item>a,
.qodef-custom-header-layout.qodef-skin--light nav>ul>li.current-menu-ancestor>a,
.qodef-custom-header-layout.qodef-skin--light nav>ul>li.current-menu-item>a {
    color: var(--qode-header-light-hover-color) !important
}

#qodef-page-header-inner.qodef-skin--light .qodef-opener-icon.qodef-source--icon-pack:not(.qodef--opened),
#qodef-page-header-inner.qodef-skin--light .qodef-opener-icon.qodef-source--predefined:not(.qodef--opened),
#qodef-page-header-inner.qodef-skin--light .qodef-opener-icon.qodef-source--svg-path:not(.qodef--opened),
.qodef-custom-header-layout.qodef-skin--light .qodef-opener-icon.qodef-source--icon-pack:not(.qodef--opened),
.qodef-custom-header-layout.qodef-skin--light .qodef-opener-icon.qodef-source--predefined:not(.qodef--opened),
.qodef-custom-header-layout.qodef-skin--light .qodef-opener-icon.qodef-source--svg-path:not(.qodef--opened) {
    color: var(--qode-header-light-color) !important
}

#qodef-page-header-inner.qodef-skin--light .widget.widget_level_core_woo_dropdown_cart .qodef-m-opener,
.qodef-custom-header-layout.qodef-skin--light .widget.widget_level_core_woo_dropdown_cart .qodef-m-opener {
    color: var(--qode-header-light-color) !important
}

#qodef-page-header-inner.qodef-skin--light .widget p>a,
.qodef-custom-header-layout.qodef-skin--light .widget p>a {
    color: var(--qode-header-light-color) !important
}

#qodef-page-header-inner.qodef-skin--dark nav>ul>li>a,
.qodef-custom-header-layout.qodef-skin--dark nav>ul>li>a {
    color: var(--qode-header-dark-color) !important
}

#qodef-page-header-inner.qodef-skin--dark nav>ul>li>a:hover,
.qodef-custom-header-layout.qodef-skin--dark nav>ul>li>a:hover {
    color: var(--qode-header-dark-hover-color) !important
}

#qodef-page-header-inner.qodef-skin--dark nav>ul>li>a>span,
.qodef-custom-header-layout.qodef-skin--dark nav>ul>li>a>span {
    color: var(--qode-header-dark-color) !important
}

#qodef-page-header-inner.qodef-skin--dark nav>ul>li>a>span::after,
.qodef-custom-header-layout.qodef-skin--dark nav>ul>li>a>span::after {
    background-color: var(--qode-header-dark-hover-color) !important
}

#qodef-page-header-inner.qodef-skin--dark nav>ul>li.current-menu-ancestor>a,
#qodef-page-header-inner.qodef-skin--dark nav>ul>li.current-menu-item>a,
.qodef-custom-header-layout.qodef-skin--dark nav>ul>li.current-menu-ancestor>a,
.qodef-custom-header-layout.qodef-skin--dark nav>ul>li.current-menu-item>a {
    color: var(--qode-header-dark-hover-color) !important
}

#qodef-page-header-inner.qodef-skin--dark .qodef-opener-icon.qodef-source--icon-pack:not(.qodef--opened),
#qodef-page-header-inner.qodef-skin--dark .qodef-opener-icon.qodef-source--predefined:not(.qodef--opened),
#qodef-page-header-inner.qodef-skin--dark .qodef-opener-icon.qodef-source--svg-path:not(.qodef--opened),
.qodef-custom-header-layout.qodef-skin--dark .qodef-opener-icon.qodef-source--icon-pack:not(.qodef--opened),
.qodef-custom-header-layout.qodef-skin--dark .qodef-opener-icon.qodef-source--predefined:not(.qodef--opened),
.qodef-custom-header-layout.qodef-skin--dark .qodef-opener-icon.qodef-source--svg-path:not(.qodef--opened) {
    color: var(--qode-header-dark-color) !important
}

#qodef-page-header-inner.qodef-skin--dark .widget.widget_level_core_woo_dropdown_cart .qodef-m-opener,
.qodef-custom-header-layout.qodef-skin--dark .widget.widget_level_core_woo_dropdown_cart .qodef-m-opener {
    color: var(--qode-header-dark-color) !important
}

#qodef-page-header-inner.qodef-skin--dark .widget p>a,
.qodef-custom-header-layout.qodef-skin--dark .widget p>a {
    color: var(--qode-header-dark-color) !important
}

#qodef-page-header .qodef-header-logo-link img.qodef--main {
    opacity: 1
}

#qodef-page-header .qodef-header-logo-link img.qodef--dark,
#qodef-page-header .qodef-header-logo-link img.qodef--light {
    opacity: 0
}

#qodef-page-header .qodef-header-logo-link img:not(.qodef--main) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%)
}

#qodef-page-header .qodef-header-logo-link.qodef-source--svg-path svg {
    display: block;
    width: 50px;
    height: auto;
    fill: currentColor;
    stroke: currentColor
}

#qodef-page-header .qodef-header-logo-link.qodef-source--textual {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 24px;
    line-height: 1.20833em;
    font-weight: 400;
    transition: color .2s ease-out
}

@media only screen and (max-width:1440px) {
    #qodef-page-header .qodef-header-logo-link.qodef-source--textual {
        font-size: 22px;
        line-height: 1.22727em
    }
}

#qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link img.qodef--dark,
#qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link img.qodef--main {
    opacity: 0
}

#qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link img.qodef--light {
    opacity: 1
}

.qodef-side-area--opened #qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link img.qodef--main {
    opacity: 1
}

.qodef-side-area--opened #qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link img.qodef--light {
    opacity: 0
}

#qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link.qodef-source--svg-path,
#qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link.qodef-source--textual {
    color: var(--qode-header-light-color) !important
}

#qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link.qodef-source--svg-path:hover,
#qodef-page-header-inner.qodef-skin--light .qodef-header-logo-link.qodef-source--textual:hover {
    color: var(--qode-header-light-hover-color) !important
}

#qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link img.qodef--light,
#qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link img.qodef--main {
    opacity: 0
}

#qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link img.qodef--dark {
    opacity: 1
}

.qodef-side-area--opened #qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link img.qodef--main {
    opacity: 1
}

.qodef-side-area--opened #qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link img.qodef--dark {
    opacity: 0
}

#qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link.qodef-source--svg-path,
#qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link.qodef-source--textual {
    color: var(--qode-header-dark-color) !important
}

#qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link.qodef-source--svg-path:hover,
#qodef-page-header-inner.qodef-skin--dark .qodef-header-logo-link.qodef-source--textual:hover {
    color: var(--qode-header-dark-hover-color) !important
}

.qodef-header--divided #qodef-page-header .qodef-divided-header-left-wrapper,
.qodef-header--divided #qodef-page-header .qodef-divided-header-right-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center
}

.qodef-header--divided #qodef-page-header .qodef-divided-header-left-wrapper {
    justify-content: flex-start
}

.qodef-header--divided #qodef-page-header .qodef-header-logo-link {
    justify-content: center;
    margin: auto
}

.qodef-header--divided #qodef-page-header .qodef-divided-header-right-wrapper {
    justify-content: flex-end
}

.qodef-header--divided #qodef-page-header .qodef-header-navigation {
    margin: 0
}

.qodef-header--divided #qodef-page-header .qodef-widget-holder {
    margin-left: 0
}

.qodef-header--divided #qodef-page-header-inner {
    align-items: stretch;
    justify-content: center
}

.qodef-header--fullscreen-vertical #qodef-page-header {
    position: fixed;
    top: 0;
    right: 0;
    width: 530px;
    height: 100%
}

.qodef-header--fullscreen-vertical #qodef-page-header-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px 40px 96px
}

.qodef-header--fullscreen-vertical #qodef-page-header-inner .qodef-widget-holder {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-left: 0
}

.qodef-header--fullscreen-vertical #qodef-page-header-inner .qodef-widget-holder .widget {
    margin: 0 0 15px
}

.qodef-header--fullscreen-vertical #qodef-page-header-inner .qodef-widget-holder .widget:last-child {
    margin-bottom: 0
}

.qodef-header--fullscreen-vertical #qodef-page-header-inner .qodef-header-logo-link {
    flex: 1 1 auto
}

.qodef-header--fullscreen-vertical #qodef-page-header-inner .qodef-fullscreen-menu-opener {
    padding-left: 20px
}

.qodef-header--fullscreen-vertical .qodef-fullscreen-vertical-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.qodef-header--fullscreen-vertical.admin-bar #qodef-page-header {
    top: 32px;
    height: calc(100% - 32px)
}

.qodef-header--fullscreen-vertical #qodef-page-footer,
.qodef-header--fullscreen-vertical #qodef-page-outer {
    padding-right: 530px
}

.qodef-header--fullscreen-vertical.qodef-fullscreen-menu--opened .qodef-header-logo-link {
    visibility: hidden
}

@media only screen and (max-width:1024px) {

    .qodef-header--fullscreen-vertical #qodef-page-footer,
    .qodef-header--fullscreen-vertical #qodef-page-outer {
        padding-right: 0
    }
}

.qodef-header--minimal #qodef-page-header-inner {
    align-items: flex-start;
    padding-top: 25px
}

.qodef-header--minimal #qodef-page-header-inner .qodef-header-logo-link {
    margin-right: auto
}

.qodef-header--minimal #qodef-page-header-inner .qodef-widget-holder {
    align-items: flex-start;
    margin-right: 30px
}

.qodef-header--minimal #qodef-page-header-inner .qodef-widget-holder.qodef--one .wp-block-columns {
    max-width: calc(64vw - 100px);
    gap: 160px
}

@media only screen and (max-width:1440px) {
    .qodef-header--minimal #qodef-page-header-inner .qodef-widget-holder.qodef--one .wp-block-columns {
        max-width: calc(64.4vw - 100px);
        gap: 100px
    }
}

@media only screen and (max-width:1280px) {
    .qodef-header--minimal #qodef-page-header-inner .qodef-widget-holder.qodef--one .wp-block-columns {
        gap: 80px
    }

    .qodef-header--minimal #qodef-page-header-inner .qodef-widget-holder.qodef--one .wp-block-columns .wp-block-column:first-child {
        flex-basis: 50% !important
    }
}

.qodef-header--minimal #qodef-page-header-inner .qodef-widget-holder.qodef--one .wp-block-columns .wp-block-column>p {
    margin: -6px 0 0;
    line-height: 1.5em
}

.qodef-header--standard #qodef-page-header-inner .qodef-widget-holder .widget {
    margin: 0 30px
}

.qodef-header--standard #qodef-page-header-inner .qodef-widget-holder .widget:first-child {
    margin-left: 0
}

.qodef-header--standard #qodef-page-header-inner .qodef-widget-holder .widget:last-child {
    margin-right: 0
}

.qodef-header-standard--right #qodef-page-header-inner .qodef-header-navigation {
    margin-left: auto
}

.qodef-header-standard--right #qodef-page-header-inner .qodef-widget-holder {
    margin-left: 60px
}

.qodef-header-standard--center #qodef-page-header-inner .qodef-header-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%
}

.qodef-header-standard--center #qodef-page-header-inner .qodef-header-logo {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: inline-flex;
    align-items: center
}

.qodef-header-standard--center #qodef-page-header-inner .qodef-header-navigation {
    margin-left: auto;
    margin-right: auto
}

.qodef-header-standard--center #qodef-page-header-inner .qodef-widget-holder {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 0
}

.qodef-header-standard--left #qodef-page-header-inner .qodef-header-navigation {
    margin-right: auto
}

.qodef-header-standard--left #qodef-page-header-inner .qodef-header-logo-link {
    margin-right: 60px
}

.qodef-header--switch #qodef-page-header {
    height: 280px;
    padding: 40px 0;
    pointer-events: none
}

.qodef-header--switch #qodef-page-header a {
    pointer-events: all
}

.qodef-header--switch #qodef-page-header .qodef-header-logo-link {
    align-items: flex-start
}

.qodef-header--switch #qodef-page-header .qodef-header-logo-link img {
    margin: 0
}

.qodef-header--switch #qodef-page-header .qodef-header-logo-link img:not(.qodef--main):not(.qodef--sticky) {
    top: 0;
    left: 50%;
    transform: translateX(-50%)
}

.qodef-header--switch #qodef-page-header-inner {
    align-items: flex-start;
    justify-content: space-between
}

.qodef-header--switch #qodef-page-header-inner>* {
    display: flex;
    flex: 1 1 0
}

.qodef-header--switch #qodef-page-header-inner .qodef-header-holder--left {
    max-height: 100%;
    justify-content: flex-start
}

.qodef-header--switch #qodef-page-header-inner .qodef-header-logo-link {
    justify-content: center
}

.qodef-header--switch #qodef-page-header-inner .qodef-header-holder--right {
    justify-content: flex-end
}

.qodef-header--switch #qodef-page-header-inner:after {
    display: none
}

.qodef-header--switch .qodef-header-switch-navigation {
    position: relative;
    width: 400px
}

.qodef-header--switch .qodef-header-switch-navigation ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.qodef-header--switch .qodef-header-switch-navigation ul li {
    position: relative;
    padding: 0;
    transition: opacity .2s ease-out;
    transition-delay: .1s;
    opacity: 1;
    pointer-events: all
}

.qodef-header--switch .qodef-header-switch-navigation ul li a {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;
    text-transform: lowercase;
    font-size: 22px;
    font-weight: 400;
    white-space: nowrap
}

@media only screen and (max-width:1440px) {
    .qodef-header--switch .qodef-header-switch-navigation ul li a {
        font-size: 18px;
        line-height: 1.27778em
    }
}

.qodef-header--switch .qodef-header-switch-navigation ul li a>span {
    position: relative;
    padding-left: 7px
}

.qodef-header--switch .qodef-header-switch-navigation ul li a>span::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 100%;
    transform-origin: bottom;
    transition: transform .3s cubic-bezier(.25, .46, .35, .94), color .2s ease-out;
    transform: scaleY(0);
    background-color: currentColor
}

.qodef-header--switch .qodef-header-switch-navigation ul li a .qodef-menu-item-arrow {
    display: none
}

.qodef-header--switch .qodef-header-switch-navigation ul li a.qodef-wider {
    white-space: normal
}

.qodef-header--switch .qodef-header-switch-navigation ul li a:hover {
    color: currentColor
}

.qodef-header--switch .qodef-header-switch-navigation ul li a:hover>span::after {
    transform: scaleY(1);
    background-color: currentColor
}

.qodef-header--switch .qodef-header-switch-navigation ul li.current-menu-ancestor>a,
.qodef-header--switch .qodef-header-switch-navigation ul li.current-menu-item>a {
    color: currentColor
}

.qodef-header--switch .qodef-header-switch-navigation ul li.current-menu-ancestor>a>span::after,
.qodef-header--switch .qodef-header-switch-navigation ul li.current-menu-item>a>span::after {
    transform: scaleY(1);
    background-color: currentColor
}

.qodef-header--switch .qodef-header-switch-navigation ul li.qodef-menu--back a {
    text-transform: none;
    background-image: none
}

.qodef-header--switch .qodef-header-switch-navigation ul li.qodef-menu--back a::after {
    display: none !important
}

.qodef-header--switch .qodef-header-switch-navigation ul li .qodef-drop-down-second {
    transform: translateZ(0)
}

.qodef-header--switch .qodef-header-switch-navigation ul li .qodef-drop-down-second .sub-menu {
    position: absolute;
    left: 130%;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all .2s cubic-bezier(.46, .03, .52, .96)
}

.qodef-header--switch .qodef-header-switch-navigation ul li .qodef-drop-down-second li {
    margin: 7px 0
}

.qodef-header--switch .qodef-header-switch-navigation ul li .qodef-drop-down-second li a {
    position: relative;
    font-size: 18px
}

.qodef-header--switch .qodef-header-switch-navigation ul li .qodef-drop-down-second-inner {
    position: relative;
    display: block;
    padding: 0;
    z-index: 997
}

.qodef-header--switch .qodef-header-switch-navigation ul li.qodef--hide-link>a {
    cursor: default
}

.qodef-header--switch .qodef-header-switch-navigation ul li.qodef-menu-sibling--open {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0s
}

.qodef-header--switch .qodef-header-switch-navigation ul li.qodef-menu-sibling--open>a {
    opacity: 0;
    pointer-events: none !important
}

.qodef-header--switch .qodef-header-switch-navigation ul li.qodef-menu-item--open>a {
    opacity: 0;
    pointer-events: none
}

.qodef-header--switch .qodef-header-switch-navigation ul li.qodef-menu-item--open .qodef-drop-down-second-inner>.sub-menu,
.qodef-header--switch .qodef-header-switch-navigation ul li.qodef-menu-item--open>.sub-menu {
    left: 0;
    visibility: visible;
    opacity: 1;
    transition: all .3s cubic-bezier(.65, .05, .36, 1) 50ms
}

.qodef-header--switch .qodef-header-switch-navigation>ul {
    display: inline-block;
    max-width: 100%;
    margin-top: -7px
}

.qodef-header--switch .qodef-header-switch-navigation>ul>li {
    margin: 7px 0
}

.qodef-header--switch .qodef-header-switch-navigation>ul>li>a {
    position: relative;
    overflow: hidden
}

.qodef-header--switch .qodef-header-switch-navigation .qodef-menu-item-icon {
    margin-right: 5px
}

.qodef-header--switch .qodef-skin--light .qodef-header-switch-navigation ul li a {
    color: var(--qode-header-light-color) !important
}

.qodef-header--switch .qodef-skin--dark .qodef-header-switch-navigation ul li a {
    color: var(--qode-header-dark-color) !important
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid {
        width: 1100px
    }

    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 1100px)/ 2)
    }

    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 1100px)/ 2)
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid {
        width: 960px
    }

    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 960px)/ 2)
    }

    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 960px)/ 2)
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid {
        width: 768px
    }

    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 768px)/ 2)
    }

    .qodef-content-grid-1400.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 768px)/ 2)
    }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid {
        width: 1100px
    }

    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 1100px)/ 2)
    }

    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 1100px)/ 2)
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid {
        width: 960px
    }

    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 960px)/ 2)
    }

    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 960px)/ 2)
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid {
        width: 768px
    }

    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 768px)/ 2)
    }

    .qodef-content-grid-1300.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 768px)/ 2)
    }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid {
        width: 1100px
    }

    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 1100px)/ 2)
    }

    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 1100px)/ 2)
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid {
        width: 960px
    }

    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 960px)/ 2)
    }

    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 960px)/ 2)
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid {
        width: 768px
    }

    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 768px)/ 2)
    }

    .qodef-content-grid-1200.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 768px)/ 2)
    }
}

@media only screen and (min-width:1281px) and (max-width:1366px) {
    .qodef-content-grid-1100.qodef-header--vertical .qodef-content-grid {
        width: 960px
    }

    .qodef-content-grid-1100.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 960px)/ 2)
    }

    .qodef-content-grid-1100.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 960px)/ 2)
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1100.qodef-header--vertical .qodef-content-grid {
        width: 768px
    }

    .qodef-content-grid-1100.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--right {
        padding-left: calc((100% - 768px)/ 2)
    }

    .qodef-content-grid-1100.qodef-header--vertical .qodef-content-grid.qodef-extended-grid--left {
        padding-right: calc((100% - 768px)/ 2)
    }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 1100px
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 960px
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 768px
    }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 1100px
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 960px
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 768px
    }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 1100px
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 960px
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 768px
    }
}

@media only screen and (min-width:1281px) and (max-width:1366px) {
    .qodef-content-grid-1100.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 960px
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1100.qodef-header--vertical section.qodef-elementor-content-grid>.elementor-container {
        width: 768px
    }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 1100px)/ 2)
    }

    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 1100px)/ 2)
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 960px)/ 2)
    }

    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 960px)/ 2)
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 768px)/ 2)
    }

    .qodef-content-grid-1400.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 768px)/ 2)
    }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 1100px)/ 2)
    }

    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 1100px)/ 2)
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 960px)/ 2)
    }

    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 960px)/ 2)
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 768px)/ 2)
    }

    .qodef-content-grid-1300.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 768px)/ 2)
    }
}

@media only screen and (min-width:1441px) and (max-width:1680px) {
    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 1100px)/ 2)
    }

    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 1100px)/ 2)
    }
}

@media only screen and (min-width:1281px) and (max-width:1440px) {
    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 960px)/ 2)
    }

    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 960px)/ 2)
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 768px)/ 2)
    }

    .qodef-content-grid-1200.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 768px)/ 2)
    }
}

@media only screen and (min-width:1281px) and (max-width:1366px) {
    .qodef-content-grid-1100.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 960px)/ 2)
    }

    .qodef-content-grid-1100.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 960px)/ 2)
    }
}

@media only screen and (min-width:1025px) and (max-width:1280px) {
    .qodef-content-grid-1100.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--right>.elementor-container {
        padding-left: calc((100% - 768px)/ 2)
    }

    .qodef-content-grid-1100.qodef-header--vertical section.qodef-elementor-content-grid.qodef-extended-grid--left>.elementor-container {
        padding-right: calc((100% - 768px)/ 2)
    }
}

.qodef-header--vertical #qodef-page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 33%;
    height: 100%
}

.qodef-header--vertical #qodef-page-header .qodef-header-logo-link {
    box-sizing: content-box
}

.qodef-header--vertical #qodef-page-header-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 40px
}

.qodef-header--vertical #qodef-page-header-inner .qodef-widget-holder {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-left: 0
}

.qodef-header--vertical #qodef-page-header-inner .qodef-widget-holder .widget {
    margin: 0 0 15px
}

.qodef-header--vertical #qodef-page-header-inner .qodef-widget-holder .widget:last-child {
    margin-bottom: 0
}

.qodef-header--vertical #qodef-page-header-inner .qodef-widget-holder .widget.widget_block a {
    position: relative;
    display: inline-block;
    color: inherit;
    padding-bottom: 2px
}

.qodef-header--vertical #qodef-page-header-inner .qodef-widget-holder .widget.widget_block a:hover {
    color: var(--qode-main-color)
}

.qodef-header--vertical.admin-bar #qodef-page-header {
    top: 32px;
    height: calc(100% - 32px)
}

.qodef-header--vertical #qodef-page-footer,
.qodef-header--vertical #qodef-page-outer {
    padding-left: 33%
}

.qodef-header--vertical .qodef-header-vertical-navigation {
    position: relative;
    width: 100%;
    height: 100%;
    margin-top: 48px
}

.qodef-header--vertical .qodef-header-vertical-navigation ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li {
    position: relative;
    padding: 0;
    transition: opacity .2s ease-out;
    transition-delay: .1s;
    opacity: 1;
    pointer-events: all
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li a {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400;
    font-family: "Nunito Sans", sans-serif;
    text-transform: lowercase;
    font-size: 22px;
    font-weight: 400;
    white-space: nowrap
}

@media only screen and (max-width:1440px) {
    .qodef-header--vertical .qodef-header-vertical-navigation ul li a {
        font-size: 18px;
        line-height: 1.27778em
    }
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li a>span {
    position: relative;
    padding-left: 7px
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li a>span::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 100%;
    transform-origin: bottom;
    transition: transform .3s cubic-bezier(.25, .46, .35, .94), color .2s ease-out;
    transform: scaleY(0);
    background-color: currentColor
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li a .qodef-menu-item-arrow {
    display: none
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li a.qodef-wider {
    white-space: normal
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li.qodef-menu--back a {
    text-transform: none;
    background-image: none
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li.qodef-menu--back a::after {
    display: none !important
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second {
    transform: translateZ(0)
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second .sub-menu {
    position: absolute;
    left: 130%;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: all .2s cubic-bezier(.46, .03, .52, .96)
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li {
    margin: 12px 0
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li a {
    position: relative;
    font-size: 18px
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li>a {
    position: relative;
    overflow: hidden
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li>a:hover {
    color: var(--qode-main-color)
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li>a:hover>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li.current-menu-ancestor>a,
.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li.current-menu-item>a {
    color: var(--qode-main-color)
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li.current-menu-ancestor>a>span::after,
.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second li.current-menu-item>a>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li .qodef-drop-down-second-inner {
    position: relative;
    display: block;
    padding: 0;
    z-index: 997
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li.qodef--hide-link>a {
    cursor: default
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li.qodef-menu-sibling--open {
    opacity: 0;
    pointer-events: none;
    transition-delay: 0s
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li.qodef-menu-sibling--open>a {
    opacity: 0
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li.qodef-menu-item--open>a {
    opacity: 0;
    pointer-events: none
}

.qodef-header--vertical .qodef-header-vertical-navigation ul li.qodef-menu-item--open .qodef-drop-down-second-inner>.sub-menu,
.qodef-header--vertical .qodef-header-vertical-navigation ul li.qodef-menu-item--open>.sub-menu {
    left: 0;
    visibility: visible;
    opacity: 1;
    transition: all .3s cubic-bezier(.65, .05, .36, 1) 50ms
}

.qodef-header--vertical .qodef-header-vertical-navigation>ul {
    display: inline-block;
    max-width: 100%;
    margin-top: -3px
}

.qodef-header--vertical .qodef-header-vertical-navigation>ul>li {
    margin: 3px 0
}

.qodef-header--vertical .qodef-header-vertical-navigation>ul>li>a {
    position: relative;
    overflow: hidden
}

.qodef-header--vertical .qodef-header-vertical-navigation>ul>li>a:hover {
    color: var(--qode-main-color)
}

.qodef-header--vertical .qodef-header-vertical-navigation>ul>li>a:hover>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-header--vertical .qodef-header-vertical-navigation>ul>li.current-menu-ancestor>a,
.qodef-header--vertical .qodef-header-vertical-navigation>ul>li.current-menu-item>a {
    color: var(--qode-main-color)
}

.qodef-header--vertical .qodef-header-vertical-navigation>ul>li.current-menu-ancestor>a>span::after,
.qodef-header--vertical .qodef-header-vertical-navigation>ul>li.current-menu-item>a>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-header--vertical .qodef-header-vertical-navigation .qodef-menu-item-icon {
    margin-right: 5px
}

.qodef-header--vertical .qodef-skin--light .qodef-header-vertical-navigation ul li a {
    color: var(--qode-header-light-color) !important
}

.qodef-header--vertical .qodef-skin--dark .qodef-header-vertical-navigation ul li a {
    color: var(--qode-header-dark-color) !important
}

.qodef-header--fixed-display #qodef-page-header,
.qodef-header--fixed-display #qodef-top-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%
}

.qodef--boxed.qodef-header--fixed-display #qodef-top-area {
    left: 30px;
    width: calc(100% - 60px)
}

.qodef--boxed.qodef-header--fixed-display #qodef-page-header {
    left: 30px;
    width: calc(100% - 60px)
}

.qodef-header--fixed-display.admin-bar #qodef-page-header,
.qodef-header--fixed-display.admin-bar #qodef-top-area {
    top: 32px
}

.qodef-header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    border-bottom: 1px solid #e3e3e3;
    background-color: #fff;
    transform: translateY(-100%);
    transition: transform .5s ease, top .5s ease;
    z-index: 100
}

.qodef-header--sticky-display .qodef-header-sticky {
    transform: translateY(0)
}

.qodef-header--sticky-display.qodef-side-area--opened .qodef-header-sticky {
    transform: translateY(-100%)
}

.admin-bar.qodef-header--sticky-display .qodef-header-sticky {
    top: 32px
}

.qodef-header-sticky .qodef-widget-holder {
    margin-left: 60px !important
}

.qodef-header-sticky .qodef-widget-holder .widget {
    margin: 0 30px !important
}

.qodef-header-sticky .qodef-widget-holder .widget:first-child {
    margin-left: 0 !important
}

.qodef-header-sticky .qodef-widget-holder .widget:last-child {
    margin-right: 0 !important
}

.qodef-header-sticky .qodef-widget-holder .widget.widget_level_core_woo_dropdown_cart {
    margin-right: 12px !important
}

.qodef-header-sticky-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 50px
}

.qodef-header-sticky-inner.qodef-content-grid {
    padding: 0
}

.qodef-header-standard--right .qodef-header-sticky-inner .qodef-header-logo-link {
    margin-right: auto
}

.qodef-header-standard--left .qodef-header-sticky-inner {
    justify-content: flex-start
}

.qodef-header-standard--left .qodef-header-sticky-inner .qodef-header-logo-link {
    margin-right: 20px
}

.qodef-header-standard--left .qodef-header-sticky-inner .qodef-header-navigation {
    margin-left: 20px;
    margin-right: auto
}

#qodef-top-area {
    position: relative;
    background-color: #000;
    z-index: 101
}

#qodef-top-area .qodef-widget-holder {
    display: flex;
    align-items: center;
    height: 100%
}

#qodef-top-area .qodef-widget-holder.qodef--left {
    margin-right: auto
}

#qodef-top-area .qodef-widget-holder.qodef--right {
    margin-left: auto
}

#qodef-top-area .qodef-widget-holder .widget {
    margin: 0 12px
}

#qodef-top-area .qodef-widget-holder .widget:first-child {
    margin-left: 0
}

#qodef-top-area .qodef-widget-holder .widget:last-child {
    margin-right: 0
}

#qodef-top-area .qodef-widget-holder .widget.widget_text p {
    margin: 0
}

#qodef-top-area .widget {
    display: inline-block;
    vertical-align: middle;
    line-height: 1
}

#qodef-top-area .widget .textwidget>*,
#qodef-top-area .widget .widget p {
    margin: 0
}

#qodef-top-area div[id^=monster-widget-placeholder],
#qodef-top-area div[id^=woo-monster-widget-placeholder] {
    display: none
}

#qodef-top-area-inner {
    align-items: center;
    display: flex;
    height: 30px;
    justify-content: space-between;
    padding: 0 50px;
    position: relative;
    border-bottom: 0 solid transparent
}

#qodef-top-area-inner.qodef-content-grid {
    padding: 0 !important
}

#qodef-top-area-inner.qodef-alignment--center .qodef-widget-holder {
    margin: 0 auto
}

#qodef-multiple-map-notice {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 30px 15%;
    text-align: center;
    color: var(--qode-main-color);
    background-color: #fafafa
}

.qodef-map-marker-holder {
    transform: translate(-50%, -100%);
    z-index: 10
}

.qodef-map-marker-holder.qodef-active {
    z-index: 500
}

.qodef-map-marker-holder.qodef-active .qodef-map-marker svg path:first-child {
    fill: #3ebeb4
}

.qodef-map-marker-holder.qodef-active .qodef-map-marker svg path:last-child {
    fill: #51a69e
}

.qodef-map-marker-holder#qodef-geo-location-marker .qodef-info-window {
    display: block
}

.qodef-map-marker-holder#qodef-geo-location-marker .qodef-map-marker svg path:first-child {
    fill: #3ebeb4
}

.qodef-map-marker-holder#qodef-geo-location-marker .qodef-map-marker svg path:last-child {
    fill: #51a69e
}

.qodef-map-marker-holder .qodef-map-marker {
    position: relative;
    width: 50px;
    height: auto;
    transition: all .15s ease-out
}

.qodef-map-marker-holder .qodef-map-marker .qodef-map-marker-inner {
    position: relative;
    width: 100%;
    height: 100%
}

.qodef-map-marker-holder .qodef-map-marker svg {
    width: 100%;
    height: auto
}

.qodef-map-marker-holder .qodef-map-marker svg path {
    transition: fill .2s ease-in-out
}

.qodef-map-marker-holder .qodef-map-marker:hover svg path:first-child {
    fill: #3ebeb4
}

.qodef-map-marker-holder .qodef-map-marker:hover svg path:last-child {
    fill: #51a69e
}

.qodef-map-marker-holder .qodef-info-window {
    position: absolute;
    top: -10px;
    left: -17px;
    display: none;
    min-width: 284px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, .5);
    z-index: 10;
    transition: opacity .3s ease;
    transform: translate(0, -100%)
}

.qodef-map-marker-holder .qodef-info-window-inner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px
}

.qodef-map-marker-holder .qodef-info-window-inner:after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 35px;
    width: 5px;
    height: 5px;
    background-color: #fff;
    border-top: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    transform: rotate(-135deg)
}

.qodef-map-marker-holder .qodef-info-window-inner .qodef-info-window-image {
    margin-right: 12px
}

.qodef-map-marker-holder .qodef-info-window-inner .qodef-info-window-image img {
    display: block;
    width: 65px
}

.qodef-map-marker-holder .qodef-info-window-inner .qodef-info-window-title {
    margin: 0;
    transition: color .2s ease-out
}

.qodef-map-marker-holder .qodef-info-window-inner .qodef-info-window-location {
    margin: 3px 0 0;
    font-family: "Nunito Sans", sans-serif;
    font-size: 14px;
    line-height: 1.2em
}

.qodef-map-marker-holder .qodef-info-window-inner .qodef-info-window-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5
}

.qodef-map-marker-holder .qodef-info-window-inner .qodef-info-window-link:hover~.qodef-info-window-details .qodef-info-window-title {
    color: var(--qode-main-color)
}

.qodef-cluster-marker {
    width: 50px;
    height: auto
}

.qodef-cluster-marker.qodef-active svg path:first-child,
.qodef-cluster-marker:hover svg path:first-child {
    fill: #3ebeb4
}

.qodef-cluster-marker.qodef-active svg path:last-child,
.qodef-cluster-marker:hover svg path:last-child {
    fill: #51a69e
}

.qodef-cluster-marker .qodef-cluster-marker-inner {
    position: relative
}

.qodef-cluster-marker .qodef-cluster-marker-inner .qodef-cluster-marker-number {
    position: absolute;
    top: -4px;
    left: 0;
    right: 0;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #000
}

.qodef-cluster-marker svg {
    width: 100%;
    height: auto
}

.qodef-cluster-marker svg path {
    transition: fill .2s ease-in-out
}

.qodef-places-geo-radius {
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    width: 100%;
    height: 48px;
    display: none;
    padding: 10px 20px;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    z-index: 9999
}

.qodef-places-geo-radius .qodef-range-slider-label {
    display: inline-flex;
    align-items: center;
    height: 100%
}

.qodef-places-geo-radius .noUi-target {
    position: relative;
    display: inline-flex;
    height: 3px;
    width: calc(100% - 70px);
    background-color: #eaeaea;
    border: 0;
    direction: ltr;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    touch-action: none;
    -ms-user-select: none;
    user-select: none;
    margin-left: 10px
}

.qodef-places-geo-radius .noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    touch-action: none;
    -ms-user-select: none;
    user-select: none
}

.qodef-places-geo-radius .noUi-target .noUi-base {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1
}

.qodef-places-geo-radius .noUi-target .noUi-connects {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0
}

.qodef-places-geo-radius .noUi-target .noUi-connect,
.qodef-places-geo-radius .noUi-target .noUi-origin {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    will-change: transform;
    transform-origin: 0 0
}

.qodef-places-geo-radius .noUi-target .noUi-connect {
    background-color: var(--qode-main-color)
}

.qodef-places-geo-radius .noUi-target .noUi-handle {
    position: absolute;
    top: -3px;
    left: auto;
    right: -9px;
    width: 9px;
    height: 9px;
    background-color: var(--qode-main-color);
    cursor: pointer
}

.qodef-places-geo-radius .noUi-target .noUi-tooltip {
    position: absolute;
    left: 50%;
    bottom: 7px;
    display: block;
    font-size: 12px;
    white-space: nowrap;
    transform: translateX(-50%)
}

.qodef-places-geo-radius .noUi-target .noUi-tooltip:after {
    content: ' km'
}

.qodef-places-geo-radius .noUi-state-drag * {
    cursor: inherit !important
}

.qodef-places-geo-radius .noUi-draggable {
    cursor: ew-resize
}

.qodef-places-geo-radius [disabled] .noUi-handle,
.qodef-places-geo-radius [disabled].noUi-handle,
.qodef-places-geo-radius [disabled].noUi-target {
    cursor: not-allowed !important
}

#qodef-page-mobile-header {
    background-color: #ffffff00
}

#qodef-page-mobile-header .qodef-widget-holder {
    display: flex;
    align-items: center;
    height: 100%
}

#qodef-page-mobile-header .qodef-widget-holder .widget {
    margin: 0 0 0 12px
}

#qodef-page-mobile-header .widget {
    display: inline-block;
    vertical-align: middle;
    line-height: 1
}

#qodef-page-mobile-header .widget .textwidget>*,
#qodef-page-mobile-header .widget .widget p {
    margin: 0
}

#qodef-page-mobile-header div[id^=monster-widget-placeholder],
#qodef-page-mobile-header div[id^=woo-monster-widget-placeholder] {
    display: none
}

#qodef-page-mobile-header .qodef-mobile-header-opener.qodef--opened {
    color: var(--qode-main-color)
}

#qodef-page-mobile-header .qodef-mobile-header-opener.qodef--opened .qodef-m-icon {
    visibility: hidden
}

#qodef-page-mobile-header .qodef-mobile-header-opener.qodef--opened .qodef-m-icon.qodef--close {
    visibility: visible
}

.qodef-mobile-header--sticky #qodef-page-mobile-header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(-100%)
}

.qodef-mobile-header--sticky-animation #qodef-page-mobile-header {
    transition: transform .3s ease, top .3s ease
}

.qodef-mobile-header--sticky-display #qodef-page-mobile-header {
    transform: translateY(0)
}

.admin-bar.qodef-mobile-header--sticky-display #qodef-page-mobile-header {
    top: 46px
}

.qodef--passepartout #qodef-page-mobile-header-inner {
    padding: 0
}

.qodef-mobile-header-navigation ul li .qodef-content-grid {
    width: 100%
}

#qodef-page-mobile-header .qodef-header-logo-image.qodef--customizer {
    max-height: 100%;
    width: auto;
    margin-right: auto
}

#qodef-page-mobile-header .qodef-mobile-header-logo-link img.qodef--main {
    opacity: 1
}

#qodef-page-mobile-header .qodef-mobile-header-logo-link img.qodef--dark,
#qodef-page-mobile-header .qodef-mobile-header-logo-link img.qodef--light {
    opacity: 0
}

#qodef-page-mobile-header .qodef-mobile-header-logo-link img:not(.qodef--main) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%)
}

#qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--svg-path svg {
    display: block;
    width: 50px;
    height: auto;
    fill: currentColor;
    stroke: currentColor
}

#qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--textual {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 24px;
    line-height: 1.20833em;
    font-weight: 400;
    transition: color .2s ease-out
}

@media only screen and (max-width:1440px) {
    #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--textual {
        font-size: 22px;
        line-height: 1.22727em
    }
}

.qodef-mobile-header-navigation ul li a .qodef-menu-item-icon {
    margin-right: 5px
}

.qodef-mobile-header-navigation ul li.qodef--hide-link>a {
    cursor: default
}

.qodef-mobile-header-navigation .qodef-mega-menu-widget-holder {
    display: none
}

.qodef-mobile-header--minimal #qodef-page-mobile-header-inner {
    justify-content: flex-end
}

.qodef-mobile-header--minimal #qodef-page-mobile-header-inner .qodef-mobile-header-logo-link {
    margin-right: auto
}

.qodef-mobile-header--minimal #qodef-page-mobile-header-inner .qodef-opener-icon {
    margin-left: 20px
}

.qodef-fullscreen-menu--opened {
    overflow-y: hidden
}

.qodef-fullscreen-menu--opened #qodef-page-mobile-header .qodef-mobile-header-logo-link img.qodef--light {
    opacity: 1
}

.qodef-fullscreen-menu--opened #qodef-page-mobile-header .qodef-mobile-header-logo-link img.qodef--dark,
.qodef-fullscreen-menu--opened #qodef-page-mobile-header .qodef-mobile-header-logo-link img.qodef--main {
    opacity: 0
}

.qodef-fullscreen-menu--opened #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--svg-path,
.qodef-fullscreen-menu--opened #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--textual {
    color: var(--qode-header-light-color) !important
}

.qodef-fullscreen-menu--opened #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--svg-path:hover,
.qodef-fullscreen-menu--opened #qodef-page-mobile-header .qodef-mobile-header-logo-link.qodef-source--textual:hover {
    color: var(--qode-header-light-hover-color) !important
}

.qodef-mobile-header--side-area #qodef-page-mobile-header-inner .qodef-side-area-mobile-header-opener {
    margin-left: 20px
}

#qodef-side-area-mobile-header {
    position: fixed;
    top: 0;
    right: -340px;
    width: 340px;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 70px 60px;
    background-color: #000;
    overflow: hidden;
    visibility: hidden;
    z-index: 9999;
    transition: all .6s cubic-bezier(.77, 0, .175, 1)
}

#qodef-side-area-mobile-header .qodef-m-close {
    position: absolute;
    top: 26px;
    right: 40px;
    display: block;
    z-index: 1000;
    color: #fff
}

#qodef-side-area-mobile-header .qodef-m-navigation {
    width: 100%
}

#qodef-side-area-mobile-header .qodef-m-navigation ul {
    margin: 0;
    padding: 0;
    list-style: none
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li {
    position: relative;
    padding: 0;
    margin: 0
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li .qodef-drop-down-second {
    display: none;
    transform: translateZ(0)
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li .qodef-drop-down-second-inner {
    position: relative;
    display: block;
    padding: 0;
    z-index: 997
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li .qodef-menu-item-arrow {
    position: absolute;
    top: 14px;
    right: 0;
    width: 7px;
    height: auto;
    color: #fff;
    z-index: 9;
    cursor: pointer;
    transition: color .2s ease-out;
    transform: rotate(0);
    transition: transform .2s ease-out, color .2s ease-out
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li .qodef-menu-item-arrow:hover {
    color: #fff
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li ul li>a {
    position: relative;
    display: flex;
    white-space: normal;
    margin: 8px 0;
    overflow: hidden;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 1.6875em;
    font-weight: 400;
    color: #fff
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li ul li>a .qodef-menu-item-icon {
    flex-shrink: 0
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li ul li>a>span {
    --qodef-underline-position-y: calc(100% - 3px);
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
    transition: background-size .5s cubic-bezier(.25, .46, .35, .94), color .2s ease-out
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li ul li.current-menu-ancestor>a>span,
#qodef-side-area-mobile-header .qodef-m-navigation ul li ul li.current-menu-item>a>span,
#qodef-side-area-mobile-header .qodef-m-navigation ul li ul li:hover>a>span {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li ul ul {
    display: none
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li.qodef--hide-link>a {
    cursor: default
}

#qodef-side-area-mobile-header .qodef-m-navigation ul li.qodef-menu-item--open>.qodef-menu-item-arrow {
    transform: rotate(90deg)
}

#qodef-side-area-mobile-header .qodef-m-navigation .qodef-menu-item-icon {
    margin-right: 5px
}

#qodef-side-area-mobile-header .qodef-m-navigation .qodef-mega-menu-widget-holder {
    display: none
}

#qodef-side-area-mobile-header .qodef-m-navigation>ul>li>a {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400;
    color: #fff;
    font-family: "Nunito Sans", sans-serif;
    text-transform: lowercase
}

@media only screen and (max-width:1440px) {
    #qodef-side-area-mobile-header .qodef-m-navigation>ul>li>a {
        font-size: 18px;
        line-height: 1.27778em
    }
}

#qodef-side-area-mobile-header .qodef-m-navigation>ul>li>a>span {
    position: relative;
    padding-left: 7px
}

#qodef-side-area-mobile-header .qodef-m-navigation>ul>li>a>span::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 100%;
    transform-origin: bottom;
    transition: transform .3s cubic-bezier(.25, .46, .35, .94), color .2s ease-out;
    transform: scaleY(0);
    background-color: #fff
}

#qodef-side-area-mobile-header .qodef-m-navigation>ul>li>a:hover {
    color: var(--qode-main-color)
}

#qodef-side-area-mobile-header .qodef-m-navigation>ul>li>a:hover>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

#qodef-side-area-mobile-header .qodef-m-navigation>ul>li.current-menu-ancestor>a,
#qodef-side-area-mobile-header .qodef-m-navigation>ul>li.current-menu-item>a {
    color: var(--qode-main-color)
}

#qodef-side-area-mobile-header .qodef-m-navigation>ul>li.current-menu-ancestor>a>span::after,
#qodef-side-area-mobile-header .qodef-m-navigation>ul>li.current-menu-item>a>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

#qodef-side-area-mobile-header .qodef-widget-holder {
    position: absolute;
    left: 60px;
    bottom: 20px;
    width: calc(100% - 2*60px);
    height: auto
}

#qodef-side-area-mobile-header .qodef-widget-holder .widget {
    margin: 0
}

.admin-bar #qodef-side-area-mobile-header {
    top: 32px;
    height: calc(100vh - 32px)
}

#qodef-side-area-mobile-header.qodef--opened {
    right: 0;
    visibility: visible
}

.qodef-mobile-header--standard #qodef-page-mobile-header-inner {
    justify-content: flex-end
}

.qodef-mobile-header--standard #qodef-page-mobile-header-inner .qodef-mobile-header-logo-link {
    margin-right: auto
}

.qodef-header-navigation .qodef-menu-item-icon {
    display: block;
    margin-right: 5px;
    line-height: inherit
}

.qodef-header-navigation .qodef-menu-item-icon:before {
    display: block;
    line-height: inherit
}

.qodef-header-navigation ul li .qodef-drop-down-second {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    width: auto;
    height: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 10
}

.qodef-header-navigation ul li .qodef-drop-down-second.qodef-drop-down--start {
    opacity: 1;
    visibility: visible;
    overflow: visible;
    z-index: 20;
    transition: opacity .2s ease-out
}

.qodef-header-navigation ul li .qodef-mega-menu-widget-holder {
    display: none;
    white-space: normal
}

.qodef-header-navigation ul li.qodef-menu-item--narrow .qodef-drop-down-second.qodef-drop-down--right {
    left: auto;
    right: 0
}

.qodef-header-navigation ul li.qodef-menu-item--narrow .qodef-drop-down-second-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-header-navigation ul li.qodef-menu-item--narrow .qodef-drop-down-second-inner>ul {
    position: relative;
    top: auto;
    opacity: 1;
    visibility: visible
}

.qodef-header-navigation ul li.qodef-menu-item--narrow .qodef-drop-down-second-inner ul li>a {
    font-size: 15px
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-mega-menu-widget-holder {
    display: block
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second {
    background-color: #fff;
    border: 1px solid #e3e3e3
}

.qodef--boxed .qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner,
.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner:not(.qodef-content-grid) {
    padding: 0 50px
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul {
    display: flex;
    justify-content: space-between;
    padding: 49px 0 48px;
    margin: 0 -15px
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li {
    width: 100%;
    padding: 0 15px
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li>a {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "Nunito Sans", sans-serif;
    text-transform: lowercase;
    font-size: 12px
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li>a>span {
    position: relative;
    padding-left: 7px
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li>a>span::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 1px;
    height: 100%;
    transform-origin: bottom;
    transition: transform .3s cubic-bezier(.25, .46, .35, .94), color .2s ease-out;
    transform: scaleY(0);
    background-color: currentColor
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li>a>span {
    padding-left: 0;
    text-transform: uppercase
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li>a>span::after {
    height: 1px;
    width: 0
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li>a:hover {
    color: var(--qode-main-color)
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li>a:hover>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li>a:hover::after {
    height: 1px;
    width: 100%
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li .qodef-menu-item-arrow {
    display: none
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li ul {
    padding-top: 6px
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li ul li>a {
    position: relative;
    display: flex;
    white-space: normal;
    margin: 8px 0;
    overflow: hidden;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 1.6875em;
    font-weight: 400;
    color: #000;
    font-size: 15px
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li ul li>a .qodef-menu-item-icon {
    flex-shrink: 0
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li ul li>a>span {
    --qodef-underline-position-y: calc(100% - 3px);
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
    transition: background-size .5s cubic-bezier(.25, .46, .35, .94), color .2s ease-out
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li ul li.current-menu-item>a>span,
.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li ul li:hover>a>span {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li.current-menu-ancestor>a,
.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li.current-menu-item>a {
    color: var(--qode-main-color)
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li.current-menu-ancestor>a>span::after,
.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li.current-menu-item>a>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li.qodef--hide-link:not(.current-menu-ancestor):not(.current-menu-item)>a:hover {
    color: var(--qode-main-color)
}

.qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner>ul>li.qodef--hide-link:not(.current-menu-ancestor):not(.current-menu-item)>a:hover>span::after {
    transform: scaleY(1);
    background-color: var(--qode-main-color)
}

.qodef-header-navigation>ul>li>a {
    font-size: 18px
}

body:not(.qodef-drop-down-second--full-width) .qodef-header-navigation ul li.qodef-menu-item--wide .qodef-drop-down-second-inner {
    padding: 0 50px
}

.qodef-opener-icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: 1
}

.qodef-opener-icon .qodef-m-icon {
    position: relative;
    visibility: visible
}

.qodef-opener-icon .qodef-m-icon.qodef--close {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden
}

.qodef-opener-icon.qodef-source--icon-pack {
    font-size: 35px
}

.qodef-opener-icon.qodef-source--icon-pack * {
    display: inline-block;
    line-height: inherit
}

.qodef-opener-icon.qodef-source--icon-pack :before {
    display: inline-block;
    line-height: inherit
}

.qodef-opener-icon.qodef-source--icon-pack.qodef--opened .qodef-m-icon.qodef--open {
    visibility: hidden
}

.qodef-opener-icon.qodef-source--icon-pack.qodef--opened .qodef-m-icon.qodef--close {
    visibility: visible
}

.qodef-opener-icon.qodef-source--svg-path span {
    position: relative
}

.qodef-opener-icon.qodef-source--svg-path span>* {
    vertical-align: middle
}

.qodef-opener-icon.qodef-source--svg-path span path,
.qodef-opener-icon.qodef-source--svg-path span>* {
    fill: currentColor;
    stroke: currentColor
}

.qodef-opener-icon.qodef-source--svg-path.qodef--opened .qodef-m-icon.qodef--open {
    visibility: hidden
}

.qodef-opener-icon.qodef-source--svg-path.qodef--opened .qodef-m-icon.qodef--close {
    visibility: visible
}

.qodef-opener-icon.qodef-source--predefined .qodef-m-lines {
    position: relative;
    display: block;
    width: 20px;
    height: 13px
}

.qodef-opener-icon.qodef-source--predefined .qodef-m-line {
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: currentColor;
    transition: top .15s ease, transform .3s ease
}

.qodef-opener-icon.qodef-source--predefined .qodef-m-line.qodef--1 {
    top: 0
}

.qodef-opener-icon.qodef-source--predefined .qodef-m-line.qodef--2 {
    top: 6px
}

.qodef-opener-icon.qodef-source--predefined .qodef-m-line.qodef--3 {
    bottom: 0
}

.qodef-opener-icon.qodef-source--predefined.qodef--opened .qodef-m-line.qodef--1 {
    top: 6px;
    transform: rotate(45deg)
}

.qodef-opener-icon.qodef-source--predefined.qodef--opened .qodef-m-line.qodef--2 {
    top: 6px;
    transform: rotate(-45deg)
}

.qodef-opener-icon.qodef-source--predefined.qodef--opened .qodef-m-line.qodef--3 {
    opacity: 0
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu {
    display: block;
    transform: rotate(-80deg);
    transition: transform 1.6s
}

.qodef-browser--firefox .qodef-opener-icon.qodef-source--predefined .qodef-svg--menu {
    transform: none
}

.qodef-opener-icon.qodef-source--predefined .qodef-svg--menu path {
    stroke-dasharray: 265;
    stroke-dashoffset: 530;
    transition: stroke-dashoffset 1.6s
}

.qodef-opener-icon.qodef-source--predefined:hover .qodef-svg--menu {
    transform: rotate(80deg) translateZ(0)
}

.qodef-browser--firefox .qodef-opener-icon.qodef-source--predefined:hover .qodef-svg--menu {
    transform: none
}

.qodef-opener-icon.qodef-source--predefined:hover .qodef-svg--menu path {
    stroke-dashoffset: 0
}

.qodef-opener-icon.qodef-source--predefined:hover .qodef-svg--menu-close {
    animation: qode-draw-line .7s cubic-bezier(.22, .61, .36, 1)
}

.qodef-parallax {
    position: relative;
    width: 100%;
    overflow: hidden
}

.qodef-parallax.elementor-section {
    overflow: visible
}

.qodef-parallax-row-holder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden
}

.qodef-parallax-img-holder {
    position: absolute;
    top: -50%;
    height: 150%;
    left: 0;
    width: 100%;
    opacity: 0
}

.qodef-browser--edge .qodef-parallax-img-holder,
.qodef-browser--ms-explorer .qodef-parallax-img-holder,
.touchevents .qodef-parallax-img-holder {
    top: 0;
    height: 100%;
    opacity: 1
}

.qodef-parallax-content-holder {
    position: relative;
    z-index: 99
}

.qodef-parallax-img-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    will-change: transform
}

.qodef-parallax-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.touchevents .qodef-parallax-img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.qodef-browser--edge .qodef-parallax-img,
.qodef-browser--ms-explorer .qodef-parallax-img {
    height: 100%
}

.qodef-contact-form-7,
.rs-layer {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-contact-form-7 p,
.rs-layer p {
    margin: 0
}

.qodef-contact-form-7 br,
.rs-layer br {
    display: none
}

.qodef-contact-form-7 .ajax-loader,
.rs-layer .ajax-loader {
    display: none
}

.qodef-contact-form-7 .wpcf7-form-control-wrap,
.rs-layer .wpcf7-form-control-wrap {
    display: block;
    max-width: 100%
}

.qodef-contact-form-7 .wpcf7-not-valid-tip,
.rs-layer .wpcf7-not-valid-tip {
    margin: 10px 0 0 0
}

.qodef-contact-form-7 .wpcf7-response-output,
.rs-layer .wpcf7-response-output {
    border-width: 1px !important;
    margin: 2em 0 0 0 !important;
    padding: 1em 1.5em !important
}

.qodef-contact-form-7 .wpcf7-form,
.rs-layer .wpcf7-form {
    position: relative
}

.qodef-contact-form-7 .wpcf7-form:before,
.rs-layer .wpcf7-form:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    content: '';
    visibility: hidden;
    z-index: 98
}

.qodef-contact-form-7 .wpcf7-form:after,
.rs-layer .wpcf7-form:after {
    animation: qode-rotate 2s linear infinite;
    background-color: transparent;
    border: 2px solid #fafafa;
    border-radius: 50%;
    border-top: 2px solid var(--qode-main-color);
    content: '';
    height: 24px;
    left: calc(50% - 12px);
    opacity: 1;
    position: absolute;
    top: calc(50% - 12px);
    visibility: hidden;
    width: 24px;
    z-index: 99
}

.qodef-contact-form-7 .wpcf7-form.submitting>*,
.rs-layer .wpcf7-form.submitting>* {
    opacity: .3
}

.qodef-contact-form-7 .wpcf7-form.submitting:after,
.qodef-contact-form-7 .wpcf7-form.submitting:before,
.rs-layer .wpcf7-form.submitting:after,
.rs-layer .wpcf7-form.submitting:before {
    visibility: visible
}

.wpcf7 .qodef-contact-form input[type=email],
.wpcf7 .qodef-contact-form input[type=tel],
.wpcf7 .qodef-contact-form input[type=text],
.wpcf7 .qodef-contact-form textarea {
    margin-bottom: 15px;
    border-color: #848484
}



.wpcf7 .qodef-contact-form input[type=email]:focus,
.wpcf7 .qodef-contact-form input[type=tel]:focus,
.wpcf7 .qodef-contact-form input[type=text]:focus,
.wpcf7 .qodef-contact-form textarea:focus {
    border-color: var(--qode-main-color)
}

.wpcf7 .qodef-contact-form input[type=email]:-ms-input-placeholder,
.wpcf7 .qodef-contact-form input[type=tel]:-ms-input-placeholder,
.wpcf7 .qodef-contact-form input[type=text]:-ms-input-placeholder,
.wpcf7 .qodef-contact-form textarea:-ms-input-placeholder {
    color: #acacac;
}

.wpcf7 .qodef-contact-form input[type=email]::placeholder,
.wpcf7 .qodef-contact-form input[type=tel]::placeholder,
.wpcf7 .qodef-contact-form input[type=text]::placeholder,
.wpcf7 .qodef-contact-form textarea::placeholder {
    color: #2e2e2e;
}

.wpcf7 .qodef-contact-form button[type=submit] {
    margin-top: 28px
}

@media only screen and (max-width:680px) {
    .wpcf7 .qodef-contact-form .qodef-grid.qodef-col-num--2 .qodef-grid-item {
        width: 100% !important
    }
}

.wpcf7 .qodef-contact-form-2 .wpcf7-form-control-wrap {
    margin: 0 0 1px !important
}

.wpcf7 .qodef-contact-form-2 h3 {
    margin-bottom: 52px
}

.wpcf7 .qodef-contact-form-2 input[type=email],
.wpcf7 .qodef-contact-form-2 input[type=tel],
.wpcf7 .qodef-contact-form-2 input[type=text],
.wpcf7 .qodef-contact-form-2 textarea {
    margin-bottom: 15px;
    border-color: #acacac
}

.wpcf7 .qodef-contact-form-2 input[type=email]:focus,
.wpcf7 .qodef-contact-form-2 input[type=tel]:focus,
.wpcf7 .qodef-contact-form-2 input[type=text]:focus,
.wpcf7 .qodef-contact-form-2 textarea:focus {
    border-color: var(--qode-main-color)
}

.wpcf7 .qodef-contact-form-2 input[type=email]:-ms-input-placeholder,
.wpcf7 .qodef-contact-form-2 input[type=tel]:-ms-input-placeholder,
.wpcf7 .qodef-contact-form-2 input[type=text]:-ms-input-placeholder,
.wpcf7 .qodef-contact-form-2 textarea:-ms-input-placeholder {
    color: #acacac
}

.wpcf7 .qodef-contact-form-2 input[type=email]::placeholder,
.wpcf7 .qodef-contact-form-2 input[type=tel]::placeholder,
.wpcf7 .qodef-contact-form-2 input[type=text]::placeholder,
.wpcf7 .qodef-contact-form-2 textarea::placeholder {
    color: #acacac
}

.wpcf7 .qodef-contact-form-2 button[type=submit] {
    margin-top: 39px
}

.wpcf7 .qodef-newsletter {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 45px 30px 53px;
    background-color: #3a3a3a
}

.wpcf7 .qodef-newsletter h4 {
    color: #fff;
    margin-bottom: 36px
}

.wpcf7 .qodef-newsletter .wpcf7-form-control-wrap {
    width: 100%;
    margin-bottom: 0
}

.wpcf7 .qodef-newsletter input[type=email] {
    padding: 8px 0;
    border-color: #fff
}

.wpcf7 .qodef-newsletter input[type=email]:focus {
    border-color: var(--qode-main-color)
}

.wpcf7 .qodef-newsletter input[type=email]:-ms-input-placeholder {
    color: #acacac
}

.wpcf7 .qodef-newsletter input[type=email]::placeholder {
    color: #acacac
}

.wpcf7 .qodef-newsletter button[type=submit] {
    justify-content: center;
    width: 100%;
    margin-top: 36px;
    border-color: #fff;
    color: #fff;
    --qodef-hover-bg-color: #fff
}

.wpcf7 .qodef-newsletter button[type=submit]:hover {
    color: #000
}

.qodef-instagram-list #sb_instagram {
    padding: 0 !important;
    overflow: hidden !important
}

html.elementor-html .qodef-instagram-list #sb_instagram #sbi_images .sbi_item.sbi_transition {
    opacity: 1 !important
}

.qodef-instagram-list #sb_instagram #sbi_images .sbi_item .sbi_photo {
    transition: .2s ease-out
}

.qodef-instagram-list #sb_instagram #sbi_images .sbi_item .sbi_photo:hover {
    opacity: 1 !important
}

.qodef-instagram-list .sbi_photo_wrap a {
    background-image: none !important;
    height: auto !important;
    padding-bottom: 0 !important
}

.qodef-instagram-list .sbi_photo_wrap a:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    transition: .2s ease-out;
    pointer-events: none
}

.qodef-instagram-list .sbi_photo_wrap a:after {
    color: #fff;
    content: '\f16d';
    font-family: 'Font Awesome 5 Brands';
    font-size: 20px;
    right: 12px;
    opacity: 0;
    position: absolute;
    top: 18px;
    transition: opacity .2s ease-out
}

.qodef-instagram-list .sbi_photo_wrap a:hover:before {
    opacity: .5
}

.qodef-instagram-list .sbi_photo_wrap a:hover:after {
    opacity: 1
}

.qodef-instagram-list .sbi_photo_wrap a img {
    display: inline-block !important
}

.qodef-instagram-list.qodef-swiper-container #sb_instagram #sbi_images .sbi_item {
    margin: inherit !important
}

.qodef-twitter-list.qodef-layout--columns.qodef--no-bottom-info #ctf .ctf-tweet-actions {
    display: none
}

.qodef-twitter-list.qodef-layout--columns #ctf {
    overflow: hidden
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-item {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    float: left;
    border: 0
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-author-box {
    margin-left: 0;
    display: block;
    margin-bottom: 8px
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-author-box-link {
    line-height: 1 !important;
    display: flex;
    align-items: center;
    flex-flow: row wrap
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-author-avatar {
    width: 37px;
    height: 37px;
    margin: 0 7px 0 0;
    float: none;
    border-radius: 0
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-author-name {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400;
    font-weight: 600 !important;
    margin: 0 4px 0 0
}

@media only screen and (max-width:1440px) {
    .qodef-twitter-list.qodef-layout--columns #ctf .ctf-author-name {
        font-size: 18px;
        line-height: 1.27778em
    }
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-screename-sep {
    display: none
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-context,
.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-meta,
.qodef-twitter-list.qodef-layout--columns #ctf .ctf-twitterlink {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 1.6875em;
    font-weight: 400;
    color: #000;
    margin-right: 4px;
    margin-left: 0
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-context:last-child,
.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-meta:last-child,
.qodef-twitter-list.qodef-layout--columns #ctf .ctf-twitterlink:last-child {
    margin-right: 0
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-meta {
    margin-top: 4px
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-author-screenname {
    display: none
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-content {
    display: flex;
    align-items: baseline;
    margin-left: 0;
    padding: 0;
    margin-top: -5px
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-content:before {
    content: '\e094';
    font-family: ElegantIcons;
    padding-right: 10px;
    font-size: 18px;
    position: relative;
    top: 5px
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-actions {
    display: flex;
    align-items: baseline;
    margin-left: 0;
    margin-top: -3px
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-actions:before {
    content: '\e094';
    font-family: ElegantIcons;
    padding-right: 10px;
    font-size: 18px;
    visibility: hidden
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-actions a {
    padding: 0;
    font-size: 14px;
    margin-right: 8px
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-actions a:last-child {
    margin-right: 0
}

.qodef-twitter-list.qodef-layout--columns #ctf .ctf-tweet-actions a.ctf-reply {
    margin-left: 0
}

.qodef-twitter-list.qodef-layout--columns .ctf-tweets:before {
    content: "";
    display: table;
    table-layout: fixed
}

.qodef-twitter-list.qodef-layout--columns .ctf-tweets:after {
    clear: both
}

.qodef-twitter-list.qodef-layout--columns.qodef-col-num--1 #ctf .ctf-tweets>.ctf-item {
    width: 100%
}

.qodef-twitter-list.qodef-layout--columns.qodef-col-num--2 #ctf .ctf-tweets>.ctf-item {
    width: 50%
}

@media only screen and (min-width:681px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--2.qodef-responsive--predefined #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (min-width:1441px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--2.qodef-responsive--custom #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

.qodef-twitter-list.qodef-layout--columns.qodef-col-num--3 #ctf .ctf-tweets>.ctf-item {
    width: 33.33333%
}

@media only screen and (min-width:769px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--3.qodef-responsive--predefined #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (min-width:1441px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--3.qodef-responsive--custom #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

.qodef-twitter-list.qodef-layout--columns.qodef-col-num--4 #ctf .ctf-tweets>.ctf-item {
    width: 25%
}

@media only screen and (min-width:1025px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--4.qodef-responsive--predefined #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (min-width:1441px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--4.qodef-responsive--custom #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

.qodef-twitter-list.qodef-layout--columns.qodef-col-num--5 #ctf .ctf-tweets>.ctf-item {
    width: 20%
}

@media only screen and (min-width:1367px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--5.qodef-responsive--predefined #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (min-width:1441px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--5.qodef-responsive--custom #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

.qodef-twitter-list.qodef-layout--columns.qodef-col-num--6 #ctf .ctf-tweets>.ctf-item {
    width: 16.66667%
}

@media only screen and (min-width:1441px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--6.qodef-responsive--predefined #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

@media only screen and (min-width:1441px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--6.qodef-responsive--custom #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

.qodef-twitter-list.qodef-layout--columns.qodef-col-num--7 #ctf .ctf-tweets>.ctf-item {
    width: 14.28571%
}

@media only screen and (min-width:1367px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--7.qodef-responsive--predefined #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

@media only screen and (min-width:1441px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--7.qodef-responsive--custom #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

.qodef-twitter-list.qodef-layout--columns.qodef-col-num--8 #ctf .ctf-tweets>.ctf-item {
    width: 12.5%
}

@media only screen and (min-width:1367px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--8.qodef-responsive--predefined #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

@media only screen and (min-width:1441px) {
    .qodef-twitter-list.qodef-layout--columns.qodef-col-num--8.qodef-responsive--custom #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

.qodef-twitter-list.qodef-gutter--no.qodef--no-bottom-space {
    margin-bottom: 0
}

.qodef-twitter-list.qodef-gutter--no #ctf .ctf-tweets {
    margin: 0
}

.qodef-twitter-list.qodef-gutter--no #ctf .ctf-tweets>.ctf-item {
    padding: 0;
    margin: 0
}

.qodef-twitter-list.qodef-gutter--tiny.qodef--no-bottom-space {
    margin-bottom: -8px
}

.qodef-twitter-list.qodef-gutter--tiny #ctf .ctf-tweets {
    margin: 0 -4px
}

.qodef-twitter-list.qodef-gutter--tiny #ctf .ctf-tweets>.ctf-item {
    padding: 0 4px;
    margin: 0 0 8px
}

.qodef-twitter-list.qodef-gutter--small.qodef--no-bottom-space {
    margin-bottom: -20px
}

.qodef-twitter-list.qodef-gutter--small #ctf .ctf-tweets {
    margin: 0 -10px
}

.qodef-twitter-list.qodef-gutter--small #ctf .ctf-tweets>.ctf-item {
    padding: 0 10px;
    margin: 0 0 20px
}

.qodef-twitter-list.qodef-gutter--normal.qodef--no-bottom-space {
    margin-bottom: -30px
}

.qodef-twitter-list.qodef-gutter--normal #ctf .ctf-tweets {
    margin: 0 -15px
}

.qodef-twitter-list.qodef-gutter--normal #ctf .ctf-tweets>.ctf-item {
    padding: 0 15px;
    margin: 0 0 30px
}

.qodef-twitter-list.qodef-gutter--medium.qodef--no-bottom-space {
    margin-bottom: -40px
}

.qodef-twitter-list.qodef-gutter--medium #ctf .ctf-tweets {
    margin: 0 -20px
}

.qodef-twitter-list.qodef-gutter--medium #ctf .ctf-tweets>.ctf-item {
    padding: 0 20px;
    margin: 0 0 40px
}

.qodef-twitter-list.qodef-gutter--large.qodef--no-bottom-space {
    margin-bottom: -50px
}

.qodef-twitter-list.qodef-gutter--large #ctf .ctf-tweets {
    margin: 0 -25px
}

.qodef-twitter-list.qodef-gutter--large #ctf .ctf-tweets>.ctf-item {
    padding: 0 25px;
    margin: 0 0 50px
}

.qodef-twitter-list.qodef-gutter--huge.qodef--no-bottom-space {
    margin-bottom: -74px
}

.qodef-twitter-list.qodef-gutter--huge #ctf .ctf-tweets {
    margin: 0 -37px
}

.qodef-twitter-list.qodef-gutter--huge #ctf .ctf-tweets>.ctf-item {
    padding: 0 37px;
    margin: 0 0 74px
}

.qodef-twitter-list.qodef-gutter--enormous.qodef--no-bottom-space {
    margin-bottom: -100px
}

.qodef-twitter-list.qodef-gutter--enormous #ctf .ctf-tweets {
    margin: 0 -50px
}

.qodef-twitter-list.qodef-gutter--enormous #ctf .ctf-tweets>.ctf-item {
    padding: 0 50px;
    margin: 0 0 100px
}

#qodef-page-footer .widget .qodef-twitter-list.qodef-layout--columns #ctf a {
    color: #ccc
}

#qodef-page-footer .widget .qodef-twitter-list.qodef-layout--columns #ctf a:hover {
    color: #fff
}

#qodef-side-area .widget .qodef-twitter-list.qodef-layout--columns #ctf a {
    color: #ccc
}

#qodef-side-area .widget .qodef-twitter-list.qodef-layout--columns #ctf a:hover {
    color: #fff
}

.qodef-yith-wccl--predefined .select_box {
    display: inline-block
}

.qodef-yith-wccl--predefined .select_box:after {
    display: none
}

.qodef-yith-wccl--predefined .select_box .select_option {
    vertical-align: middle;
    border: 0;
    margin: 0 5px 0 0;
    display: inline-block;
    float: none;
    position: relative
}

.qodef-yith-wccl--predefined .select_box .select_option:last-child {
    margin-right: 0
}

.qodef-yith-wccl--predefined .select_box .select_option>span:not(.yith_wccl_tooltip) {
    border: 0;
    min-width: auto;
    width: auto;
    height: auto;
    line-height: inherit
}

.qodef-yith-wccl--predefined .select_box .select_option .yith_wccl_tooltip {
    width: 100px
}

.qodef-yith-wccl--predefined .select_box .select_option .yith_wccl_tooltip>span {
    background: #fff;
    border-radius: 0;
    border: 1px solid #e3e3e3 !important;
    padding: 10px;
    color: #000
}

.qodef-yith-wccl--predefined .select_box .select_option .yith_wccl_tooltip.top span:after {
    top: 100%;
    bottom: auto
}

.qodef-yith-wccl--predefined .select_box.select_box_colorpicker .select_option {
    border: 1px solid transparent;
    padding: 1px
}

.qodef-yith-wccl--predefined .select_box.select_box_colorpicker .select_option>span:not(.yith_wccl_tooltip) {
    width: 38px;
    height: 15px
}

.qodef-yith-wccl--predefined .select_box.select_box_colorpicker .select_option.selected {
    border-color: #000
}

.qodef-yith-wccl--predefined .select_box.select_box_image .select_option {
    border: 1px solid transparent;
    padding: 1px
}

.qodef-yith-wccl--predefined .select_box.select_box_image .select_option>img:not(.yith_wccl_tooltip) {
    width: 40px;
    height: 40px;
    object-fit: cover
}

.qodef-yith-wccl--predefined .select_box.select_box_image .select_option.selected {
    border-color: #000
}

.qodef-yith-wccl--predefined .select_box.select_box_label>* {
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000
}

.qodef-yith-wccl--predefined .select_box.select_box_label .select_option {
    transition: color .2s ease-out;
    margin: 0
}

.qodef-yith-wccl--predefined .select_box.select_box_label .select_option:after {
    content: ',';
    margin-right: 5px
}

.qodef-yith-wccl--predefined .select_box.select_box_label .select_option:last-of-type:after {
    display: none
}

.qodef-yith-wccl--predefined .select_box.select_box_label .select_option.selected {
    color: var(--qode-main-color)
}

.qodef-yith-wccl--predefined li.product .variations_form.cart {
    margin: 0 0 0 20px
}

.qodef-yith-wccl--predefined li.product .variations_form.cart div.variations {
    margin-bottom: 10px;
    text-align: right
}

.qodef-yith-wccl--predefined li.product .variations_form.cart div.variations:last-child {
    margin-bottom: 0
}

#yith-quick-view-modal .yith-quick-view-overlay {
    background: rgba(255, 255, 255, .79) !important
}

#yith-quick-view-modal .yith-wcqv-wrapper {
    height: auto !important;
    width: 960px !important;
    max-width: 90vw !important
}

#yith-quick-view-modal .yith-wcqv-main {
    box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, .5) !important;
    overflow-y: hidden !important;
    max-height: 90vh !important
}

#yith-quick-view-modal #yith-quick-view-content.single-product .qodef-woo-single-inner {
    margin: 0
}

#yith-quick-view-modal #yith-quick-view-content.single-product .qodef-woo-single-inner>* {
    padding: 0
}

#yith-quick-view-modal #yith-quick-view-content.single-product .qodef-woo-single-image {
    align-self: stretch
}

#yith-quick-view-modal #yith-quick-view-content.single-product .qodef-woo-single-image .qodef-woo-product-mark {
    transform: translateX(0)
}

#yith-quick-view-modal #yith-quick-view-content.single-product .qodef-woo-single-image :not(.qodef-woo-product-mark) {
    height: 100%
}


#yith-quick-view-modal #yith-quick-view-content.single-product .entry-summary {
    align-self: center;
    padding: 30px
}

#yith-quick-view-modal #yith-quick-view-close {
    border: 0 !important;
    font-size: 0;
    line-height: 1;
    position: absolute;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px
}

#yith-quick-view-modal #yith-quick-view-close:after {
    display: block;
    line-height: 25px;
    padding: 0 3px;
    position: relative;
    font-size: 18px;
    font-family: ElegantIcons;
    content: "\4d"
}

body:not([class*=level-core]) #yith-quick-view-modal #yith-quick-view-close:after {
    content: 'x';
    font-family: initial
}

#yith-quick-view-modal .qodef-yith-wcqv-link {
    margin-top: 38px
}

.qodef-yith-wcqv--predefined .yith-wcqv-button {
    font-size: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1 !important;
    background-color: transparent !important
}

.qodef-yith-wcqv--predefined .yith-wcqv-button:before {
    font-size: 22px;
    font-weight: 400;
    content: "\f06e"
}

.qodef-yith-wcqv--predefined .qodef-woo-product-image-inner .yith-wcqv-button {
    color: #fff !important
}

.qodef-yith-wcqv--predefined .qodef-woo-product-image-inner .yith-wcqv-button:hover {
    color: var(--qode-main-color) !important
}

.qodef-yith-wcqv--predefined .qodef-woo-product-image-inner .yith-wcqv-button .blockUI {
    display: none !important
}

.yith-wcwl-form .wishlist-title-container {
    display: none !important
}

.yith-wcwl-form .shop_table {
    font-size: 100%
}

.yith-wcwl-form .shop_table tr {
    border-left: none;
    border-right: none;
    border-top: none
}

.yith-wcwl-form .shop_table td,
.yith-wcwl-form .shop_table th {
    padding: 15px 0 15px 15px;
    vertical-align: middle;
    text-align: left;
    border: 0
}

.yith-wcwl-form .shop_table td:first-child,
.yith-wcwl-form .shop_table th:first-child {
    padding-left: 0
}

.yith-wcwl-form .shop_table th {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em
}

.yith-wcwl-form .shop_table td ul {
    list-style: none;
    margin: 0;
    padding: 0
}

.yith-wcwl-form .shop_table td ul li {
    margin: 0 0 10px;
    padding: 0
}

.yith-wcwl-form .shop_table td ul li:last-child {
    margin-bottom: 0
}

.yith-wcwl-form .shop_table td ul li label {
    margin-bottom: 0
}

.yith-wcwl-form .shop_table p {
    margin: 0
}

.yith-wcwl-form .shop_table .variation:after,
.yith-wcwl-form .shop_table .variation:before {
    content: '';
    display: table;
    table-layout: fixed
}

.yith-wcwl-form .shop_table .variation:after {
    clear: both
}

.yith-wcwl-form .shop_table .variation dt {
    clear: left;
    float: left;
    font-weight: initial
}

@media only screen and (max-width:680px) {
    .yith-wcwl-form .shop_table .variation dt {
        float: none;
        width: 100%
    }
}

.yith-wcwl-form .shop_table .variation dd {
    clear: right;
    float: left;
    margin: 0 0 0 5px
}

@media only screen and (max-width:680px) {
    .yith-wcwl-form .shop_table .variation dd {
        float: none;
        margin: 0;
        width: 100%
    }
}

.yith-wcwl-form .wishlist_table.shop_table tr th {
    background: 0 0 !important
}

.yith-wcwl-form .wishlist_table.shop_table tr th.product-remove {
    width: auto !important
}

.yith-wcwl-form .wishlist_table.shop_table tr td {
    background: 0 0 !important;
    color: #000 !important;
    border-color: transparent !important
}

.yith-wcwl-form .wishlist_table .product-add-to-cart .added_to_cart,
.yith-wcwl-form .wishlist_table .product-add-to-cart .button {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 1.6875em;
    font-weight: 400;
    color: #000;
    position: relative !important;
    display: inline-flex !important;
    vertical-align: middle !important;
    width: auto !important;
    margin: 0 !important;
    text-decoration: none !important;
    text-transform: lowercase !important;
    border-radius: 0 !important;
    outline: 0 !important;
    transition: color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out !important;
    padding: 7px 43px !important;
    cursor: pointer !important;
    z-index: 3 !important;
    color: #fff !important;
    background-color: transparent !important;
    border: 1px solid var(--qode-main-color) !important;
    z-index: 1;
    transition-delay: .03s;
    overflow: hidden
}

.yith-wcwl-form .wishlist_table .product-add-to-cart .added_to_cart:after,
.yith-wcwl-form .wishlist_table .product-add-to-cart .button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: var(--qodef-hover-bg-color, var(--qode-main-color));
    transition: transform .8s cubic-bezier(.22, .61, .36, 1);
    z-index: -1;
    width: calc(100% + 2px);
    transform-origin: left;
    transform: scaleX(1)
}

.yith-wcwl-form .wishlist_table .product-add-to-cart .added_to_cart:hover,
.yith-wcwl-form .wishlist_table .product-add-to-cart .button:hover {
    color: var(--qode-main-color) !important;
    background-color: transparent !important;
    border-color: var(--qode-main-color) !important
}

.yith-wcwl-form .wishlist_table .product-add-to-cart .added_to_cart:hover:after,
.yith-wcwl-form .wishlist_table .product-add-to-cart .button:hover:after {
    transform-origin: right;
    transform: scaleX(0)
}

.yith-wcwl-form .wishlist_table .product-add-to-cart .added_to_cart i,
.yith-wcwl-form .wishlist_table .product-add-to-cart .button i {
    display: none !important
}

.yith-wcwl-form .wishlist_table .product-add-to-cart .added_to_cart:before,
.yith-wcwl-form .wishlist_table .product-add-to-cart .button:before {
    display: none !important
}

.yith-wcwl-form .wishlist_table .product-add-to-cart .added_to_cart.added,
.yith-wcwl-form .wishlist_table .product-add-to-cart .button.added {
    display: none !important
}

.qodef-yith-wcwl--predefined .yith-wcwl-add-to-wishlist {
    font-size: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1 !important;
    background-color: transparent !important
}

.qodef-yith-wcwl--predefined .yith-wcwl-add-to-wishlist a:before {
    font-size: 22px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    content: "\f004"
}

.qodef-yith-wcwl--predefined .yith-wcwl-add-to-wishlist.exists a:before {
    font-size: 22px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: "\f004"
}

.qodef-yith-wcwl--predefined .yith-wcwl-add-to-wishlist .blockOverlay,
.qodef-yith-wcwl--predefined .yith-wcwl-add-to-wishlist .feedback,
.qodef-yith-wcwl--predefined .yith-wcwl-add-to-wishlist .view-wishlist,
.qodef-yith-wcwl--predefined .yith-wcwl-add-to-wishlist i,
.qodef-yith-wcwl--predefined .yith-wcwl-add-to-wishlist span {
    display: none !important
}

.qodef-yith-wcwl--predefined .qodef-woo-product-image-inner .yith-wcwl-add-to-wishlist a {
    color: #fff !important
}

.qodef-yith-wcwl--predefined .qodef-woo-product-image-inner .yith-wcwl-add-to-wishlist a:hover {
    color: var(--qode-main-color) !important
}

.qodef-yith-wcwl--predefined #yith-wcwl-popup-message {
    display: none !important
}

.qodef-yith-wcwl--predefined .yith-wcwl-share {
    display: none !important
}

.qodef-order-tracking p:not(.form-row) {
    margin-top: 0
}

.qodef-order-tracking .form-row {
    margin: 0
}

.qodef-clients-list .qodef-e {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade-in .qodef-e {
    display: flex;
    align-items: center;
    justify-content: center
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade-in .qodef-e .qodef-e-image {
    line-height: 0;
    position: relative;
    display: inline-block;
    vertical-align: top
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade-in .qodef-e .qodef-e-image a {
    display: inline-block
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade-in .qodef-e .qodef-e-logo {
    width: auto;
    opacity: 1;
    will-change: opacity;
    transition: opacity .5s ease-out;
    display: inline-block
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade-in .qodef-e .qodef-e-hover-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    opacity: 0;
    will-change: opacity;
    transition: opacity .5s ease-out
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade-in .qodef-e:hover .qodef-e-logo {
    opacity: 0
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade-in .qodef-e:hover .qodef-e-hover-logo {
    opacity: 1
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade .qodef-e {
    display: flex;
    align-items: center;
    justify-content: center
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade .qodef-e .qodef-e-image {
    line-height: 0;
    position: relative;
    display: inline-block;
    vertical-align: top
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade .qodef-e .qodef-e-image a {
    display: inline-block
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade .qodef-e .qodef-e-logo {
    width: auto;
    opacity: var(--qodef-client-initial-opacity, 1);
    will-change: opacity;
    transition: opacity .3s ease;
    display: inline-block
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade .qodef-e .qodef-e-hover-logo {
    display: none
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--fade .qodef-e:hover .qodef-e-logo {
    opacity: var(--qodef-client-hover-opacity, .5)
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--no-hover .qodef-e {
    display: flex;
    align-items: center;
    justify-content: center
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--no-hover .qodef-e .qodef-e-image {
    line-height: 0;
    position: relative;
    display: inline-block;
    vertical-align: top
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--no-hover .qodef-e .qodef-e-image a {
    display: inline-block
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--no-hover .qodef-e .qodef-e-logo {
    width: auto;
    display: inline-block
}

.qodef-clients-list.qodef-item-layout--image-only.qodef-hover-animation--no-hover .qodef-e .qodef-e-hover-logo {
    display: none
}

.qodef-portfolio-single {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding: 0 5.25%
}

.qodef-portfolio-single.qodef-layout--custom {
    padding: 0
}

.qodef-portfolio-single.qodef-layout--custom #qodef-portfolio-single-related-items,
.qodef-portfolio-single.qodef-layout--custom #qodef-single-portfolio-navigation {
    padding: 0 5.25%
}

.qodef-portfolio-single .qodef-media iframe {
    max-height: calc(56.25vw)
}

.qodef-portfolio-single .qodef-media.qodef-swiper-container .swiper-pagination {
    position: relative;
    bottom: 0;
    text-align: left;
    margin-top: 13px;
    width: auto;
    display: inline-block
}

.qodef-portfolio-single .qodef-media.qodef-swiper-container .swiper-button-next,
.qodef-portfolio-single .qodef-media.qodef-swiper-container .swiper-button-prev {
    bottom: -10px !important;
    top: unset !important
}

.qodef-portfolio-single .qodef-media.qodef-swiper-container .swiper-button-prev {
    left: unset !important;
    right: 38px
}

.qodef-portfolio-single .qodef-media.qodef-swiper-container .swiper-button-next {
    right: 0 !important
}

.qodef-portfolio-single .qodef-portfolio-title {
    margin: 31px 0 22px
}

.qodef-portfolio-single .qodef-portfolio-info>* {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    flex-direction: column
}

.qodef-portfolio-single .qodef-portfolio-info>:last-child {
    margin-bottom: 0
}

.qodef-portfolio-single .qodef-portfolio-info .qodef-e-label {
    margin: 0 0 4px 0
}

.qodef-portfolio-single .qodef-portfolio-info .qodef-info--category a,
.qodef-portfolio-single .qodef-portfolio-info .qodef-info--category p,
.qodef-portfolio-single .qodef-portfolio-info .qodef-info--date a,
.qodef-portfolio-single .qodef-portfolio-info .qodef-info--date p {
    margin: 0;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .2em
}

.qodef-portfolio-single .qodef-portfolio-info .qodef-info--tags .qodef-e-tags {
    margin-top: 5px
}

.qodef-portfolio-single .qodef-portfolio-info .qodef-info--tags a {
    font-size: 12px;
    text-transform: lowercase;
    border: 1px solid #e3e3e3;
    padding: 2px 7px;
    margin-right: 5px;
    transition: border-color .35s ease-out
}

.qodef-portfolio-single .qodef-portfolio-info .qodef-info--tags a:hover {
    border-color: #000
}

.qodef-portfolio-single .qodef-variations--small .qodef-media {
    margin-right: 69px
}

.qodef-portfolio-single .qodef-variations--small .qodef-portfolio-content {
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 35px
}

.qodef-portfolio-single .qodef-variations--small .qodef-portfolio-info {
    margin-top: 59px
}

.qodef-portfolio-single .qodef-variations--big .qodef-e-content {
    margin-top: 99px;
    border-top: 1px solid #e3e3e3
}

.qodef-portfolio-single .qodef-variations--big .qodef-portfolio-title {
    margin: 69px 0 22px
}

.qodef-portfolio-single .qodef-variations--big .qodef-col--9 {
    border-left: 1px solid #e3e3e3
}

.qodef-portfolio-single .qodef-variations--big .qodef-col--9>* {
    padding-left: 60px
}

.qodef-portfolio-single .qodef-variations--big .qodef-portfolio-info {
    margin-top: 75px
}

.qodef-fullscreen-portfolio-slider {
    height: 100vh;
    display: flex;
    align-items: center
}

.qodef-fullscreen-portfolio-slider .qodef-e-title {
    color: #fff;
    margin: 0 0 3px
}

.qodef-fullscreen-portfolio-slider .qodef-e-title a {
    color: inherit
}

.qodef-fullscreen-portfolio-slider .qodef-e-title a:hover {
    color: currentColor
}

.qodef-fullscreen-portfolio-slider .qodef-e-media-image.qodef--background {
    margin-bottom: 0;
    height: 100%;
    background-position: center;
    background-size: cover
}

.qodef-fullscreen-portfolio-slider .qodef-e-media-image.qodef--background img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.qodef-fullscreen-portfolio-slider .qodef-e-info .qodef-category-holder {
    position: relative
}

.qodef-fullscreen-portfolio-slider .qodef-e-info .qodef-category-holder>* {
    color: #fff
}

.qodef-fullscreen-portfolio-slider .qodef-e-info a:hover {
    color: #fff
}

.qodef-fullscreen-portfolio-slider .qodef-info-separator-single-alter {
    margin: 0 10px 0 8px
}

.qodef-fullscreen-portfolio-slider .qodef-info-separator-single-alter:after {
    content: '';
    position: absolute;
    top: -2px;
    width: 1px;
    height: 17px;
    background-color: currentColor
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom {
    background-color: #e4cec7;
    animation: qode-fade-in .5s .7s both
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom * {
    cursor: none
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-content-inner a {
    cursor: pointer
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-custom-swiper {
    width: 83%;
    margin-left: auto;
    overflow: hidden
}

@media only screen and (max-width:1024px) {
    .qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-custom-swiper {
        width: 93%
    }
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-post-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide {
    width: 34%;
    transition: all .6s ease;
    left: 0;
    top: 0
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide .qodef-e-inner:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #e4cec7;
    mix-blend-mode: multiply;
    opacity: 1;
    transition: opacity .2s ease-out;
    transition-delay: .2s
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide .qodef-e-media-image {
    transition: filter .2s ease-out;
    transition-delay: .3s
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide:not(.swiper-slide-active) .qodef-e-media-image {
    filter: grayscale(1)
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide:not(.swiper-slide-active) .qodef-e-media-image:after {
    opacity: 0
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active {
    width: 50%
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active .qodef-e-inner:after {
    opacity: 0
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active .qodef-e-media-image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    background-image: linear-gradient(to top, rgba(0, 0, 0, .2) 0, rgba(0, 0, 0, 0) 100%);
    transition: opacity .2s ease-out;
    transition-delay: .2s
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active .qodef-e-content {
    opacity: 1
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active .qodef-e-content .qodef-e-text,
.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active .qodef-e-content .qodef-e-top-holder {
    transform: translateX(0);
    opacity: 1
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-inner {
    position: relative;
    top: 27%
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-content {
    position: absolute;
    bottom: 0;
    padding: 30px 38px;
    opacity: 0;
    z-index: 4;
    transition: opacity .2s ease-out;
    transition-delay: .3s
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-text,
.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-top-holder {
    transform: translateX(50px);
    opacity: 0;
    transition: opacity .6s .4s, transform .8s cubic-bezier(.22, .61, .36, 1) .4s
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-top-holder {
    transform: translateX(-50px)
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-custom-cursor {
    position: fixed;
    cursor: none;
    pointer-events: none;
    z-index: 5
}

@media only screen and (max-width:768px) {
    .qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide {
        width: 100%
    }

    .qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active {
        width: 100%
    }

    .qodef-fullscreen-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-content {
        padding: 20px
    }
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard .qodef-custom-swiper {
    width: 100%;
    height: 100%
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article .qodef-e-inner {
    height: 100%
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article .qodef-e-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding: 4.6% 6.6%;
    background-color: rgba(0, 0, 0, .12)
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article .qodef-e-content a {
    position: relative;
    z-index: 3
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article .qodef-e-post-link {
    z-index: 2
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article .qodef-e-text,
.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article .qodef-e-top-holder {
    transform: translateX(50px);
    opacity: 0;
    transition: opacity .6s .6s, transform .8s cubic-bezier(.22, .61, .36, 1) .6s
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article .qodef-e-top-holder {
    transform: translateX(-50px)
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article.swiper-slide-active .qodef-e-text,
.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article.swiper-slide-active .qodef-e-top-holder {
    transform: translateX(0);
    opacity: 1
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard .swiper-pagination {
    position: absolute !important;
    margin-top: 0;
    bottom: 45px !important;
    left: 38px !important;
    height: auto;
    width: auto !important;
    justify-content: flex-start
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard .swiper-pagination .qodef-custom-slider-bullet.swiper-pagination-bullet {
    position: relative;
    height: 11px;
    width: 42px;
    padding: 5px 0;
    margin: 0 5px !important;
    background-color: transparent;
    transition: width .2s ease-out
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard .swiper-pagination .qodef-custom-slider-bullet.swiper-pagination-bullet:after {
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    top: 5px;
    background-color: #fff;
    opacity: .5
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard .swiper-pagination .qodef-custom-slider-bullet.swiper-pagination-bullet:before {
    content: '';
    position: absolute;
    left: 0;
    height: 1px;
    width: 0;
    top: 5px;
    background-color: #fff
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard .swiper-pagination .qodef-custom-slider-bullet.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 100px;
    transform: unset
}

.qodef-fullscreen-portfolio-slider.qodef-item-layout--standard .swiper-pagination .qodef-custom-slider-bullet.swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    animation-name: progressBar;
    animation-duration: 3.5s;
    animation-fill-mode: forwards
}

@media only screen and (max-width:680px) {
    .qodef-fullscreen-portfolio-slider.qodef-item-layout--standard article .qodef-e-content {
        align-items: flex-start;
        padding: 58px 5%
    }

    .qodef-fullscreen-portfolio-slider.qodef-item-layout--standard .swiper-pagination {
        left: calc(5% - 5px) !important;
        bottom: 4.5% !important
    }
}

@keyframes progressBar {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

.qodef-portfolio-category-list .qodef-e-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-portfolio-category-list .qodef-e-image img {
    display: block
}

.qodef-portfolio-category-list .qodef-e-content a {
    position: relative;
    z-index: 1
}

.qodef-portfolio-category-list .qodef-e-title {
    margin: 0
}

.qodef-portfolio-category-list .qodef-e-description {
    margin: 9px 0 0
}

.qodef-portfolio-category-list .qodef-e-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.qodef-portfolio-category-list .qodef-e-image {
    position: relative
}

.qodef-portfolio-category-list .qodef-e-image img {
    transition: filter .5s ease-out
}

.qodef-portfolio-category-list .qodef-e-image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #e4cec7;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: opacity .5s ease-out;
    pointer-events: none
}

.qodef-portfolio-category-list .qodef-e:hover .qodef-e-image img {
    filter: grayscale(1)
}

.qodef-portfolio-category-list .qodef-e:hover .qodef-e-image:after {
    opacity: 1
}

#qodef-side-area .qodef-portfolio-category-list article:nth-child(1) {
    --qodef-transition-delay: 0s
}

#qodef-side-area .qodef-portfolio-category-list article:nth-child(2) {
    --qodef-transition-delay: 0.15s
}

#qodef-side-area .qodef-portfolio-category-list article:nth-child(3) {
    --qodef-transition-delay: 0.3s
}

#qodef-side-area .qodef-portfolio-category-list article:nth-child(4) {
    --qodef-transition-delay: 0.45s
}

#qodef-side-area .qodef-portfolio-category-list article:nth-child(5) {
    --qodef-transition-delay: 0.6s
}

#qodef-side-area .qodef-portfolio-category-list article:nth-child(6) {
    --qodef-transition-delay: 0.75s
}

#qodef-side-area .qodef-portfolio-category-list .qodef-e-image {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    transition: -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) .2s;
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) .2s;
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) .2s, -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) .2s
}

.qodef-side-area-animate--in #qodef-side-area .qodef-portfolio-category-list .qodef-e-image {
    transition: -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay);
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay);
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay), -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0)
}

#qodef-side-area .qodef-portfolio-category-list .qodef-e-content {
    transform: translateX(50px);
    opacity: 0;
    transition: opacity .3s .4s, transform .3s cubic-bezier(.22, .61, .36, 1) .4s
}

.qodef-side-area-animate--in #qodef-side-area .qodef-portfolio-category-list .qodef-e-content {
    transition: opacity .8s var(--qodef-transition-delay), transform 1s cubic-bezier(.22, .61, .36, 1) var(--qodef-transition-delay);
    transform: translateX(0);
    opacity: 1
}

.qodef-portfolio-category-list.qodef-item-layout--gallery .qodef-e-inner:hover .qodef-e-content {
    opacity: 1
}

.qodef-portfolio-category-list.qodef-item-layout--gallery .qodef-e-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 31px 40px;
    background-color: rgba(0, 0, 0, .4);
    opacity: 0;
    transition: opacity .2s ease-out
}

.qodef-portfolio-category-list.qodef-item-layout--gallery .qodef-e-description,
.qodef-portfolio-category-list.qodef-item-layout--gallery .qodef-e-title {
    color: #fff
}

.qodef-portfolio-category-list.qodef-item-layout--standard .qodef-e-content {
    margin-top: 21px
}

.qodef-portfolio-category-list.qodef-item-layout--standard .qodef-e-title {
    display: inline-block;
    --qodef-underline-position-y: 100%;
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height)
}

.qodef-portfolio-category-list.qodef-item-layout--standard .qodef-e-inner:hover .qodef-e-title {
    animation: qode-inline-underline-initial-hover .8s cubic-bezier(.57, .39, 0, .86) 1 forwards
}

.qodef-portfolio-list .qodef-e-inner {
    position: relative
}

.qodef-portfolio-list .qodef-e-info>* {
    font-family: "DM Sans", sans-serif
}

.qodef-portfolio-list .qodef-e-content,
.qodef-portfolio-list .qodef-e-inner,
.qodef-portfolio-list .qodef-e-media {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-portfolio-list .qodef-e-media>* {
    margin-bottom: 30px
}

.qodef-portfolio-list .qodef-e-media iframe {
    display: block;
    height: 100%
}

.qodef-portfolio-list .qodef-e-media-image {
    position: relative;
    display: inline-block;
    vertical-align: top;
    max-width: 100%
}

.qodef-portfolio-list .qodef-e-media-image a,
.qodef-portfolio-list .qodef-e-media-image img {
    display: block;
    width: 100%
}

.qodef-portfolio-list .qodef-e-media-image.qodef--background {
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover
}

.qodef-portfolio-list .qodef-e-media-image.qodef--background img {
    visibility: hidden
}

.qodef-portfolio-list .qodef-e-title {
    margin: 0
}

.qodef-portfolio-list .qodef-e-excerpt {
    margin: 15px 0 0
}

.qodef-portfolio-list .qodef-e-read-more {
    margin: 15px 0 0
}

.qodef-portfolio-list .qodef-e-top-holder {
    margin-bottom: 2px
}

.qodef-portfolio-list .qodef-e-post-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.qodef-portfolio-list .qodef-m-pagination {
    margin-top: 35px
}

.qodef-portfolio-list.qodef-items--fixed .qodef-e-image {
    height: 100%;
    overflow: hidden
}

.qodef-portfolio-list.qodef-items--fixed .qodef-e-media-image {
    height: 100%
}

.qodef-portfolio-list.qodef-items--fixed .qodef-e-media-image a {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    height: 100%
}

.qodef-portfolio-list .qodef-custom-margin .qodef-e-inner {
    width: auto
}

.qodef-portfolio-list.qodef--zigzag article:nth-of-type(even) .qodef-e-inner {
    margin-top: 85px
}

@media only screen and (max-width:680px) {
    .qodef-portfolio-list.qodef--zigzag article:nth-of-type(even) .qodef-e-inner {
        margin-top: 0
    }
}

.qodef-portfolio-list .qodef-e.qodef--has-appear .qodef-e-inner {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    -webkit-clip-path: inset(-1px 100% -100px -1px);
    clip-path: inset(-1px 100% -100px -1px)
}

.qodef-portfolio-list .qodef-e.qodef--has-appear.qodef--appeared .qodef-e-inner {
    transition: -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) 0s;
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) 0s;
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) 0s, -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) 0s;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0);
    -webkit-clip-path: inset(-1px -1px -100px -1px);
    clip-path: inset(-1px -1px -100px -1px)
}

.qodef-portfolio-list.qodef-item-layout--image-hover .qodef-e-inner:hover .qodef-e-arrow,
.qodef-portfolio-list.qodef-item-layout--image-hover .qodef-e-inner:hover .qodef-e-image-hover {
    opacity: 1
}

.qodef-portfolio-list.qodef-item-layout--image-hover .qodef-e-inner:hover .qodef-e-arrow {
    transform: translateX(0)
}

.qodef-portfolio-list.qodef-item-layout--image-hover .qodef-e-image-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .35s ease-in-out
}

.qodef-portfolio-list.qodef-item-layout--image-hover .qodef-e-arrow {
    position: absolute;
    bottom: -30px;
    right: 0;
    opacity: 0;
    transition: opacity .35s, transform .5s cubic-bezier(.22, .61, .36, 1);
    transform: translateX(-15px)
}

.qodef-portfolio-list.qodef-item-layout--image-hover .qodef-e-arrow:hover svg path:nth-of-type(2) {
    animation: qode-draw-line .7s cubic-bezier(.22, .61, .36, 1)
}

.qodef-portfolio-list.qodef-item-layout--info-below .qodef-e-content {
    margin-top: 21px
}

@media only screen and (max-width:680px) {
    .qodef-portfolio-list.qodef-item-layout--info-below.qodef-gutter--tiny .qodef-grid-item {
        margin-bottom: 69px !important
    }

    .qodef-portfolio-list.qodef-item-layout--info-below.qodef-gutter--tiny .qodef-grid-item .qodef-e-content {
        margin-bottom: 0 !important
    }
}

.qodef-item-layout--info-below.qodef-hover-animation--change-image .qodef-e .qodef-e-image {
    position: relative
}

.qodef-item-layout--info-below.qodef-hover-animation--change-image .qodef-e .qodef-e-media-image-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .35s ease-in-out
}

.qodef-item-layout--info-below.qodef-hover-animation--change-image .qodef-e .qodef-e-media-image-hover img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.qodef-item-layout--info-below.qodef-hover-animation--change-image .qodef-e:hover .qodef-e-media-image-hover {
    opacity: 1
}

.qodef-item-layout--info-below.qodef-hover-animation--move-text .qodef-e {
    --qodef-move-x: 30px
}

.qodef-item-layout--info-below.qodef-hover-animation--move-text .qodef-e:hover .qodef-e-title {
    animation: qode-text-from-right .8s forwards cubic-bezier(.22, .61, .36, 1)
}

.qodef-item-layout--info-below.qodef-hover-animation--move-text .qodef-e:hover .qodef-e-info {
    animation: qode-text-from-left .85s .15s forwards cubic-bezier(.22, .61, .36, 1)
}

.qodef-item-layout--info-below.qodef-hover-animation--overlay .qodef-e {
    --qodef-move-x: 30px
}

.qodef-item-layout--info-below.qodef-hover-animation--overlay .qodef-e .qodef-e-media-image>a:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, .3);
    opacity: 0;
    transition: opacity .5s ease-out
}

.qodef-item-layout--info-below.qodef-hover-animation--overlay .qodef-e:hover .qodef-e-media-image>a:after {
    opacity: 1
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-inner {
    overflow: hidden
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-media-image.qodef--background {
    margin-bottom: 0
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-content {
    display: none
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-title {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-title a {
    color: inherit
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-title a:hover {
    color: var(--qode-main-color)
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-info>* {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-info a:hover {
    color: var(--qode-main-color)
}

.qodef-portfolio-list.qodef-item-layout--info-follow.qodef-hover-animation--follow article .qodef-e-post-link {
    z-index: 2
}

.qodef-e-content-follow {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    pointer-events: none;
    visibility: hidden
}

.qodef-e-content-follow.qodef-right {
    text-align: right;
    transform: translateX(-100%)
}

.qodef-e-content-follow .qodef-e-title {
    margin: 0;
    color: #fff
}

.qodef-e-content-follow .qodef-e-title a {
    color: inherit
}

.qodef-e-content-follow .qodef-e-info>* {
    color: #fff
}

.qodef-e-content-follow .qodef-e-text,
.qodef-e-content-follow .qodef-e-top-holder {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    transition: all .2s ease-out
}

.qodef-e-content-follow .qodef-e-text>*,
.qodef-e-content-follow .qodef-e-top-holder>* {
    display: inline-block;
    background-color: var(--qode-main-color);
    z-index: 10
}

.qodef-e-content-follow .qodef-e-top-holder {
    margin-bottom: 2px
}

.qodef-e-content-follow .qodef-e-top-holder>* {
    padding: 0 5px
}

.qodef-e-content-follow .qodef-e-text>* {
    padding: 3px 5px
}

.qodef-e-content-follow.qodef-is-active {
    visibility: visible
}

.qodef-e-content-follow.qodef-is-active .qodef-e-text,
.qodef-e-content-follow.qodef-is-active .qodef-e-top-holder {
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0)
}

.qodef-e-content-follow.qodef-is-active .qodef-e-text {
    transition-delay: .2s
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover {
    margin: 0
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-media-image.qodef--background {
    margin-bottom: 0
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px 40px 35px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 30%, rgba(0, 0, 0, .6) 100%)
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-content a {
    position: relative;
    z-index: 3
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-title {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-title a {
    color: inherit
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-title a:hover {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-info>* {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-info a:hover {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-post-link {
    z-index: 2
}

@media only screen and (max-width:1024px) {
    .qodef-portfolio-list.qodef-item-layout--info-on-hover article .qodef-e-content {
        padding: 20px 28px 22px
    }
}

.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e-content {
    opacity: 0;
    transition: opacity .5s ease-out
}

.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e-text,
.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e-top-holder {
    opacity: 0;
    transition: opacity .35s, transform .5s cubic-bezier(.22, .61, .36, 1)
}

.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e-top-holder {
    transform: translateX(30px)
}

.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e-text {
    transform: translateX(-30px)
}

.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e:hover .qodef-e-content {
    opacity: 1
}

.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e:hover .qodef-e-text,
.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e:hover .qodef-e-top-holder {
    transform: translateX(0);
    opacity: 1
}

.qodef-item-layout--info-on-hover.qodef-hover-animation--fade-in .qodef-e:hover .qodef-e-text {
    transition-delay: .1s
}

.qodef-portfolio-list.qodef-item-layout--info-on-image {
    margin: 0
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-media-image.qodef--background {
    margin-bottom: 0
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 6.1% 6.6%;
    background-color: rgba(0, 0, 0, .12)
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-content a {
    position: relative;
    z-index: 3
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-title {
    color: #fff;
    margin: 0 0 3px
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-title a {
    color: inherit
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-title a:hover {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-info .qodef-category-holder>* {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-info a {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-info a:hover {
    color: #fff
}

.qodef-portfolio-list.qodef-item-layout--info-on-image article .qodef-e-post-link {
    z-index: 2
}

.qodef-portfolio-list.qodef-item-layout--info-on-image .qodef-e {
    --qodef-move-x: 38px
}

.qodef-portfolio-list.qodef-item-layout--info-on-image .qodef-e:hover .qodef-e-title {
    animation: qode-text-from-right .8s forwards cubic-bezier(.22, .61, .36, 1)
}

.qodef-portfolio-list.qodef-item-layout--info-on-image .qodef-e:hover .qodef-e-info {
    animation: qode-text-from-left .85s .15s forwards cubic-bezier(.22, .61, .36, 1)
}

.qodef-portfolio-project-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column
}

.qodef-portfolio-project-info .qodef-e-label {
    margin: 0 0 4px 0
}

.qodef-portfolio-project-info .qodef-e-category a,
.qodef-portfolio-project-info .qodef-e-category p {
    margin: 0;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .2em
}

.qodef-portfolio-project-info .entry-date {
    margin: 0;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .2em
}

.qodef-portfolio-project-info .qodef-e-tag {
    margin-top: 5px
}

.qodef-portfolio-project-info .qodef-e-tag a {
    font-size: 12px;
    text-transform: lowercase;
    border: 1px solid #e3e3e3;
    padding: 2px 7px;
    margin-right: 5px;
    transition: border-color .2s ease-out
}

.qodef-portfolio-project-info .qodef-e-tag a:hover {
    border-color: #000
}

.qodef-portfolio-project-info a,
.qodef-portfolio-project-info p {
    margin: 0
}

.qodef-portfolio-slider {
    display: flex;
    padding: 0 0 48px
}

.qodef-portfolio-slider .qodef-e-tagline {
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000
}

.qodef-portfolio-slider .qodef-e-tagline:before {
    content: "";
    position: relative;
    width: 5px;
    height: 5px;
    background-color: currentColor;
    border-radius: 50%;
    top: 50%;
    margin-right: 13px
}

.qodef-portfolio-slider .qodef-custom-swiper {
    overflow: hidden
}

.qodef-portfolio-slider article {
    position: absolute;
    opacity: 0;
    transition: opacity .2s ease-out
}

.qodef-portfolio-slider article .qodef-e-inner {
    height: 100%
}

.qodef-portfolio-slider article .qodef-e-title {
    margin: 0 0 3px
}

.qodef-portfolio-slider article .qodef-e-info .qodef-category-holder {
    position: relative
}

.qodef-portfolio-slider article .qodef-e-post-link {
    z-index: 2
}

.qodef-portfolio-slider article .qodef-info-separator-single-alter {
    margin: 0 10px 0 8px
}

.qodef-portfolio-slider article .qodef-info-separator-single-alter:after {
    content: '';
    position: absolute;
    top: -2px;
    width: 1px;
    height: 17px;
    background-color: currentColor
}

.qodef-portfolio-slider article.qodef--active {
    opacity: 1;
    z-index: 1
}

.qodef-portfolio-slider .qodef-e-media-image a,
.qodef-portfolio-slider .qodef-e-media-image img {
    width: 100%
}

.qodef-portfolio-slider.qodef-skin--light .qodef-e-tagline {
    color: #fff
}

.qodef-portfolio-slider.qodef-skin--light article .qodef-e-title {
    color: #fff
}

.qodef-portfolio-slider.qodef-skin--light article .qodef-e-title-link {
    color: #fff
}

.qodef-portfolio-slider.qodef-skin--light article .qodef-e-title-link:hover {
    color: #fff
}

.qodef-portfolio-slider.qodef-skin--light article .qodef-e-info>* {
    color: #fff
}

.qodef-portfolio-slider.qodef-skin--light article .qodef-category-holder a {
    color: #fff
}

.qodef-portfolio-slider.qodef-skin--light article .qodef-category-holder a:hover {
    color: currentColor
}

.qodef-portfolio-slider.qodef-skin--light .swiper-button-next,
.qodef-portfolio-slider.qodef-skin--light .swiper-button-prev {
    color: #fff
}

.qodef-portfolio-slider.qodef-item-layout--double-image.qodef--backwards .qodef-info-image.swiper-slide-next {
    opacity: .999 !important;
    z-index: 1
}

.qodef-portfolio-slider.qodef-item-layout--double-image.qodef--backwards .qodef-info-image.swiper-slide-next .qodef-e-media-image img {
    animation: qodef-transform-image 1.3s forwards
}

.qodef-portfolio-slider.qodef-item-layout--double-image.qodef--backwards .qodef-info-image.swiper-slide-next .qodef-e-media-image-secondary img {
    animation: qodef-transform-image 1s forwards
}

.qodef-portfolio-slider.qodef-item-layout--double-image:not(.qodef--backwards) .qodef-info-image.swiper-slide-prev {
    z-index: 1;
    opacity: .999 !important
}

.qodef-portfolio-slider.qodef-item-layout--double-image:not(.qodef--backwards) .qodef-info-image.swiper-slide-prev .qodef-e-media-image img {
    animation: qodef-transform-image 1.3s forwards
}

.qodef-portfolio-slider.qodef-item-layout--double-image:not(.qodef--backwards) .qodef-info-image.swiper-slide-prev .qodef-e-media-image-secondary img {
    animation: qodef-transform-image 1s forwards
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-images {
    width: 100%
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info {
    position: absolute;
    z-index: 2;
    height: 100%;
    min-width: 25%;
    min-height: 100px
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-custom-swiper {
    height: 100%
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-image-wrapper {
    display: flex;
    align-items: flex-end;
    height: 100%
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-media-image {
    width: calc(75% - 8px);
    height: 100%
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-media-image img {
    height: 100%;
    object-fit: cover
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-media-image-secondary {
    width: calc(25% + 8px);
    margin-right: 8px
}

.qodef-portfolio-slider.qodef-item-layout--double-image .swiper-button-next,
.qodef-portfolio-slider.qodef-item-layout--double-image .swiper-button-prev {
    top: unset;
    bottom: -17px
}

.qodef-portfolio-slider.qodef-item-layout--double-image .swiper-button-next {
    left: unset !important;
    right: 0 !important
}

.qodef-portfolio-slider.qodef-item-layout--double-image .swiper-button-prev {
    left: unset !important;
    right: 50px !important
}

.qodef-portfolio-slider.qodef-item-layout--double-image .swiper-pagination-fraction {
    bottom: 18px;
    width: auto
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-info-bottom .qodef-e .qodef-e-text,
.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-info-bottom .qodef-e .qodef-e-top-holder {
    transform: translateX(50px);
    opacity: 0;
    transition: opacity .8s, transform 1s cubic-bezier(.22, .61, .36, 1)
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-info-bottom .qodef-e .qodef-e-top-holder {
    transform: translateX(-50px)
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-text,
.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-top-holder {
    transform: translateX(0);
    opacity: 1
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-top-holder {
    transition-delay: .2s
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info-image {
    -webkit-clip-path: inset(1px);
    clip-path: inset(1px)
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info-image .qodef-e-media-image,
.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info-image .qodef-e-media-image-secondary {
    overflow: hidden
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info-image.swiper-slide-active {
    z-index: 2
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info-image.swiper-slide-active .qodef-e-media-image img,
.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info-image.swiper-slide-active .qodef-e-media-image-secondary img {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0)
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info-image.swiper-slide-active .qodef-e-media-image-secondary img {
    animation: qodef-clip-transform-image .8s cubic-bezier(.25, .46, .45, .94) forwards
}

.qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info-image.swiper-slide-active .qodef-e-media-image img {
    animation: qodef-clip-image 1.3s cubic-bezier(.25, .46, .45, .94) forwards
}

@media only screen and (max-width:1366px) {
    .qodef-portfolio-slider.qodef-item-layout--double-image {
        max-height: 80vh
    }
}

@media only screen and (max-width:768px) {
    .qodef-portfolio-slider.qodef-item-layout--double-image {
        flex-direction: column;
        max-height: initial
    }

    .qodef-portfolio-slider.qodef-item-layout--double-image .qodef-info {
        position: relative;
        min-width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--double-image article {
        min-height: 60px;
        top: 30px;
        transform: translate(0, 0)
    }

    .qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-media-image {
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--double-image .qodef-e-media-image-secondary {
        display: none
    }
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom {
    animation: qode-fade-in .5s .7s both;
    flex-direction: column
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide {
    width: 20%;
    transition: all .6s ease
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef--draging .qodef-e-info-bottom .qodef-e .qodef-e-content {
    opacity: 0 !important
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef--slide-next-prevented .swiper-button-next {
    transition: opacity .5s;
    opacity: .5
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef--slide-prev-prevented .swiper-button-prev {
    transition: opacity .5s;
    opacity: .5
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-bottom .qodef-e .qodef-e-content {
    transition: opacity .1s
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-bottom .qodef-e .qodef-e-content .qodef-e-text,
.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-bottom .qodef-e .qodef-e-content .qodef-e-top-holder {
    transform: translateX(50px);
    opacity: 0;
    transition: opacity .6s .2s, transform .8s cubic-bezier(.22, .61, .36, 1) .2s
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-bottom .qodef-e .qodef-e-content .qodef-e-top-holder {
    transform: translateX(-50px)
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-content {
    opacity: 1
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-content .qodef-e-text,
.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-content .qodef-e-top-holder {
    transform: translateX(0);
    opacity: 1;
    transition-delay: .7s, .7s
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-bottom .qodef-e.qodef--active.qodef--hidden .qodef-e-content {
    opacity: 0
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-info {
    position: absolute;
    bottom: 48px;
    right: 20px;
    width: 50%
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom article {
    top: 0;
    left: 0;
    bottom: unset;
    right: unset;
    padding-top: 33px
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-tagline {
    margin-bottom: 64px
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-item {
    display: flex;
    flex-direction: column;
    margin-right: 85px
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-item:last-of-type {
    margin-right: 0
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-excerpt {
    margin-top: 14px;
    width: 70%
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-label {
    position: relative;
    display: inline-flex;
    align-items: center
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-label:before {
    content: "";
    position: relative;
    width: 5px;
    height: 5px;
    background-color: currentColor;
    border-radius: 50%;
    margin-right: 13px
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-item-link,
.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-info-item-text {
    margin-left: 18px
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-custom-fields {
    margin-top: 27px;
    display: flex;
    flex-wrap: wrap
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-media-image a {
    width: 100%;
    height: 100%
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-media-image img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-button-next,
.qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-button-prev {
    top: unset;
    bottom: -35px
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-button-next {
    left: unset !important;
    right: 100px !important
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-button-prev {
    left: unset !important;
    right: 150px !important
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-pagination-fraction {
    bottom: 0;
    width: auto
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 .swiper-slide {
    width: 31%
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 .swiper-slide.swiper-slide-active {
    width: 47%
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 .qodef-info {
    right: 0;
    top: unset;
    bottom: 48px
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 article {
    padding-top: 17px;
    padding-left: 10px
}

.qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--4 .swiper-slide.swiper-slide-active {
    width: 46%
}

@media only screen and (max-width:1024px) {
    .qodef-portfolio-slider.qodef-item-layout--image-zoom {
        flex-direction: column-reverse
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-tagline {
        order: 1
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-info {
        position: relative;
        width: 100%;
        bottom: 0;
        right: 0
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-excerpt {
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-button-next {
        left: unset !important;
        right: 7% !important
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-button-prev {
        left: unset !important;
        right: calc(7% + 50px) !important
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide {
        width: 42%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active {
        width: 42%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 .swiper-slide {
        width: 42%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 .swiper-slide.swiper-slide-active {
        width: 42%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 article {
        padding-left: 0;
        margin-right: 7%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 .qodef-info {
        right: 0;
        bottom: 0;
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--4 .swiper-slide {
        width: 42%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--4 .swiper-slide.swiper-slide-active {
        width: 42%
    }
}

@media only screen and (max-width:680px) {
    .qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide {
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .swiper-slide.swiper-slide-active {
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom .qodef-e-custom-fields {
        flex-direction: column
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 .swiper-slide {
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--3 .swiper-slide.swiper-slide-active {
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--4 .swiper-slide {
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--image-zoom.qodef-slides-number--4 .swiper-slide.swiper-slide-active {
        width: 100%
    }
}

.qodef-portfolio-slider.qodef-item-layout--standard .qodef-info {
    width: 25%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.qodef-portfolio-slider.qodef-item-layout--standard .qodef-images {
    width: 75%;
    display: block;
    overflow: hidden
}

.qodef-portfolio-slider.qodef-item-layout--standard article {
    bottom: 0
}

.qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom {
    position: relative;
    min-height: 60px
}

.qodef-portfolio-slider.qodef-item-layout--standard .swiper-button-next,
.qodef-portfolio-slider.qodef-item-layout--standard .swiper-button-prev {
    top: unset;
    bottom: -17px
}

.qodef-portfolio-slider.qodef-item-layout--standard .swiper-button-next {
    left: unset !important;
    right: 16% !important
}

.qodef-portfolio-slider.qodef-item-layout--standard .swiper-button-prev {
    left: unset !important;
    right: calc(16% + 50px) !important
}

.qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom .qodef-e .qodef-e-text,
.qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom .qodef-e .qodef-e-top-holder {
    transform: translateX(50px);
    opacity: 0;
    transition: opacity .8s, transform 1s cubic-bezier(.22, .61, .36, 1)
}

.qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom .qodef-e .qodef-e-top-holder {
    transform: translateX(-50px)
}

.qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom .qodef-e.qodef--active {
    z-index: 2
}

.qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-text,
.qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-top-holder {
    transform: translateX(0);
    opacity: 1
}

.qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom .qodef-e.qodef--active .qodef-e-top-holder {
    transition-delay: .2s
}

@media only screen and (max-width:768px) {
    .qodef-portfolio-slider.qodef-item-layout--standard {
        flex-direction: column
    }

    .qodef-portfolio-slider.qodef-item-layout--standard .qodef-images,
    .qodef-portfolio-slider.qodef-item-layout--standard .qodef-info {
        width: 100%
    }

    .qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-top {
        margin: 0 0 39px
    }

    .qodef-portfolio-slider.qodef-item-layout--standard .qodef-e-info-bottom {
        margin-bottom: 40px
    }

    .qodef-portfolio-slider.qodef-item-layout--standard .swiper-button-next {
        left: unset !important;
        right: 30px !important
    }

    .qodef-portfolio-slider.qodef-item-layout--standard .swiper-button-prev {
        left: unset !important;
        right: 80px !important
    }
}

.qodef-tabbed-portfolio .qodef-section--opener {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1
}

.qodef-tabbed-portfolio .qodef-e-items-wrapper {
    opacity: 0;
    transition: opacity .7s
}

.qodef-tabbed-portfolio .qodef-e-items-wrapper.qodef--init {
    opacity: 1
}

.qodef-tabbed-portfolio .qodef-e-items-wrapper.qodef--init .ui-accordion-content:nth-of-type(2) .qodef-e-media-image {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0);
    animation: qodef-reveal-image 1s cubic-bezier(.57, .18, 0, .92) .2s forwards
}

.qodef-tabbed-portfolio .qodef-e-info--top {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 0 42px;
    margin: 0 20px;
    border-top: 1px solid #e3e3e3;
    cursor: pointer
}

.qodef-tabbed-portfolio .qodef-e-info-bottom {
    display: flex;
    padding-bottom: 62px;
    margin: 0 20px
}

.qodef-tabbed-portfolio .qodef-e-info-bottom:last-of-type {
    border-bottom: 1px solid #e3e3e3
}

.qodef-tabbed-portfolio .qodef-e-left {
    padding-right: 4%
}

.qodef-tabbed-portfolio .qodef-e-left,
.qodef-tabbed-portfolio .qodef-e-right {
    width: 50%
}

.qodef-tabbed-portfolio .qodef-category-holder {
    position: absolute;
    left: 50%
}

.qodef-tabbed-portfolio .qodef-category-holder a {
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000
}

.qodef-tabbed-portfolio .qodef-e-title {
    margin: 0
}

.qodef-tabbed-portfolio .qodef-e-excerpt {
    margin: 0 0 14px
}

.qodef-tabbed-portfolio .qodef-e-read-more {
    margin: 0
}

.qodef-tabbed-portfolio .qodef-e-year {
    font-family: "DM Sans", sans-serif;
    font-size: 13px;
    letter-spacing: .1em
}

.qodef-tabbed-portfolio .qodef-e-post-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.qodef-tabbed-portfolio:after {
    content: '';
    position: absolute;
    left: 20px;
    bottom: 0;
    height: 1px;
    width: calc(100% - 40px);
    background-color: #e3e3e3
}

.qodef-tabbed-portfolio .ui-accordion-content-active .qodef-e-media-image {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0)
}

.qodef-tabbed-portfolio .ui-accordion-content-active.qodef--image-loaded .qodef-e-media-image {
    animation: qodef-reveal-image 1s cubic-bezier(.57, .18, 0, .92) forwards
}

.qodef-tabbed-portfolio .ui-accordion-header:after {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: #000;
    transition: transform 1s ease-out;
    transform-origin: left;
    transform: scaleX(0)
}

.qodef-tabbed-portfolio .ui-accordion-header.ui-state-active:after {
    transform: scaleX(1)
}

#qodef-portfolio-single-related-items {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 157px
}

@media only screen and (max-width:680px) {
    #qodef-portfolio-single-related-items {
        margin-top: 98px
    }
}

#qodef-single-portfolio-navigation {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 139px 0 3px
}

#qodef-single-portfolio-navigation .qodef-m-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    border-top: 1px solid #e3e3e3
}

#qodef-single-portfolio-navigation .qodef-m-nav {
    position: relative;
    display: flex;
    align-items: center
}

#qodef-single-portfolio-navigation .qodef-m-nav>* {
    display: block;
    line-height: inherit
}

#qodef-single-portfolio-navigation .qodef-m-nav.qodef--prev .qodef-m-nav-icon {
    margin-top: 1px;
    margin-right: 5px
}

#qodef-single-portfolio-navigation .qodef-m-nav.qodef--next {
    flex-direction: row-reverse;
    text-align: right;
    margin-left: auto
}

#qodef-single-portfolio-navigation .qodef-m-nav.qodef--next .qodef-m-nav-icon {
    margin-top: 1px;
    margin-left: 5px
}

#qodef-single-portfolio-navigation .qodef-m-nav.qodef--next:hover svg path:nth-of-type(2),
#qodef-single-portfolio-navigation .qodef-m-nav.qodef--prev:hover svg path:nth-of-type(2) {
    animation: qode-draw-line .7s cubic-bezier(.22, .61, .36, 1)
}

#qodef-single-portfolio-navigation .qodef-m-nav.qodef--back-link {
    position: absolute;
    top: 10px;
    left: calc(50% - 16px);
    text-transform: lowercase
}

#qodef-single-portfolio-navigation .qodef-m-nav.qodef--back-link .qodef-m-nav-icon {
    font-size: 32px
}

#qodef-single-portfolio-navigation .qodef-m-nav-icon {
    font-size: 30px
}

#qodef-single-portfolio-navigation .qodef-m-nav-label {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 1.6875em;
    font-weight: 400;
    color: #000;
    text-transform: lowercase
}

@media only screen and (max-width:680px) {
    #qodef-single-portfolio-navigation {
        margin: 76px 0 3px
    }
}

.qodef-portfolio-single.qodef-layout--gallery-big #qodef-single-portfolio-navigation,
.qodef-portfolio-single.qodef-layout--images-big #qodef-single-portfolio-navigation,
.qodef-portfolio-single.qodef-layout--masonry-big #qodef-single-portfolio-navigation,
.qodef-portfolio-single.qodef-layout--slider-big #qodef-single-portfolio-navigation {
    margin: 169px 0 3px
}

@media only screen and (max-width:680px) {

    .qodef-portfolio-single.qodef-layout--gallery-big #qodef-single-portfolio-navigation,
    .qodef-portfolio-single.qodef-layout--images-big #qodef-single-portfolio-navigation,
    .qodef-portfolio-single.qodef-layout--masonry-big #qodef-single-portfolio-navigation,
    .qodef-portfolio-single.qodef-layout--slider-big #qodef-single-portfolio-navigation {
        margin: 76px 0 3px
    }
}

.qodef-portfolio-single.qodef-layout--images-big .qodef-media .qodef-grid-item {
    display: block;
    margin-bottom: 10px;
    float: none
}

.qodef-portfolio-single.qodef-layout--images-big .qodef-media .qodef-grid-item:last-of-type {
    margin-bottom: 0
}

.qodef-portfolio-single.qodef-layout--images-small .qodef-media .qodef-grid-item {
    display: block;
    margin-bottom: 10px;
    float: none
}

.qodef-portfolio-single.qodef-layout--images-small .qodef-media .qodef-grid-item:last-child {
    margin-bottom: 0
}

.qodef-team-list .qodef-e-role {
    margin: 0;
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000
}

.qodef-team-list .qodef-e-title {
    margin: 2px 0 9px
}

.qodef-team-list .qodef-team-member-social-icons {
    margin: 0
}

.qodef-team-list .qodef-team-member-social-icon {
    font-size: 14px;
    margin-right: 15px
}

.qodef-team-list .qodef-team-member-social-icon:hover {
    color: #787878
}

.qodef-team-list .qodef-team-member-social-icon:last-of-type {
    margin-right: 0
}

.qodef-team-list .qodef-e:nth-child(3) {
    --qodef-transition-delay: .2s
}

.qodef-team-list .qodef-e:nth-child(2) {
    --qodef-transition-delay: .4s
}

.qodef-team-list .qodef-e:nth-child(4) {
    --qodef-transition-delay: .6s
}

.qodef-team-list .qodef-e.qodef--has-appear .qodef-e-image {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0)
}

.qodef-team-list .qodef-e.qodef--has-appear.qodef--appeared .qodef-e-image {
    transition: -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay, 0s);
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay, 0s);
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay, 0s), -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay, 0s);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0)
}

.qodef-team-list .qodef-e-additional-link:nth-child(2) {
    --qodef-transition-delay: .8s
}

.qodef-team-list .qodef-e-additional-link.qodef--has-appear .qodef-e-inner {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0)
}

.qodef-team-list .qodef-e-additional-link.qodef--has-appear.qodef--appeared .qodef-e-inner {
    transition: -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay);
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay);
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay), -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) var(--qodef-transition-delay);
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0)
}

.qodef-team-list.qodef-item-layout--info-below .qodef-e-content {
    margin: 14px 0 0
}

.qodef-team-list.qodef-item-layout--info-on-hover .qodef-e-inner:hover .qodef-e-content {
    opacity: 1;
    z-index: 1
}

.qodef-team-list.qodef-item-layout--info-on-hover .qodef-e-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity .2s ease-out;
    z-index: -1
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-inner {
    display: flex
}

.qodef-team-list.qodef-item-layout--info-right .qodef-team-member-social-icons {
    position: absolute;
    bottom: 12%
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-title {
    margin: 0 0 9px
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-content,
.qodef-team-list.qodef-item-layout--info-right .qodef-e-image {
    width: 50%
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-image img {
    width: 100%;
    object-fit: cover
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 6.2%
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-additional-link {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-additional-link .qodef-e-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-additional-link .qodef-e-link {
    --qodef-underline-position-y: 100%;
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height)
}

.qodef-team-list.qodef-item-layout--info-right .qodef-e-additional-link .qodef-e-link:hover {
    animation: qode-inline-underline-initial-hover .8s cubic-bezier(.57, .39, 0, .86) 1 forwards
}

.qodef-team-list.qodef-item-layout--info-right.qodef-content-align--center-content .qodef-team-member-social-icons {
    position: relative;
    bottom: unset;
    margin-top: 50px
}

.qodef-team-list.qodef-item-layout--info-right.qodef-enable-border {
    border-bottom: 1px solid #e3e3e3
}

.qodef-team-list.qodef-item-layout--info-right.qodef-enable-border .qodef-e-inner {
    padding: 55px 0;
    border-top: 1px solid #e3e3e3
}

.qodef-team-list.qodef-item-layout--info-right.qodef-enable-border .qodef-team-member-social-icons {
    bottom: calc(12% + 55px)
}

.qodef-team-list.qodef-item-layout--info-right.qodef-enable-border.qodef-content-align--center-content .qodef-team-member-social-icons {
    bottom: unset
}

.qodef-team-list.qodef-item-layout--info-right.qodef-layout--checkered.qodef-grid.qodef-layout--columns.qodef-col-num--2>.qodef-grid-inner>.qodef-grid-item {
    width: 40%
}

.qodef-team-list.qodef-item-layout--info-right.qodef-layout--checkered.qodef-grid.qodef-layout--columns.qodef-col-num--2>.qodef-grid-inner>.qodef-grid-item:nth-child(4n+3) {
    margin-left: 20%
}

@media only screen and (max-width:1024px) {
    .qodef-team-list.qodef-item-layout--info-right.qodef-layout--checkered.qodef-grid.qodef-layout--columns.qodef-col-num--2>.qodef-grid-inner>.qodef-grid-item {
        width: 100%
    }

    .qodef-team-list.qodef-item-layout--info-right.qodef-layout--checkered.qodef-grid.qodef-layout--columns.qodef-col-num--2>.qodef-grid-inner>.qodef-grid-item:nth-child(4n+3) {
        margin-left: 0
    }

    .qodef-team-list.qodef-item-layout--info-right.qodef-layout--checkered.qodef-grid.qodef-layout--columns.qodef-col-num--2>.qodef-grid-inner>.qodef-grid-item:nth-child(even) .qodef-e-inner {
        flex-direction: row-reverse
    }

    .qodef-team-list.qodef-item-layout--info-right .qodef-e-additional-link {
        position: relative;
        width: 100%
    }
}

@media only screen and (max-width:680px) {
    .qodef-team-list.qodef-item-layout--info-right .qodef-e-inner {
        flex-direction: column
    }

    .qodef-team-list.qodef-item-layout--info-right .qodef-e-image {
        width: 100%
    }

    .qodef-team-list.qodef-item-layout--info-right .qodef-e-content {
        width: 100%;
        padding: 7% 0 48px
    }

    .qodef-team-list.qodef-item-layout--info-right .qodef-team-member-social-icons {
        position: relative;
        bottom: 0;
        margin-top: 20px
    }

    .qodef-team-list.qodef-item-layout--info-right.qodef-enable-border .qodef-e-inner {
        padding: 55px 0 48px
    }

    .qodef-team-list.qodef-item-layout--info-right.qodef-enable-border .qodef-e-content {
        padding: 7% 0 0
    }

    .qodef-team-list.qodef-item-layout--info-right.qodef-enable-border .qodef-team-member-social-icons {
        bottom: -5px
    }

    .qodef-team-list.qodef-item-layout--info-right.qodef-layout--checkered.qodef-grid.qodef-layout--columns.qodef-col-num--2>.qodef-grid-inner>.qodef-grid-item:nth-child(even) .qodef-e-inner {
        flex-direction: column
    }
}

.qodef-testimonials-list .qodef-e-inner {
    padding-bottom: 37px;
    width: 80%;
    margin: 0 auto;
    text-align: center
}

.qodef-testimonials-list .qodef-e-media-image {
    line-height: 0;
    margin-bottom: 25px
}

.qodef-testimonials-list .qodef-e-media-image img {
    width: auto
}

.qodef-testimonials-list .qodef-e-title {
    margin: 0 0 35px
}

.qodef-testimonials-list .qodef-e-text {
    margin: 0 0 41px
}

.qodef-testimonials-list .qodef-e-author {
    margin: 0
}

.qodef-testimonials-list .qodef-e-author-name:after {
    content: ','
}

.qodef-testimonials-list.qodef-skin--light .qodef-e-author,
.qodef-testimonials-list.qodef-skin--light .qodef-e-text,
.qodef-testimonials-list.qodef-skin--light .qodef-e-title {
    color: #fff
}

.qodef-testimonials-list.qodef-skin--light .swiper-pagination-bullet:before {
    background-color: #fff
}

.qodef-testimonials-list.qodef-swiper-container .qodef-e .qodef-e-author {
    opacity: 0;
    transform: translateX(150px);
    transition: opacity 1s, transform 1s cubic-bezier(.22, .61, .36, 1)
}

.qodef-testimonials-list.qodef-swiper-container .qodef-e.swiper-slide-active .qodef-e-author {
    opacity: 1;
    transform: translateX(0);
    transition-delay: .3s
}

.qodef-review-rating {
    display: flex
}

.qodef-review-rating .qodef-rating-inner {
    display: flex;
    flex-basis: 50%
}

.qodef-review-rating label {
    margin: 0 10px 0 0
}

.qodef-review-rating .qodef-comment-rating-box {
    display: flex;
    margin: 0
}

.qodef-review-rating .qodef-star-rating {
    position: relative;
    display: block;
    margin: 0 .1em 0 0;
    cursor: pointer;
    color: #000
}

.qodef-review-rating .qodef-star-rating svg {
    display: block;
    width: 14px;
    height: auto;
    fill: none;
    stroke: currentColor
}

.qodef-review-rating .qodef-star-rating.active svg {
    fill: currentColor
}

.qodef-review-rating .qodef-comments-ratings {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    line-height: 1
}

.qodef-review-rating .qodef-comments-ratings .qodef-m-inner {
    position: relative;
    display: inline-block;
    vertical-align: top
}

.qodef-review-rating .qodef-comments-ratings .qodef-m-star {
    color: #000
}

.qodef-review-rating .qodef-comments-ratings .qodef-m-star svg {
    display: inline-block;
    margin-right: .1em;
    width: 14px;
    height: auto
}

.qodef-review-rating .qodef-comments-ratings .qodef-m-star svg:last-child {
    margin-right: 0
}

.qodef-review-rating .qodef-comments-ratings .qodef-m-star.qodef--active {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    white-space: nowrap
}

.qodef-review-rating .qodef-comments-ratings .qodef-m-star.qodef--active svg {
    fill: currentColor
}

.qodef-reviews-per-criteria .qodef-item-reviews-average-rating {
    color: var(--qode-main-color);
    font-size: 60px;
    line-height: 1em;
    font-weight: 700
}

.qodef-reviews-per-criteria .qodef-item-reviews-verbal-description {
    vertical-align: middle;
    margin: 2px 0 0
}

.qodef-reviews-per-criteria .qodef-item-reviews-rating-icon {
    vertical-align: middle;
    font-size: 20px
}

.qodef-reviews-per-criteria .qodef-item-reviews-display-right {
    vertical-align: middle;
    padding-left: 200px
}

.qodef-reviews-per-criteria .qodef-item-reviews-display-right .qodef-item-reviews-display-bar {
    line-height: 28px
}

.qodef-reviews-per-criteria .qodef-item-reviews-display-right .qodef-item-reviews-display-bar .qodef-item-reviews-display-bar-inner {
    padding: 6px 0
}

.qodef-reviews-per-mark .qodef-reviews-number-holder {
    display: flex;
    align-items: center
}

.qodef-reviews-per-mark .qodef-reviews-number-wrapper {
    display: flex;
    flex-direction: column;
    width: 25%;
    text-align: center
}

.qodef-reviews-per-mark .qodef-reviews-number {
    font-size: 72px;
    color: #ffd740;
    font-weight: 700;
    line-height: 1em
}

.qodef-reviews-per-mark .qodef-stars-wrapper {
    display: table-cell;
    vertical-align: middle
}

.qodef-reviews-per-mark .qodef-stars-wrapper .qodef-stars {
    display: block
}

.qodef-reviews-per-mark .qodef-stars-wrapper .qodef-stars i {
    color: #ffd740
}

.qodef-reviews-per-mark .qodef-reviews-count {
    font-weight: 700;
    font-size: 14px
}

.qodef-reviews-per-mark .qodef-rating-percentage-wrapper {
    width: 75%;
    padding: 29px 50px 40px;
    background-color: #fafafa;
    border-left: 1px solid #e5e5e5
}

.qodef-reviews-simple .qodef-reviews-number-wrapper .qodef-reviews-summary {
    display: inline-block;
    vertical-align: middle
}

.qodef-reviews-simple .qodef-reviews-number-wrapper .qodef-stars-wrapper {
    display: inline-block;
    vertical-align: middle;
    padding: 0 0 0 15px
}

.qodef-reviews-simple .qodef-reviews-number-wrapper .qodef-stars-wrapper-inner {
    display: block
}

.qodef-comment-list .qodef-comment-name {
    float: none
}

.qodef-comment-list .qodef-review-rating {
    display: inline-block;
    width: 100%
}

.qodef-comment-list .qodef-rating-inner {
    display: inline-block;
    width: 100%
}

@keyframes cover_search_fade_out {
    0% {
        opacity: 1;
        visibility: visible;
        z-index: 100
    }

    100% {
        opacity: 0;
        visibility: hidden;
        z-index: 0
    }
}

@keyframes cover_search_fade_in {
    0% {
        opacity: 0;
        visibility: hidden;
        z-index: 100
    }

    100% {
        opacity: 1;
        visibility: visible;
        z-index: 100
    }
}

.qodef-search-cover-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    z-index: -1;
    transition: opacity .3s ease
}

.qodef-search-cover-form .qodef-m-inner {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px
}

.qodef-search-cover-form .qodef-m-form-field {
    padding: 0;
    margin-bottom: 0;
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--qode-main-color);
    background-color: transparent;
    border: 0;
    border-radius: 0;
    outline-offset: 0
}

.qodef-search-cover-form .qodef-m-form-field:focus {
    color: var(--qode-main-color)
}

.qodef-search-cover-form .qodef-m-form-field:-ms-input-placeholder {
    color: inherit;
    opacity: 1
}

.qodef-search-cover-form .qodef-m-form-field::placeholder {
    color: inherit;
    opacity: 1
}

.qodef-search-cover-form .qodef-m-close {
    flex-shrink: 0
}

.qodef-search-cover-form .qodef-m-close.qodef-source--icon-pack {
    font-size: 23px
}

.qodef-covers-search--fadeout .qodef-search-cover-form {
    animation: cover_search_fade_out .3s ease both
}

.qodef-covers-search--fadein .qodef-search-cover-form {
    animation: cover_search_fade_in .3s ease both
}

.qodef-header--sticky-display #qodef-page-header>.qodef-search-cover-form {
    display: none
}

.qodef-fullscreen-search-holder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, .98);
    z-index: 100
}

.qodef-fullscreen-menu-animate--in .qodef-fullscreen-search-holder {
    display: none
}

.qodef-fullscreen-search-holder .qodef-m-inner {
    width: 100%
}

.qodef-fullscreen-search-holder .qodef-m-form {
    position: relative;
    width: 60%;
    margin: 0 auto
}

.qodef-fullscreen-search-holder .qodef-m-form-field {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 25px;
    color: #000;
    line-height: 2em;
    background: 0 0;
    border: 0;
    border-radius: 0
}

.qodef-fullscreen-search-holder .qodef-m-form-submit {
    position: absolute;
    right: 0;
    bottom: -20px;
    width: auto;
    padding: 0;
    margin: 0;
    color: #000;
    background: 0 0;
    border: 0;
    outline: 0;
    opacity: 0;
    cursor: pointer;
    transition: color .2s ease-out
}

.qodef-fullscreen-search-holder .qodef-m-form-submit:hover {
    color: var(--qode-main-color);
    background: 0 0
}

.qodef-fullscreen-search--opened .qodef-fullscreen-search-holder .qodef-m-form-submit {
    opacity: 1;
    bottom: 10px;
    transition: color .2s ease, opacity .2s ease-out 1.1s, bottom .4s ease-out .9s
}

.qodef-fullscreen-search-holder .qodef-m-form-submit.qodef-source--icon-pack {
    font-size: 18px
}

.qodef-fullscreen-search-holder .qodef-m-form-line {
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 0;
    border-bottom: 1px solid #000;
    transition: all .3s ease
}

.qodef-fullscreen-search--opened .qodef-fullscreen-search-holder .qodef-m-form-line {
    width: 100%;
    transition: width .5s ease .6s
}

.qodef-fullscreen-search-holder .qodef-m-close {
    position: absolute;
    top: 33px;
    right: 70px;
    color: #000;
    opacity: 0;
    visibility: hidden;
    z-index: 1
}

.admin-bar .qodef-fullscreen-search-holder .qodef-m-close {
    top: 72px
}

.qodef-fullscreen-search-holder .qodef-m-close:hover {
    color: #000
}

.qodef-fullscreen-search-holder .qodef-m-close.qodef-source--icon-pack {
    font-size: 25px
}

.qodef-fullscreen-search--opened .qodef-fullscreen-search-holder .qodef-m-close {
    opacity: 1;
    visibility: visible
}

.qodef-fullscreen-search--fadeout .qodef-fullscreen-search-holder {
    animation: fullscreen_search_fade_out .3s ease both
}

.qodef-fullscreen-search--fadein .qodef-fullscreen-search-holder {
    animation: fullscreen_search_fade_in .3s ease both
}

@keyframes fullscreen_search_fade_out {
    0% {
        opacity: 1;
        visibility: visible
    }

    100% {
        opacity: 0;
        visibility: hidden
    }
}

@keyframes fullscreen_search_fade_in {
    0% {
        opacity: 0;
        visibility: hidden
    }

    100% {
        opacity: 1;
        visibility: visible
    }
}

.qodef-search-opener>* {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    line-height: inherit
}

.qodef-search-opener.qodef-source--icon-pack {
    font-size: 23px
}

.qodef-accordion {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    visibility: hidden;
    overflow-anchor: none
}

.qodef-accordion .qodef-accordion-content-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-accordion.qodef--init {
    visibility: visible
}

.qodef-accordion.qodef-layout--simple {
    padding-bottom: 44px;
    border-bottom: 1px solid #e3e3e3
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 44px 0 0;
    padding: 12px 0 6px 0;
    transition: color .2s ease-out, background-color .2s ease-out;
    cursor: pointer;
    border-top: 1px solid #e3e3e3
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title:first-child {
    margin-top: 0
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title:before {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform .5s ease-out;
    transform-origin: left;
    transform: scaleX(0)
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title.ui-state-hover .qodef-accordion-mark .qodef-icon--minus {
    -webkit-clip-path: inset(0 8px);
    clip-path: inset(0 8px)
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title .qodef-accordion-mark {
    position: relative;
    flex-shrink: 0;
    width: 29px;
    height: 11px;
    text-align: center
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title .qodef-accordion-mark span {
    position: absolute;
    display: block;
    transition: opacity .35s, -webkit-clip-path .35s;
    transition: clip-path .35s, opacity .35s;
    transition: clip-path .35s, opacity .35s, -webkit-clip-path .35s
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title .qodef-accordion-mark span:before {
    display: block;
    line-height: inherit
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title .qodef-accordion-mark span.qodef-icon--minus {
    background-color: #000;
    width: 29px;
    height: 1px;
    top: 5px
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title .qodef-accordion-mark span.qodef-icon--plus {
    background-color: #000;
    width: 1px;
    height: 11px;
    left: 14px
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title .qodef-accordion-mark span.qodef-icon--minus,
.qodef-accordion.qodef-layout--simple .qodef-accordion-title .qodef-accordion-mark span.qodef-icon--plus {
    -webkit-clip-path: inset(0 0);
    clip-path: inset(0 0)
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title.ui-state-active:before {
    transform: scaleX(1)
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title.ui-state-active .qodef-accordion-mark span.qodef-icon--minus {
    opacity: 1
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-title.ui-state-active .qodef-accordion-mark span.qodef-icon--plus {
    -webkit-clip-path: inset(5px 0);
    clip-path: inset(5px 0)
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-content {
    padding: 16px 0 2px;
    margin: 0
}

.qodef-accordion.qodef-layout--simple .qodef-accordion-content p {
    margin: 0
}

.qodef-accordion.qodef-layout--simple.qodef-behavior--toggle .qodef-accordion-content {
    display: none
}

.qodef-banner {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-banner .qodef-m-banner-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1
}

.qodef-banner .qodef-m-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block
}

.qodef-banner .qodef-m-content-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex
}

.qodef-banner .qodef-m-title {
    margin: 10px 0 4px
}

.qodef-banner .qodef-m-title-inner {
    display: inline-block
}

.qodef-banner .qodef-m-subtitle {
    font-family: "DM Sans", sans-serif;
    margin: 0;
    opacity: .5;
    letter-spacing: 0
}

.qodef-banner .qodef-m-text-field {
    font-size: 15px;
    color: var(--qode-main-color);
    margin: 0 0 10px;
    font-style: italic
}

.qodef-banner .qodef-m-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    overflow: hidden
}

.qodef-banner .qodef-m-image img {
    display: block;
    transition: all .35s ease-out
}

.qodef-banner.qodef-layout--link-button .qodef-m-image-holder {
    position: relative
}

.qodef-banner.qodef-layout--link-button .qodef-m-content {
    position: relative
}

.qodef-banner.qodef-layout--link-button .qodef-m-content-inner {
    position: relative;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;
    margin: 27px 0 0
}

.qodef-banner.qodef-layout--link-button .qodef-m-title {
    margin: 0
}

.qodef-banner.qodef-layout--link-button .qodef-m-text {
    margin: 0
}

.qodef-banner.qodef-layout--link-button .qodef-m-button {
    margin: 12px 0 0
}

.qodef-banner.qodef-layout--link-button .qodef-m-image {
    overflow: hidden
}

.qodef-banner.qodef-layout--link-button .qodef-m-image img {
    max-width: unset;
    width: calc(100% + 10px);
    transform: translateX(-8px);
    transition: transform .5s cubic-bezier(.25, .46, .35, .94)
}

.qodef-banner.qodef-layout--link-button:hover .qodef-m-image img {
    transform: translateX(0)
}

.qodef-banner.qodef-layout--link-overlay .qodef-m-content-inner {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding: 30px
}

.qodef-button.qodef-html--link {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 1.6875em;
    font-weight: 400;
    color: #000;
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    width: auto;
    margin: 0;
    text-decoration: none;
    text-transform: lowercase;
    border-radius: 0;
    outline: 0;
    transition: color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out;
    padding: 9px 68px
}

.qodef-button.qodef-size--small {
    padding: 7px 43px
}

.qodef-button.qodef-size--large {
    padding: 11px 51px
}

.qodef-button.qodef-size--full {
    width: 100%;
    padding: 12px 20px;
    justify-content: center;
    text-align: center
}

.qodef-button.qodef-layout--filled.qodef-html--link {
    color: #fff;
    background-color: transparent;
    border: 1px solid var(--qode-main-color);
    z-index: 1;
    transition-delay: .03s;
    overflow: hidden
}

.qodef-button.qodef-layout--filled.qodef-html--link:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: var(--qodef-hover-bg-color, var(--qode-main-color));
    transition: transform .8s cubic-bezier(.22, .61, .36, 1);
    z-index: -1;
    width: calc(100% + 2px);
    transform-origin: left;
    transform: scaleX(1)
}

.qodef-button.qodef-layout--filled.qodef-html--link:hover {
    color: var(--qode-main-color);
    background-color: transparent;
    border-color: var(--qode-main-color)
}

.qodef-button.qodef-layout--filled.qodef-html--link:hover:after {
    transform-origin: right;
    transform: scaleX(0)
}

.qodef-button.qodef-layout--outlined {
    color: var(--qode-main-color);
    background-color: transparent;
    border: 1px solid var(--qode-main-color);
    z-index: 1;
    transition-delay: .03s
}

.qodef-button.qodef-layout--outlined:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-color: var(--qodef-hover-bg-color, var(--qode-main-color));
    transition: transform .8s cubic-bezier(.22, .61, .36, 1);
    z-index: -1;
    transform-origin: right;
    transform: scaleX(0)
}

.qodef-button.qodef-layout--outlined:hover {
    color: #fff;
    background-color: transparent;
    border-color: var(--qode-main-color)
}

.qodef-button.qodef-layout--outlined:hover:after {
    transform-origin: left;
    transform: scaleX(1)
}

.qodef-button.qodef-layout--textual {
    padding: 0 !important;
    color: #000;
    background-color: transparent;
    border: 0
}

.qodef-button.qodef-layout--textual .qodef-m-text {
    position: relative;
    --qodef-underline-position-y: calc(90% - -2px);
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height)
}

.qodef-button.qodef-layout--textual:hover .qodef-m-text {
    animation: qode-inline-underline-initial-hover .8s cubic-bezier(.57, .39, 0, .86) 1 forwards
}

.qodef-call-to-action.qodef-layout--standard {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-call-to-action.qodef-layout--standard .qodef-m-inner {
    display: flex;
    align-items: center
}

.qodef-call-to-action.qodef-layout--standard.qodef-style--stretched .qodef-m-inner {
    justify-content: space-between
}

.qodef-call-to-action.qodef-layout--standard.qodef-style--centered .qodef-m-inner {
    justify-content: center
}

.qodef-call-to-action.qodef-layout--standard .qodef-m-content {
    padding-right: 20px;
    max-width: 47%
}

.qodef-call-to-action.qodef-layout--standard .qodef-m-content>* {
    margin: 0
}

.qodef-call-to-action.qodef-layout--standard .qodef-m-button {
    flex: 0 0 auto
}

.qodef-countdown .qodef-m-date {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.qodef-countdown .qodef-digit,
.qodef-countdown .qodef-label {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: center
}

.qodef-countdown.qodef-show--1 .qodef-digit-wrapper {
    width: 100%
}

.qodef-countdown.qodef-show--2 .qodef-digit-wrapper {
    width: 50%
}

.qodef-countdown.qodef-show--3 .qodef-digit-wrapper {
    width: 33.33333%
}

.qodef-countdown.qodef-show--4 .qodef-digit-wrapper {
    width: 25%
}

.qodef-countdown.qodef-show--5 .qodef-digit-wrapper {
    width: 20%
}

.qodef-countdown.qodef-show--6 .qodef-digit-wrapper {
    width: 16.66667%
}

.qodef-countdown.qodef-countdown--light .qodef-digit,
.qodef-countdown.qodef-countdown--light .qodef-label {
    color: #fff
}

.qodef-countdown.qodef-layout--simple .qodef-digit-wrapper {
    display: inline-block;
    vertical-align: top;
    padding: 0 20px;
    min-height: 75px
}

.qodef-countdown.qodef-layout--simple .qodef-digit {
    font-family: "DM Sans", sans-serif;
    font-weight: 500;
    font-size: 45px;
    line-height: 1;
    color: #000
}

.qodef-countdown.qodef-layout--simple .qodef-label {
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    margin: 12px 0 0 0
}

.qodef-counter.qodef-layout--simple {
    text-align: center
}

.qodef-counter.qodef-layout--simple .qodef-m-digit {
    font-family: inherit;
    font-weight: 600;
    font-size: 45px;
    line-height: 1;
    color: #000
}

.qodef-counter.qodef-layout--simple .qodef-m-title {
    margin: 10px 0 0 0
}

.qodef-counter.qodef-layout--simple .qodef-m-text {
    margin: 0
}

.widget.widget_level_core_custom_font {
    margin-bottom: 0 !important
}

.qodef-custom-font {
    position: relative;
    margin: 0
}

.qodef-dropcaps {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-dropcaps .qodef-m-letter {
    position: relative;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    margin: 0 10px 5px 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 45px;
    line-height: 1.2em;
    font-weight: 400
}

@media only screen and (max-width:1440px) {
    .qodef-dropcaps .qodef-m-letter {
        font-size: 38px;
        line-height: 1.13158em
    }
}

.qodef-dropcaps .qodef-m-text {
    display: inline;
    margin: 0
}

.qodef-dropcaps:not(.qodef-type--simple) .qodef-m-letter {
    width: 40px;
    height: 40px;
    font-size: 24px;
    line-height: 1.20833em;
    font-weight: 400;
    color: #fff;
    background-color: #000
}

@media only screen and (max-width:1440px) {
    .qodef-dropcaps:not(.qodef-type--simple) .qodef-m-letter {
        font-size: 22px;
        line-height: 1.22727em
    }
}

.qodef-dropcaps.qodef-type--circle .qodef-m-letter {
    border-radius: 100%
}

.qodef-frame-slider-holder {
    position: relative;
    margin: 0 auto 106px;
    max-width: 630px
}

.qodef-frame-slider-holder .qodef-frame-slider-image {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    max-width: 630px;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 100
}

.qodef-frame-slider-holder .qodef-frame-slider-image img {
    width: 100%
}

.qodef-frame-slider-holder .qodef-m-items {
    position: relative;
    max-width: 94%;
    width: 100%;
    margin: 0 auto
}

.qodef-frame-slider-holder .qodef-m-swiper {
    overflow: hidden
}

.qodef-frame-slider-holder .qodef-m-item {
    height: 0;
    width: 0;
    margin: 3% 0;
    padding-left: 50%;
    padding-top: calc(100% * 445 / 592)
}

.qodef-frame-slider-holder .qodef-m-item img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover
}

.qodef-frame-slider-holder .swiper-pagination-bullets {
    bottom: -106px !important
}

.elementor-section {
    transition: background-color .2s ease-out
}

.qodef-fullscreen-tabs {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    opacity: 0;
    animation: qode-fade-in 1s .5s both
}

.qodef-fullscreen-tabs .qodef-e-inner {
    height: 100%;
    display: flex
}

.qodef-fullscreen-tabs .qodef-m-tab {
    display: flex;
    position: relative;
    height: 100%;
    transition: .8s ease;
    width: 248px;
    overflow: hidden
}

.qodef-fullscreen-tabs .qodef-m-tab.active-tab div.qodef-m-title:after {
    transform: scaleY(1)
}

.qodef-fullscreen-tabs .qodef-m-tab.active-tab::after {
    opacity: 0
}

.qodef-fullscreen-tabs .qodef-m-tab.active-tab .qodef-e-image,
.qodef-fullscreen-tabs .qodef-m-tab.active-tab .qodef-e-mobile-image {
    animation: qodef-reveal-image 1s cubic-bezier(.57, .18, 0, .92) forwards
}

.qodef-fullscreen-tabs .qodef-m-tab::before {
    content: "";
    display: block;
    width: 29px;
    height: 1px;
    position: absolute;
    right: 23px;
    top: 22px;
    background-color: #000;
    -webkit-clip-path: inset(-1px 0);
    clip-path: inset(-1px 0);
    transition: opacity .35s, -webkit-clip-path .35s;
    transition: clip-path .35s, opacity .35s;
    transition: clip-path .35s, opacity .35s, -webkit-clip-path .35s
}

.qodef-fullscreen-tabs .qodef-m-tab::after {
    content: "";
    display: block;
    width: 1px;
    height: 11px;
    position: absolute;
    right: 36px;
    top: 17px;
    background-color: #000;
    opacity: 1
}

.qodef-fullscreen-tabs .qodef-m-tab:hover:before {
    -webkit-clip-path: inset(-1px 8px -1px 10px);
    clip-path: inset(-1px 8px -1px 10px)
}

.qodef-fullscreen-tabs .qodef-tab-wrapper {
    position: relative
}

.qodef-fullscreen-tabs div.qodef-m-title {
    display: flex;
    width: 100%;
    max-width: 248px;
    justify-content: flex-start;
    align-items: flex-end;
    border-left: 1px solid #e7e7da;
    height: 100%;
    position: absolute;
    bottom: 0;
    z-index: 50;
    cursor: pointer
}

.qodef-fullscreen-tabs div.qodef-m-title:after {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 1px;
    height: 100%;
    background-color: #000;
    transition: transform .5s ease-out;
    transform-origin: bottom;
    transform: scaleY(0)
}

.qodef-fullscreen-tabs .qodef-m-title-vertical {
    font-size: 52px;
    font-weight: 300;
    line-height: 42px;
    font-family: "DM Sans", sans-serif;
    color: #000;
    display: flex;
    transform: rotate(270deg);
    transform-origin: 0 5%;
    white-space: nowrap;
    align-items: center;
    position: relative;
    left: 55px;
    bottom: -45px
}

.qodef-fullscreen-tabs .qodef-m-content {
    position: relative;
    height: 100%;
    display: flex;
    justify-content: stretch;
    transition: .8s ease
}

.qodef-fullscreen-tabs .qodef-m-content>* {
    flex: 0 1 auto
}

.qodef-fullscreen-tabs .qodef-e-image img,
.qodef-fullscreen-tabs .qodef-e-mobile-image img {
    position: relative;
    display: block;
    height: auto;
    width: -moz-fit-content;
    width: fit-content
}

.qodef-fullscreen-tabs .qodef-e-image {
    display: flex;
    max-width: 40%
}

.qodef-fullscreen-tabs .qodef-e-mobile-image {
    display: none;
    max-width: 100%
}

.qodef-fullscreen-tabs .qodef-m-text {
    font-size: 16px
}

.qodef-fullscreen-tabs .qodef-e-content-inner {
    position: relative;
    display: flex;
    max-width: 60%;
    z-index: 10;
    flex-direction: column;
    justify-content: flex-end;
    padding: 40px 80px
}

.qodef-fullscreen-tabs .qodef-button {
    position: relative;
    margin-top: 28px;
    display: inline-flex;
    line-height: 1;
    font-size: 13px
}

.qodef-fullscreen-tabs .qodef-button .qodef-m-text {
    padding-bottom: 3px
}

.qodef-google-map {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-google-map .qodef-m-map {
    display: block;
    width: 100%
}

.qodef-google-map .qodef-m-map embed,
.qodef-google-map .qodef-m-map iframe,
.qodef-google-map .qodef-m-map object {
    width: 100%;
    display: block
}

.qodef-google-map .qodef-m-map img {
    max-width: none
}

.qodef-highlight .qodef-highlight-text {
    padding: 0 5px;
    color: #fff;
    background-color: #000
}

.qodef-icon-list-item {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    line-height: 1
}

.qodef-icon-list-item .qodef-e-title {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    line-height: 1
}

.qodef-icon-list-item .qodef-e-title a {
    display: block
}

.qodef-icon-list-item .qodef-e-title-inner {
    display: flex;
    align-items: center
}

.qodef-icon-list-item .qodef-icon-holder {
    flex-shrink: 0;
    margin-right: 5px;
    float: none
}

.qodef-icon-list-item .qodef-icon-holder .qodef-icon {
    font-size: 16px
}

.qodef-icon-list-item.qodef-icon--custom-icon .qodef-e-icon-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: top
}

.qodef-icon-list-item.qodef-icon--custom-icon .qodef-e-icon-wrapper a,
.qodef-icon-list-item.qodef-icon--custom-icon .qodef-e-icon-wrapper img {
    display: block
}

#qodef-page-sidebar .widget.widget_level_core_icon_list_item {
    margin-bottom: 0
}

#qodef-page-footer .widget.widget_level_core_icon_list_item {
    margin-bottom: 0
}

#qodef-side-area .widget.widget_level_core_icon_list_item {
    margin-bottom: 0
}

.qodef-icon-with-text .qodef-m-title {
    margin: 0
}

.qodef-icon-with-text .qodef-m-title a {
    display: block
}

.qodef-icon-with-text .qodef-m-text {
    margin: 8px 0 0
}

.qodef-icon-with-text .qodef-m-icon-wrapper {
    line-height: 1
}

.qodef-icon-with-text .qodef-icon-holder {
    float: none
}

.qodef-icon-with-text .qodef-m-content {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-icon-with-text.qodef--custom-icon .qodef-m-icon-wrapper {
    position: relative;
    display: inline-block;
    vertical-align: top
}

.qodef-icon-with-text.qodef--custom-icon .qodef-m-icon-wrapper a,
.qodef-icon-with-text.qodef--custom-icon .qodef-m-icon-wrapper img {
    display: block
}

.qodef-icon-with-text.qodef-layout--before-content {
    display: flex;
    align-items: flex-start
}

.qodef-icon-with-text.qodef-layout--before-content .qodef-m-icon-wrapper {
    flex-shrink: 0;
    margin-right: 15px
}

.qodef-icon-with-text.qodef-layout--before-title .qodef-m-title {
    line-height: 1
}

.qodef-icon-with-text.qodef-layout--before-title .qodef-m-title-inner {
    display: flex;
    align-items: center
}

.qodef-icon-with-text.qodef-layout--before-title .qodef-m-icon-wrapper {
    flex-shrink: 0;
    margin-right: 5px
}

.qodef-icon-with-text.qodef-layout--before-title.qodef--retina img {
    max-width: 50%
}

.qodef-icon-with-text.qodef-layout--top .qodef-m-title {
    margin-top: 21px
}

.qodef-icon-with-text.qodef-layout--top.qodef-alignment--center {
    text-align: center
}

.qodef-icon-with-text.qodef-layout--top.qodef-alignment--left {
    text-align: left
}

.qodef-icon-with-text.qodef-layout--top.qodef-alignment--right {
    text-align: right
}

.qodef-icon-with-text.qodef-layout--top.qodef--retina img {
    max-width: 50%;
    margin: 0 auto
}

.qodef-icon-holder {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: auto;
    line-height: 1
}

.qodef-icon-holder a {
    display: block
}

.qodef-icon-holder .qodef-icon {
    display: block;
    line-height: inherit
}

.qodef-icon-holder .qodef-icon:before {
    display: block;
    line-height: inherit
}

.qodef-icon-holder.qodef-layout--circle,
.qodef-icon-holder.qodef-layout--square {
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    color: #fff;
    background-color: var(--qode-main-color);
    transition: color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out
}

.qodef-icon-holder.qodef-layout--circle a,
.qodef-icon-holder.qodef-layout--square a {
    color: inherit;
    transition: none
}

.qodef-icon-holder.qodef-layout--circle:hover,
.qodef-icon-holder.qodef-layout--square:hover {
    background-color: #000
}

.qodef-icon-holder.qodef-layout--circle {
    border-radius: 50%
}

.qodef-icon-holder.qodef-size--tiny {
    font-size: 1.33333333em
}

.qodef-icon-holder.qodef-size--small {
    font-size: 2em
}

.qodef-icon-holder.qodef-size--medium {
    font-size: 3em
}

.qodef-icon-holder.qodef-size--large {
    font-size: 4em
}

.qodef-icon-holder.qodef-size--huge {
    font-size: 5em
}

.widget.widget_level_core_icon {
    display: inline-block;
    line-height: 1
}

#qodef-page-sidebar .widget.widget_level_core_icon {
    margin-bottom: 0
}

#qodef-page-footer .widget.widget_level_core_icon {
    margin-bottom: 0
}

#qodef-side-area .widget.widget_level_core_icon {
    margin-bottom: 0
}

.qodef-image-gallery.qodef-image-border img {
    border: 1px solid #e3e3e3
}

.qodef-image-gallery.qodef-slider-type--image-zoom .swiper-slide {
    width: 31%;
    transition: all .6s ease
}

.qodef-image-gallery.qodef-slider-type--image-zoom .swiper-slide.swiper-slide-active {
    width: 47%
}

.qodef-image-hotspots .qodef-m-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.qodef-image-hotspots .qodef-m-image {
    height: 100%
}

.qodef-image-hotspots .qodef-m-image img {
    height: 100%;
    object-fit: cover
}

.qodef-image-hotspots .qodef-e {
    position: absolute;
    left: var(--qode-hotspot-left-1920, 0);
    top: var(--qode-hotspot-top-1920, 0)
}

@media only screen and (max-width:1366px) {
    .qodef-image-hotspots .qodef-e {
        left: var(--qode-hotspot-left-1366, 0);
        top: var(--qode-hotspot-top-1366, 0)
    }
}

@media only screen and (max-width:1024px) {
    .qodef-image-hotspots .qodef-e {
        left: var(--qode-hotspot-left-1024, 0);
        top: var(--qode-hotspot-top-1024, 0)
    }
}

@media only screen and (max-width:768px) {
    .qodef-image-hotspots .qodef-e {
        left: var(--qode-hotspot-left-768, 0);
        top: var(--qode-hotspot-top-768, 0)
    }
}

@media only screen and (max-width:680px) {
    .qodef-image-hotspots .qodef-e {
        left: var(--qode-hotspot-left-680, 0);
        top: var(--qode-hotspot-top-680, 0)
    }
}

.qodef-image-hotspots .qodef-e-link,
.qodef-image-hotspots .qodef-e-no-link {
    position: relative
}

.qodef-image-hotspots .qodef-e-link:hover .qodef-e-content:before,
.qodef-image-hotspots .qodef-e-no-link:hover .qodef-e-content:before {
    transform: scaleX(1)
}

.qodef-image-hotspots .qodef-e-link:hover .qodef-e-title,
.qodef-image-hotspots .qodef-e-no-link:hover .qodef-e-title {
    opacity: 1;
    transform: translateX(0)
}

.qodef-image-hotspots .qodef-e-link:hover .qodef-e-text,
.qodef-image-hotspots .qodef-e-no-link:hover .qodef-e-text {
    opacity: 1;
    transform: translateX(0);
    color: #000
}

.qodef-image-hotspots .qodef-e-pin {
    border: 1px solid currentColor;
    border-radius: 50%;
    color: #fff;
    flex-shrink: 0;
    height: 21px;
    position: relative;
    width: 21px;
    z-index: 1
}

.qodef-image-hotspots .qodef-e-pin:after {
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    height: 11px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 11px
}

.qodef-image-hotspots .qodef-e-content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    left: 100%;
    position: absolute;
    top: 10px;
    z-index: 2
}

.qodef-image-hotspots .qodef-e-content:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform .5s cubic-bezier(.25, .46, .35, .94)
}

.qodef-image-hotspots .qodef-e-title {
    color: #fff;
    line-height: 1em;
    margin: 0;
    padding: 5px 0 5px 40px;
    white-space: nowrap;
    opacity: 0;
    transform: translateX(20px);
    transition: opacity .35s, transform .5s cubic-bezier(.22, .61, .36, 1), color .2s ease-out
}

.qodef-image-hotspots .qodef-e-text {
    color: #fff;
    line-height: 1em;
    margin: 0;
    padding: 2px 13px 7px 10px;
    opacity: 0;
    transform: translateX(20px);
    transition: opacity .35s, transform .5s cubic-bezier(.22, .61, .36, 1), color .2s ease-out
}

@media only screen and (max-width:680px) {
    .qodef-image-hotspots .qodef-e-title {
        padding: 5px 0 5px 20px;
        white-space: normal
    }
}

.qodef-image-marquee {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-image-marquee .qodef-m-content {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin: 0 auto;
    overflow: hidden
}

.qodef-image-marquee .qodef-m-content.qodef--mobile {
    display: none
}

.qodef-image-marquee .qodef-m-content.qodef--mobile .qodef-m-image {
    background-size: cover !important
}

.qodef-image-marquee .qodef-m-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%
}

.qodef-image-marquee .qodef-m-image.qodef-image--copy {
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translateX(100%) translateZ(0)
}

.qodef-image-marquee .qodef-m-image a {
    position: relative;
    display: block
}

.qodef-image-marquee.qodef--move-on-scroll {
    overflow: hidden
}

.qodef-image-marquee.qodef--move-on-scroll .qodef-m-content {
    display: flex;
    flex-direction: row;
    width: max-content;
    flex-wrap: nowrap
}

.qodef-image-marquee.qodef--move-on-scroll .qodef-m-content .qodef-m-image {
    position: relative
}

.qodef-image-marquee.qodef--move-on-scroll .qodef-m-content .qodef-image--copy {
    transform: translateX(0)
}

.qodef-image-marquee.qodef--move-on-scroll .qodef-m-content.qodef--mobile {
    display: none
}

.qodef-image-marquee.qodef--move-on-scroll.qodef--marquee-right .qodef-m-content {
    float: right
}

@keyframes qode-move-marquee {
    0% {
        transform: translateX(0) translateZ(0)
    }

    100% {
        transform: translateX(-100%) translateZ(0)
    }
}

@keyframes qode-move-marquee-copy {
    0% {
        transform: translateX(100%) translateZ(0)
    }

    100% {
        transform: translateX(0) translateZ(0)
    }
}

.qodef-image-with-text {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-image-with-text .qodef-m-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-image-with-text .qodef-m-image a,
.qodef-image-with-text .qodef-m-image img {
    display: block
}

.qodef-image-with-text .qodef-m-content {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-image-with-text .qodef-info-holder {
    display: flex;
    align-items: flex-end;
    justify-content: space-between
}

.qodef-image-with-text .qodef-m-title {
    color: currentColor;
    margin: 16px 0 0
}

.qodef-image-with-text .qodef-e-arrow {
    opacity: 0;
    transition: opacity .2s ease-out
}

.qodef-image-with-text .qodef-m-text {
    margin: 15px 0 0
}

.qodef-image-with-text.qodef-enable-border .qodef-m-image img {
    border: 1px solid #e3e3e3
}

.qodef-image-with-text:hover .qodef-e-arrow {
    opacity: 1
}

.qodef-image-with-text.qodef-content-align--left .qodef-info-holder {
    justify-content: flex-start
}

.qodef-image-with-text.qodef-content-align--center .qodef-m-image img {
    margin: 0 auto
}

.qodef-image-with-text.qodef-content-align--center .qodef-m-content {
    text-align: center
}

.qodef-image-with-text.qodef-content-align--center .qodef-info-holder {
    justify-content: center
}

.qodef-image-with-text.qodef-content-align--right .qodef-m-image img {
    margin: 0 0 0 auto
}

.qodef-image-with-text.qodef-content-align--right .qodef-m-content {
    text-align: right
}

.qodef-image-with-text.qodef-content-align--right .qodef-info-holder {
    justify-content: flex-end
}

.qodef-image-with-text.qodef--hover-predefined .qodef-m-title {
    --qodef-underline-position-y: 100%;
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
    transition: background-size .5s cubic-bezier(.25, .46, .35, .94), color .2s ease-out
}

.qodef-image-with-text.qodef--hover-predefined:hover .qodef-m-title {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

.qodef-image-with-text.qodef--has-appear .qodef-m-image {
    -webkit-clip-path: inset(0 calc(var(--qodef-clip, 100) * 1%) 0 0);
    clip-path: inset(0 calc(var(--qodef-clip, 100) * 1%) 0 0)
}

.qodef-image-with-text.qodef--has-appear .qodef-info-holder {
    transform: translateX(calc(var(--qodef-clip, 100) * .4px));
    opacity: calc((100 - var(--qodef-clip, 100)) * .01)
}

.qodef-info-section.qodef-layout--background-text {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-info-section.qodef-layout--background-text .qodef-m-info {
    position: relative;
    z-index: 2
}

.qodef-info-section.qodef-layout--background-text .qodef-m-title {
    margin: 0 0 18px
}

.qodef-info-section.qodef-layout--background-text .qodef-e-text,
.qodef-info-section.qodef-layout--background-text .qodef-m-text {
    margin: 0
}

.qodef-info-section.qodef-layout--background-text .qodef-button {
    margin-top: 15px
}

.qodef-info-section.qodef-layout--background-text .qodef-m-tagline {
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000;
    margin-bottom: 50px
}

.qodef-info-section.qodef-layout--background-text .qodef-m-tagline:before {
    content: "";
    position: relative;
    width: 5px;
    height: 5px;
    background-color: currentColor;
    border-radius: 50%;
    top: 50%;
    margin-right: 13px
}

.qodef-info-section.qodef-layout--background-text .qodef-m-background-text {
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, .1);
    text-align: center;
    font-weight: 700;
    font-size: 250px
}

.qodef-info-section.qodef-layout--background-text.qodef-background-text-pos--top-left .qodef-m-background-text {
    top: 0;
    left: -20px
}

.qodef-info-section.qodef-layout--background-text.qodef-background-text-pos--top-right .qodef-m-background-text {
    top: 0;
    right: -20px
}

.qodef-info-section.qodef-layout--background-text.qodef-background-text-pos--bottom-left .qodef-m-background-text {
    bottom: 0;
    left: -20px
}

.qodef-info-section.qodef-layout--background-text.qodef-background-text-pos--bottom-right .qodef-m-background-text {
    bottom: 0;
    right: -20px
}

.qodef-info-section.qodef-layout--background-text.qodef-background-text-pos--center .qodef-m-background-text {
    font-size: 450px;
    top: 34px;
    left: 0;
    right: 0;
    text-align: center
}

.qodef-info-section.qodef-layout--horizontal {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-info-section.qodef-layout--horizontal .qodef-m-content {
    display: flex;
    align-items: flex-start
}

.qodef-info-section.qodef-layout--horizontal .qodef-m-info {
    display: flex;
    align-items: flex-start;
    width: 68%
}

.qodef-info-section.qodef-layout--horizontal .qodef-m-title {
    width: 32%;
    margin: 0
}

.qodef-info-section.qodef-layout--horizontal .qodef-m-days {
    width: 32.2%;
    display: flex;
    flex-direction: column;
    margin-top: 10px
}

.qodef-info-section.qodef-layout--horizontal .qodef-e-text {
    margin: 0
}

.qodef-info-section.qodef-layout--horizontal .qodef-e-text-holder {
    width: 45%;
    margin-top: 10px
}

.qodef-info-section.qodef-layout--horizontal .qodef-m-button {
    width: 22.8%;
    min-width: 75px;
    text-align: right
}

.qodef-info-section.qodef-layout--horizontal .qodef-button {
    margin-top: 10px
}

.qodef-info-section.qodef-layout--horizontal .qodef-m-tagline {
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000;
    margin-bottom: 50px
}

.qodef-info-section.qodef-layout--horizontal .qodef-m-tagline:before {
    content: "";
    position: relative;
    width: 5px;
    height: 5px;
    background-color: currentColor;
    border-radius: 50%;
    top: 50%;
    margin-right: 13px
}

.qodef-info-section.qodef-layout--horizontal .qodef-m-background-text {
    position: absolute;
    display: inline-block;
    color: rgba(0, 0, 0, .1);
    text-align: center;
    font-weight: 700;
    font-size: 250px
}

.qodef-info-section.qodef-layout--horizontal.qodef-background-text-pos--top-left .qodef-m-background-text {
    top: 0;
    left: -20px
}

.qodef-info-section.qodef-layout--horizontal.qodef-background-text-pos--top-right .qodef-m-background-text {
    top: 0;
    right: -20px
}

.qodef-info-section.qodef-layout--horizontal.qodef-background-text-pos--bottom-left .qodef-m-background-text {
    bottom: 0;
    left: -20px
}

.qodef-info-section.qodef-layout--horizontal.qodef-background-text-pos--bottom-right .qodef-m-background-text {
    bottom: 0;
    right: -20px
}

.qodef-info-section.qodef-layout--horizontal.qodef-background-text-pos--center .qodef-m-background-text {
    font-size: 450px;
    top: 34px;
    left: 0;
    right: 0;
    text-align: center
}

@media only screen and (max-width:1024px) {
    .qodef-info-section.qodef-layout--horizontal .qodef-m-content {
        flex-direction: column
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-m-info {
        position: relative;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row-reverse
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-button {
        margin-top: 1px
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-m-title {
        width: 100%;
        margin-bottom: 14px
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-e-text-holder,
    .qodef-info-section.qodef-layout--horizontal .qodef-m-button,
    .qodef-info-section.qodef-layout--horizontal .qodef-m-days {
        width: 50%
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-e-text-holder {
        order: 1
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-e-text {
        width: 80%
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-m-button {
        text-align: left;
        position: absolute;
        top: 74px
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-m-days {
        margin-bottom: 22px
    }
}

@media only screen and (max-width:680px) {

    .qodef-info-section.qodef-layout--horizontal .qodef-e-text-holder,
    .qodef-info-section.qodef-layout--horizontal .qodef-m-button,
    .qodef-info-section.qodef-layout--horizontal .qodef-m-days,
    .qodef-info-section.qodef-layout--horizontal .qodef-m-title {
        width: 100%
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-e-text {
        width: 100%
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-m-button {
        top: unset;
        bottom: 0
    }

    .qodef-info-section.qodef-layout--horizontal .qodef-e-text-holder {
        margin-bottom: 60px
    }
}

.qodef-info-slider {
    position: relative;
    display: flex
}

.qodef-info-slider .qodef-info {
    width: 53%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5.85% 0 0
}

.qodef-info-slider .qodef-e-tagline {
    display: flex;
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000
}

.qodef-info-slider .qodef-e-tagline:before {
    content: "";
    position: relative;
    width: 5px;
    height: 5px;
    background-color: currentColor;
    border-radius: 50%;
    top: 50%;
    margin-right: 13px
}

.qodef-info-slider .qodef-e-info-top {
    width: 65%
}

.qodef-info-slider .qodef-m-title {
    margin: 23px 0
}

.qodef-info-slider .qodef-button {
    margin: 7px 0 0
}

.qodef-info-slider .qodef-e-item {
    position: absolute;
    bottom: 12px
}

.qodef-info-slider .qodef-e-item .qodef-e-link:hover .qodef-item-title {
    animation: qode-inline-underline-initial-hover .8s cubic-bezier(.57, .39, 0, .86) 1 forwards
}

.qodef-info-slider .qodef-images {
    width: 47%;
    display: block;
    overflow: hidden
}

.qodef-info-slider .qodef-images img {
    width: 100%
}

.qodef-info-slider .qodef-item-title {
    position: relative;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400;
    --qodef-underline-position-y: 92%;
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height)
}

@media only screen and (max-width:1440px) {
    .qodef-info-slider .qodef-item-title {
        font-size: 18px;
        line-height: 1.27778em
    }
}

.qodef-info-slider .qodef-item-label {
    position: absolute;
    left: calc(100% + 3px);
    top: -1px;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: .07em;
    line-height: 1.3em;
    color: #fff;
    padding: 0 2px 0 3px;
    background-color: #d79999
}

.qodef-info-slider .qodef-e-item {
    opacity: 0;
    transition: opacity .3s
}

.qodef-info-slider .qodef-e-item .qodef-item-title {
    opacity: 0;
    transform: translateX(50px);
    transition: opacity .8s, transform 1s cubic-bezier(.22, .61, .36, 1);
    display: inline-block;
    transition-delay: .3s, .3s
}

.qodef-info-slider .qodef-e-item.qodef--active {
    opacity: 1;
    z-index: 2
}

.qodef-info-slider .qodef-e-item.qodef--active .qodef-item-title {
    opacity: 1;
    transform: translateX(0)
}

.qodef-info-slider .qodef-swiper-container-custom {
    opacity: 0;
    visibility: hidden;
    transition: opacity .5s, visibility .01s;
    padding: 0 0 46px
}

.qodef-info-slider .qodef-swiper-container-custom.swiper-container-initialized {
    opacity: 1;
    visibility: visible
}

.qodef-interactive-link-showcase {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    height: 73vh;
    /* visibility: hidden */
}

.admin-bar .qodef-interactive-link-showcase {
    height: calc(73vh - 32px)
}

.qodef-interactive-link-showcase .qodef-m-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat
}

.qodef-interactive-link-showcase .qodef-m-image.qodef--prev {
    z-index: 2
}

.qodef-interactive-link-showcase .qodef-m-image.qodef--active {
    opacity: 1;
    z-index: 5;
    animation: qodef-clip-image 1s cubic-bezier(.25, .46, .45, .94) forwards
}

.qodef-interactive-link-showcase .qodef-m-image img {
    display: none
}

.qodef-interactive-link-showcase .qodef-m-items {
    display: flex;
    align-items: flex-start;
    z-index: 10;
    padding: 0 4.92%
}

.qodef-interactive-link-showcase .qodef-m-item {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: auto;
    padding: 0 14px 1px;
    margin: 10px 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.13889em;
    font-weight: 400;
    overflow-y: hidden;
    transition: color .2s ease-out, background-color .2s ease-out
}

@media only screen and (max-width:1440px) {
    .qodef-interactive-link-showcase .qodef-m-item {
        font-size: 30px;
        line-height: 1.16667em
    }
}

.qodef-interactive-link-showcase .qodef-m-item .qodef-e-title {
    position: relative;
    display: inline;
    z-index: 2
}

.qodef-interactive-link-showcase .qodef-m-item .qodef-e-title:before {
    content: '';
    bottom: 0;
    left: -10px;
    background-color: currentColor;
    position: absolute;
    width: 1px;
    height: 100%;
    transform-origin: bottom;
    transition: transform .5s cubic-bezier(.25, .46, .35, .94), color .2s ease-out;
    transform: scaleY(0)
}

.qodef-interactive-link-showcase .qodef-m-item.qodef--active .qodef-e-title:before {
    transform: scaleY(1)
}

.qodef-interactive-link-showcase .qodef-m-item:last-of-type {
    margin: 10px 0 0;
    padding: 0 14px 2px
}

.qodef-interactive-link-showcase.qodef--init {
    visibility: visible
}

.qodef-interactive-link-showcase.qodef-skin--light .qodef-m-item {
    color: #fff
}

.qodef-interactive-link-showcase.qodef-layout--list .qodef-e-link {
    display: block;
    width: 100%;
    height: 100%
}

.qodef-interactive-link-showcase.qodef-layout--list .qodef-m-images {
    position: relative;
    display: block;
    margin-left: auto;
    width: 65%;
    height: 100%;
    overflow: hidden
}

.qodef-interactive-link-showcase.qodef-layout--list .qodef-m-items {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 35%;
    justify-content: flex-end;
    flex-direction: column
}

.qodef-interactive-link-showcase.qodef-layout--slider .swiper-container {
    width: 100%;
    height: 100%;
    z-index: 10
}

.qodef-interactive-link-showcase.qodef-layout--slider .qodef-m-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 5
}

.qodef-interactive-link-showcase.qodef-layout--slider .qodef-m-item {
    height: auto;
    margin: 0 5%
}

.qodef-pricing-table {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-pricing-table .qodef-m-inner {
    padding: 7.1% 12.65%
}

.qodef-pricing-table .qodef-m-image {
    text-align: center;
    margin-bottom: 65px
}

.qodef-pricing-table .qodef-m-title {
    margin: 0
}

.qodef-pricing-table .qodef-m-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 20px;
    border-bottom: 1px solid #c2c2c2
}

.qodef-pricing-table .qodef-featured-label {
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000;
    background-color: #000;
    color: #fff;
    height: 20px;
    padding: 0 7px
}

.qodef-pricing-table .qodef-m-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #c2c2c2;
    padding-top: 35px
}

.qodef-pricing-table .qodef-e-label {
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000;
    margin-bottom: 13px
}

.qodef-pricing-table .qodef-m-price {
    margin-top: -3px
}

.qodef-pricing-table .qodef-m-price .qodef-m-price-wrapper {
    display: flex;
    margin: 0;
    line-height: 1;
    justify-content: center
}

.qodef-pricing-table .qodef-m-content {
    margin: 34px 0 38px
}

.qodef-pricing-table .qodef-m-content ul {
    list-style: none;
    margin: 0
}

.qodef-pricing-table .qodef-m-content ul li {
    color: #000;
    margin-bottom: 8px;
    margin-left: 27px;
    position: relative
}

.qodef-pricing-table .qodef-m-content ul li:last-child {
    margin-bottom: 0
}

.qodef-pricing-table .qodef-m-content ul li:before {
    content: '';
    position: absolute;
    /* background: url('../img/check-mark.png'); */
    background-repeat: no-repeat;
    top: 8px;
    left: -25px;
    width: 13px;
    height: 10px;
    display: inline-block;
    filter: invert(1);
    margin-right: 15px
}

.qodef-pricing-table .qodef-m-button .qodef-button {
    margin: 0
}

@media only screen and (max-width:1024px) {
    .qodef-pricing-table .qodef-m-inner {
        padding: 7.1% 7%
    }
}

.qodef-progress-bar {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    visibility: hidden
}

.qodef-progress-bar .qodef-m-inner {
    position: relative;
    display: flex;
    flex-direction: column
}

.qodef-progress-bar .qodef-m-canvas {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-progress-bar .qodef-m-canvas svg {
    display: block
}

.qodef-progress-bar .qodef-m-title {
    margin: 20px 0 0
}

.qodef-progress-bar.qodef--init {
    visibility: visible
}

.qodef-progress-bar:not(.qodef-layout--line) .progressbar-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400
}

@media only screen and (max-width:1440px) {
    .qodef-progress-bar:not(.qodef-layout--line) .progressbar-text {
        font-size: 18px;
        line-height: 1.27778em
    }
}

.qodef-progress-bar.qodef-layout--semi-circle .progressbar-text {
    padding-top: 20px;
    transform: none !important
}

.qodef-progress-bar.qodef-layout--line .qodef-m-inner {
    flex-direction: column-reverse
}

.qodef-progress-bar.qodef-layout--line .proggress {
    width: 0
}

.qodef-progress-bar.qodef-layout--line .percentCount {
    position: absolute;
    top: -24px;
    right: 0;
    width: auto
}

.qodef-progress-bar.qodef-layout--line .qodef-m-title {
    margin: 0 0 7px
}

.qodef-progress-bar.qodef-layout--line.qodef-percentage--floating .percentCount {
    left: 0;
    right: auto;
    transform: translateX(-50%)
}

.qodef-section-title {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-section-title .qodef-m-title {
    margin: 0
}

.qodef-section-title .qodef-m-text {
    margin: 25px 0 0
}

.qodef-section-title.qodef-alignment--center {
    text-align: center
}

.qodef-section-title.qodef-alignment--left {
    text-align: left
}

.qodef-section-title.qodef-alignment--right {
    text-align: right
}

.qodef-separator {
    font-size: 0;
    height: auto;
    line-height: 1;
    position: relative
}

.qodef-separator .qodef-m-line {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    border-bottom: 1px solid #e3e3e3;
    margin: 10px 0
}

.qodef-separator.qodef-position--center {
    text-align: center
}

.qodef-separator.qodef-position--left {
    text-align: left
}

.qodef-separator.qodef-position--right {
    text-align: right
}

@media only screen and (max-width:1024px) {
    .qodef-separator.qodef-disabled--1024 {
        display: none
    }
}

@media only screen and (max-width:768px) {
    .qodef-separator.qodef-disabled--768 {
        display: none
    }
}

@media only screen and (max-width:680px) {
    .qodef-separator.qodef-disabled--680 {
        display: none
    }
}

@media only screen and (max-width:480px) {
    .qodef-separator.qodef-disabled--480 {
        display: none
    }
}

#qodef-page-sidebar .widget.widget_level_core_separator {
    margin-bottom: 0
}

#qodef-page-footer .widget.widget_level_core_separator {
    margin-bottom: 0
}

#qodef-side-area .widget.widget_level_core_separator {
    margin-bottom: 0
}

.qodef-single-image img {
    display: block
}

.qodef-single-image.qodef--has-appear .qodef-m-image {
    -webkit-clip-path: inset(0 100% 0 0);
    clip-path: inset(0 100% 0 0)
}

.qodef-single-image.qodef--has-appear.qodef--appeared .qodef-m-image {
    transition: -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) 0s;
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) 0s;
    transition: clip-path 1s cubic-bezier(.57, .18, 0, .92) 0s, -webkit-clip-path 1s cubic-bezier(.57, .18, 0, .92) 0s;
    -webkit-clip-path: inset(0 0 0 0);
    clip-path: inset(0 0 0 0)
}

.qodef-split-showcase {
    height: 100vh;
    display: flex
}

.qodef-split-showcase .qodef-info-left,
.qodef-split-showcase .qodef-info-right {
    width: 50%
}

.qodef-split-showcase .qodef-m-label {
    position: absolute;
    top: 50%;
    color: #fff;
    margin: 0;
    text-transform: lowercase;
    transform: rotate(-90deg) translateY(-50%);
    z-index: 3;
    --qodef-underline-position-y: 100%;
    --qodef-underline-height: 2px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
    transition: background-size .5s cubic-bezier(.25, .46, .35, .94), color .2s ease-out
}

.qodef-split-showcase .qodef-e-media-image {
    height: 100%
}

.qodef-split-showcase .qodef-e-media-image img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.qodef-split-showcase .qodef-e-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4
}

.qodef-split-showcase .qodef-info-left {
    position: relative
}

.qodef-split-showcase .qodef-info-left .qodef-m-label {
    left: 7px
}

.qodef-split-showcase .qodef-info-left:hover .qodef-m-label {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

.qodef-split-showcase .qodef-info-left:hover .qodef-e-media-image img {
    filter: grayscale(1)
}

.qodef-split-showcase .qodef-info-left:hover .qodef-e-media-image:after {
    opacity: 1
}

.qodef-split-showcase .qodef-info-right {
    position: relative
}

.qodef-split-showcase .qodef-info-right .qodef-m-label {
    right: -20px
}

.qodef-split-showcase .qodef-info-right:hover .qodef-m-label {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

.qodef-split-showcase .qodef-info-right:hover .qodef-e-media-image img {
    filter: grayscale(1)
}

.qodef-split-showcase .qodef-info-right:hover .qodef-e-media-image:after {
    opacity: 1
}

.qodef-split-showcase .qodef-m-image {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
    z-index: 3
}

.qodef-split-showcase .qodef-e-media-image img {
    transition: filter .5s ease-out
}

.qodef-split-showcase .qodef-e-media-image:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #e4cec7;
    mix-blend-mode: multiply;
    opacity: 0;
    transition: opacity .5s ease-out;
    pointer-events: none
}

.qodef-split-showcase img {
    display: block
}

@media only screen and (max-width:1024px) {
    .qodef-split-showcase {
        height: calc(100vh - 70px)
    }
}

.qodef-tabs {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    visibility: hidden
}

.qodef-tabs.qodef--init {
    visibility: visible
}

.qodef-tabs .qodef-tabs-navigation {
    margin: 0;
    padding: 0;
    list-style: none
}

.qodef-tabs .qodef-tabs-content {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-tabs .qodef-tabs-content p {
    margin: 0
}

.qodef-tabs.qodef-layout--simple .qodef-tabs-navigation {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #e3e3e3
}

.qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li {
    margin: 0;
    padding: 0
}

.qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a {
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400;
    display: block;
    transition: .2s ease-out;
    padding: 0 61px 18px;
    text-align: left;
    position: relative
}

@media only screen and (max-width:1440px) {
    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a {
        font-size: 18px;
        line-height: 1.27778em
    }
}

.qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a:after {
    content: '';
    height: 1px;
    width: 100%;
    bottom: -1px;
    left: 0;
    position: absolute;
    background-color: var(--qode-main-color);
    transition: opacity .35s, transform .7s cubic-bezier(.22, .61, .36, 1);
    transform: scaleX(0);
    transform-origin: left
}

.qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a:hover:after,
.qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li.active a:after,
.qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li.ui-state-active a:after,
.qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li.ui-state-hover a:after {
    transform: scaleX(1)
}

@media only screen and (max-width:1024px) {
    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a {
        padding: 0 30px 18px !important
    }
}

@media only screen and (max-width:768px) {
    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation {
        flex-direction: column;
        align-items: flex-start;
        border-bottom: 0
    }

    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li {
        margin: 0 0 26px 0;
        padding: 0
    }

    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a {
        padding: 0 18px 18px !important
    }

    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li:last-child {
        margin-bottom: 0
    }
}

@media only screen and (max-width:480px) {
    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a {
        padding: 0 0 10px !important
    }

    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a::after {
        background-color: transparent
    }

    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li a:hover::after,
    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li.active a::after,
    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li.ui-state-active a::after,
    .qodef-tabs.qodef-layout--simple .qodef-tabs-navigation li.ui-state-hover a::after {
        background-color: var(--qode-main-color)
    }
}

.qodef-tabs.qodef-layout--simple.qodef-orientation--vertical {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 100%
}

.qodef-tabs.qodef-layout--simple.qodef-orientation--vertical .qodef-tabs-navigation {
    flex-shrink: 0;
    display: block;
    padding-right: 26px;
    margin-right: 26px;
    border-right: 1px solid #e3e3e3
}

.qodef-tabs.qodef-layout--simple.qodef-orientation--vertical .qodef-tabs-navigation li {
    width: 100%;
    margin-bottom: 26px
}

.qodef-tabs.qodef-layout--simple.qodef-orientation--vertical .qodef-tabs-navigation li:last-child {
    margin-bottom: 0
}

.qodef-tabs.qodef-layout--simple.qodef-orientation--vertical .qodef-tabs-navigation li a {
    display: inline-block;
    width: auto
}

.qodef-tabs.qodef-layout--simple.qodef-orientation--vertical .qodef-tabs-content {
    margin-top: 0
}

.qodef-tabs.qodef-layout--simple .qodef-tabs-content {
    margin: 26px 0 0
}

@keyframes qode-text-marquee-left {
    0% {
        transform: translateX(0)
    }

    100% {
        transform: translateX(-100%)
    }
}

@keyframes qode-text-marquee-right {
    0% {
        transform: translateX(-100%)
    }

    100% {
        transform: translateX(0)
    }
}

.qodef-text-marquee .qodef-m-content {
    display: flex;
    font-family: "DM Sans", sans-serif;
    font-size: 105px;
    font-weight: 400;
    line-height: 1em;
    overflow: hidden;
    white-space: nowrap
}

.qodef-text-marquee .qodef-m-content-inner {
    padding-right: 25px
}

.qodef--init.qodef-direction--left.qodef-text-marquee .qodef-m-content-inner {
    animation: qode-text-marquee-left var(--qode-marquee-duration, 25s) linear infinite
}

.qodef--init.qodef-direction--right.qodef-text-marquee .qodef-m-content-inner {
    animation: qode-text-marquee-right var(--qode-marquee-duration, 25s) linear infinite
}

.qodef-text-marquee .qodef-m-text-1 {
    color: var(--qode-main-color)
}

.qodef-text-marquee .qodef-m-text-2 {
    color: var(--qode-main-color)
}

.qodef-text-marquee .qodef-m-text-3 {
    color: var(--qode-main-color)
}

.qodef-text-marquee .qodef-m-separator {
    color: var(--qode-main-color);
    position: relative
}

.qodef-video-button {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

.qodef-video-button .qodef-m-play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1
}

.qodef-video-button .qodef-m-label {
    position: absolute;
    color: #fff;
    bottom: 63px;
    left: 140px;
    --qodef-underline-position-y: 94%;
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height)
}

.qodef-video-button .qodef-svg--play {
    position: absolute;
    left: calc(50% - 7px);
    top: calc(50% - 8px)
}

.qodef-video-button .qodef-m-play-inner {
    width: 80px;
    height: 80px;
    position: absolute;
    bottom: 38px;
    border-radius: 50%;
    left: 38px
}

.qodef-video-button .qodef-m-play-inner .qodef-svg--circle {
    color: #fff;
    display: block;
    overflow: visible;
    transform: rotate(-80deg);
    transition: transform 1.6s
}

.qodef-video-button .qodef-m-play-inner .qodef-svg--circle path {
    stroke-dasharray: 265;
    stroke-dashoffset: 530;
    transition: stroke-dashoffset 1.6s
}

.qodef-video-button:hover .qodef-m-label {
    animation: qode-inline-underline-initial-hover .8s cubic-bezier(.57, .39, 0, .86) 1 forwards
}

.qodef-video-button:hover .qodef-svg--circle {
    transform: rotate(80deg)
}

.qodef-video-button:hover .qodef-svg--circle path {
    stroke-dashoffset: 0
}

#qodef-side-area {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 120px 75px 35px;
    text-align: center;
    background-color: #fff;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    z-index: 9999;
    transition: all .6s cubic-bezier(.77, 0, .175, 1)
}

.qodef-side-area--opened #qodef-side-area {
    animation: qode-fade-in .25s linear both
}

.qodef-side-area-animate--in #qodef-side-area {
    animation: qode-fade-in .25s linear both
}

.qodef-side-area-animate--out #qodef-side-area {
    animation: qode-fade-out .25s linear both
}

#qodef-side-area.qodef-alignment--center {
    text-align: center
}

#qodef-side-area.qodef-alignment--right {
    text-align: right
}

#qodef-side-area.qodef-alignment--left {
    text-align: left
}

#qodef-side-area .widget.widget_level_core_portfolio_list {
    max-width: 1480px;
    margin: 0 auto
}

#qodef-side-area .widget.widget_level_core_portfolio_list .qodef-portfolio-list.qodef-item-layout--info-below .qodef-e-content {
    text-align: left
}

#qodef-side-area .widget.widget_level_core_portfolio_list .qodef-portfolio-list.qodef-item-layout--info-below .qodef-e-title .qodef-e-title-link {
    --qodef-underline-position-y: 100%;
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 100% var(--qodef-underline-height)
}

#qodef-side-area .widget.widget_level_core_portfolio_list .qodef-portfolio-list.qodef-item-layout--info-below .qodef-e:hover .qodef-e-title .qodef-e-title-link {
    animation: qode-inline-underline-initial-hover .8s cubic-bezier(.57, .39, 0, .86) 1 forwards
}

#qodef-side-area-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0;
    bottom: 0;
    height: 100%
}

#qodef-side-area-close {
    position: absolute;
    top: 48px;
    right: 60px;
    display: none
}

.qodef-side-area--opened #qodef-side-area-close {
    display: block
}

.admin-bar #qodef-side-area-close {
    top: 65px
}

.qodef-side-area-cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    z-index: 0;
    transition: opacity .2s ease-in-out, z-index .2s ease-in-out
}

.qodef-side-area--opened .qodef-side-area-cover {
    opacity: 1;
    z-index: 1002
}

.qodef-side-area--opened #qodef-page-header,
.qodef-side-area--opened #qodef-page-mobile-header {
    background-color: transparent !important;
    z-index: 10000;
    pointer-events: none
}

.qodef-side-area--opened #qodef-page-header .qodef-header-logo-link,
.qodef-side-area--opened #qodef-page-mobile-header .qodef-header-logo-link {
    pointer-events: all
}

.qodef-side-area--opened #qodef-page-header .qodef-widget-holder,
.qodef-side-area--opened #qodef-page-header nav[role=navigation],
.qodef-side-area--opened #qodef-page-mobile-header .qodef-widget-holder,
.qodef-side-area--opened #qodef-page-mobile-header nav[role=navigation] {
    display: none
}

.qodef-social-share {
    position: relative;
    display: inline-block;
    vertical-align: top
}

.qodef-social-share .qodef-social-title {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 5px 0 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    color: #000;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400
}

@media only screen and (max-width:1440px) {
    .qodef-social-share .qodef-social-title {
        font-size: 18px;
        line-height: 1.27778em
    }
}

.qodef-social-share ul {
    position: relative;
    display: inline-block;
    vertical-align: top;
    list-style: none;
    padding: 0;
    margin: 0
}

.qodef-social-share li {
    position: relative;
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    padding: 0;
    margin: 0
}

.qodef-social-share li a {
    font-size: 20px
}

.qodef-social-share li.qodef-facebook-share a:hover {
    color: #3b5998
}

.qodef-social-share li.qodef-twitter-share a:hover {
    color: #00aced
}

.qodef-social-share li.qodef-linkedin-share a:hover {
    color: #007bb5
}

.qodef-social-share li.qodef-tumblr-share a:hover {
    color: #32506d
}

.qodef-social-share li.qodef-pinterest-share a:hover {
    color: #cb2027
}

.qodef-social-share li.qodef-vk-share a:hover {
    color: #45668e
}

.qodef-social-share.qodef-layout--dropdown {
    position: relative;
    display: inline-block;
    vertical-align: bottom
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-title {
    margin: 0;
    vertical-align: middle
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-icon {
    vertical-align: middle
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-icon::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    /* background: url(../img/share.png) */
}

.qodef-social-share.qodef-layout--dropdown:hover .qodef-social-share-dropdown ul li {
    opacity: 1;
    visibility: visible;
    cursor: pointer
}

.qodef-social-share.qodef-layout--dropdown:hover .qodef-social-share-dropdown ul li:nth-child(2) {
    transition-delay: .2s
}

.qodef-social-share.qodef-layout--dropdown:hover .qodef-social-share-dropdown ul li:nth-child(3) {
    transition-delay: .3s
}

.qodef-social-share.qodef-layout--dropdown:hover .qodef-social-share-dropdown ul li:nth-child(4) {
    transition-delay: .4s
}

.qodef-social-share.qodef-layout--dropdown:hover .qodef-social-share-dropdown ul li:nth-child(5) {
    transition-delay: .5s
}

.qodef-social-share.qodef-layout--dropdown:hover .qodef-social-share-dropdown ul li:nth-child(6) {
    transition-delay: .6s
}

.qodef-social-share.qodef-layout--dropdown:hover .qodef-social-share-dropdown ul li:nth-child(7) {
    transition-delay: .7s
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-dropdown-opener {
    display: block;
    font-size: 16px
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-dropdown-opener .qodef-social-share-title {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-dropdown {
    position: absolute;
    visibility: hidden;
    z-index: 950
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-dropdown ul {
    position: relative;
    display: block;
    z-index: 990;
    margin: 0;
    padding: 0 !important
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-dropdown li {
    position: absolute;
    display: block;
    text-align: center;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    transition: opacity .2s ease-out, visibility .2s ease-out
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-dropdown li a {
    font-size: 16px;
    transition: color .2s ease-out, background-color .2s ease-out
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-dropdown li a:hover {
    color: #787878
}

.qodef-social-share.qodef-layout--dropdown .qodef-social-share-dropdown li * {
    display: block;
    line-height: inherit
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown {
    bottom: 0;
    left: 0
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li {
    width: 90px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #e3e3e3
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li:not(:first-child) {
    border-top: none
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li.qodef-facebook-share a:hover {
    background-color: #3b5998;
    color: #fff
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li.qodef-twitter-share a:hover {
    background-color: #00aced;
    color: #fff
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li.qodef-linkedin-share a:hover {
    background-color: #007bb5;
    color: #fff
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li.qodef-tumblr-share a:hover {
    background-color: #32506d;
    color: #fff
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li.qodef-pinterest-share a:hover {
    background-color: #cb2027;
    color: #fff
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li.qodef-vk-share a:hover {
    background-color: #45668e;
    color: #fff
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li a {
    font-size: 14px;
    color: #000;
    background-color: #fff
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li:nth-child(1) {
    bottom: -30px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li:nth-child(2) {
    bottom: -60px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li:nth-child(3) {
    bottom: -90px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li:nth-child(4) {
    bottom: -120px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li:nth-child(5) {
    bottom: -150px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li:nth-child(6) {
    bottom: -180px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--bottom .qodef-social-share-dropdown li:nth-child(7) {
    bottom: -210px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown {
    top: 0;
    right: 0
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li {
    width: calc((90px / 3) - 8px)
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(1) {
    left: 5px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(2) {
    left: 35px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(3) {
    left: 65px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(4) {
    left: 95px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(5) {
    left: 125px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(6) {
    left: 155px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(7) {
    left: 185px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown {
    top: 0;
    left: 0
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li {
    width: calc(90px / 3 - 8px)
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(1) {
    right: 5px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(2) {
    right: 35px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(3) {
    right: 65px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(4) {
    right: 95px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(5) {
    right: 125px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(6) {
    right: 155px
}

.qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(7) {
    right: 185px
}

.qodef-social-share.qodef-layout--list li {
    margin-right: 15px
}

.qodef-social-share.qodef-layout--list li:last-child {
    margin-right: 0
}

.qodef-social-share.qodef-layout--text li {
    margin-right: 15px
}

.qodef-social-share.qodef-layout--text li:last-child {
    margin-right: 0
}

.widget .qodef-social-share.qodef-layout--list .qodef-social-title {
    display: block;
    margin-bottom: 21px
}

.widget .qodef-social-share.qodef-layout--text .qodef-social-title {
    display: block;
    margin-bottom: 21px
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--list li.qodef-facebook-share a:hover {
    color: #3b5998
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--list li.qodef-twitter-share a:hover {
    color: #00aced
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--list li.qodef-linkedin-share a:hover {
    color: #007bb5
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--list li.qodef-tumblr-share a:hover {
    color: #32506d
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--list li.qodef-pinterest-share a:hover {
    color: #cb2027
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--list li.qodef-vk-share a:hover {
    color: #45668e
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--text li.qodef-facebook-share a:hover {
    color: #3b5998
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--text li.qodef-twitter-share a:hover {
    color: #00aced
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--text li.qodef-linkedin-share a:hover {
    color: #007bb5
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--text li.qodef-tumblr-share a:hover {
    color: #32506d
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--text li.qodef-pinterest-share a:hover {
    color: #cb2027
}

#qodef-page-sidebar .widget .qodef-social-share.qodef-layout--text li.qodef-vk-share a:hover {
    color: #45668e
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--dropdown .qodef-social-title {
    color: #ccc
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--list .qodef-social-title {
    color: #ccc
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--list li.qodef-facebook-share a:hover {
    color: #3b5998
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--list li.qodef-twitter-share a:hover {
    color: #00aced
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--list li.qodef-linkedin-share a:hover {
    color: #007bb5
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--list li.qodef-tumblr-share a:hover {
    color: #32506d
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--list li.qodef-pinterest-share a:hover {
    color: #cb2027
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--list li.qodef-vk-share a:hover {
    color: #45668e
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--text .qodef-social-title {
    color: #ccc
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--text li.qodef-facebook-share a:hover {
    color: #3b5998
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--text li.qodef-twitter-share a:hover {
    color: #00aced
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--text li.qodef-linkedin-share a:hover {
    color: #007bb5
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--text li.qodef-tumblr-share a:hover {
    color: #32506d
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--text li.qodef-pinterest-share a:hover {
    color: #cb2027
}

#qodef-page-footer .widget .qodef-social-share.qodef-layout--text li.qodef-vk-share a:hover {
    color: #45668e
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--dropdown .qodef-social-title {
    color: #ccc
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--list .qodef-social-title {
    color: #ccc
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--list li.qodef-facebook-share a:hover {
    color: #3b5998
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--list li.qodef-twitter-share a:hover {
    color: #00aced
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--list li.qodef-linkedin-share a:hover {
    color: #007bb5
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--list li.qodef-tumblr-share a:hover {
    color: #32506d
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--list li.qodef-pinterest-share a:hover {
    color: #cb2027
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--list li.qodef-vk-share a:hover {
    color: #45668e
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--text .qodef-social-title {
    color: #ccc
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--text li.qodef-facebook-share a:hover {
    color: #3b5998
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--text li.qodef-twitter-share a:hover {
    color: #00aced
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--text li.qodef-linkedin-share a:hover {
    color: #007bb5
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--text li.qodef-tumblr-share a:hover {
    color: #32506d
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--text li.qodef-pinterest-share a:hover {
    color: #cb2027
}

#qodef-side-area .widget .qodef-social-share.qodef-layout--text li.qodef-vk-share a:hover {
    color: #45668e
}

#qodef-page-spinner {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2000
}

#qodef-page-spinner .qodef-m-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: var(--qode-main-color);
    background-color: #fff
}

#qodef-page-spinner .qodef-m-spinner {
    position: relative
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom {
    position: relative;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--1 {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
    z-index: 1;
    animation: qode-atom-shrink 4.5s infinite linear
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--1:before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    height: 100%;
    width: 100%;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2:before,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3:before,
#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    margin: 0 auto;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2 {
    animation: qode-atom-zindex 1.5s .75s infinite steps(2, end)
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--2:before {
    transform: rotate(0);
    animation: qode-atom-postion 1.5s infinite ease, qode-atom-size 1.5s -1.125s infinite ease
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3 {
    transform: rotate(120deg);
    animation: qode-atom-zindex 1.5s -.25s infinite steps(2, end)
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--3:before {
    animation: qode-atom-postion 1.5s -1s infinite ease, qode-atom-size 1.5s -.75s infinite ease
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4 {
    transform: rotate(240deg);
    animation: qode-atom-zindex 1.5s .25s infinite steps(2, end)
}

#qodef-page-spinner.qodef-layout--atom .qodef-m-atom-item.qodef-atom--4:before {
    animation: qode-atom-postion 1.5s -.5s infinite ease, qode-atom-size 1.5s -125ms infinite ease
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock {
    position: relative;
    width: 50px;
    height: 50px;
    margin: -25px 0 0 -25px;
    animation: qode-clock 8s infinite steps(8)
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item {
    position: absolute;
    height: 50px;
    width: 50px
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item:before {
    content: '';
    position: absolute;
    height: 18.31501832px;
    width: 18.31501832px;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--1 {
    transform: rotate(-135deg);
    animation: qode-clock-1 1s ease-in infinite
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--2 {
    transform: rotate(-90deg);
    animation: qode-clock-2 1s ease-in infinite
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--3 {
    transform: rotate(-45deg);
    animation: qode-clock-3 1s ease-in infinite
}

#qodef-page-spinner.qodef-layout--clock .qodef-m-clock-item.qodef-clock--4 {
    animation: qode-clock-4 1s ease-in infinite
}

#qodef-page-spinner.qodef-layout--cube .qodef-m-cube {
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    background-color: currentColor;
    animation: qode-cube 1.2s infinite ease-in-out
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulses {
    position: relative;
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulse {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: currentColor;
    border-radius: 50%;
    opacity: .6;
    animation: qode-double-pulse 2s infinite ease-in-out
}

#qodef-page-spinner.qodef-layout--double-pulse .qodef-m-pulse.qodef-pulse--2 {
    animation-delay: -1s
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-five-rotating-circles {
    position: relative;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item {
    position: absolute;
    width: 100%;
    height: 100%
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--2 {
    animation-delay: calc((600ms) - 1500ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--3 {
    animation-delay: calc((900ms) - 1500ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--1 .qodef-circle--4 {
    animation-delay: calc((1200ms) - 1500ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 {
    transform: rotateZ(45deg)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--1 {
    animation-delay: calc((300ms) - 1400ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--2 {
    animation-delay: calc((600ms) - 1400ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--3 {
    animation-delay: calc((900ms) - 1400ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--2 .qodef-circle--4 {
    animation-delay: calc((1200ms) - 1400ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 {
    transform: rotateZ(90deg)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--1 {
    animation-delay: calc((300ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--2 {
    animation-delay: calc((600ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--3 {
    animation-delay: calc((900ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-m-circle-item.qodef-item--3 .qodef-circle--4 {
    animation-delay: calc((1200ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle {
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: currentColor;
    border-radius: 100%;
    animation: qode-bounce-delay 1.2s infinite ease-in-out both
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--1 {
    top: 0;
    left: 0
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--2 {
    top: 0;
    right: 0
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--3 {
    right: 0;
    bottom: 0
}

#qodef-page-spinner.qodef-layout--five-rotating-circles .qodef-e-circle.qodef-circle--4 {
    left: 0;
    bottom: 0
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion {
    position: relative;
    width: 30px;
    height: 30px;
    margin: -45px 0 0 -30px
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 40px;
    width: 40px;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--1 {
    top: 0;
    left: 50%;
    animation: qode-fussion-ball-1 1s 0s ease infinite;
    z-index: 1
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--2 {
    top: 50%;
    left: 100%;
    animation: qode-fussion-ball-2 1s 0s ease infinite;
    z-index: 2
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--3 {
    top: 100%;
    left: 50%;
    animation: qode-fussion-ball-3 1s 0s ease infinite;
    z-index: 1
}

#qodef-page-spinner.qodef-layout--fusion .qodef-m-fusion-item.qodef-fusion--4 {
    top: 50%;
    left: 0;
    animation: qode-fussion-ball-4 1s 0s ease infinite;
    z-index: 2
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-lines {
    width: 60px;
    height: 60px;
    margin: 0 0 0 -30px
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--1 {
    position: absolute;
    width: 60px;
    height: 4px;
    margin-left: 10px;
    background-color: currentColor;
    animation: qode-lines-spin 1.5s infinite ease calc((100ms) - 100ms)
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--2 {
    position: absolute;
    width: 60px;
    height: 4px;
    margin-left: 10px;
    background-color: currentColor;
    animation: qode-lines-spin 1.5s infinite ease calc((200ms) - 100ms)
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--3 {
    position: absolute;
    width: 60px;
    height: 4px;
    margin-left: 10px;
    background-color: currentColor;
    animation: qode-lines-spin 1.5s infinite ease calc((300ms) - 100ms)
}

#qodef-page-spinner.qodef-layout--lines .qodef-m-line.qodef-line--4 {
    position: absolute;
    width: 60px;
    height: 4px;
    margin-left: 10px;
    background-color: currentColor;
    animation: qode-lines-spin 1.5s infinite ease calc((400ms) - 100ms)
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis {
    position: relative;
    height: 50px;
    width: 50px;
    margin: -25px 0 0 -25px;
    animation: qode-mitosis-invert 2s infinite steps(2, end)
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--1 {
    animation: qode-mitosis-ball-1 1s infinite alternate linear
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--2 {
    animation: qode-mitosis-ball-2 1s infinite alternate linear
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--3 {
    animation: qode-mitosis-ball-3 1s infinite alternate linear
}

#qodef-page-spinner.qodef-layout--mitosis .qodef-m-mitosis-item.qodef-mitosis--4 {
    animation: qode-mitosis-ball-4 1s infinite alternate linear
}

#qodef-page-spinner.qodef-layout--predefined .qodef-m-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    opacity: 0
}

#qodef-page-spinner.qodef-layout--predefined .qodef-m-spinner-text {
    opacity: 0;
    font-size: 54px;
    line-height: 1.09259em;
    font-weight: 400;
    font-size: 53px
}

@media only screen and (max-width:1440px) {
    #qodef-page-spinner.qodef-layout--predefined .qodef-m-spinner-text {
        font-size: 48px;
        line-height: 1.08333em
    }
}

#qodef-page-spinner.qodef-layout--predefined .qodef-m-spinner-images {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

#qodef-page-spinner.qodef-layout--predefined .qodef-m-spinner-images .qodef-m-spinner-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-clip-path: inset(0 calc(var(--qodef-clip, 0) * 1%) 0 0);
    clip-path: inset(0 calc(var(--qodef-clip, 0) * 1%) 0 0)
}

#qodef-page-spinner.qodef-layout--predefined .qodef-m-spinner-images .qodef-m-spinner-image img {
    max-width: unset;
    width: 100%;
    height: 100%;
    object-fit: cover
}

#qodef-page-spinner.qodef-layout--predefined.qodef--init .qodef-m-textual {
    opacity: 1
}

#qodef-page-spinner.qodef-layout--progress-bar .qodef-m-spinner-heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between
}

#qodef-page-spinner.qodef-layout--progress-bar .qodef-m-spinner-heading>* {
    margin: 0;
    opacity: 0;
    animation: qode-fade-in 1s forwards
}

#qodef-page-spinner.qodef-layout--progress-bar .qodef-m-spinner-number-holder {
    flex-shrink: 0;
    animation-delay: .5s
}

#qodef-page-spinner.qodef-layout--progress-bar .qodef-m-spinner-line {
    position: relative;
    display: block;
    height: 3px;
    width: 500px;
    margin-top: 20px;
    background-color: #e5e1dc;
    overflow: hidden;
    opacity: 0;
    animation: qode-fade-in 1s .3s forwards
}

#qodef-page-spinner.qodef-layout--progress-bar .qodef-m-spinner-line-front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--qode-main-color);
    width: 0
}

#qodef-page-spinner.qodef-layout--pulse-circles .qodef-m-pulse-circles {
    position: relative;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px
}

#qodef-page-spinner.qodef-layout--pulse-circles .qodef-m-pulse-circle {
    position: absolute;
    height: 100%;
    width: 100%;
    background: currentColor;
    border: 0 solid currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--pulse-circles .qodef-m-pulse-circle.qodef-pulse--1 {
    animation: qode-pulse-circles-pulse 3s infinite calc((-750ms) + 750ms)
}

#qodef-page-spinner.qodef-layout--pulse-circles .qodef-m-pulse-circle.qodef-pulse--2 {
    animation: qode-pulse-circles-pulse 3s infinite calc((-1500ms) + 750ms)
}

#qodef-page-spinner.qodef-layout--pulse-circles .qodef-m-pulse-circle.qodef-pulse--3 {
    animation: qode-pulse-circles-pulse 3s infinite calc((-2250ms) + 750ms)
}

#qodef-page-spinner.qodef-layout--pulse-circles .qodef-m-pulse-circle.qodef-pulse--4 {
    animation: qode-pulse-circles-pulse 3s infinite calc((-3000ms) + 750ms)
}

#qodef-page-spinner.qodef-layout--pulse .qodef-m-pulse {
    width: 32px;
    height: 32px;
    margin: -16px 0 0 -16px;
    background-color: currentColor;
    border-radius: 16px;
    animation: qode-scaleout 1s infinite ease-in-out
}

#qodef-page-spinner.qodef-layout--rotate-circles .qodef-m-circles {
    width: 60px;
    height: 20px
}

#qodef-page-spinner.qodef-layout--rotate-circles .qodef-m-circles>div {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 4px;
    background-color: currentColor;
    border-radius: 100%;
    opacity: 0;
    animation: qode-ball-fall 1.5s ease infinite both
}

#qodef-page-spinner.qodef-layout--rotate-circles .qodef-m-circles>div:nth-last-of-type(1) {
    animation-delay: calc((100ms) - 300ms)
}

#qodef-page-spinner.qodef-layout--rotate-circles .qodef-m-circles>div:nth-last-of-type(2) {
    animation-delay: calc((200ms) - 300ms)
}

#qodef-page-spinner.qodef-layout--rotate-circles .qodef-m-circles>div:nth-last-of-type(3) {
    animation-delay: calc((300ms) - 300ms)
}

#qodef-page-spinner.qodef-layout--rotating-cubes .qodef-m-cubes {
    position: relative;
    width: 32px;
    height: 32px;
    margin: -16px 0 0 -16px
}

#qodef-page-spinner.qodef-layout--rotating-cubes .qodef-m-cube {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: currentColor;
    animation: qode-rotating-cubes 1.8s infinite ease-in-out
}

#qodef-page-spinner.qodef-layout--rotating-cubes .qodef-m-cube.qodef-cube--2 {
    animation-delay: -.9s
}

#qodef-page-spinner.qodef-layout--stripes .qodef-m-stripes {
    width: 50px;
    height: 60px;
    margin: -30px 0 0 -25px;
    text-align: center
}

#qodef-page-spinner.qodef-layout--stripes .qodef-m-stripe {
    display: inline-block;
    float: left;
    height: 100%;
    width: 6px;
    margin: 0 3px 0 0;
    background-color: currentColor;
    animation: qode-stretch-delay 1.2s infinite ease-in-out
}

#qodef-page-spinner.qodef-layout--stripes .qodef-m-stripe.qodef-stripe--2 {
    animation-delay: calc((200ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--stripes .qodef-m-stripe.qodef-stripe--3 {
    animation-delay: calc((300ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--stripes .qodef-m-stripe.qodef-stripe--4 {
    animation-delay: calc((400ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--stripes .qodef-m-stripe.qodef-stripe--5 {
    animation-delay: calc((500ms) - 1300ms)
}

#qodef-page-spinner.qodef-layout--textual .qodef-m-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%
}

#qodef-page-spinner.qodef-layout--textual .qodef-m-textual {
    opacity: 0
}

#qodef-page-spinner.qodef-layout--textual .qodef-m-text {
    display: flex;
    font-family: "DM Sans", sans-serif;
    font-size: 30px;
    line-height: 1;
    font-weight: 500;
    white-space: nowrap;
    text-transform: uppercase
}

#qodef-page-spinner.qodef-layout--textual .qodef-m-char {
    display: inline-block;
    opacity: 0;
    will-change: opacity
}

#qodef-page-spinner.qodef-layout--textual .qodef-m-char.qodef-m-empty-char {
    white-space: pre
}

#qodef-page-spinner.qodef-layout--textual.qodef--init .qodef-m-textual {
    opacity: 1
}

#qodef-page-spinner.qodef-layout--two-rotating-circles .qodef-m-two-rotating-circles {
    position: relative;
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px;
    text-align: center;
    animation: qode-rotate-circles 2s infinite linear
}

#qodef-page-spinner.qodef-layout--two-rotating-circles .qodef-m-circle {
    position: absolute;
    top: 0;
    width: 60%;
    height: 60%;
    display: inline-block;
    background-color: currentColor;
    border-radius: 100%;
    transform-origin: center bottom;
    animation: qode-bounce 2s infinite ease-in-out
}

#qodef-page-spinner.qodef-layout--two-rotating-circles .qodef-m-circle.qodef-circle--2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s
}

#qodef-page-spinner.qodef-layout--wave-circles .qodef-m-wave-circles {
    width: 150px;
    height: 30px;
    margin: -15px 0 0 -75px
}

#qodef-page-spinner.qodef-layout--wave-circles .qodef-m-wave-circle {
    float: left;
    height: 30px;
    width: 30px;
    margin-right: 10px;
    background: currentColor;
    border-radius: 50%
}

#qodef-page-spinner.qodef-layout--wave-circles .qodef-m-wave-circle.qodef-wave--1 {
    animation: qode-wave-jump-3 1.8s infinite ease-out
}

#qodef-page-spinner.qodef-layout--wave-circles .qodef-m-wave-circle.qodef-wave--2 {
    animation: qode-wave-jump-2 1.8s infinite ease-out
}

#qodef-page-spinner.qodef-layout--wave-circles .qodef-m-wave-circle.qodef-wave--3 {
    animation: qode-wave-jump-1 1.8s infinite ease-out
}

#qodef-page-spinner.qodef-layout--wave-circles .qodef-m-wave-circle.qodef-wave--4 {
    margin-right: 0;
    animation: qode-wave-wipe 1.8s infinite cubic-bezier(.5, 0, .5, 1)
}

#qodef-page-spinner.qodef-layout--wave .qodef-m-wave {
    width: 72px;
    margin: -9px 0 0 -36px;
    text-align: center
}

#qodef-page-spinner.qodef-layout--wave .qodef-m-wave-item {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 3px 0 0;
    background-color: currentColor;
    border-radius: 100%;
    animation: qode-bounce-delay 1.4s infinite ease-in-out both
}

#qodef-page-spinner.qodef-layout--wave .qodef-m-wave-item.qodef-wave--1 {
    animation-delay: -.32s
}

#qodef-page-spinner.qodef-layout--wave .qodef-m-wave-item.qodef-wave--2 {
    animation-delay: -.16s
}

.qodef-sp-holder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 115;
    visibility: hidden;
    transition: opacity .2s ease-out;
    background-color: rgba(0, 0, 0, .65);
    display: flex;
    align-items: center;
    justify-content: center
}

.qodef-sp-holder .qodef-sp-inner {
    width: 90%;
    max-width: 600px;
    background-color: #fff;
    padding: 40px;
    opacity: 0;
    box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, .5);
    transition: transform .3s cubic-bezier(.06, .47, .38, .99), opacity .2s ease-out
}

.qodef-sp-holder .qodef-sp-close {
    position: absolute;
    top: 16px;
    right: 16px;
    display: block;
    color: #000
}

.qodef-sp-holder .qodef-sp-close:hover {
    color: #000
}

.qodef-sp-holder .qodef-sp-close svg {
    stroke: currentColor;
    fill: currentColor
}

.qodef-sp-holder .qodef-sp-content-container {
    background-color: #fafafa;
    background-size: cover;
    padding: 40px
}

.qodef-sp-holder .qodef-sp-title {
    margin: 0 0 30px 0
}

.qodef-sp-holder .qodef-sp-subtitle {
    margin: 0 0 20px 0
}

.qodef-sp-holder .wpcf7 {
    width: 100%;
    display: block
}

.qodef-sp-holder .qodef-sp-prevent {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    margin-top: 28px
}

.qodef-sp-holder .qodef-sp-prevent-inner {
    cursor: pointer
}

.qodef-sp-holder .qodef-sp-prevent-inner>* {
    position: relative;
    display: inline-block;
    vertical-align: top;
    line-height: 1
}

.qodef-sp-holder .qodef-sp-prevent-input {
    margin: 1px 1px 0 0;
    width: 11px;
    height: 11px;
    border: 1px solid #000;
    overflow: hidden
}

.qodef-sp-holder .qodef-sp-prevent-input svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .2s ease-out
}

.qodef-sp-holder .qodef-sp-prevent-input.qodef-sp-prevent-clicked svg {
    opacity: 1
}

.qodef-sp-holder .qodef-sp-prevent-label {
    font-size: 13px
}

.qodef-sp-opened .qodef-sp-holder {
    opacity: 1;
    visibility: visible;
    transition: opacity .2s ease-out
}

.qodef-sp-opened .qodef-sp-holder .qodef-sp-inner {
    opacity: 1;
    transform: scale(1);
    transition: transform .8s cubic-bezier(.26, .63, 0, .96)
}

.qodef-page-title {
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: cover
}

.qodef-page-title.qodef-image--responsive {
    height: auto !important
}

.qodef-page-title.qodef-image--responsive .qodef-m-content {
    position: absolute;
    top: 0;
    left: 0
}

.qodef-page-title.qodef-image--responsive .qodef-m-content.qodef-content-grid {
    left: 50%;
    transform: translateX(-50%)
}

.qodef-page-title.qodef-alignment--center {
    text-align: center
}

.qodef-page-title.qodef-alignment--right {
    text-align: right
}

.qodef-page-title .qodef-m-image {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-page-title .qodef-m-image img {
    display: block;
    width: 100%
}

.qodef-page-title .qodef-m-content.qodef-content-full-width {
    padding: 0 50px
}

.qodef-page-title .qodef-breadcrumbs a,
.qodef-page-title .qodef-breadcrumbs span {
    font-size: 15px;
    line-height: 22px;
    color: #686868
}

.qodef-page-title .qodef-breadcrumbs a {
    --qodef-underline-position-y: 100%;
    --qodef-underline-height: 1px;
    background-repeat: no-repeat;
    background-image: linear-gradient(90deg, currentColor 0, currentColor 100%);
    background-position: 100% var(--qodef-underline-position-y);
    background-size: 0 var(--qodef-underline-height);
    transition: background-size .5s cubic-bezier(.25, .46, .35, .94), color .2s ease-out
}

.qodef-page-title .qodef-breadcrumbs a:hover {
    background-size: 100% var(--qodef-underline-height);
    background-position: 0 var(--qodef-underline-position-y)
}

.qodef-page-title .qodef-breadcrumbs .qodef-breadcrumbs-separator {
    margin: 0 5px
}

.qodef-page-title .qodef-breadcrumbs .qodef-breadcrumbs-separator:after {
    content: '/'
}

.qodef-page-title.qodef-title--standard-with-breadcrumbs .qodef-breadcrumbs {
    margin-top: 5px
}

.qodef-page-title.qodef-title--standard .qodef-m-title {
    margin: 0 0 3.75%
}

.qodef-page-title.qodef-title--standard .qodef-m-subtitle {
    position: absolute;
    bottom: 7.6%;
    margin: 4px 0 0;
    font-size: 12px;
    line-height: 1.16667em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-family: "DM Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    color: #000
}

.qodef-page-title.qodef-title--standard .qodef-m-subtitle:before {
    content: "";
    position: relative;
    width: 5px;
    height: 5px;
    background-color: currentColor;
    border-radius: 50%;
    top: 50%;
    margin-right: 13px
}

.widget.qodef-author-info .qodef-author-info-image {
    margin-bottom: 21px;
    display: inline-block
}

.widget.qodef-author-info .qodef-author-info-name {
    margin: 0 0 21px
}

.widget.qodef-author-info .qodef-author-info-description {
    margin: 0
}

.widget .qodef-contact-info-widget {
    margin: 0
}

#qodef-top-area .widget .qodef-contact-info-widget a {
    font-size: 16px
}

.qodef-social-icons-group .qodef-icon-holder {
    margin-right: 9px
}

.qodef-social-icons-group .qodef-icon-holder a {
    transition: color .2s ease-out
}

.qodef-social-icons-group .qodef-icon-holder:last-child {
    margin-right: 0
}

.qodef-social-icons-group .qodef-icon-holder.qodef--textual a {
    color: inherit
}

#qodef-page-footer-top-area .qodef-social-icons-group {
    display: flex;
    flex-direction: column
}

#qodef-page-footer-top-area .qodef-social-icons-group .qodef-icon-holder {
    margin-right: 0;
    margin-bottom: 14px
}

#qodef-page-footer-top-area .qodef-social-icons-group .qodef-icon-holder:last-child {
    margin-bottom: 0
}

.widget.widget_level_core_social_icons_group .qodef-icon-holder.qodef-layout--circle,
.widget.widget_level_core_social_icons_group .qodef-icon-holder.qodef-layout--square {
    background-color: #fafafa
}

.widget.widget_level_core_social_icons_group .qodef-icon-holder.qodef-layout--circle:hover,
.widget.widget_level_core_social_icons_group .qodef-icon-holder.qodef-layout--square:hover {
    color: #000
}

.widget.widget_level_core_social_icons_group .qodef-icon-holder.qodef-layout--circle:hover a,
.widget.widget_level_core_social_icons_group .qodef-icon-holder.qodef-layout--square:hover a {
    color: #fff
}

.widget_level_core_sticky_sidebar {
    position: relative;
    width: 100%;
    height: 0;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
    background: 0 0 !important
}

.qodef-wishlist {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-wishlist .qodef-m-link {
    line-height: 1
}

.qodef-wishlist .qodef-m-link.qodef--added {
    color: var(--qode-main-color)
}

.qodef-wishlist .qodef-m-link.qodef--added i {
    font-weight: 900
}

.qodef-wishlist .qodef-m-response {
    position: absolute;
    top: -6px;
    bottom: auto;
    right: -6px;
    left: auto;
    width: auto;
    padding: 0 10px;
    font-size: 13px;
    line-height: 30px;
    color: #000;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, .5);
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transform: translateY(0);
    transition: opacity .2s ease
}

.qodef-wishlist .qodef-m-response.qodef--show {
    opacity: 1;
    visibility: visible
}

.qodef-wishlist-dropdown {
    position: relative;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    margin: 0 10px
}

.qodef-wishlist-dropdown .qodef-m-inner {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    height: 100%
}

.qodef-wishlist-dropdown .qodef-m-link {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%
}

.qodef-wishlist-dropdown .qodef-m-link>* {
    margin: 0;
    line-height: 1
}

.qodef-wishlist-dropdown .qodef-m-link {
    color: #000
}

#qodef-top-area .qodef-wishlist-dropdown .qodef-m-link {
    color: #000
}

.qodef-wishlist-dropdown .qodef-m-link-icon {
    flex-shrink: 0;
    margin-right: 2px;
    font-size: 18px
}

.qodef-wishlist-dropdown .qodef-m-link-icon:before {
    display: block;
    line-height: inherit
}

.qodef-wishlist-dropdown .qodef-m-link-icon {
    color: var(--qode-main-color)
}

#qodef-top-area .qodef-wishlist-dropdown .qodef-m-link-icon {
    color: var(--qode-main-color)
}

.qodef-wishlist-dropdown .qodef-m-link-count {
    font-family: "DM Sans", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.25em;
    font-weight: 400
}

@media only screen and (max-width:1440px) {
    .qodef-wishlist-dropdown .qodef-m-link-count {
        font-size: 18px;
        line-height: 1.27778em
    }
}

.qodef-wishlist-dropdown .qodef-m-items {
    position: absolute;
    top: 100%;
    left: auto;
    right: 0;
    width: 266px;
    padding: 25px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #e3e3e3;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    transition: all .35s cubic-bezier(.53, .01, .18, 1)
}

.qodef-wishlist-dropdown .qodef-m-item {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 25px
}

.qodef-wishlist-dropdown .qodef-m-item:last-child {
    margin-bottom: 0
}

.qodef-wishlist-dropdown .qodef-e-image-link {
    flex-shrink: 0;
    max-width: 60px;
    margin-right: 15px
}

.qodef-wishlist-dropdown .qodef-e-image-link img {
    display: block;
    border: 1px solid #e3e3e3;
    border-radius: 4px
}

.qodef-wishlist-dropdown .qodef-e-title {
    margin: 0
}

.qodef-wishlist-dropdown .qodef-e-remove {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px;
    font-size: 13px;
    line-height: 1;
    z-index: 3
}

.qodef-wishlist-dropdown .qodef-e-remove * {
    display: block;
    line-height: inherit
}

.qodef-wishlist-dropdown .qodef-e-remove :before {
    display: block;
    line-height: inherit
}

.touchevents .qodef-wishlist-dropdown {
    cursor: pointer
}

.qodef-wishlist-dropdown.qodef-items--has:hover .qodef-m-items {
    opacity: 1;
    visibility: visible
}

.qodef-working-hours-list {
    position: relative;
    display: inline-block;
    width: 100%;
    vertical-align: top
}

.qodef-working-hours-list .qodef-working-hours-item {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px
}

.qodef-working-hours-list .qodef-e-day {
    position: relative;
    margin: 0
}

.qodef-working-hours-list .qodef-e-day-icon {
    position: absolute;
    top: -2px;
    right: -8px;
    font-size: 8px;
    color: var(--qode-main-color)
}

.qodef-working-hours-list .qodef-e-line {
    flex-grow: 1;
    margin: 0 10px;
    border-bottom: 1px solid #bfb8b6;
    opacity: .5
}

.qodef-working-hours-list .qodef-e-time {
    margin: 0;
    font-family: "Nunito Sans", sans-serif;
    font-style: italic
}

.qodef-working-hours-list .qodef-e-time.qodef--closed {
    color: var(--qode-main-color)
}

.qodef-working-hours-list .qodef-m-footer {
    position: relative;
    top: 3px;
    font-size: 14px;
    color: #817c7b
}

.qodef-working-hours-list .qodef-m-footer-icon {
    position: relative;
    top: -5px;
    right: 0;
    font-size: 8px;
    color: var(--qode-main-color)
}

@media only screen and (max-width:1440px) {
    .qodef-background-text .qodef-m-background-text {
        font-size: 135px
    }
}

@media only screen and (max-width:680px) {
    .qodef-background-text .qodef-m-background-text {
        display: none !important
    }
}

@media only screen and (max-width:1440px) {
    .qodef-blog.qodef-item-layout--info-right .qodef-e-content {
        flex-basis: 54%
    }

    .qodef-blog.qodef-item-layout--info-right .qodef-e-media {
        flex-basis: 46%
    }
}

@media only screen and (max-width:680px) {
    .qodef-blog.qodef-item-layout--info-right .qodef-e-content {
        flex-direction: column;
        align-items: flex-start
    }

    .qodef-blog.qodef-item-layout--info-right .qodef-e-content-inner {
        padding: 30px 0
    }
}

@media only screen and (max-width:1280px) {
    .qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article.format-link .qodef-e-link {
        padding: 25px
    }

    .qodef-blog.qodef-item-layout--standard:not(.qodef-col-num--1) article.format-quote .qodef-e-quote {
        padding: 25px
    }
}

@media only screen and (max-width:1440px) {

    .qodef-blog--wide #qodef-author-info,
    .qodef-blog--wide #qodef-page-comments-form,
    .qodef-blog--wide #qodef-page-comments-list {
        max-width: 62.7%
    }
}

@media only screen and (max-width:1024px) {
    .qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-top-holder .qodef-e-info {
        max-width: 100%
    }

    .qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-author-holder,
    .qodef-blog--wide .qodef--single>.qodef-blog-item .qodef-e-content .qodef-e-title.entry-title {
        max-width: 100%
    }

    .qodef-blog--wide #qodef-author-info,
    .qodef-blog--wide #qodef-page-comments-form,
    .qodef-blog--wide #qodef-page-comments-list {
        max-width: 100%
    }
}

@media only screen and (max-width:1440px) {
    #qodef-author-info .qodef-m-content {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .qodef-blog--wide #qodef-author-info .qodef-m-content {
        padding-top: 27px;
        padding-bottom: 27px
    }
}

@media only screen and (max-width:1280px) {
    #qodef-author-info .qodef-m-content {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .qodef-blog--wide #qodef-author-info .qodef-m-content {
        padding-top: 20px;
        padding-bottom: 20px
    }
}

@media only screen and (max-width:680px) {
    #qodef-author-info .qodef-m-inner {
        flex-direction: column;
        align-items: center
    }

    #qodef-author-info .qodef-m-inner .qodef-m-image {
        width: 100%
    }

    #qodef-author-info .qodef-m-inner .qodef-m-image a>img {
        width: 100%;
        height: auto
    }

    #qodef-author-info .qodef-m-content {
        position: relative;
        padding: 67px 50px;
        margin-top: 23px
    }

    #qodef-author-info .qodef-m-content .qodef-m-social-icons {
        top: 0
    }

    .qodef-blog--wide #qodef-author-info .qodef-m-content {
        padding: 67px 50px
    }
}

@media only screen and (max-width:1024px) {
    body:not(.qodef-content-behind-header) #qodef-page-outer {
        margin-top: 0 !important
    }
}

@media only screen and (max-width:1280px) {
    .qodef-header--divided #qodef-page-header .qodef-header-navigation .menu-divided-left>ul>li:first-child {
        margin-left: 0
    }

    .qodef-header--divided #qodef-page-header .qodef-header-navigation .menu-divided-right>ul>li:last-child {
        margin-right: 0
    }

    .qodef-header--divided #qodef-page-header .qodef-header-navigation>ul>li {
        margin: 0 8px
    }

    .qodef-header--divided #qodef-page-header .qodef-header-navigation>ul>li>a {
        font-size: 17px
    }
}

@media only screen and (max-width:1440px) {
    .qodef-header--fullscreen-vertical #qodef-page-header {
        width: 440px
    }

    .qodef-header--fullscreen-vertical #qodef-page-footer,
    .qodef-header--fullscreen-vertical #qodef-page-outer {
        padding-right: 440px
    }
}

@media only screen and (max-width:1366px) {
    .qodef-header--fullscreen-vertical #qodef-page-header-inner {
        padding: 20px 40px 36px
    }
}

@media only screen and (max-width:1024px) {
    .qodef-header--fullscreen-vertical #qodef-page-header-inner {
        display: none
    }

    .qodef-header--fullscreen-vertical #qodef-page-footer,
    .qodef-header--fullscreen-vertical #qodef-page-outer {
        padding-right: 0 !important
    }
}

@media only screen and (max-width:1440px) {
    .qodef-header--standard #qodef-page-header-inner .qodef-header-logo-link {
        margin-right: 24px
    }

    .qodef-header--standard #qodef-page-header-inner .qodef-widget-holder {
        white-space: nowrap
    }

    .qodef-header--standard #qodef-page-header-inner .qodef-widget-holder .widget {
        margin: 0 12px
    }

    .qodef-header--standard #qodef-page-header-inner .qodef-widget-holder .widget:first-child {
        margin-left: 0
    }

    .qodef-header--standard #qodef-page-header-inner .qodef-widget-holder .widget:last-child {
        margin-right: 0
    }

    .qodef-header--standard #qodef-page-header-inner .qodef-widget-holder .qodef-button.qodef-html--link {
        padding: 9px 34px
    }

    .qodef-header--standard #qodef-page-header-inner .qodef-widget-holder .qodef-button.qodef-html--link.qodef-layout--textual {
        margin-right: 20px !important
    }
}

@media only screen and (max-width:1024px) {
    .qodef-header--vertical #qodef-page-header-inner {
        display: none
    }

    .qodef-header--vertical #qodef-page-footer,
    .qodef-header--vertical #qodef-page-outer {
        padding-left: 0 !important
    }
}

@media only screen and (max-width:1440px) {
    .qodef-header--vertical #qodef-page-header {
        background-size: contain
    }
}

@media only screen and (max-width:1024px) {
    #qodef-top-area {
        display: none
    }
}

@media only screen and (max-width:1440px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:680px) {
    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }

    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }

    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }

    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }

    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }

    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }

    .qodef-twitter-list.qodef-responsive--predefined.qodef-col-num--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--1.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 16.66667%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 14.28571%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 12.5%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--1.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 16.66667%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 14.28571%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 12.5%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--1.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 16.66667%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 14.28571%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

@media only screen and (max-width:1440px) and (min-width:1367px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 12.5%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1440--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--1.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 16.66667%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 14.28571%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

@media only screen and (max-width:1366px) and (min-width:1025px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 12.5%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1366--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--1.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 16.66667%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 14.28571%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

@media only screen and (max-width:1024px) and (min-width:769px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 12.5%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--1024--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--1.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 16.66667%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 14.28571%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

@media only screen and (max-width:768px) and (min-width:681px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 12.5%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--768--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

@media only screen and (max-width:680px) and (min-width:481px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--1.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:680px) and (min-width:481px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(2n+1) {
        clear: both
    }
}

@media only screen and (max-width:680px) and (min-width:481px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(3n+1) {
        clear: both
    }
}

@media only screen and (max-width:680px) and (min-width:481px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(4n+1) {
        clear: both
    }
}

@media only screen and (max-width:680px) and (min-width:481px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(5n+1) {
        clear: both
    }
}

@media only screen and (max-width:680px) and (min-width:481px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 16.66667%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(6n+1) {
        clear: both
    }
}

@media only screen and (max-width:680px) and (min-width:481px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 14.28571%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(7n+1) {
        clear: both
    }
}

@media only screen and (max-width:680px) and (min-width:481px) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 12.5%
    }

    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--680--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item:nth-child(8n+1) {
        clear: both
    }
}

@media only screen and (max-width:480px) and (min-width:0) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--480--1.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 100%
    }
}

@media only screen and (max-width:480px) and (min-width:0) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--480--2.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 50%
    }
}

@media only screen and (max-width:480px) and (min-width:0) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--480--3.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 33.33333%
    }
}

@media only screen and (max-width:480px) and (min-width:0) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--480--4.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 25%
    }
}

@media only screen and (max-width:480px) and (min-width:0) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--480--5.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 20%
    }
}

@media only screen and (max-width:480px) and (min-width:0) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--480--6.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 16.66667%
    }
}

@media only screen and (max-width:480px) and (min-width:0) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--480--7.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 14.28571%
    }
}

@media only screen and (max-width:480px) and (min-width:0) {
    .qodef-twitter-list.qodef-responsive--custom.qodef-col-num--480--8.qodef-layout--columns #ctf .ctf-tweets>.ctf-item {
        width: 12.5%
    }
}

@media only screen and (max-width:1440px) {
    .qodef-twitter-list.qodef-gutter--huge.qodef--no-bottom-space {
        margin-bottom: -70px
    }

    .qodef-twitter-list.qodef-gutter--huge #ctf .ctf-tweets {
        margin: 0 -35px
    }

    .qodef-twitter-list.qodef-gutter--huge #ctf .ctf-tweets>.ctf-item {
        padding: 0 35px;
        margin: 0 0 70px
    }
}

@media only screen and (max-width:680px) {
    #yith-quick-view-modal #yith-quick-view-content.single-product .qodef-woo-single-inner {
        flex-wrap: wrap
    }
}

@media only screen and (max-width:680px) {
    #yith-quick-view-modal #yith-quick-view-content.single-product .qodef-woo-single-image {
        max-height: 30vh
    }
}

@media only screen and (max-width:680px) {
    #yith-quick-view-modal #yith-quick-view-content.single-product .woocommerce-product-details__short-description {
        display: none
    }
}

@media only screen and (max-width:1440px) {
    .qodef-portfolio-single .qodef-variations--small .qodef-media {
        margin-right: 30px
    }
}

@media only screen and (max-width:1024px) {
    .qodef-portfolio-single .qodef-variations--small .qodef-media {
        margin: 0 0 50px
    }

    .qodef-portfolio-single .qodef-variations--small .qodef-portfolio-title {
        margin-top: 0
    }

    .qodef-portfolio-single .qodef-variations--big .qodef-portfolio-info {
        margin-top: 50px
    }

    .qodef-portfolio-single .qodef-variations--big .qodef-col--9 {
        border-left: 0
    }

    .qodef-portfolio-single .qodef-variations--big .qodef-col--9>* {
        padding-left: 0
    }

    .qodef-portfolio-single .qodef-variations--big .qodef-portfolio-title {
        margin-top: 50px
    }

    .qodef-portfolio-single .qodef-variations--big .qodef-e-content {
        margin-top: 0;
        border-top: none
    }

    .qodef-portfolio-single .qodef-variations--big .qodef-e-content .qodef-grid-inner {
        display: flex;
        flex-direction: column-reverse
    }

    .qodef-portfolio-single .qodef-variations--big .qodef-e-content .qodef-portfolio-content {
        padding-bottom: 35px;
        margin-bottom: 22px;
        border-bottom: 1px solid #e3e3e3
    }

    .qodef-portfolio-single .qodef-variations--big #qodef-single-portfolio-navigation {
        margin-top: 135px
    }

    .qodef-portfolio-single #qodef-portfolio-single-related-items .qodef-portfolio-list.qodef-gutter--tiny article {
        margin-bottom: 30px !important
    }
}

@media only screen and (max-width:1024px) and (max-width:680px) {
    .qodef-portfolio-single #qodef-portfolio-single-related-items .qodef-portfolio-list.qodef-gutter--tiny article:last-of-type {
        margin-bottom: 0 !important
    }
}

@media only screen and (max-width:1024px) {
    .qodef-tabbed-portfolio .qodef-e-year {
        display: none
    }
}

@media only screen and (max-width:768px) {
    .qodef-tabbed-portfolio .qodef-e-info--top {
        flex-direction: column;
        align-items: flex-start;
        padding: 23px 0 25px
    }

    .qodef-tabbed-portfolio .qodef-category-holder {
        position: relative;
        left: 0;
        margin: 5px 0 0
    }

    .qodef-tabbed-portfolio .qodef-e-info-bottom {
        flex-direction: column
    }

    .qodef-tabbed-portfolio .qodef-e-left {
        padding-right: 0;
        margin-bottom: 42px
    }

    .qodef-tabbed-portfolio .qodef-e-left,
    .qodef-tabbed-portfolio .qodef-e-right {
        width: 100%
    }
}

@media only screen and (max-width:1440px) {
    .qodef-call-to-action.qodef-layout--standard .qodef-m-content {
        max-width: 66%
    }
}

@media only screen and (max-width:1024px) {
    .qodef-call-to-action.qodef-layout--standard .qodef-m-content {
        max-width: 58%
    }
}

@media only screen and (max-width:680px) {
    .qodef-call-to-action.qodef-layout--standard .qodef-m-inner {
        display: block
    }

    .qodef-call-to-action.qodef-layout--standard .qodef-m-button {
        margin-top: 20px
    }

    .qodef-call-to-action.qodef-layout--standard .qodef-m-content {
        max-width: 100%
    }
}

@media only screen and (max-width:768px) {

    .qodef-countdown.qodef-show--4 .qodef-digit-wrapper,
    .qodef-countdown.qodef-show--5 .qodef-digit-wrapper,
    .qodef-countdown.qodef-show--6 .qodef-digit-wrapper {
        width: 33.33333333333333%;
        margin-bottom: 20px
    }
}

@media only screen and (max-width:1024px) {
    .qodef-frame-slider-holder {
        margin-bottom: 96px
    }

    .qodef-frame-slider-holder .swiper-pagination-bullets {
        bottom: -96px !important
    }
}

@media only screen and (max-width:768px) {
    .qodef-frame-slider-holder {
        margin-bottom: 86px
    }

    .qodef-frame-slider-holder .swiper-pagination-bullets {
        bottom: -86px !important
    }
}

@media only screen and (max-width:680px) {
    .qodef-frame-slider-holder {
        margin-bottom: 76px
    }

    .qodef-frame-slider-holder .swiper-pagination-bullets {
        bottom: -76px !important
    }
}

@media only screen and (max-width:480px) {
    .qodef-frame-slider-holder {
        margin-bottom: 46px
    }

    .qodef-frame-slider-holder .swiper-pagination-bullets {
        bottom: -46px !important
    }
}

@media only screen and (max-width:1440px) {

    .qodef-fullscreen-tabs .qodef-m-tab:not(.active-tab),
    .qodef-fullscreen-tabs div.qodef-m-title {
        max-width: 100px
    }

    .qodef-fullscreen-tabs .qodef-m-title-vertical {
        height: 100px;
        font-size: 42px;
        left: 4px;
        bottom: -99px
    }

    .qodef-fullscreen-tabs .qodef-e-content-inner {
        max-width: 60%
    }
}

@media only screen and (max-width:1366px) {
    .qodef-fullscreen-tabs .qodef-m-title-vertical {
        left: 10px;
        bottom: -87px;
        height: 90px;
        font-size: 32px
    }
}

@media only screen and (max-width:1280px) {

    .qodef-fullscreen-tabs .qodef-m-tab:not(.active-tab),
    .qodef-fullscreen-tabs div.qodef-m-title {
        max-width: 90px
    }
}

@media only screen and (max-width:1024px) {
    .qodef-fullscreen-tabs .qodef-m-tab {
        max-height: 50vh
    }

    .qodef-fullscreen-tabs .qodef-e-content-inner {
        padding: 40px 30px
    }
}

@media only screen and (max-width:768px) {
    .qodef-fullscreen-tabs .qodef-e-inner {
        flex-direction: column-reverse
    }

    .qodef-fullscreen-tabs .qodef-m-content {
        padding-top: 1px;
        border-right: 0;
        flex-direction: column
    }

    .qodef-fullscreen-tabs .qodef-e-content-inner {
        max-width: 100%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        bottom: auto;
        left: 0;
        padding: 23px 0 45px
    }

    .qodef-fullscreen-tabs .qodef-m-tab,
    .qodef-fullscreen-tabs .qodef-m-tab:not(.active-tab),
    .qodef-fullscreen-tabs .qodef-tab-wrapper,
    .qodef-fullscreen-tabs div.qodef-m-title {
        width: 100% !important;
        max-width: 100%;
        height: 100%;
        max-height: 91px;
        min-height: 91px
    }

    .qodef-fullscreen-tabs .qodef-m-tab {
        max-height: 100%
    }

    .qodef-fullscreen-tabs .qodef-m-tab:not(:last-child) {
        margin-left: 0
    }

    .qodef-fullscreen-tabs .qodef-m-tab.active-tab .qodef-m-content {
        height: -moz-fit-content !important;
        height: fit-content !important
    }

    .qodef-fullscreen-tabs .qodef-m-tab::before {
        top: 45px
    }

    .qodef-fullscreen-tabs .qodef-m-tab::after {
        top: 40px
    }

    .qodef-fullscreen-tabs .qodef-tab-wrapper {
        max-height: 90px
    }

    .qodef-fullscreen-tabs div.qodef-m-title {
        justify-content: flex-start;
        align-items: center;
        height: auto
    }

    .qodef-fullscreen-tabs .qodef-m-title-vertical {
        bottom: 0;
        left: 0;
        transform: none;
        justify-content: space-between;
        width: 100%;
        font-size: 28px;
        padding: 7px 35px 10px 20px
    }

    .qodef-fullscreen-tabs .qodef-e-mobile-image+.qodef-e-image {
        display: none
    }

    .qodef-fullscreen-tabs .qodef-e-mobile-image {
        display: flex
    }
}

@media only screen and (max-width:480px) {
    .qodef-fullscreen-tabs .qodef-m-content {
        width: 100%
    }

    .qodef-fullscreen-tabs .qodef-button {
        margin-bottom: 0 !important
    }
}

@media only screen and (max-width:1024px) {
    .qodef-highlight.qodef-title-break--disabled .qodef-m-title br {
        display: none
    }
}

@media only screen and (max-width:680px) {
    .qodef-image-marquee .qodef-m-content.qodef--desktop {
        display: none
    }

    .qodef-image-marquee .qodef-m-content.qodef--mobile {
        display: block
    }

    .qodef-image-marquee.qodef--move-on-scroll .qodef-m-content.qodef--mobile {
        display: flex
    }
}

@media only screen and (max-width:1024px) {
    .qodef-info-section.qodef-title-break--disabled .qodef-m-title br {
        display: none
    }
}

@media only screen and (max-width:1440px) {
    .qodef-info-slider .qodef-e-info-top {
        width: 90%
    }
}

@media only screen and (max-width:1024px) {
    .qodef-info-slider .qodef-info {
        margin: 0
    }
}

@media only screen and (max-width:768px) {
    .qodef-info-slider {
        flex-direction: column
    }

    .qodef-info-slider .qodef-images,
    .qodef-info-slider .qodef-info {
        width: 100%
    }

    .qodef-info-slider .qodef-images {
        margin-top: 30px
    }

    .qodef-info-slider .qodef-e-info-top {
        width: 100%
    }

    .qodef-info-slider .qodef-e-info-top h1 {
        font-size: 24px;
        line-height: 1.20833em;
        font-weight: 400
    }
}

@media only screen and (max-width:768px) and (max-width:1440px) {
    .qodef-info-slider .qodef-e-info-top h1 {
        font-size: 22px;
        line-height: 1.22727em
    }
}

@media only screen and (max-width:768px) {
    .qodef-info-slider .qodef-m-title {
        width: 75%
    }

    .qodef-info-slider .qodef-e-info-bottom {
        display: flex;
        position: absolute;
        justify-content: flex-end;
        height: 30px;
        width: 100%;
        margin-top: 20px;
        bottom: -2px;
        right: 0
    }

    .qodef-info-slider .qodef-e-info-bottom .qodef-e-item {
        bottom: 0
    }
}

@media only screen and (max-width:480px) {
    .qodef-info-slider .qodef-m-title {
        width: 100%
    }
}

@media only screen and (min-width:1921px) {
    .qodef-info-slider {
        justify-content: space-between
    }

    .qodef-info-slider .qodef-images {
        width: 40%
    }
}

@media only screen and (max-width:1440px) {
    .qodef-interactive-link-showcase.qodef-layout--list .qodef-m-item {
        font-size: 25px
    }
}

@media only screen and (max-width:1024px) {
    .qodef-interactive-link-showcase.qodef-layout--list {
        display: flex;
        flex-direction: column-reverse
    }

    .qodef-interactive-link-showcase.qodef-layout--list .qodef-m-images {
        margin-left: unset;
        width: 100%
    }

    .qodef-interactive-link-showcase.qodef-layout--list .qodef-m-items {
        position: relative;
        padding: 20px 4.92%;
        height: auto;
        width: 100%
    }
}

@media only screen and (max-width:1366px) {
    .qodef-interactive-link-showcase.qodef-type--standard {
        padding: 100px 0
    }

    .qodef-interactive-link-showcase.qodef-type--standard .qodef-m-images {
        width: 60%
    }

    .qodef-interactive-link-showcase.qodef-type--standard .qodef-m-items {
        width: 40%
    }
}

@media only screen and (max-width:1024px) {
    .qodef-interactive-link-showcase.qodef-type--standard {
        padding: 60px 0
    }

    .qodef-interactive-link-showcase.qodef-type--standard .qodef-m-images {
        width: 80%
    }

    .qodef-interactive-link-showcase.qodef-type--standard .qodef-m-items {
        width: 100%;
        padding-left: 60px;
        align-items: flex-start
    }

    .qodef-interactive-link-showcase.qodef-type--standard .qodef-m-item {
        font-size: 36px;
        line-height: 1.13889em;
        font-weight: 400;
        padding-bottom: 3px
    }
}

@media only screen and (max-width:1024px) and (max-width:1440px) {
    .qodef-interactive-link-showcase.qodef-type--standard .qodef-m-item {
        font-size: 30px;
        line-height: 1.16667em
    }
}

@media only screen and (max-width:680px) {
    .qodef-interactive-link-showcase.qodef-type--standard {
        padding: 30px 0
    }

    .qodef-interactive-link-showcase.qodef-type--standard .qodef-m-items {
        padding-left: 30px
    }
}

@media only screen and (max-width:1024px) {
    .qodef-section-title.qodef-title-break--disabled .qodef-m-title br {
        display: none
    }
}

@media only screen and (max-width:680px) {
    .qodef-tabs.qodef-layout--simple.qodef-orientation--vertical {
        display: block
    }

    .qodef-tabs.qodef-layout--simple.qodef-orientation--vertical .qodef-tabs-navigation {
        padding-right: 0;
        margin-right: 0;
        border-right: 0
    }

    .qodef-tabs.qodef-layout--simple.qodef-orientation--vertical .qodef-tabs-content {
        margin-top: 26px
    }

    .qodef-tabs.qodef-layout--simple .qodef-tabs-content {
        margin: 26px 0 0
    }
}

@media only screen and (max-width:680px) {
    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(1) {
        left: 1px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(2) {
        left: 46px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(3) {
        left: 91px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(4) {
        left: 136px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(5) {
        left: 181px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(6) {
        left: 226px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--right .qodef-social-share-dropdown li:nth-child(7) {
        left: 271px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown {
        left: auto;
        right: 0
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(1) {
        right: auto;
        left: 1px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(2) {
        right: auto;
        left: 46px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(3) {
        right: auto;
        left: 91px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(4) {
        right: auto;
        left: 136px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(5) {
        right: auto;
        left: 181px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(6) {
        right: auto;
        left: 226px
    }

    .qodef-social-share.qodef-layout--dropdown.qodef-dropdown--left .qodef-social-share-dropdown li:nth-child(7) {
        right: auto;
        left: 271px
    }
}

@media only screen and (max-width:1024px) {
    body:not(.qodef-content-behind-header) .qodef-page-title .qodef-m-content {
        padding-top: 0 !important
    }

    .qodef-page-title .qodef-m-content.qodef-content-full-width {
        padding: 0 7%
    }
}

@media only screen and (max-width:1024px) {
    #qodef-page-sidebar .qodef-social-icons-group {
        justify-content: flex-start;
    }
}