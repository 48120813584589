/* General */

  .faq-page {
    max-width: 1400px;
    margin: 0 auto;
  }

  .faq-page .hero-section {
    background: url("https://level-contractor.thepreview.pro/images/client-reviews/faq-banner.webp") no-repeat center center/cover;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  }

  .faq-section {
    padding: 40px 20px;
  }
  
  .faq-list {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    background: #f9f9f9;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  }
  
  .faq-item.active {
    background: #e6f7ff;
    border-color: #000000;
  }
  
  .faq-question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
  }
  
  .faq-question:hover {
    background: #f0f0f0;
  }
  
  .faq-answer {
    padding: 15px;
    font-size: 1rem;
    color: #555;
    background: #fff;
  }
  


  .faq-question span {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
    transition: transform 0.3s ease;
  }
  
  .faq-item.active .faq-question span {
    transform: rotate(180deg);
  }
  

  .faq-page .cta-section {
    text-align: center;
    padding: 40px 20px;
    background: #00000094;
    color: #fff;
  } 

  /* .cta-section h2 {
    color: #000;
  }

  .cta-button {
    background: #fff;
    color: #333;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .cta-button:hover {
    background: #0056b3;
    color: #fff;
  } */



  