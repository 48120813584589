.footer-divider {
    border: 0;
    height: 1px;
    background: #ccc;
    margin: 0 auto;
  }
  
  .footer {
    background-color: #f4f3ef;
    color: #000000;
    padding: 40px 20px 15px;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
  
  .footer-column {
    flex: 1;
    min-width: 200px;
  }

.footer-column .contact{
  display: block;
  margin: 10px 0;
}
  .footer-logo {
    width: 150px;
    margin-bottom: 10px;
  }
  
  .license-number {
    font-size: 14px;
    margin-bottom: 10px;
    color: #000;
  }
  
  .license-link {
    color: #000;
    font-size: large;
    font-weight: bold;
    text-decoration: none;
  }
  
  .license-link:hover {
    text-decoration: underline;
  }
  
  .footer-description {
    font-size: 14px;
    line-height: 1.6;
    color: #000;
  }

  .quick-links {
    list-style: none;
    padding: 0;
  }
  
  .quick-links li {
    margin: 5px 0;
  }
  
  .quick-links a {
    color: #000;
    text-decoration: none;
  }
  
  .quick-links a:hover {
    text-decoration: underline;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .social-icons img {
    width: 32px;
    height: 32px;
  }
  
  .bbb-logo img {
    width: 165px;
    height: 75px;
    margin-top: 10px;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    font-size: 14px;
    color: #000;
  }
  
  .footer-bottom p {
    margin: 0;
  }
  

  @media (max-width: 768px) {
    .footer-column {
        min-width: 250px;
        margin-left: 30px;
    }
  }

  /* HomeService Container */
.home-service-container {
  padding: 20px;
  margin: 0 auto;
}

/* Grid Layout */
.home-service-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns for desktop */
  gap: 20px; /* Space between cards */
}

/* Individual Service Card */
.home-service-card {
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  overflow: hidden;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* background-color: #fff; */
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.home-service-card:hover {
  transform: translateY(-5px);
}

/* Image */
.home-service-image-link {
  display: block;
}

.home-service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 575px; /* Maintain aspect ratio */
  display: block;
}

/* Content */
.home-service-content {
  margin-top: 20px;
}

.home-service-heading {
  color: inherit;
  font-size: 20px;
  font-style: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  line-height: inherit;
  text-transform: inherit;
  margin: 0;
}

.home-service-details-link {
  align-items: center;
  display: inline-flex;
  flex-wrap: wrap;
  font-family: Alice, serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: .2em;
  line-height: 1.16667em;
  text-transform: uppercase
}

.home-service-details-link:hover {
  text-decoration: underline;
}

/* Responsive Styles */

/* For tablets: 2 cards per row */
@media (max-width: 1024px) {
  .home-service-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* For mobile: 1 card per row */
@media (max-width: 768px) {
  .home-service-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .home-service-card {
    margin: 0 auto; /* Center-align the card */
  }
}

@media (max-width: 430px) {
  .home-service-grid {
    grid-template-columns: 1fr;
  }
}


/* Container for Home Work Section */
.home-work-container {
  padding: 40px 60px;
  text-align: left;
}

.heading-our-work h3 {
  margin-bottom: 30px;
}

/* Grid Layout */
.home-work-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 cards per row on desktop */
  gap: 20px;
}

@media (max-width: 1024px) {
  .home-work-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 cards per row on tablets */
  }
}


/* Individual Work Card */
.home-work-card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-work-card:hover {
  transform: translateY(-5px); /* Slight lift on hover */
}

.home-work-card.vertical-card .home-work-image {
  width: 370px;
  height: 484px;
  object-fit: cover;
}

.home-work-card.horizontal-card .home-work-image {
  width: 370px;
  height: 249px;
  object-fit: cover;
  margin-top: 95px;
}

.home-work-heading {
  margin: 10px 0;
  text-align: left;
  transition: color 0.3s ease;
}

.home-work-card:hover .home-work-heading {
  color: inherit; /* Darker blue on hover */
}

/* Button Styling */
.work-btn {
  margin-top: 50px;
}

@media (max-width: 768px) {
  .home-work-grid {
    grid-template-columns: repeat(2, 1fr); /* 1 card per row on mobile */
    gap: 30px;
  }
  .home-work-card.vertical-card .home-work-image {
    width: 279px;
    height: 364px;
  }
  
  .home-work-card.horizontal-card .home-work-image {
    width: 279px;
    height: 187px;
  }
}

@media (max-width: 430px) {
  .home-work-grid {
    grid-template-columns: 1fr;
   
  }
  .home-work-card.vertical-card .home-work-image {
    width: 365px;
    height: 477px;
    object-fit: cover;
  }
  
  .home-work-card.horizontal-card .home-work-image {
    width: 365px;
    height: 245px;
    object-fit: cover;
  }
}

