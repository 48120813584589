/* Finance.css */

.finance-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.finance-heading {
   
    margin-bottom: 20px;
}

.finance-description {
    margin-bottom: 40px;
}

.finance-options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.finance-card {
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.finance-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.finance-logo {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.finance-name {
    margin-bottom: 10px;
}

.finance-text {
    color: #7f8c8d;
}

.consultation-section {
    margin-top: 40px;
    padding: 20px;
    background: #f8f9fa;
    border-radius: 8px;
}

.consultation-section h2 {
    margin-bottom: 15px;
}

