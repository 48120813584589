.about-us {   
    line-height: 1.6;
    max-width: 1500px;
    margin: 0 auto;
  }
  
  .about-section {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  
  .about-text {
    flex: 1;
    padding: 20px;
  }
  
  .about-text h1{
    font-size:  48px;
  }
  .about-text p {
    margin-top: 10px;
  }
  
  .about-image img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);  
  }
  
  .mission-section {
    padding: 20px;
    text-align: center;
  }
  
  .mission-section h2 {
    color: #000000;
    margin-bottom: 10px;
  }
  
  .mission-section p {
    margin-bottom: 20px;
    padding: 0 20%;
  }
  
  .mission-images {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .mission-images img {
    width: 40%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  }
  
  .team-section {
    padding: 20px;
    text-align: center;
  }
  
  .team-section h2 {
    margin-bottom: 20px;
  }
  
  .team-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .team-member {
    text-align: center;
  }
  
  .team-member img {
    width: 150px;
    height: auto;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  }
  
  .team-member h3 {
    margin-top: 10px;
  }
  
  .team-member p {
    font-size: 16px;
    color: #777;
  }
  
  .abt-highlight {
    max-width: 1200px;
    margin: 0 auto;
  }



  @media (max-width: 1278px) {
    .about-section {
      flex-direction: column;
    }
  }  


  @media (max-width: 768px) {
   .about-section {
    flex-direction: column;
   }
   .mission-section p {
    padding: 0;
   }

}

@media (max-width: 480px) {
    .about-section {
        flex-direction: column;
    }
}


.serbox {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}