 .form-box {
  max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #d9d0d0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }
 
 
 .form-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #909090;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-container h1 {
    text-align: center;
    color: #000000;
  }

  
  .form-step {
    margin-bottom: 20px;
  }

  .form-step h2 {
    color: #000;
  }
  
  .options label {
    display: block;
    margin: 10px 0;
    cursor: pointer;
    color: #fff
  }
  
  input[type="radio"] {
    margin-right: 10px;
    ;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
  }
  
  .form-container input[type="text"],
  .form-container input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .checkbox {
    margin-top: 20px;
  }
  
  .privacy-policy a {
    color: #000000;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  
  .form-navigation {
    display: flex;
    justify-content: space-between;
  }
  
   button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #000000;
    color: #fff;
    cursor: pointer;
  }
  
  .form-navigation button:hover {
    background-color: #535353;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  