


/* Hero Banner */
.hero-banner {
  background: url("https://level-contractor.thepreview.pro/Backend/admin/uploads/hthjhy.webp") no-repeat center center/cover;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  position: relative;
}

.hero-content {
  background: rgba(131, 124, 124, 50%);
  padding: 20px;
  border-radius: 10px;
}

/* Highlights Section */
.highlights-section {
  padding: 2rem;
  text-align: center;
  margin: 4rem auto;
  /* background-color: #f9f9f9; */
}

.highlights-grid {
  display: grid;
  gap: 20px;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr); /* Default: 4 columns for desktop */
}

.highlight {
  text-align: center;
  padding: 20px;
  max-width: 250px;
  margin: 0 auto;
  background: linear-gradient(rgb(217 208 208), rgba(255, 255, 255, 1) 75%, rgb(255 255 255) 95%);
  box-shadow: 0 4px 8px rgba(0, 4, 10, 0.4);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.highlight:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.highlight h3 {
  margin-top: 10px;
  font-size: 1.2rem;
  color: #333;
}

.highlight p {
  font-size: 0.95rem;
  color: #666;
}

/* Tablet: 2 columns */
@media (max-width: 1024px) {
  .highlight {
    max-width: 300px;
  }
}

/* Mobile: 1 column */
@media (max-width: 768px) {
  .highlights-grid {
    grid-template-columns: repeat(2, 1fr); /* 1 column for mobile */
    gap: 15px; /* Adjust spacing for smaller screens */
  }

  .highlight h3 {
    font-size: 1rem; /* Slightly smaller headings */
  }

  .highlight p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .highlights-grid {
    grid-template-columns: repeat(1, 1fr); /* 1 column for mobile */
    gap: 15px; /* Adjust spacing for smaller screens */
  }
}

/* Gallery Section */
.gallery-section {
  padding: 40px;
}

.gallery-grid {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.gallery-grid img {
  width: 30%;
  border-radius: 5px;
}

.map iframe {
  margin-top: 20px;
}
