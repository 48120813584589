body, h1, h2, h3, h4, h5, h6, p, a, div,.qodef-theme-button, button[type=submit], input[type=submit], button{
    font-family: "Alice", serif !important;
}


/* Header.css */

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    background: #fff;
  }
  
  .logo img {
    height: 50px;
  }
  
  .quote-section {
    flex: 1;
    text-align: center;
    font-size: 1rem;
    color: #333;
  }
  
  .right-buttons {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .home-button img {
    width: 30px;
    height: 30px;
  }
  
  .menu-icon {
    cursor: pointer;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .quote-section {
      display: none;
    }
  
    .right-buttons {
      justify-content: flex-end;
      width: 100%;
    }
  }
  
  @media screen and (min-width: 1025px) {
    .bottom-nav {
      display: none; /* Hide on desktops */
    }
  }
  
  @media screen and (max-width: 1024px) {
    .bottom-nav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #b3b3b3;
      display: flex;
      justify-content: space-around;
      padding: 10px 0;
      border-top: 1px solid #ccc;
      box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
      z-index: 1000;
    }
  
    .bottom-nav-item {
      text-align: center;
      font-size: 14px;
      color: #000;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .bottom-nav-item .icon {
      font-size: 20px;
    }
  }
  






/* Left-side buttons */
/* Left-side buttons */
.qodef-left-buttons {
    display: flex;
    align-items: center;
    gap: 15px;
}

.home-button {
    font-size: 1rem;
    background-color: transparent;
    color: black;
    border: 1px solid #000;
    border-radius: 0;
    width: 150px;
    height: 50px;
    cursor: pointer;
    margin-right: 10px;
    padding: 10px 10px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: color 0.3s ease;
    text-decoration: none;
    display: flex;
    align-items: center;  /* Center the icon and text */
    justify-content: center;
    gap: 10px; /* Space between icon and text */
}

.phone-icon {
    width: 18px;
    height: 18px;
    fill: currentColor;
}

.home-button:hover {
    color: #fff;
}

.home-button::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: transform 0.8s cubic-bezier(.22,.61,.36,1);
    z-index: -1;
    transform-origin: right;
    transform: scaleX(0);
}

.home-button:hover::after {
    transform: scaleX(1);
}



.p-20 {
    padding: 2rem;
}

/* globle css */
.flex-main{
    display: flex;
}
.flex-space-evenly{
    justify-content: space-evenly;
}
.mrt-sh{
    margin-top: 80px !important;
}
.mrr-60{
    margin-right: 60px;
}

.mr-30 {
    margin-right: 20px;
}

.mrb-20{
    margin-bottom: 20px !important;
}
#qodef-page-outer {
    margin-top: -115px;
}

#qodef-page-footer-top-area {
    background-color: #f4f3ef;
}
.foo-logo{
    width: 60%;
}
.social-icon{
    display: flex;
}
.social-icon a{
    margin-right: 10px;
}
.menu-icon{
    cursor: pointer;
}
.pop-logo{
    margin-bottom: 30px;
    width: 38%
}
.cont-head{
    margin-bottom: 50px;
}
.home-ser{
    padding: 10px;
}
.home-ser-box{
    display: flex;
    justify-content: center;
}
/* services css section  */
.ser-first {
    padding-left: 100px;
    padding-top: 150px;
    margin-top: -130px;
}
.ser-second {
    padding: 90px 100px;
    border-top: 1px solid #4d4a4a;
}
.ser-second .elementor-column-gap-default{
    align-items: center;
    justify-content: center;
}
.ser-details{
    padding-right: 38%;
}
.ser-third{
    padding: 100px;
}
.ser-third .elementor-column-gap-default{
    align-items: center;
    justify-content: center;
}
.ser-details2{
   padding-left: 100px;
}
.ser-fourth{
    padding: 130px 0px 130px 0px;
}
#qodef-page-footer-bottom-area {
    background-color: #f4f3ef;
}
.ser-det{
    text-align: center;
}


#qodef-fullscreen-area {
    background-color: #ffffff;
}

#qodef-page-inner {
    padding: 0 0 0 0;
}

/* Gallery page css */
.work-heading{
    text-align: center;
}

/* button CSS  */
.category-buttons {
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .category-buttons button {
    background-color: #007bff00;
    color: black;
    border: 1px solid #000;
    border-radius: 0;
    padding: 0.5rem 2rem;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 15px;
    font-size: 16px;
    position: relative;
    z-index: 1; /* Ensure the text is above the :after element */
    overflow: hidden; /* Prevents the :after element from overflowing the button */
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
  .category-buttons button.active {
    background-color: #000000;
    color: rgb(255, 255, 255);
    border: 1px solid #000;
    border-radius: 0;
    padding: 12px 62px;
    cursor: pointer;
  }
  
  .category-buttons button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 101%;
    background-color: #000;
    transition: transform 0.8s cubic-bezier(.22,.61,.36,1);
    z-index: -1;
    transform-origin: right;
    transform: scaleX(0);
  }
  
  .category-buttons button:hover:after {
    transform: scaleX(1);
  }
  
  .category-buttons button:hover {
    color: #fff; /* Changes the text color to white on hover */
  }

  /* second button css */
  .category-buttonsgl a { /* Changed button to a */
    display: inline-block; /* Ensure it behaves like a button */
    background-color: #007bff00;
    color: black;
    border: 1px solid #000;
    border-radius: 0;
    width: 270px;
    height: 50px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 15px;
    position: relative;
    z-index: 1; /* Ensure the text is above the :after element */
    overflow: hidden; /* Prevents the :after element from overflowing the link */
    transition: color 0.3s ease; /* Smooth color transition */
    text-decoration: none; /* Remove underline from links */
  }
  
  .category-buttonsgl a.active { /* Changed button.active to a.active */
    background-color: #000000;
    color: rgb(255, 255, 255);
    border: 1px solid #000;
    border-radius: 0;
    cursor: pointer;
    width: 270px;
    height: 50px;
  }
  
  .category-buttonsgl a:after { /* Changed button:after to a:after */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: transform 0.8s cubic-bezier(.22,.61,.36,1);
    z-index: -1;
    transform-origin: right;
    transform: scaleX(0);
  }
  
  .category-buttonsgl a:hover:after { /* Changed button:hover:after to a:hover:after */
    transform: scaleX(1);
  }
  
  .category-buttonsgl a:hover { /* Changed button:hover to a:hover */
    color: #fff; /* Changes the text color to white on hover */
  }

  .work-page {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
  }

    .work-btn{
    padding-bottom: 50px;
    text-align: center;
    }
    .work-gallery {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

}

.work-item {
  position: relative;
  /* border: 1px solid black; */
  
}

.work-item img {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #333;
}

  /* button CSS END */


  .gallery {
    display: flex;
    flex-wrap: wrap;
  }
  
  .gallery a {
    flex: 1 0 32%;
    margin: 0.5rem;
    box-sizing: border-box;
  }
  
  .gallery img {
    width: 100%;
    height: auto;
    display: block;
  }
  .contact-hour{
    flex-grow: 1;
    flex-shrink: 0.3;
  }

  /* Services Section CSS Start  */
  /* ServiceDetailPage.css */

.service-detail-page {
    display: flex;
}

/* Sidebar Styling */
.sidebar {
    width: max-content;
    padding: 15px 15px 0 0;
    border-right: 1px solid #4d4a4a;
    flex-shrink: 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar ul li {
    margin: 0px 0;
}

.sidebar ul li a {
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    text-align: center;
    color: #333;
    /* background-color: #f5f5f5; */
    transition: background-color 0.3s, color 0.3s;
}

/* Service Content Styling */
.service-content {
    flex-grow: 1;
    padding: 15px 20px;
}

.ser-banner-image {
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-content h2 {
    margin-top: 0;
    font-size: 28px;
}



.service-content p {
    font-size: 16px;
    line-height: 1.6;
}
.ser-cont{
    text-align: left;
    margin-top: 20px;
}
.hom-mid-banner {
    display: flex;
    flex-direction: column; /* Stack elements vertically on smaller screens */
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 5% 2%; /* Adjust padding for better responsiveness */
}

.mid-banner-head {
    text-align: center;
    z-index: 1;
    position: relative;
}

.mid-banner-head h5 {
    color: #fff;
    margin-bottom: 30px;
}

.mid-banner-head h3 {
    margin-bottom: 15px;
    color: #fff;
}

.mid-banner-service {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    justify-content: center; /* Center items */
    text-align: left;
}

.mid-banner-service ul {
    list-style-type: none; /* Remove default list styles */
    padding: 0; /* Remove default padding */
}

.mid-banner-service ul li  {
    position: relative;
    color: #fff;
    margin: 5px 20px; /* Add margin for spacing */
}

.mid-banner-service ul li a {
    color: #fff;
}

.mid-banner-service ul li::before {
    position: absolute;
    content: "";
    width: 15px;
    height: 15px;
    border-radius: 20px;
    left: -25px; /* Adjust position for bullet */
    background-color: #a9b1ab;
    top: 6px;
}

.mid-btn {
    text-align: center;
}

.mid-btn button {
    color: white; /* Text color */
}


/* Responsive Styles */
@media (max-width: 768px) {
   .mid-banner-service {
       flex-direction: column; /* Stack service lists vertically on smaller screens */
       align-items: center; /* Center align items */
   }

   .mid-banner-service ul {
       margin-bottom: 20px; /* Space between lists */
   }
}


.heading-our-work {
    text-align: center;
    padding-bottom: 40px;
}
.work-sec-pd {
    padding-top: 120px !important;
    padding-bottom: 40px !important;
    padding-right: 100px;
    padding-left: 100px;
}
.work-sec-box{
    display: flex;
    justify-content: center;
    align-items: center;
}
.wbox-home{
    padding: 35px;
}

/* TestimonialsSlider CSS start here  */
.testimonials-container {
    max-width: 100%;
    margin: auto;
    padding: 8% 40px;
    box-sizing: border-box;
  }
  
  /* Testimonial */
  .testimonial {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    background-color: #727272;
    border-radius: 15px;
    overflow: hidden;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 0 20px 0 0;
  }
  
  .testimonial-image img {
    width: 150px;
    height: auto;
    /* border-radius: 50%; */
    /* margin-bottom: 20px; */
  }
  
  .testimonial-content {
    text-align: center;
    color: #fff;
  }
  
  .testimonial-content h3 {
    font-size: 24px;
    margin: 10px 0;
  }
  
  .testimonial-title {
    font-size: 18px;
    color: #ffa726;
  }
  
  .testimonial-text {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .testimonial-signature {
    width: 170px;
    margin: 5px auto;
}
  
  /* Slider Navigation */
  .slick-next, .slick-prev {
    background-color: #ffa726;
    border-radius: 50%;
    height: 35px;
    padding: 5px;
    width: 35px;
    margin: 0 -15px;
}
  
  .slick-prev:before, .slick-next:before {
    color: #fff;
    font-size: 20px;
  }
  
  .slick-dots li button:before {
    color: #fff;
    font-size: 12px;
  }
  
  .slick-dots li.slick-active button:before {
    color: #ffa726;
  }
  
  /* Media Queries for Responsive Design */
  @media (min-width: 768px) {
    .testimonial {
      flex-direction: row;
      text-align: left;
    }
  
    .testimonial-image {
      flex: 1;
      text-align: center;
    }
  
    .testimonial-image img {
      width: 100%;
      height: auto;
    }
  
    .testimonial-content {
      flex: 2;
      padding-left: 20px;
    }
  }
  
  @media (min-width: 1024px) {
    .testimonials-container {
      max-width: 900px;
    }
  
    .testimonial-content h3 {
      font-size: 28px;
    }
  
    .testimonial-title {
      font-size: 20px;
    }
  
    .testimonial-text {
      font-size: 18px;
    }
  }
  
  

@media only screen and (max-width: 1024px) {
     /* custom media query */
     .home-ser-box {
        flex-direction: column;
        padding-left: 5px;
    }

    #qodef-page-inner {
        padding: 0 0 0 0 !important;
    }
    .work-sec-pd {
        padding-top: 40px !important;
        padding-right: 0px;
        padding-left: 0px;
    }
    .work-sec-box{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .wbox-home{
        padding: 10px;
    }
.ser-first{
    padding-left: 26px;
    padding-top: 170px;
    margin-top: -140px !important;
}
.ser-second {
    padding: 0 5% 12%;
}
.ser-details {
    padding-right: 7%;
}
.ser-img{
    padding-top: 50px;
}
.ser-third {
    padding: 12% 7% 12% 7%;
}
.ser-details2 {
    padding-left: 0;
}
.ser-third .elementor-column-gap-default{
    flex-direction: column-reverse;
}
.ser-fourth {
    padding: 70px 0px 70px 0px;
}
.mob-mrb-50{
    margin-bottom: 50px !important;
}
.mob-pdt-50{
    padding-top: 50px !important;
}
.gallery a {
    flex: 1 0 50% !important;
}
.work-heading {
    margin-top: 50px;
}
.elementor-1303 .elementor-element.elementor-element-fbc184f{
    margin-top: 0 !important;
}
.service-detail-page {
    flex-direction: column-reverse;
    align-items: center;
}
.service-content {
    padding: 0px;
}
.sidebar {
    border-right: none;
}
.banner-image img {
    height: auto !important;
}

}



  

.qodef-header-sticky .qodef-header-sticky-inner {
    padding-left: 25px;
    padding-right: 25px;
}

#qodef-page-header .qodef-header-logo-link {
    height: 20px;
}

#qodef-page-mobile-header .qodef-mobile-header-logo-link {
    height: 18px;
}

.qodef-page-title .qodef-m-content {
    padding-top: 100px;
}

.widget .qodef-search-opener:hover {
    color: #787878;
}

.widget .qodef-search-opener:hover {
    color: #787878;
}

.qodef-header--minimal #qodef-page-header {
    background-color: rgba(255, 255, 255, 0);
}

.qodef-header--minimal #qodef-page-header-inner {
    padding-left: 25px;
    padding-right: 25px;
}
.elementor-kit-8 {
    --e-global-color-primary: #6EC1E4;
    --e-global-color-secondary: #54595F;
    --e-global-color-text: #7A7A7A;
    --e-global-color-accent: #61CE70;
    --e-global-typography-primary-font-family: "Roboto";
    --e-global-typography-primary-font-weight: 600;
    --e-global-typography-secondary-font-family: "Roboto Slab";
    --e-global-typography-secondary-font-weight: 400;
    --e-global-typography-text-font-family: "Roboto";
    --e-global-typography-text-font-weight: 400;
    --e-global-typography-accent-font-family: "Roboto";
    --e-global-typography-accent-font-weight: 500;
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px;
}

.e-con {
    --container-max-width: 1140px;
}

.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}

.elementor-element {
    --widgets-spacing: 20px;
}

    

h1.entry-title {
    display: var(--page-title-display);
}

@media(max-width:1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1024px;
    }

    .e-con {
        --container-max-width: 1024px;
    }
}

@media(max-width:767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 767px;
    }

    .e-con {
        --container-max-width: 767px;
    }
}

.elementor-1303 .elementor-element.elementor-element-fbc184f {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: -145px;
    margin-bottom: 0px;
    padding: 160px 0px 280px 0px;
}

.elementor-1303 .elementor-element.elementor-element-fbc184f>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1303 .elementor-element.elementor-element-369bdee>.elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-1303 .elementor-element.elementor-element-309e9e6 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 100px 188px 100px;
}

.elementor-1303 .elementor-element.elementor-element-309e9e6>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1303 .elementor-element.elementor-element-8771755>.elementor-element-populated {
    margin: -86px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.elementor-1303 .elementor-element.elementor-element-baf5d2b>.elementor-element-populated {
    margin: -86px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 0px 0px 0px 0px;
}

.mid-main-sec {
    background-image: url("https://level-contractor.thepreview.pro/images/Home/Mid-Banner.webp");
    background-repeat: no-repeat;
    background-size: cover;
}
.mid-main-sec:before{
    content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgb(0 0 0 / 60%);
}



.elementor-1303 .elementor-element.elementor-element-5192a63 {
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.elementor-1303 .elementor-element.elementor-element-5192a63>.elementor-background-overlay {
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

.elementor-1303 .elementor-element.elementor-element-a807736>.elementor-element-populated {
    padding: 33.5% 53% 4% 5%;
}

.elementor-1303 .elementor-element.elementor-element-cc81c28 {
    font-family: "Nunito Sans", Sans-serif;
    font-weight: 400;
}

.elementor-1303 .elementor-element.elementor-element-497a2c7 {
    padding: 0px 0px 0px 0px;
}

.elementor-1303 .elementor-element.elementor-element-50d6c51>.elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
    padding: 262px 0px 0px 0px;
}

.elementor-1303 .elementor-element.elementor-element-2d50417>.elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 100px 0px 100px;
}

.elementor-1303 .elementor-element.elementor-element-4e75dd2 {
    padding: 140px 0px 140px 0px;
}

.elementor-1303 .elementor-element.elementor-element-3e77b4a>.elementor-widget-wrap>.elementor-widget:not(.elementor-widget__width-auto):not(.elementor-widget__width-initial):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 0px;
}

.elementor-1303 .elementor-element.elementor-element-3e77b4a>.elementor-element-populated {
    margin: 0% 17% 0% 0%;
    --e-column-margin-right: 17%;
    --e-column-margin-left: 0%;
    padding: 60px 0px 0px 100px;
}

.elementor-1303 .elementor-element.elementor-element-50ebd58>.elementor-widget-container {
    padding: 40px 0px 0px 0px;
}

.elementor-1303 .elementor-element.elementor-element-c7866c6>.elementor-element-populated {
    margin: 0px 100px 0px 0px;
    --e-column-margin-right: 100px;
    --e-column-margin-left: 0px;
    padding: 110px 0px 0px 0px;
}

@media(max-width:1440px) {
    .elementor-1303 .elementor-element.elementor-element-a807736>.elementor-element-populated {
        padding: 033.5% 45% 4% 5%;
    }
}

@media(min-width:768px) {
    .elementor-1303 .elementor-element.elementor-element-8771755 {
        width: 47.5%;
    }

    .elementor-1303 .elementor-element.elementor-element-baf5d2b {
        width: 46%;
    }
}

@media(max-width:1440px) and (min-width:768px) {
    .elementor-1303 .elementor-element.elementor-element-8771755 {
        width: 50%;
    }
}

@media(max-width:1024px) and (min-width:768px) {
    .elementor-1303 .elementor-element.elementor-element-8771755 {
        width: 100%;
    }

    .elementor-1303 .elementor-element.elementor-element-3e77b4a {
        width: 100%;
    }

    .elementor-1303 .elementor-element.elementor-element-c7866c6 {
        width: 100%;
    }
}

@media(max-width:880px) and (min-width:768px) {
    .elementor-1303 .elementor-element.elementor-element-8771755 {
        width: 100%;
    }

    .elementor-1303 .elementor-element.elementor-element-3e77b4a {
        width: 100%;
    }

    .elementor-1303 .elementor-element.elementor-element-c7866c6 {
        width: 100%;
    }
}

@media(max-width:1024px) {



    .elementor-1303 .elementor-element.elementor-element-fbc184f {
        padding: 170px 0px 186px 0px;
        margin-top: -145px !important;
    }
    .header-item {
        margin: 15px 0 0 0;
        line-height: 35px;
        font-size: 24px;
    }

    .elementor-1303 .elementor-element.elementor-element-309e9e6 {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px 70px 118px 70px;
    }

    .elementor-1303 .elementor-element.elementor-element-8771755.elementor-column>.elementor-widget-wrap {
        justify-content: center;
    }

    .elementor-1303 .elementor-element.elementor-element-73bb9fc>.elementor-widget-container {
        margin: 0px 0px 0px 0px;
        padding: 0px 0px 0px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-73bb9fc {
        width: auto;
        max-width: auto;
    }

    .elementor-1303 .elementor-element.elementor-element-a807736>.elementor-element-populated {
        padding: 33.5% 36% 4% 5%;
    }

    .elementor-1303 .elementor-element.elementor-element-cc81c28>.elementor-widget-container {
        padding: 0px 0px 0px 20px;
    }

    .elementor-1303 .elementor-element.elementor-element-497a2c7 {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px 0px 0px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-50d6c51>.elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
        padding: 0px 0px 0px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-2d50417>.elementor-widget-container {
        margin: 135px 0px 0px 0px;
        padding: 0% 7% 0% 7%;
    }

    .elementor-1303 .elementor-element.elementor-element-4e75dd2 {
        padding: 60px 0px 130px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-3e77b4a>.elementor-element-populated {
        padding: 60px 0px 0px 70px;
    }

    .elementor-1303 .elementor-element.elementor-element-c7866c6>.elementor-element-populated {
        margin: 0px 70px 0px 70px;
        --e-column-margin-right: 70px;
        --e-column-margin-left: 70px;
    }
}

@media(max-width:880px) {
    .elementor-1303 .elementor-element.elementor-element-309e9e6 {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px 0px 0px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-8771755>.elementor-element-populated {
        padding: 0px 70px 0px 70px;
    }

    .elementor-1303 .elementor-element.elementor-element-73bb9fc>.elementor-widget-container {
        padding: 0px 0px 118px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-a807736>.elementor-element-populated {
        padding: 33.5% 20% 4% 5%;
    }

    .elementor-1303 .elementor-element.elementor-element-cc81c28>.elementor-widget-container {
        padding: 0px 0px 0px 20px;
    }

    .elementor-1303 .elementor-element.elementor-element-2d50417>.elementor-widget-container {
        padding: 0px 70px 0px 70px;
    }

    .elementor-1303 .elementor-element.elementor-element-3e77b4a>.elementor-element-populated {
        margin: 0% 3% 0% 0%;
        --e-column-margin-right: 3%;
        --e-column-margin-left: 0%;
    }

    .elementor-1303 .elementor-element.elementor-element-c7866c6>.elementor-element-populated {
        margin: 0px 70px 0px 70px;
        --e-column-margin-right: 70px;
        --e-column-margin-left: 70px;
    }
}

@media(max-width:767px) {
    .elementor-1303 .elementor-element.elementor-element-fbc184f {
        padding: 170px 0px 183px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-8771755>.elementor-element-populated {
        padding: 0px 30px 0px 30px;
    }

    .elementor-1303 .elementor-element.elementor-element-73bb9fc>.elementor-widget-container {
        padding: 0px 0px 108px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-a807736>.elementor-element-populated {
        padding: 33.5% 7% 4% 5%;
    }

    .elementor-1303 .elementor-element.elementor-element-cc81c28>.elementor-widget-container {
        padding: 0px 0px 0px 10px;
    }

    .elementor-1303 .elementor-element.elementor-element-497a2c7 {
        padding: 0px 0px 0px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-50d6c51>.elementor-element-populated {
        margin: 0px 0px 0px 0px;
        --e-column-margin-right: 0px;
        --e-column-margin-left: 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-2d50417>.elementor-widget-container {
        padding: 0px 30px 0px 30px;
    }

    .elementor-1303 .elementor-element.elementor-element-4e75dd2 {
        padding: 112px 0px 116px 0px;
    }

    .elementor-1303 .elementor-element.elementor-element-3e77b4a>.elementor-element-populated {
        margin: 0% 0% 0% 0%;
        --e-column-margin-right: 0%;
        --e-column-margin-left: 0%;
        padding: 0px 26px 0px 26px;
    }

    .elementor-1303 .elementor-element.elementor-element-c7866c6>.elementor-element-populated {
        margin: 0px 26px 0px 26px;
        --e-column-margin-right: 26px;
        --e-column-margin-left: 26px;
        padding: 63px 0px 0px 0px;
    }
}


:root {
    --wp-admin-theme-color: #007cba;
    --wp-admin-theme-color--rgb: 0, 124, 186;
    --wp-admin-theme-color-darker-10: #006ba1;
    --wp-admin-theme-color-darker-10--rgb: 0, 107, 161;
    --wp-admin-theme-color-darker-20: #005a87;
    --wp-admin-theme-color-darker-20--rgb: 0, 90, 135;
    --wp-admin-border-width-focus: 2px;
    --wp-block-synced-color: #7a00df;
    --wp-block-synced-color--rgb: 122, 0, 223;
    --wp-bound-block-color: var(--wp-block-synced-color)
}

@media (min-resolution:192dpi) {
    :root {
        --wp-admin-border-width-focus: 1.5px
    }
}

.wp-element-button {
    cursor: pointer
}

:root {
    --wp--preset--font-size--normal: 16px;
    --wp--preset--font-size--huge: 42px
}

:root .has-very-light-gray-background-color {
    background-color: #eee
}

:root .has-very-dark-gray-background-color {
    background-color: #313131
}

:root .has-very-light-gray-color {
    color: #eee
}

:root .has-very-dark-gray-color {
    color: #313131
}

:root .has-vivid-green-cyan-to-vivid-cyan-blue-gradient-background {
    background: linear-gradient(135deg, #00d084, #0693e3)
}

:root .has-purple-crush-gradient-background {
    background: linear-gradient(135deg, #34e2e4, #4721fb 50%, #ab1dfe)
}

:root .has-hazy-dawn-gradient-background {
    background: linear-gradient(135deg, #faaca8, #dad0ec)
}

:root .has-subdued-olive-gradient-background {
    background: linear-gradient(135deg, #fafae1, #67a671)
}

:root .has-atomic-cream-gradient-background {
    background: linear-gradient(135deg, #fdd79a, #004a59)
}

:root .has-nightshade-gradient-background {
    background: linear-gradient(135deg, #330968, #31cdcf)
}

:root .has-midnight-gradient-background {
    background: linear-gradient(135deg, #020381, #2874fc)
}

.has-regular-font-size {
    font-size: 1em
}

.has-larger-font-size {
    font-size: 2.625em
}

.has-normal-font-size {
    font-size: var(--wp--preset--font-size--normal)
}

.has-huge-font-size {
    font-size: var(--wp--preset--font-size--huge)
}

.has-text-align-center {
    text-align: center
}

.has-text-align-left {
    text-align: left
}

.has-text-align-right {
    text-align: right
}

#end-resizable-editor-section {
    display: none
}

.aligncenter {
    clear: both
}

.items-justified-left {
    justify-content: flex-start
}

.items-justified-center {
    justify-content: center
}

.items-justified-right {
    justify-content: flex-end
}

.items-justified-space-between {
    justify-content: space-between
}

.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important
}

.screen-reader-text:focus {
    background-color: #ddd;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000
}

html :where(.has-border-color) {
    border-style: solid
}

html :where([style*=border-top-color]) {
    border-top-style: solid
}

html :where([style*=border-right-color]) {
    border-right-style: solid
}

html :where([style*=border-bottom-color]) {
    border-bottom-style: solid
}

html :where([style*=border-left-color]) {
    border-left-style: solid
}

html :where([style*=border-width]) {
    border-style: solid
}

html :where([style*=border-top-width]) {
    border-top-style: solid
}

html :where([style*=border-right-width]) {
    border-right-style: solid
}

html :where([style*=border-bottom-width]) {
    border-bottom-style: solid
}

html :where([style*=border-left-width]) {
    border-left-style: solid
}

html :where(img[class*=wp-image-]) {
    height: auto;
    max-width: 100%
}

:where(figure) {
    margin: 0 0 1em
}

html :where(.is-position-sticky) {
    --wp-admin--admin-bar--position-offset: var(--wp-admin--admin-bar--height, 0px)
}

@media screen and (max-width:600px) {
    html :where(.is-position-sticky) {
        --wp-admin--admin-bar--position-offset: 0px
    }
}



:root {
    --wp--preset--aspect-ratio--square: 1;
    --wp--preset--aspect-ratio--4-3: 4/3;
    --wp--preset--aspect-ratio--3-4: 3/4;
    --wp--preset--aspect-ratio--3-2: 3/2;
    --wp--preset--aspect-ratio--2-3: 2/3;
    --wp--preset--aspect-ratio--16-9: 16/9;
    --wp--preset--aspect-ratio--9-16: 9/16;
    --wp--preset--color--black: #000000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #ffffff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: 0.44rem;
    --wp--preset--spacing--30: 0.67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}

:where(.is-layout-flex) {
    gap: 0.5em;
}

:where(.is-layout-grid) {
    gap: 0.5em;
}

body .is-layout-flex {
    display: flex;
    justify-content: center;
}

.is-layout-flex {
    flex-wrap: wrap;
    align-items: center;
}

.is-layout-flex> :is(*, div) {
    margin: 0;
}

body .is-layout-grid {
    display: grid;
}

.is-layout-grid> :is(*, div) {
    margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em;
}

:where(.wp-block-post-template.is-layout-flex) {
    gap: 1.25em;
}

:where(.wp-block-post-template.is-layout-grid) {
    gap: 1.25em;
}

.has-black-color {
    color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
    color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
    color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
    color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
    color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
    color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
    color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
    color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
    color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
    color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
    color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
    color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
    background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
    background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
    background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
    background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
    background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
    background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
    background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
    background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
    background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
    background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
    background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
    border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
    border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
    border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
    border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
    border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
    border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
    border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
    border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
    border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
    border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
    border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
    background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
    background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
    background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
    background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
    background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
    background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
    background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
    background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
    background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
    background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
    background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
    font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
    font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
    font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
    font-size: var(--wp--preset--font-size--x-large) !important;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em;
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em;
}
.elementor-widget-text-editor.elementor-drop-cap-view-stacked .elementor-drop-cap {
    background-color: #818a91;
    color: #fff
}

.elementor-widget-text-editor.elementor-drop-cap-view-framed .elementor-drop-cap {
    color: #818a91;
    border: 3px solid;
    background-color: transparent
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap {
    margin-top: 8px
}

.elementor-widget-text-editor:not(.elementor-drop-cap-view-default) .elementor-drop-cap-letter {
    width: 1em;
    height: 1em
}

.elementor-widget-text-editor .elementor-drop-cap {
    float: left;
    text-align: center;
    line-height: 1;
    font-size: 50px
}

.elementor-widget-text-editor .elementor-drop-cap-letter {
    display: inline-block
}


.scroll-to-top {
    position: fixed;
    bottom: 80px;
    right: 30px;
    z-index: 1000;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #333;
    color: #fff;
    border: none;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s, transform 0.3s;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
  }
  
  .scroll-to-top:hover {
    opacity: 1;
    transform: scale(1.1);
  }
  
  .scroll-to-top:active {
    transform: scale(0.95);
  }
  