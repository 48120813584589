.featured-project-section {
  border: 1px solid #000;
  padding: 20px 20px 0 20px;
  margin: 10px auto 50px;
}

.featured-project-page {
  padding: 20px;
  max-width: 1200px; /* Limit the width for better readability */
  margin: auto; /* Center the content */
}

.featured-project {
  display: flex;
  flex-direction: row; /* Stack elements on smaller screens */
  gap: 20px;
  align-items: center; /* Center align items */
  margin-bottom: 40px;
}

.project-video img{
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s;
}

.project-video img:hover {
  transform: scale(1.05);
}

.project-details {
  max-width: 600px; /* Limit width for better readability */
  text-align: left; /* Center align text */
}

.project-details h2 {
  font-size: 32px; /* Increased font size for emphasis */
  text-align: left;
}

.project-details h3 {
  font-size: 24px; /* Increased font size for emphasis */
}

.project-details p {
  font-size: 18px; /* Increased font size for better readability */
  margin: 10px 0;
}

.quote-btn {
  padding: 12px 24px; /* Increased padding for a better button size */
  background-color: #000000;
  color: #fff;
  border: none;
  border-radius: 5px; /* Rounded corners for the button */
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s; /* Smooth transition effect */
}

.quote-btn:hover {
  background-color: #777; /* Darker shade on hover */
}

.project-video {
  width: 100%;
  max-width: 800px; /* Limit video width */
}

/* .project-video iframe {
  width: 100%;
  height: auto;
} */

.project-gallery {
  display: flex;
  flex-wrap: wrap; /* Allow images to wrap on smaller screens */
  gap: 10px;
  margin-bottom: 40px;
}

.project-gallery img {
  width: calc(33.33% - 10px); /* Adjust width to account for gaps */
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}


.other-services {
  text-align: center;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.other-services h2 {
  margin-bottom: 20px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.service-card {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
  border-radius: 8px; /* Rounded corners for service cards */
  overflow: hidden; /* Prevent overflow of images */
  transition: transform 0.3s; /* Smooth scaling effect on hover */
  background-color: #909090;
}

.service-card:hover {
  transform: scale(1.05); /* Slightly enlarge card on hover */
}

.service-card img {
  max-width: 100%;
  height: auto;
}

.service-card h3 {
  font-size: 20px; /* Increased font size for better readability */
}

/* Responsive Styles */

@media (max-width: 768px) { 
 .featured-project {
     flex-direction: column; 
     align-items: center; 
 }

 .project-gallery img {
     width: calc(50% - 10px); /* Two images per row on tablets */
 }

 .services-grid {
     grid-template-columns: repeat(2, 1fr); /* Two columns on tablets */
 }
}

@media (max-width: 480px) { 
 .project-gallery img {
     width: calc(100% - 10px); /* One image per row on mobile */
 }

 .services-grid {
     grid-template-columns: repeat(1, 1fr); /* Single column on mobile */
 }
}
