.blog-detail-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    /* background-color: #a9b1ab; */
  }
  
  .blog-detail-title {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .blog-detail-meta {
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .blog-detail-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .blog-detail-content {
    font-size: 16px;
    line-height: 1.6;
    color: #000000;
  }
  