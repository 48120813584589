.locations-container {
    padding: 20px;
    color: #333;
    max-width: 1200px;
    margin: 0 auto;
}

.locations-heading {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 20px;
    color: #000000; /* Blue for emphasis */
}

.locations-description {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
}

.locations-list {
    margin-bottom: 30px;
}

.locations-subheading {
    font-size: 1.5rem;
    margin-bottom: 15px;
    text-align: center;
    color: #000000;
}

.locations-ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.locations-item {
    /* margin: 5px 15px;
    padding: 10px 15px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px; */
    font-size: 1rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #007bff00;
    color: black;
    border: 1px solid #000;
    border-radius: 0;
    width: 200px;
    height: 50px;
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 15px;
    padding: 12px;
    position: relative;
    z-index: 1; /* Ensure the text is above the :after element */
    overflow: hidden; /* Prevents the :after element from overflowing the link */
    transition: color 0.3s ease; /* Smooth color transition */
    text-decoration: none; /* Remove underline from links */
}

.locations-item.active {
    background-color: #000000;
    color: rgb(255, 255, 255);
    border: 1px solid #000;
    border-radius: 0;
    cursor: pointer;
    width: 270px;
    height: 50px;
}

.locations-item:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    transition: transform 0.8s cubic-bezier(.22,.61,.36,1);
    z-index: -1;
    transform-origin: right;
    transform: scaleX(0);
}

.locations-item:hover::after {
    transform: scaleX(1);
}

.locations-item:hover {
    color: #fff;
}

.map-container {
    margin-top: 20px;
    text-align: center;
}

.google-map {
    display: none;
    width: 100%;
    height: 500px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}
