.fullscreen-area {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #b3b3b3;
    z-index: 1000;
  }
  
  .fullscreen-left {
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;  
  }
  
  .fullscreen-logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .pop-logo {
    max-width: 200px;
  }
  
  .fullscreen-logo span {
    font-weight: normal;
  }
  
  .fullscreen-menu {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .fullscreen-menu li {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .fullscreen-right {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fullscreen-menu a:hover {
    color: #fff;
  }
  
  .fullscreen-image {
    width: 100%;
    height: auto;
    max-height: calc(100vh - 100px);
    object-fit: cover;
  }
  
  .fullscreen-footer {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }
  
  .close-button {
    background: none;
    cursor: pointer;
    padding: 2px 6px;
    border-radius: 30px;
    font-size: 38px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1001;
    color: #000;
}
  
  @media (min-width: 768px) {
    .fullscreen-area {
      flex-direction: row;
    }
  
    .fullscreen-left {
      flex: 1;
      padding: 40px;
    }
  
    .fullscreen-logo {
      font-size: 28px;
    }
  
    .fullscreen-menu li {
      font-size: 24px;
    }
  
    .fullscreen-right {
      flex: 2;
    }
  
    .fullscreen-footer {
      font-size: 14px;
    }
  }
  


  .services-menu {
    position: relative;
  }

  .services-dropdown {
    position: absolute;
    top: 100%;
    left: 20%;
    width: 200px;
    max-height: 300px; /* Limit the height for the dropdown */
    overflow-y: auto; /* Enable vertical scrolling */
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  
  .services-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .services-toggle .arrow {
    margin-left: 90px;
    margin-top: -25px;
    border: solid #333;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    transition: transform 0.3s;
  }
  
  .services-toggle .arrow.open {
    transform: rotate(-135deg);
    margin-left: 95px;
  }

  /* Media query for mobile devices */
@media (max-width: 468px) {
  .services-toggle .arrow {
      margin-left: 70px; /* Adjusted margin for mobile screens */
  }

  .services-toggle .arrow.open {
      margin-left: 75px; /* Adjusted margin when open for mobile screens */
  }
}
  .sub-menu {
    list-style: none;
    margin: 10px 0 0;
    padding: 0 0 0 20px;
  }
  
  .sub-menu li {
    margin: 5px 0;
  }
  
  .sub-menu a {
    color: #333;
    text-decoration: none;
    font-size: 14px;
  }
  
  .sub-menu a:hover {
    text-decoration: underline;
  }
  