.service-page {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

.service-header {
    text-align: center;
    margin-bottom: 40px;
}

.header-image {
    width: 100%;
    height: auto;
    border-radius: 8px; /* Rounded corners for header image */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.service-header h1 {
    margin-top: 20px;
}

.showcase-section {
    margin: 30px auto;
}

.service-introduction,
.service-benefits,
.service-process,
.work-showcase,
.service-testimonials,
.service-call-to-action {
    margin-bottom: 40px;
}

.section-image {
    width: 50%;
    height: auto;
    border-radius: 8px; /* Rounded corners for section images */
    margin-bottom: 20px; /* Spacing below images */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.section-content {
    display: flex;
    gap: 20px;
}

/* Benefits Section */
.service-benefits {
    background-image: url(https://level-contractor.thepreview.pro/images/services/home-remodeling-services/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.bathroom-remodeling {
    background-image: url(https://level-contractor.thepreview.pro/images/services/bathroom-remodeling/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.garage-conversion {
    background-image: url(https://level-contractor.thepreview.pro/images/services/garage-conversion/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.room-addition {
    background-image: url(https://level-contractor.thepreview.pro/images/services/room-addition/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.room-addition h2, .garage-conversion h2, .landscaping h2, .concrete-and-driveway h2, .exterior-interior-painting h2, .outdoor-patio-remodeling h2 {
    backdrop-filter: blur(10px);
}


.kitchen-remodeling {
    background-image: url(https://level-contractor.thepreview.pro/images/services/kitchen-remodeling/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.landscaping {
    background-image: url(https://level-contractor.thepreview.pro/images/services/landscaping/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.adu {
    background-image: url(https://level-contractor.thepreview.pro/images/services/adu/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.concrete-and-driveway {
    background-image: url(https://level-contractor.thepreview.pro/images/services/concrete-and-driveway/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.roofing {
    background-image: url(https://level-contractor.thepreview.pro/images/services/roofing/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.outdoor-patio-remodeling {
    background-image: url(https://level-contractor.thepreview.pro/images/services/outdoor-patio-remodeling/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.d-build {
    background-image: url(https://level-contractor.thepreview.pro/images/services/3d-build/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.exterior-interior-painting {
    background-image: url(https://level-contractor.thepreview.pro/images/services/exterior-interior-painting/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}

.window-door-installation {
    background-image: url(https://level-contractor.thepreview.pro/images/services/window-door-installation/bg.jpg); /* Light blue background */
    background-size: cover;
    background-position: center;
    padding: 20px;
    border-radius: 8px; /* Rounded corners */
}


.benefits-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px; /* Space between cards */
}

.benefit-card {
    background-color: #ffffffa8; /* White background for cards */
    border-radius: 8px; /* Rounded corners */
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow */
}



.benefit-icon {
    font-size: 24px; /* Larger icon size */
}

/* Process Section */
.service-process {
    padding: 40px;
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 10px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
}

.service-process h2 {
    color: #000000;
}

.process-steps {
    display: flex;
    flex-direction: column; /* Vertical layout for steps */
    gap: 20px; /* Space between cards */
}

.process-card {
    display: flex;
    align-items: center;
    background-color: #d9d0d0; /* White background for cards */
    border-radius: 8px; /* Rounded corners */
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.process-card:hover {
    transform: translateY(-5px); /* Lift effect on hover */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.process-icon {
    font-size: 36px; /* Larger icon size for emphasis */
    margin-right: 15px; /* Space between icon and text */
    color: #007BFF; /* Color for icons */
}

.process-content {
    flex-grow: 1; /* Allow content to take remaining space */
}

.process-content h3 {
    font-size: 1.5em; /* Increased font size for headings */
    margin-bottom: 5px; /* Space below heading */
}

.process-content p {
    font-size: 1em; /* Standard font size for descriptions */
    color: #555; /* Dark gray color for text */
}


.gallery-grid {
    display:grid ;
   grid-template-columns :repeat(auto-fill,minmax(400px,1fr)) ;
   gap :10px ;
}

.gallery-item img {
   width :100% ;
   height:auto ;
   box-shadow: 0px 4px 10px #00000063;
   border-radius :8px ; /* Rounded corners for gallery images */
}

.service-call-to-action {
   text-align:center ;
}



@media (max-width: 768px)  {
    .section-content {
        flex-direction: column;
    }
    .section-content img {
        width: 100%;
    }
    .highlights-grid {
        flex-direction: column;
    }
}

