.review-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

/* h1,
h2 {
  text-align: center;
  color: #333;
} */

.review-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 20px 0;
}

@media (max-width: 768px) {
  /* Adjust the breakpoint as needed */
  .review-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for tablets */
  }
}

@media (max-width: 480px) {
  /* Further adjust for mobile */
  .review-grid {
    grid-template-columns: 1fr; /* Single column for mobile */
  }
}

.review-card {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  background-color: #f9f9f9;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-photo {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.review-content h3 {
  margin: 0 0 5px;
  font-size: 18px;
  color: #555;
}

.review-date {
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
}

.rating {
  color: #ff9800;
  font-size: 16px;
  margin-bottom: 10px;
}

.rating span {
  color: #555;
}

.review-content h4 {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.load-more {
  display: block;
  margin: 20px auto;
  background-color: #007bff00;
    color: black;
    border: 1px solid #000;
    border-radius: 0;
    padding: 12px 62px;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    z-index: 1; /* Ensure the text is above the :after element */
    overflow: hidden; /* Prevents the :after element from overflowing the button */
    transition: color 0.3s ease; /* Smooth color transition */
}

.load-more:active {
  background-color: #000000;
  color: rgb(255, 255, 255);
  border: 1px solid #000;
  border-radius: 0;
  padding: 12px 62px;
  cursor: pointer;
}

.load-more::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 101%;
  background-color: #000;
  transition: transform 0.8s cubic-bezier(.22,.61,.36,1);
  z-index: -1;
  transform-origin: right;
  transform: scaleX(0);
}

.load-more:hover::after {
  transform: scaleX(1);
}

.load-more:hover {
  color: #fff; /* Changes the text color to white on hover */
}

.add-review {
  margin-top: 40px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #d9d0d0;
}

.review-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.review-form input,
.review-form select,
.review-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 20px;
  border: 1px solid #909090;
  border-radius: 4px;
  box-sizing: border-box;
}

