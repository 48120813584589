/* src/BannerSlider.css */

.padding-margin {
  padding: 160px 0px 200px 0;
  margin-top: -145px;
}

.feed-container {
  max-width: 100%;
  margin: 0 auto;
}

.banner-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
}

.banner-image {
  width: 78%;
  position: relative; /* Ensure it can contain the animation */
  overflow: hidden; /* Hide the overflow to handle clipping */
}

.banner-image img {
  width: 100%;
  height: 600px !important;
  object-fit: cover;
  transition: opacity 0.8s ease-in-out; /* Transition effect for opacity */
}

.banner-image img.loading {
  opacity: 0.5; /* Slight transparency to indicate loading state */
}

.banner-image img.loaded {
  opacity: 1; /* Full opacity once loaded */
}

.header-list {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 5%;
  box-sizing: border-box;
}

/* Left bar effect on active/hovered heading */
.header-item {
  margin: 15px 0;
  font-size: 32px;
  cursor: pointer;
  color: #000000;
  padding-left: 20px; /* Space for the left bar */
  position: relative;
  transition: color 0.3s ease;
}

.header-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: transparent;
  transform-origin: bottom;
  transition: transform .5s cubic-bezier(.25,.46,.35,.94),color .2s ease-out;
  transform: scaleY(0)
}

/* Show the left bar on hover or when active */
.header-item:hover::before,
.header-item.active::before {
  background-color: rgb(0, 0, 0); /* Customize this color as needed */
  transform: scaleY(1);
}

.header-item:hover,
.header-item.active {
  color: #fff; /* Optional: Change text color on hover or active */
}

/* Animation effect */
.banner-image.animate img {
  animation: qodef-clip-image 1s cubic-bezier(.25,.46,.45,.94) forwards;
}

@keyframes qodef-clip-image {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0 0 0 0);
  }
}

@media (max-width: 768px) {
  .banner-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .banner-image {
    width: 100%;
  }

  .header-list {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
  }

  .header-item {
    font-size: 18px;
  }
}
